export const StorageVariables = {
    userProfile: 'asi0fjaso', // Store user data from Login -
    lastPanel: '456opk', // Store the last panel accessed by the user ( worker or client)
    fcm: 'asfi78y', // Last Firebase Token
    geolocation: 'cvbio', // User's adresses or Istance address
    hasSineProfile: 'srg9p87', // True or false, if has SINE profile
    curriculumStep: 'xcvklj', // Wich step of the curriculum the user stoped
    sawMap: '35thuil', // controls the map notification
    lastCurriculumParam: 'cvbu8opy', // last search param used for SIne search
    cookies: 'faseop8', // is user has accepted cookies
    enterprise: '089fgsdu', // is user has accepted cookies
    enterpriseData: 'awf897f', // Enterprise data
    externUserAccess: '89901fujmf', // Enterprise data
};
