import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Icon, List, Rate, Skeleton} from "antd";
import {useHistory} from "react-router-dom";

import moment from "moment";
import Loading from "../../../Generic/Loading";
import {Images} from "../../../../Helpers/Images";


const ServiceProviderMain = (props) => {
    const history = useHistory();

    return (
        <div>
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}> <span style={{fontSize: 22, color: '#333', fontWeight: 'bold'}}> {'Sobre Mim'} </span> </div>
                <div style={{marginTop: 20, marginBottom: 20, fontSize: 18}}>
                    <span  style={{whiteSpace: 'break-spaces'}}>
                        {props.bio? props.bio : 'Nada informado'}
                    </span>
                    <br/>
                    {props.actualOccupation?.extra_fields?.registration && <span> Registro Profissional - <strong> {props.actualOccupation?.extra_fields?.registration} </strong></span>}
                </div>
            </div>
            {props.address?.length > 0 && <div style={{marginTop: 50}}>
                <span style={{fontSize: 22, color: '#333', fontWeight: 'bold'}}> Localização </span>
                <div style={{marginTop: 20, marginBottom: 40, fontSize: 18}}>
                    {props.address.map((item,index) =>
                    <span key={index}>
                        <Icon type='environment' className={'primary-sub-color'}/> {item.street_name}, {item.district} - {item.city}/{item.state} <br/>
                    </span>
                    )}
                </div>
            </div>
            }
            <div style={{marginTop: 50}}>
            <span style={{fontSize: 22, color: '#333', fontWeight: 'bold'}}> Avaliações  </span>
            <div style={{marginTop: 20, fontSize: 18}}>
                {!props.reviewsLoading?
                    <div>
                        {props.reviews.length > 0?
                            <>
                                <List
                                    className='alternateColorsList'
                                    style={{overflow: 'auto'}}
                                    itemLayout="horizontal"
                                    dataSource={props.reviews}
                                    renderItem={item => (
                                        <List.Item  style={{padding: 10, borderRadius: 5}}>
                                            <Skeleton avatar title={false} loading={false} active>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Avatar style={{width: 64, height: 64}} size='default' src={item.user.profile_picture.full || Images.user_place_holder}/>
                                                    }
                                                    title={<div style={{display: 'flex', marginTop: 5, justifyContent: 'space-between'}}>
                                                        <span style={{fontWeight: "bold", fontSize: 17}}>{item.user.name}  </span>
                                                        <div>
                                                            <span className={'primary-sub-color'} style={{marginRight: 5}}>
                                                                <Icon type='calendar'/> {moment.utc(item.created_at).local().format('DD-MM-YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>}
                                                    description={<span> <span style={{color: 'gray'}}> Avaliação do serviço de - <strong className={'primary-sub-color'}>{props.occupationName} </strong> </span> <br/>  <Rate style={{fontSize: 14}} disabled allowHalf={true} defaultValue={item.rating} /> ({item.rating}) <br/><br/> <span style={{ fontSize: 16, color: '#5f5f6f'}}>{item.review}</span>   {item.reply&& <div> <br/> Resposta em {moment.utc(item.reply.created_at).local().format('DD-MM-YYYY')} - {item.reply.content} </div>} </span>}
                                                />
                                            </Skeleton>
                                        </List.Item>
                                    )}
                                />
                                {props.nextPage&& <div className='loadMore' onClick={() => props.loadMore()}> Carregar Mais </div>}
                            </>
                            :
                            <div className={'empty-card empty-card-blue'} style={{margin: 0, boxShadow: 'unset'}}>
                                <div style={{display: 'flex'}}>
                                    <Icon style={{alignSelf: 'center'}} type={'star'}/>
                                    <div style={{marginLeft: '20px', color: '#545a5d'}}>
                                         <div>
                                            <span style={{ color: 'gray'}}> Esse profissional ainda não possui nenhuma avaliação! </span>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    :
                    <Loading/>
                }
            </div>

            </div>
        </div>
    );
};

ServiceProviderMain.propTypes = {
    bio: PropTypes.any,
    reviewsLoading: PropTypes.any,
    occupationName: PropTypes.any,
    nextPage: PropTypes.any,
    loadMore: PropTypes.any
};

export default ServiceProviderMain;
