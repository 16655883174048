import React, {useEffect, useRef, useState} from 'react';
import {Button, Carousel, Col, Form, Icon, Input, message, notification, Popover, Row, Select} from "antd";
import * as moment from "moment";
import useApi from "../../../../Hooks/Api";
import useAuth from "../../../../Hooks/Auth";
import TextArea from "antd/lib/input/TextArea";
import {Link} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {Images} from "../../../../Helpers/Images";


moment.locale('pt-br');
const ServiceForm = props => {
    const history = useHistory();
    const user = useSelector((state) => state).authReducer;
    const api = useApi({initialLoading: true});
    const [loading, setLoading] = useState(false);
    const [addresses, setAddresses] = useState();
    const {getFieldError, getFieldsValue, getFieldDecorator, validateFields} = props.form;
    const [currentAddress, setCurrentAddress] = useState();
    const workOrder = useRef('');
    const carouselRef = useRef();
    const [showAnimation, setShowAnimation] = useState(false);

    const getData = async () => {
        api.get(`user/${user.user_id}/addresses`, (res) => {
            setAddresses(res.addresses);
            if(res.addresses.length > 0) {
                initMap(res.addresses[0]);
            } else {
                let key = 'noAddress';
                notification.open({
                    duration: 8,
                    description: <div>
                        <div style={{marginBottom: 20, textAlign: 'center'}}>
                            <img src={Images.address_not_found} style={{height: 150}}/>
                        </div>
                        <div style={{marginBottom: 20}}> Você não possui nenhum endereço cadastrado, clique abaixo para cadastrar! </div>
                        <div style={{textAlign: 'center'}}> <Button onClick={() => {notification.close('noAddress'); history.push('/painel/cliente/enderecos')}} icon={'caret-right'} type={'primary'}> Clique aqui </Button> </div>

                    </div>,
                    key,
                    placement: 'topRight'
                });
            }
        }, (e) => {
            message.error('Ops, não foi possível recuperar seus endereços, tente novamente')
        });
    }

    const nextStep = () => {
        if (getFieldsValue().address) {
            carouselRef.current.next()
        } else {
            message.warning('Escolha um endereço')
        }
    }

    const configMap = (loc) => {
        setCurrentAddress({name: loc.full.street_name.concat(' - ', loc.full.street_number, ', ', loc.full.district, '/', loc.full.cep), id: loc.full.address_id});
        function doConfig() {

            try {
                let map = new window.google.maps.Map(document.getElementById('map'), {
                    zoom: 16,
                    center: loc.latLong
                });

                let marker = new window.google.maps.Marker({
                    position: loc.latLong,
                    map: map,
                    title: loc.full.name
                });
            }
            catch (e) {
                message.warning('Ops, não foi possível carregar o Mapa')
            }
        }
        if (document.getElementById('map')) {
            doConfig();
        } else {
            setTimeout(() => {
                configMap(loc)
            },[500])
        }
    }

    const initMap = (address) => {
        let myLatLng = {latLong: {lat: address.latitude, lng: address.longitude}, full: address};
        configMap(myLatLng);
    }

    const changeMap = (address) => {
        if(address !== 'new') {
            let res = addresses.filter((item) => item.address_id === address);
            if (res.length === 0) {
                res = props.address?.filter((item) => item.address_id === address);
            }
            const position = {latLong: {lat: res[0].latitude, lng: res[0].longitude}, full: res[0]};

            configMap(position);
        }
    }

    const doRequest = () => {
        validateFields(async (error, values) => {
            if (error) return 0;
            if (values) {
                if(values.data?.length > 500) {
                    message.error('A mensagem deve ter menos de 500 caracteres');
                    return 0;
                }
                setLoading(true);
                message.loading('Solicitando serviço ao prestador');
                let objToSend = {};
                let formAux = [];

                objToSend.next_days = values.next_days;
                objToSend.week_days = values.week_days;
                objToSend.days_period = values.days_period;
                objToSend.data = {text: values.data || ''};
                objToSend.address_id = currentAddress.id;
                objToSend.occupation_id = props.actualOccupation.occupation_id;
                objToSend.worker_id = props.worker.user_id;

                props.formFields.forEach((item, index) => {
                    let auxItem = item;
                    delete auxItem.options;
                    auxItem.answer = getFieldsValue()[index];
                    formAux.push(auxItem);
                })
                objToSend.form = formAux;

                api.post('work-order/request', objToSend, (res) => {
                    setLoading(false);
                    message.destroy();
                    workOrder.current = res.work_order_id;
                    carouselRef.current.next();
                    setShowAnimation(true);
                    window.scrollToElement('body');
                }, (e) => {
                    setLoading(false);
                    message.error(e.message || 'Ops, não foi possivel solicitar o serviço, tente novamente')
                });
            }
        })
    }

    useEffect(() => {
       getData();
    },[]);
    return (
        <div>
            <Carousel effect={"fade"} ref={carouselRef}>
                <div>
                    <div style={{width: '100%', padding: window.innerWidth < 768? 0 : '30px', paddingTop: 5, borderRadius: 10}}>
                        <Row style={{marginBottom: 20, textAlign: 'center'}}>
                            <span className={'primary-sub-color'} style={{fontSize: 25, fontWeight: 'bold'}}> Escolha o endereço a ser prestado o serviço </span><br/>
                        </Row>
                        <Row gutter={[32, 32]}>
                            <Col span={24}>
                                <Form.Item required={false} className='hideDots'>
                                    <div style={{display: 'flex'}}>
                                        {getFieldDecorator('address', {initialValue: addresses&& addresses[0]?.address_id})
                                        (<Select
                                            mode="default"
                                            style={{width: '100%', minWidth: 0}}
                                            placeholder={<span>{api.loading? <span> <Icon type={'loading'}/> Carregando </span> : 'Selecione Aqui'} </span>}
                                            allowClear={false}
                                            loading={api.loading}
                                            size={'large'}
                                            onChange={(e) => changeMap(e)}
                                            suffixIcon={<Icon className={'primary-sub-color'} type="down" style={{fontSize: '17px'}}/>}
                                        >
                                            {addresses?.map((item, index) =>
                                                <Select.Option key={index} value={item.address_id}>
                                                    <span> <strong> {item.name} </strong> <Icon type={'arrow-right'}/> {item.street_name} - {item.street_number} , {item.district}/ {item.cep} </span>
                                                </Select.Option>
                                            )}
                                            {props.address?.map((item, index) =>
                                                <Select.Option  key={index+'b'} value={item.address_id}>
                                                    <span> <strong> {'Endereço do Prestador'} </strong> <Icon type={'arrow-right'}/> {item.street_name} - {item.street_number} , {item.district}/ {item.cep} </span>
                                                </Select.Option>
                                            )}

                                            <Select.Option onClick={() => history.push('/painel/cliente/enderecos')} key={'new'} value={'new'}>
                                                <span> <Icon type={'plus'}/> <strong> {'Novo Endereço'} </strong>  </span>
                                            </Select.Option>
                                        </Select>)}
                                        <Button className={'hideMobile768'} style={{height: "40px", marginLeft: 20}} icon={'arrow-right'} onClick={() => nextStep()}> Proximo </Button>
                                    </div>
                                    <Button className={'showMobile768'} style={{height: "40px", marginTop: 30 }} icon={'arrow-right'} onClick={() => nextStep()}> Proximo </Button>
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row>
                            <div style={{textAlign: 'center'}}>
                                <div id='map' style={{width: '100%', minHeight: 300, borderRadius: 5}}/>
                                <Button type={"primary"} style={{height: 40, marginTop: 20}} icon={'arrow-right'} onClick={() => nextStep()}> Proximo </Button>
                            </div>
                        </Row>
                    </div>
                </div>

                <div>
                    <div style={{width: '100%', padding: window.innerWidth < 768? 0 : '30px',paddingTop: 5, borderRadius: 10}}>
                        <Row style={{marginBottom: 20, textAlign: 'center'}}>
                            <span className={'primary-sub-color'} style={{fontSize: 20, fontWeight: 'bold'}}> Formulário de soliticação de serviço </span><br/>
                            <span style={{fontSize: 17}}>
                                O formulário a seguir é opcional, mas recomendamos que você responda as questões para ajudar o prestador entender seu problema.
                            </span>
                        </Row>
                        <Row gutter={[32, 32]}>
                            <Col sm={24} md={12} style={{borderRight: '1px solid gainsboro'}}>

                                <Form.Item  required={false} className='hideDots' label={<span
                                    style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> <Icon style={{color: 'rgb(1, 165, 212)'}} type={'environment'}/> Endereço do atendimento </span>}>
                                    <div style={{display: 'flex'}}>
                                        <Popover content={currentAddress?.name}>
                                            <Input style={{textOverflow: 'ellipsis'}} className={'addressHover'} size={"large"} disabled value={currentAddress?.name}/>
                                        </Popover>
                                        <Button icon={'retweet'} onClick={() => {carouselRef.current.prev(); changeMap(currentAddress?.id)}} style={{marginLeft: 10}} size={"large"}> Trocar </Button>
                                    </div>
                                </Form.Item>

                                <Form.Item required={false} className='hideDots' label={<span
                                    style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> <Icon style={{color: 'rgb(1, 165, 212)'}} type={'alert'}/> Para quando você precisa do serviço? </span>}>
                                    {getFieldDecorator('next_days', {})(<Select
                                        mode="default"
                                        style={{width: '100%'}}
                                        placeholder={'Selecione Aqui'}
                                        allowClear={false}
                                        size={'large'}
                                        suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}
                                    >
                                        <Select.Option value={0}> O mais rapido Possível </Select.Option>
                                        <Select.Option value={3}> Nos próximos 3 dias </Select.Option>
                                        <Select.Option value={7}> Nos próximos 7 dias </Select.Option>
                                        <Select.Option value={15}> Nos próximos 15 dias </Select.Option>
                                        <Select.Option value={30}> Nos próximos 30 dias </Select.Option>
                                        <Select.Option value={90}> Nos próximos 3 meses </Select.Option>
                                    </Select>)}
                                </Form.Item>

                                <Form.Item required={false} className='hideDots' label={<span
                                    style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> <Icon style={{color: 'rgb(1, 165, 212)'}} type={'calendar'}/> Quais dias da semana você pode receber o serviço? </span>}>
                                    {getFieldDecorator('week_days', {})(<Select
                                        mode="multiple"
                                        style={{width: '100%'}}
                                        placeholder={'Selecione Aqui'}
                                        allowClear={false}
                                        size={'large'}
                                        suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}
                                    >
                                        <Select.Option value={'monday'}> Segunda-feira </Select.Option>
                                        <Select.Option value={'tuesday'}> Terça-feira </Select.Option>
                                        <Select.Option value={'wednesday'}> Quarta-feira </Select.Option>
                                        <Select.Option value={'thursday'}> Quinta-feira </Select.Option>
                                        <Select.Option value={'friday'}> Sexta-feira </Select.Option>
                                        <Select.Option value={'saturday'}> Sabado </Select.Option>
                                        <Select.Option value={'sunday'}> Domingo </Select.Option>
                                    </Select>)}
                                </Form.Item>

                                <Form.Item required={false} className='hideDots' label={<span
                                    style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> <Icon style={{color: 'rgb(1, 165, 212)'}} type={'clock-circle'}/> Quais períodos você pode receber o serviço? </span>}>
                                    {getFieldDecorator('days_period', {})(<Select
                                        mode="multiple"
                                        style={{width: '100%'}}
                                        placeholder={'Selecione Aqui'}
                                        allowClear={false}
                                        size={'large'}
                                        suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}
                                    >
                                        <Select.Option value={'morning'}> Manhã </Select.Option>
                                        <Select.Option value={'afternoon'}> Tarde </Select.Option>
                                        <Select.Option value={'evening'}> Noite </Select.Option>
                                    </Select>)}
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                {props.formFields.map((item, index) => {
                                        if (item.type === 'one-option') {
                                            return <Form.Item required={false} key={index} className='hideDots' label={<span style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> <Icon className={'primary-sub-color'} type={'question'}/> {item.question} </span>}>
                                                {getFieldDecorator([index], {})(<Select
                                                    mode="default"
                                                    style={{width: '100%'}}
                                                    placeholder={item.question}
                                                    allowClear={false}
                                                    size={'large'}
                                                    suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}
                                                >
                                                    {item.options?.map((item, index) =>
                                                        <Select.Option key={index} value={item}> {item} </Select.Option>
                                                    )}
                                                </Select>)}
                                            </Form.Item>
                                        } else if (item.type === 'multioptions') {
                                            return <Form.Item required={false} className='hideDots' label={<span style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> <Icon className={'primary-sub-color'} type={'question'}/> {item.question} </span>}>
                                                {getFieldDecorator([index], {})(<Select
                                                    mode="multiple"
                                                    style={{width: '100%'}}
                                                    placeholder={item.question}
                                                    allowClear={false}
                                                    size={'large'}
                                                    suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}>
                                                    {item.options?.map((item, index) =>
                                                        <Select.Option key={index} value={item}> {item} </Select.Option>
                                                    )}
                                                </Select>)}
                                            </Form.Item>
                                        } else {
                                            return <Row>
                                                <Form.Item
                                                    required={false}
                                                    className={'hideDots'}
                                                    label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}><strong> <Icon className={'primary-sub-color'} type={'question'}/> {item.question} </strong> </span>}>
                                                    {getFieldDecorator([index], {})(
                                                        <Input suffix={<Icon type="edit" className={'primary-sub-color'} style={{fontSize: '17px'}}/>} type= {'text'} size={"large"}/>
                                                        )}
                                                </Form.Item>
                                            </Row>
                                        }
                                    }
                                )}
                            </Col>
                            <Col span={24} style={{paddingTop: 0}}>
                                <Form.Item
                                    required={false}
                                    className={'hideDots'}
                                    style={{marginBottom: 0}}
                                    label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}><strong> Informação Adicional </strong> </span>}
                                >
                                    {getFieldDecorator('data', {
                                    })(<TextArea style={{fontSize: 17}} autoSize={{ minRows: 3, maxRows: 6 }} placeholder={"Se necessário informe aqui informações adicionais para o prestador"} />)}
                                </Form.Item>
                                <div style={{textAlign: 'right', marginBottom: 20, width: '100%'}}> <span> {getFieldsValue()?.data?.length} (500) </span> </div>
                            </Col>
                            <Col span={24} style={{textAlign: 'center'}}>
                                <Button loading={loading} disabled={loading || getFieldsValue()?.data?.length > 500} onClick={() =>{ carouselRef.current.next(); window.scrollToElement('body')}} icon={'check'} size={"large"} type={"primary"}> Solicitar serviço </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div>
                    <div>
                        <Row style={{marginBottom: 20, textAlign: 'center'}}>
                            <Col span={24} style={{textAlign: 'center'}}>
                                <img src={Images.services.request_service_confirmation} style={{height: 300, margin: 'auto', marginBottom: 20}}/>
                            </Col>
                            <Col span={24} style={{marginBottom: 30}}>
                                <span className={'primary-sub-color'} style={{fontSize: 20, fontWeight: 'bold'}}> Você está prestes a realizar uma soliticação de serviço </span><br/>
                                <span style={{fontSize: 17}}>
                                    Enviar Solicitação do serviço de <Icon type={'caret-right'}/> <strong> {props.actualOccupation.name}</strong>, para o profissional <Icon type={'caret-right'}/> <strong> {props.worker.profile.first_name + ' ' + props.worker.profile.last_name} </strong>
                                </span>
                            </Col>

                            <Col span={24} style={{textAlign: 'center'}}>
                                <Button style={{marginRight: 20}} onClick={() => carouselRef.current.prev()} icon={'arrow-left'} size={"large"} > Voltar </Button>
                                <Button loading={loading} disabled={loading} onClick={() => doRequest()} icon={'check'} size={"large"} type={"primary"}> Solicitar serviço </Button>

                            </Col>
                        </Row>
                    </div>
                </div>

                <div>
                    <div className='successAnimationCss' style={{width: '100%',padding: window.innerWidth < 768? 0 : '30px', textAlign: 'center', paddingTop: 5}}>
                        <div style={{marginBottom: 40}}>
                            <span style={{fontSize: 25, fontWeight: 'bold', color: 'limegreen'}}>Tudo certo!, serviço solicitado ao prestador! </span><br/>
                            <span style={{fontSize: 20}}>
                                   Seu interesse foi enviado ao prestador, agora é so esperar por uma proposta para dar seguimento ao serviço! <br/>
                            </span>
                        </div>
                        <div>
                            {showAnimation&& <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="170"
                                 height="170" viewBox="0 0 70 70">
                                <path id="successAnimationResult" fill="#D8D8D8"
                                      d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                                <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2"
                                        stroke-linecap="round" fill="transparent"/>
                                <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2"
                                          points="23 34 34 43 47 27" fill="transparent"/>
                            </svg>}
                        </div>

                        <div style={{textAlign: 'center', marginTop: 30, fontSize: 18}}>
                            <Link to={`/ordem-de-servico/${workOrder.current}`}> <Icon type={'file'}/> Ver ordem de serviço.</Link>
                        </div>

                    </div>
                </div>
            </Carousel>
        </div>
    );
};

ServiceForm.propTypes = {};

export default Form.create({name: 'ServiceForm'})(ServiceForm);
