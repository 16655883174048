import React, {memo, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Avatar, Col, Icon, Row, Spin} from "antd";
import Loading from "../../../Generic/Loading";
import Link from "react-router-dom/es/Link";
import NotFound from "../../../Generic/NotFound";
import {Images} from "../../../../Helpers/Images";

Spin.setDefaultIndicator(<Loading/>);


const CardListComponent = ({servicesList, loading, occupation_id}) => {

  return(
    <Row gutter={[32, 32]} style={{ margin:0}}>
      <div>
        {(servicesList.length > 0 || loading) &&
          <div style={{display: "flex", flexWrap: "wrap",}}>
            {servicesList.map((item) => {
              return (
                <Col xs={24} md={12} lg={8} className='listAnt'>
                  <div className='hire-service-card'
                       style={{display:"flex", alignItems:"center", flexDirection:"column"}}
                       onClick={() => window.open(`/prestador-de-servico/${item.id}/${occupation_id}`, "_blank")}
                  >

                    <div style={{display:"flex", alignItems:"center",cursor:"pointer" , width:"90%", justifyContent:"space-between", marginTop:12 , borderBottom:"1px solid gainsboro", paddingBottom:12}}>
                      <div style={{display:"flex", flexDirection:"column"}}>
                        <div style={{fontSize:20, fontWeight:"bolder"}}>
                          <div>{item.name}</div>
                        </div>

                        <div style={{marginTop:8}}>
                          <span style={{fontSize: 16, color: item.ratings_avg === 0 ? '#005c9f' : '#ffc53d'}}>
                            {item.price_avg !== 0 &&
                                <span>
                                  <strong style={{color: 'limegreen'}}>
                                      {item.price_avg <= 2 ? '$' :(item.price_avg <= 3)? '$$' :(item.price_avg <= 4)? '$$$': (item.price_avg <= 5)&& '$$$$'}
                                  </strong>
                                  <strong style={{marginRight: 5, marginLeft: 5}}> / </strong>
                                </span>
                            }
                            <Icon type='star'style={{marginRight:6}} theme="filled"/>
                            { item.ratings_avg === 0 ? 'Novo' :
                                <span>
                                  {item.ratings_avg.toFixed(1)}
                                  <span style={{marginLeft: 2}}>({item.ratings_count})</span>
                                </span>
                            }
                          </span>
                        </div>
                        <span style={{marginBottom: 12, color:'#aaa', marginTop:8}}>
                          <Icon style={{color: '#aaa'}} type='environment'/> {window.getDistance(item.distance)}
                        </span>
                      </div>
                      <div>
                        <Avatar style={{width: 80, height: 80}} size='default' src={item.profile_picture.thumbnail || Images.user_place_holder}/>
                      </div>
                    </div>

                    <div style={{padding:16,textAlign:"justify", fontSize:15, margin:"auto", maxWidth: '100%', overflowWrap: 'break-word'}}>
                      <span>{item.bio?.length > 300? item.bio?.slice(0, 200) + ' ... ' : item.bio}</span>
                    </div>
                  </div>
                </Col>
              )
            })}
          </div>
        }
        {(servicesList.length === 0 && !loading) &&
            <div style={{display:"flex", justifyContent:"center"}}>
              <NotFound  title={'Ainda não há prestadores de serviço para essa pesquisa'} subTitle={'Verifique novamente mais tarde se algum prestador já se cadastrou'}  img={Images.service_not_found}/>
            </div>
        }
      </div>
    </Row>
  )
}

CardListComponent.propTypes = {
  loading: PropTypes.bool,
  servicesList: PropTypes.any,
  occupation_id: PropTypes.any,
};

export default memo(CardListComponent, (prevProps, nextProps) => {
  if(prevProps.servicesList !== nextProps.servicesList) return false;
  if(prevProps.loading !== nextProps.loading) return false;
  if(prevProps.occupation_id !== nextProps.occupation_id) return false;
  return true
});