import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, Form, Icon, Input, Menu, Popover, Row, TreeSelect} from "antd";
import useLocalStorage from "../../../../Hooks/Storage";
import _ from "lodash";
import Select from "antd/es/select";
import {checkStateRestriction, showNotification} from "../../../../Hooks/Helpers";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";

const ListHeader = (props) => {

    const {getFieldDecorator, getFieldsValue, getFieldError, setFieldsValue} = props.form;
    const [popUp, setPopUp] = useState(true);
    const [geoLocation, setGeoLocation] = useState('');
    const geocode = useRef();
    const [spinIcon, setSpinIcon] = useState(0);
    const storage = useLocalStorage(StorageVariables.geolocation);


    const setAddress = (item) => {
        storage.setObject({lat: item.lat, long: item.long, loc: item.name, userLat: storage.getObject()?.userLat});
        setGeoLocation(item.name);
        setFieldsValue({locFilled: 'yes'});
    }

    useEffect(() => {
        if (props.location) {
            setFieldsValue({locFilled: 'yes'});
            if(props.location){
                setGeoLocation(_.replace(props.location,/\+/g," "));
                setPopUp(false);
                setSpinIcon(2);
            }
        }
    },[props.location]);

    useEffect(() => {
        window.scrollToElement('body');
        geocode.current = new window.google.maps.places.Autocomplete(document.querySelector('#geocodeField'), {types: ['geocode']});
        geocode.current.addListener('place_changed', getGeolocation);
    },[]);


    const getGeo = () => {
        setSpinIcon(1);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(item => {
                let geocoder = new window.google.maps.Geocoder;
                geocoder.geocode({'location': {lat: item.coords.latitude, lng: item.coords.longitude }}, function(results, status) {
                    if (results && checkStateRestriction(results[0])) {
                        setGeoLocation(results[0].formatted_address);
                        storage.setObject({lat: item.coords.latitude, long: item.coords.longitude, loc: results[0].formatted_address, userLat: storage.getObject()?.userLat});
                        setFieldsValue({locFilled: 'yes'});
                        setSpinIcon(2);
                    } else {
                        setSpinIcon(0);
                        showNotification();
                        setGeoLocation('');
                    }
                })
            });
        }
    };

    const getGeolocation = () => {
        setTimeout(() => {
            const place = geocode.current.getPlace();
            if (checkStateRestriction(place)) {
                storage.setObject({lat: place.geometry.location.lat(), long: place.geometry.location.lng(), loc: place.formatted_address, userLat: storage.getObject()?.userLat});
                setFieldsValue({locFilled: 'yes'});
                setGeoLocation(place.formatted_address);
            } else {
                showNotification();
                setGeoLocation('');
            }
        },[200]);
    }

    return (
        <Row className='listHeader'>

            <label style={{fontSize: 18}}> Para Onde você precisa do crafty? </label>

            <Form.Item validateStatus={getFieldError('locFilled')&& 'error'} help={getFieldError('locFilled')}>
                <Input className='paddingGeoSubPage' style={{ fontSize: 16, marginBottom: 20, marginTop: 20}} id='geocodeField' type='text' onChange={(e) => setGeoLocation(e.target.value)} value={geoLocation} placeholder='Digite um Endereço'
                   prefix={
                       <Popover
                           placement='bottomLeft'
                           content="Clique aqui para usar minha localização Atual!"
                           trigger="click"
                           visible={popUp}
                           onVisibleChange={() => setPopUp(!popUp)}
                       >
                           <Dropdown overlay={
                               <Menu>
                                   <Menu.Item onClick={() => getGeo()} key="default"> Usar minha localização Atual </Menu.Item>
                                   {storage.getObject()?.userLat?.map((item, index) =>
                                       <Menu.Item onClick={() => setAddress(item)} key={index}> {item.name} </Menu.Item>
                                   )}
                               </Menu>
                           }>
                               <Icon type={spinIcon === 0? "environment": spinIcon === 1? "loading": "check"} className={'primary-color'} />
                           </Dropdown>
                       </Popover>}
                   size='large' />
            </Form.Item>

            <Form.Item  required={true} help={''} style={{margin: 0}}>
                {getFieldDecorator('locFilled', {
                    rules: [
                        {
                            required: true,
                            message:  'Escolha um Local',
                        },
                    ]
                })( <Input style={{display: "none"}}/>)}
            </Form.Item>

            {window.innerWidth < 768 &&
            <div>
                <label style={{fontSize: 16, fontWeight: 'bold'}}> Tem um nome em mente? </label>
                <Form.Item>
                    {getFieldDecorator('company', {
                        initialValue: props.company&& props.company,
                        rules: []
                    })( <Input style={{marginTop: 20, fontSize: 16, marginBottom: 20}} size={'large'} placeholder={'Nome'} suffix={<Icon type="user" className={'primary-sub-color'} style={{ fontSize: '24px' }} />}/>)}
                </Form.Item>

                <label style={{fontSize: 16, fontWeight: 'bold'}}> Que tipo de serviço está buscando? </label>
                <Form.Item required={true}  help={getFieldError('groups')}>
                    {getFieldDecorator('groups', {
                        initialValue: props.groups&& props.groups,
                        rules: [
                            {
                                required: true,
                                message:  'Escolha uma categoria',
                            },
                        ]
                    })(<TreeSelect
                        showSearch
                        style={{ width: '100%' }}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto' }}
                        placeholder="Selecione o Serviço Desejado"
                        allowClear
                        className='selectSubPage'
                        treeNodeFilterProp={'title'}
                        treeData={props.establishmentsTreeList}
                        searchPlaceholder='Digite o Serviço'
                    />)}
                </Form.Item>

                <label style={{fontSize: 16, fontWeight: 'bold'}}> Filtrar </label>
                <Form.Item className='biggerSelect'>
                    {getFieldDecorator('order', {
                        initialValue: props.order&& props.order,
                        rules: [],
                    })(<Select
                        mode="default"
                        placeholder={"Selecione um filtro"}
                        allowClear={true}
                        size={'large'}
                        style={{marginTop: 20, width: '100%' , fontSize: 16, marginBottom: 20}}
                    >
                        {props.establishmentsOrders.map((item,index) =>
                            <Select.Option key={index} value={item.id}> <Icon style={{marginRight: 3}}/> {item.label}  </Select.Option>
                        )}
                    </Select>)}
                </Form.Item>
                <Button className='searchButtonSubPage' style={{marginBottom: '10px'}} onClick={() => props.searchAction()}> Buscar </Button>
            </div>
            }
        </Row>
    );
};

ListHeader.propTypes = {
    order: PropTypes.any,
    groups: PropTypes.any,
    company: PropTypes.any,
    location: PropTypes.any,
    establishmentsOrders: PropTypes.any
};

export default ListHeader;
