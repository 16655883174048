import React, {useEffect} from 'react';
import {Button, Col, Row} from "antd";
import {Fade} from "react-reveal";
import {Env} from "../Helpers/Env";
import {Images} from "../Helpers/Images";

const HowToClub = (props) => {

    useEffect(() => {
        window.scrollToElement('body');
    },[]);

    return (<div>

        <Row style={{minHeight: '400px', marginTop: '80px'}}>
            {/*<div style={{width: '100%', position: 'absolute', height: '100%', backgroundSize: 'contain', zIndex: 10, backgroundImage: "url('/img/squareBg.png')"}}/>*/}
            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 50}}>
                <Col sm={20} lg={20} xl={19} xxl={16} className='headerHowTo' style={{padding: 5, textAlign: 'center'}}>
                    <img style={{filter: 'brightness(0.2)', width: 70}} src={Images.home.establishment_icon}/>
                    <h1 style={{position: 'relative', marginBottom: 50}} className={'title title-line'}> O que é o crafty club </h1>
                    O Crafty Club é uma vitrine virtual aonde estabelecimentos comerciais expõem seus produtos e serviços aos usuários do Crafty e aos participantes dos clubes de benefícios parceiros. Entre os clubes parceiros temos empresas, associações e servidores públicos que acessam benefícios e vantagens em nosso app.
                </Col>
            </Row>


            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 50}}>
                <div className={'howItWorksBanner'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 reverseBg2'}>
                    <Col md={24} lg={8} style={{paddingRight: 20 }}>
                        <Fade delay={300} left>
                            <img style={{width: '100%', borderRadius: 10}} src={Images.establishment.about_1} />
                        </Fade>
                    </Col>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}> <div style={{minWidth: 410}}> <h1 style={{fontSize: 24, margin: 0}}> Crie seu próprio sistema de fidelidade </h1> </div> <div className={'primary-sub-color-bg'} style={{height: 2, width: '100%', alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} left cascade>
                                Com o aplicativo Crafty Biz para iOS e Android os estabelecimentos cadastrados no Crafty têm em suas mãos a possibilidade de criação de cartões fidelidade virtuais, além da possibilidade de entrar em contato diretamente com seus clientes fiéis via chat.
                            </Fade>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 50}}>
                <div className={'howItWorksBannerInvert'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2'}>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}> <div style={{minWidth: 350}}> <h1 style={{fontSize: 24, margin: 0}}> Tenha seu próprio CRM gratuito</h1> </div> <div className={'primary-sub-color-bg'} style={{height: 2, width: '100%', alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={600} right cascade>
                                Disponibilizamos um sistema de CRM para os estabelecimentos Crafty Club para a gestão de clientes e relacionamento, fácil fácil, através do app ou site.
                            </Fade>
                        </div>
                    </Col>
                    <Col md={24} lg={8} style={{paddingLeft: 20  }}>
                        <Fade delay={600} right>
                            <img style={{width: '100%', borderRadius: 10}} src={Images.establishment.about_2} />
                        </Fade>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 50}}>
                <div className={'howItWorksBanner'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 reverseBg2'}>
                    <Col md={24} lg={8} style={{paddingRight: 20 }}>
                        <Fade delay={1000} left>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.establishment.about_3} />
                        </Fade>
                    </Col>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}> <div style={{minWidth: 370}}> <h1 style={{fontSize: 24, margin: 0}}> Tenha seu próprio PDV gratuito </h1> </div> <div className={'primary-sub-color-bg'} style={{height: 2, width: '100%', alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={1000} left cascade>
                                É uma micro ou pequena empresa e não possui sistema de ponto de venda? Com o Crafty Club você tem à disposição um sistema de PDV fácil integrado no app.
                            </Fade>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 50}}>
                <div className={'howItWorksBannerInvert'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2'}>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}> <div style={{minWidth: 350}}> <h1 style={{fontSize: 24, margin: 0}}> Baixe agora o aplicativo! </h1> </div> </div>
                        <div>
                            <Fade delay={1000} right cascade>
                                O que está esperando para vender mais oferecendo seus produtos para milhares de usuários? <br/>
                                Baixe agora o app <strong className={'primary-sub-color'}> Crafty Biz </strong> na Play ou App Store e cadastre suas ofertas e acesse todos os clubes de benefícios parceiros do Crafty!
                            </Fade>

                            <div style={{display: 'flex', marginTop: 30, justifyContent: 'space-evenly'}}>
                                <Button onClick={() => window.open(Env.biz_play_store)} className={'ant-btn-sub-primary2'} style={{ height: 45, boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 3px 2px'}}> <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.gplay_icon}/> Disponível no <strong> Google Play </strong> </Button>
                                <Button onClick={() => window.open(Env.biz_apple_store)} style={{ borderColor: 'black', height: 45, color: 'black', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 3px 2px'}}> <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.apple_icon}/> Disponível na <strong> App Store </strong> </Button>
                            </div>
                        </div>
                    </Col>
                    <Col md={24} lg={8} style={{paddingLeft: 20  }}>
                        <Fade delay={1000} right>
                            <img style={{width: '100%', borderRadius: 10}} src={Images.app_club_img} />
                        </Fade>
                    </Col>
                </Col>
            </Row>
        </Row>
    </div>)
};
export default HowToClub;
