import React, {useCallback, useState} from 'react';
import {AutoComplete, Button, Checkbox, Col, Collapse, Icon, Input, Row, Select, Typography} from "antd";
import useApi from "../../../../../Hooks/Api";
import {Fade} from "react-reveal";
import {debounce} from "lodash";

const deficiencias = ['Alguma', 'Surdez bilateral parcial', 'Surdez bilateral total', 'Amputação', 'Cadeirante', 'Membros Inferiores', 'Um membro Inferior', 'Membros Superiores', 'Um membro superior', 'Nanismo', 'Ostomia', 'Paralisia Cerebral', 'Parcial', 'Mental/Intelectual', 'Baixa Visão', 'Cegueira', 'Auditiva não informada', 'Física não informada', 'Mental não informada', 'Visual não informada', 'Múltipla não informada'];
const schoolingList = ['Analfabeto', 'Fundamental Incompleto', 'Fundamental Completo', 'Médio Incompleto', 'Médio Completo', 'Superior Incompleto', 'Superior Completo', 'Mestrado', 'Doutorado', 'Especialização'];
const driver_licenseList = ['A', 'AB', 'AC', 'AD', 'AE', 'B', 'C', 'D', 'E'];
const languagesList = ['Alemão', 'Espanhol', 'Francês', 'Inglês', 'Italiano'];
const vehiclesList = [ 'Bicicleta', 'Caminhão', 'Carro', 'Moto', 'Nenhum', 'Outro Veículo motorizado', 'Outro Veículo não motorizado', 'Veículo de Tração Animal'];

const SideBarComponent = props => {

    const [data, setData] = useState([]);
    const autocompleteApi = useApi({loadingControl: true, customReturn: true});
    const [autoCompleteInput,setAutoCompleteInput] = useState();


    const [gender, setGender] = useState();
    const [age_max, setAgeMax] = useState();
    const [age_min, setAgeMin] = useState();
    const [is_student, setIsStudent] = useState();
    const [courses, setCourses] = useState();
    const [available_sleep_duty, setAvailableSleepDuty] = useState();
    const [available_leave_home, setAvailableLeaveHome] = useState();
    const [available_travel, setAvailableTravel] = useState();
    const [disabilities, setDisabilities] = useState();
    const [vehicles, setVehicles] = useState();
    const [languages, setLanguages] = useState();
    const [driver_license, setDriverLicense] = useState();
    const [schooling, setSchooling] = useState();
    const [cbo_occupation_id, setCboOccupationId] = useState();
    const [cbo_name, setCboOccupationName] = useState();

    const onChange = (e) => {
        setAutoCompleteInput(e)
    }

    const onAutoCompleteChange = value => {
        let aux = value.split(',');
        setCboOccupationId(aux[0]);
        setCboOccupationName(aux[1]);
    }

    const handleFilter = () => {
        let obj = {
            cbo_name,
            cbo_occupation_id,
            schooling,
            driver_license,
            languages,
            vehicles,
            disabilities,
            gender,
            age_max,
            age_min,
            is_student,
            available_leave_home,
            available_sleep_duty,
            available_travel,
            courses
        };

        props.searchFilter(obj);
    };

    const autoCompleteSearch = useCallback(debounce(async (query) => {
        autocompleteApi.setLoading(true);
        let searchQuery = `?query=${query}&limit=25`;

        if (!autocompleteApi.loading) {
            autocompleteApi.get(`sine/cbo-occupations/autocomplete${searchQuery}`, (res) => {
                setData(res);
            }, (e) => {
                console.log(e);
            });
        }
    }, 700), []);

    return (
        <>
            <div style={{minHeight: '165px', margin: 5}} >
                <Fade unmountOnExit mountOnEnter duration={1000}>
                    <div className={'sidebarFilters'}>
                        {/*<Divider />*/}
                        <Row style={{marginBottom: 20}}>
                            <Col span={24}>
                                <Collapse className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                        <div className="circle">
                                            <div className="horizontal"/>
                                            <div className="vertical"/>
                                        </div>
                                    </div> }>
                                    <Collapse.Panel header={<span style={{fontSize: 17, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}>Dados Básicos</span>} key="1">
                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, position: 'relative', marginBottom: 15}}>
                                            <Typography.Text>Profissão/Cargo</Typography.Text>
                                            {cbo_occupation_id&& <Icon onClick={() => {setCboOccupationName(undefined); setCboOccupationId(undefined); setAutoCompleteInput(undefined)}} type={'close'} style={{position: 'absolute', cursor: 'pointer', right: 5, top: 35, zIndex: 10, backgroundColor: '#EEEEEE', padding: 3, fontSize: 13, borderRadius: 10}}/>}
                                            <AutoComplete
                                                size="large"
                                                className='height100Autocomplete'
                                                value={autoCompleteInput}
                                                style={{ width: '100%'}}
                                                dataSource={data.map(item => {
                                                    return (
                                                        <AutoComplete.Option key={item.occupation_id+","+item.name} text={item.name}>
                                                            {item.name}
                                                        </AutoComplete.Option>
                                                    )
                                                })}
                                                onChange={(e) => onChange(e)}
                                                onSelect={onAutoCompleteChange}
                                                onSearch={autoCompleteSearch}
                                                optionLabelProp="text"
                                            >
                                                <Input className={'autocompleteInput'} size={'large'} placeholder={'Qual perfil você busca? Ex: Vendedor'} prefix={<Icon type={autocompleteApi.loading ? "loading" : "search"} />} />

                                            </AutoComplete>
                                        </div>

                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Gêneros</Typography.Text>
                                            <Select
                                                mode={'multiple'}
                                                value={gender}
                                                onChange={(value) => {setGender(value)}}
                                                disabled={props.loading}  placeholder="Todos">
                                                <Select.Option value="">Todos</Select.Option>
                                                <Select.Option value={'male'}> Masculino </Select.Option>
                                                <Select.Option value={'female'}> Feminino </Select.Option>
                                                <Select.Option value={'transgender_male'}> Homem transgênero </Select.Option>
                                                <Select.Option value={'transgender_female'}> Mulher transgênero </Select.Option>
                                                <Select.Option value={'transsex_male'}> Homem Transexual </Select.Option>
                                                <Select.Option value={'transsex_female'}> Mulher Transexual </Select.Option>
                                                <Select.Option value={'unknown'}> Não sei responder </Select.Option>
                                                <Select.Option value={'not_anwswered'}> Prefiro não responder </Select.Option>
                                                <Select.Option value={'other'}> Outros </Select.Option>
                                            </Select>
                                        </div>

                                        {/* <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Data de seleção </Typography.Text>
                                            <Select
                                                mode={'multiple'}
                                                value={activeFilters.gender?.replace(/\+/ig, " ").split(",")}
                                                onChange={(value) => {props.filter("gender", _.isEmpty(value) ? null : value)}}
                                                disabled={props.loading}  placeholder="Todos">
                                                <Select.Option value="">Todos</Select.Option>
                                                <Select.Option value={'male'}> Masculino </Select.Option>
                                                <Select.Option value={'female'}> Feminino </Select.Option>
                                                <Select.Option value={'transgender_male'}> Homem transgênero </Select.Option>
                                                <Select.Option value={'transgender_female'}> Mulher transgênero </Select.Option>
                                                <Select.Option value={'transsex_male'}> Homem Transexual </Select.Option>
                                                <Select.Option value={'transsex_female'}> Mulher Transexual </Select.Option>
                                                <Select.Option value={'unknown'}> Não sei responder </Select.Option>
                                                <Select.Option value={'not_anwswered'}> Prefiro não responder </Select.Option>
                                                <Select.Option value={'other'}> Outros </Select.Option>
                                            </Select>
                                        </div>*/}

                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Idade</Typography.Text>
                                            <div style={{display: 'flex'}}>
                                                <Input type={'number'} style={{width: '100%'}} className={'numberInput'} disabled={props.loading}  value={age_min} placeholder="Mínimo" onChange={(e) => {if(Number(e.target.value) < 110 && (age_max? Number(e.target.value) < age_max : true )) setAgeMin(Number(e.target.value) === 0? null : Number(e.target.value));}} min={0}/>
                                                <Input type={'number'} style={{width: '100%'}} className={'numberInput'} disabled={props.loading}  value={age_max} placeholder="Máximo" onChange={(e) => {if(Number(e.target.value) < 110) setAgeMax(Number(e.target.value) === 0? null : Number(e.target.value));}} min={0}/>
                                            </div>
                                        </div>

                                        <Checkbox style={{marginLeft: 8,fontSize: 16}} checked={is_student} onChange={(e) => setIsStudent(e.target.checked || '')}>Estudante</Checkbox>
                                        <Checkbox style={{fontSize: 16}} checked={courses} onChange={(e) => setCourses(e.target.checked || '')}>Possui curso profissionalizante</Checkbox>

                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>

                        <Row style={{marginBottom: 20}}>
                            <Col span={24}>
                                <Collapse className='floatsCardsResume'  defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                        <div className="circle">
                                            <div className="horizontal"/>
                                            <div className="vertical"/>
                                        </div>
                                    </div> }>
                                    <Collapse.Panel header={<span style={{fontSize: 17, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}>Disponibilidade e Acessibilidade</span>} key="1">
                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Carteira de Habilitação</Typography.Text>
                                            <Select disabled={props.loading} value={driver_license} placeholder="Todas" onChange={(value) => setDriverLicense(value)}>
                                                <Select.Option value="">Todos</Select.Option>
                                                {driver_licenseList.map(el => <Select.Option value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>

                                        <div style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text >Deficiências</Typography.Text>
                                            <Select
                                                disabled={props.loading}
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Indiferente"
                                                value={disabilities}
                                                onChange={(value) => {setDisabilities(value)}}
                                            >
                                                <Select.Option value="Nenhuma">Nenhuma</Select.Option>
                                                {deficiencias.map(el => <Select.Option value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>

                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Possui Veículo</Typography.Text>
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Indiferente"
                                                value={vehicles}
                                                onChange={(value) => {setVehicles(value)}}
                                            >
                                                {vehiclesList.map((el, index) => <Select.Option key={index} value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 20}}>
                            <Col span={24}>
                                <Collapse className='floatsCardsResume'   defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                        <div className="circle">
                                            <div className="horizontal"/>
                                            <div className="vertical"/>
                                        </div>
                                    </div> }>
                                    <Collapse.Panel header={<span style={{fontSize: 17, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}>Conhecimento e Experiência</span>} key="1">
                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text >Escolaridade</Typography.Text>
                                            <Select
                                                disabled={props.loading}
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Todas"
                                                value={schooling}
                                                onChange={(value) => {setSchooling(value)}}
                                            >
                                                {schoolingList.map(el => <Select.Option value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>

                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Outros Idiomas</Typography.Text>
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Indiferente"
                                                value={languages}
                                                onChange={(value) => {setLanguages(value)}}
                                            >
                                                {languagesList.map((el, index) => <Select.Option key={index} value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Collapse className='floatsCardsResume'  defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                        <div className="circle">
                                            <div className="horizontal"/>
                                            <div className="vertical"/>
                                        </div>
                                    </div> }>
                                    <Collapse.Panel header={<span style={{fontSize: 17, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}>Outros Filtros </span>} key="1">
                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Checkbox style={{marginLeft: 8,fontSize: 16}} checked={available_travel} onChange={(e) => setAvailableTravel(e.target.checked || "")}>Pode viajar</Checkbox>
                                            <Checkbox style={{fontSize: 16}} checked={available_sleep_duty} onChange={(e) => setAvailableSleepDuty(e.target.checked || "")}>Pode dormir no emprego</Checkbox>
                                            <Checkbox style={{fontSize: 16}} checked={available_leave_home} onChange={(e) => setAvailableLeaveHome(e.target.checked || "")}>Pode se ausentar do domicílio</Checkbox>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{marginTop: 15, display: "flex", flexDirection: "column", lineHeight: '25px'}}>
                                <Button style={{boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)'}} icon='search' className='searchButtonSubPage' onClick={() => handleFilter()}> Filtrar </Button>
                            </Col>
                        </Row>
                    </div>
                </Fade>
            </div>
        </>
    );
};

SideBarComponent.propTypes = {};

export default SideBarComponent;
