import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Button, Col, Collapse, Divider, Drawer, Icon, List, message, Modal, Popover, Row, Skeleton} from "antd";
import useApi from "../../../../Hooks/Api";
import moment from "moment";
import SideBarComponent from "./MyRecruitmentsPage/SideBarComponent";
import Loading from "../../../Generic/Loading";
import {Images} from "../../../../Helpers/Images";
import FeedbackForm from "../RecruitmentsPage/FeedbackForm";
import ResumeDrawer from "../../Public/SearchResumePage/ResumeDrawer";
import ExportModal from "../RecruitmentsPage/ExportModal";
import {Switch} from "antd/es";
import {useHistory} from "react-router-dom";
import RegisterEnterpriseComponent from "../../../../Pages/RegisterEnterpriseComponent";
import {FaMobile, FaMousePointer, FaThumbsDown} from "react-icons/fa";
import {FaThumbsUp, MdStar, MdStarBorder} from "react-icons/all";


const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}

const MyRecruitmentsPage = props => {
    const api = useApi();
    const resumesList = useRef([]);
    const [loading,setLoading] = useState(true);
    const resumeType = useRef('pendentes');
    const pagination = useRef({page: 0});
    const [showFeedback, setShowFeedback] = useState(false);
    const profile = useRef();
    const [showModal, setShowModal] = useState(false);
    const [currentItemId, setCurrentItemId] = useState();
    const [showExport, setShowExport] = useState(false);
    const [exportLoading , setExportLoading] = useState(false);
    const lastUsedFilters = useRef({});
    const [count, setCount] = useState(undefined);
    const history = useHistory();
    const [showFilter, setShowFilter] = useState(false);

    const getCounter = async (obj) => {
        obj.count_only = true;
        api.post(`sine/contacts`, obj, (res) => {
            if(res.total_count === 0) {
                setCount('Nenhum resultado');
            } else {
                setCount(res.total_count + ' resultado(s) encontrado(s)');
            }
        }, (e) => {
            console.log(e)
        });

    };

    const getData = async (clean, filters) => {
        setCount(undefined);
        if(filters) lastUsedFilters.current = filters;
        setLoading(true);
        let filter = [];
        if (resumeType.current === 'completos') {
            filter = ['completed']
        } else if (resumeType.current === 'pendentes') {
            filter = ['pending']
        }

        let auxPage = clean? 1 : pagination.current.page + 1;
        api.post(`sine/contacts`, {status: filter, page: auxPage, order: 'desc', filters}, async (res) => {
            pagination.current = {nextPage: res.next_page, page: res.page, total: res.entries};
            if(!clean) {
                resumesList.current = resumesList.current.concat(res.contacts);
            } else {
                resumesList.current = res.contacts;
            }

            setLoading(false);
            await getCounter({status: filter, page: auxPage, order: 'desc', filters});
        }, (e) => {
            message.error('Ops, não foi possível recuperar seus endereços, tente novamente');
            setLoading(false);
        });

        window.scrollToElement('body');
    }

    const handleViewProfile = (id) => {
        setCurrentItemId(id);
        setShowModal(true);
    }

    const handleJsonDownload = async (filter, type) => {
        setExportLoading(true);
        var downloadLink = window.document.createElement('a');

        filter.status = type;
        if(filter?.completed_min && filter?.completed_max) {
            if(moment(filter.completed_max, 'DD/MM/YYYY').diff(moment(filter.completed_min, 'DD/MM/YYYY'), 'days') < 0) {
                message.destroy();
                message.warning('A data mínima deve ser maior que a máxima');
                setExportLoading(false);
                return;
            }
        }
        if(filter?.completed_min) filter.completed_min = moment(filter.completed_min, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if(filter?.completed_max) filter.completed_max = moment(filter.completed_max, 'DD/MM/YYYY').format('YYYY-MM-DD');

        api.downloadFormat(`sine/export-contacts`, {filters: filter, feedback_type: filter.feedback_type}, (res) => {
            downloadLink.href = window.URL.createObjectURL(new Blob([res], {type: `text/json;charset=UTF-8`}));
            downloadLink.download = `recrutamentos.json`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setExportLoading(false);
        }, (e) => {
            console.log(e)
        });


    };
    const handleSwitch = (e) => {
        pagination.current.page = 0;
        resumesList.current = [];

        if(resumeType.current === 'completos') {
            resumeType.current = 'pendentes';
        } else {
            resumeType.current = 'completos';
        }
        getData();
    }

    useEffect(() => {
        getData();
    },[]);


    return (
        <Row style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, display: "flex", padding: 10, borderRadius:5, backgroundColor: "white"}}>

            <Drawer
                className="filter-drawer"
                placement="right"
                closable={false}
                onClose={() => {setShowFilter(false)} }
                visible={showFilter}
            >
                <div style={{padding: 10}}>
                    <SideBarComponent searchFilter={(filters) => getData(true, filters)}/>
                </div>
            </Drawer>

            <Col className={'mainComponentPanel'} span={24}>
                    <div>
                        <Row gutter={[24,24]} className={'recruitmentPageRow'}>
                            {loading ?
                                <Col xs={24}  style={{margin: "auto", marginTop: 200}}><Loading/></Col>
                                :
                                <Col xs={24}>
                                    <div className={'colBody'}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <span style={{fontSize: 20, fontWeight: 'bold'}}>
                                                {resumeType.current === 'pendentes' ? 'Currículos selecionados pendentes de feedback que não estão relacionados às vagas.' : 'Feedbacks Enviados'}
                                            </span>
                                            <div style={{display: "flex"}}>
                                                <Popover placement={'bottom'} title={'Exportar'} content={'Exportar currículos no formato JSON com os filtros selecionados'}> <Button loading={exportLoading} disabled={exportLoading} style={{marginLeft: 10, fontSize: 20}} shape={'circle'} type={'primary'} icon={"cloud-download"} onClick={() => resumeType.current === 'pendentes'? handleJsonDownload(lastUsedFilters.current, 'pending') : setShowExport(true)}/> </Popover>
                                                <Popover placement={'bottom'} title={'Filtrar'} content={'Filtre os resultados'}> <Button style={{marginLeft: 10, fontSize: 20}} shape={'circle'} type={'secondary'} icon={"filter"} onClick={() => setShowFilter(true)}/> </Popover>
                                            </div>
                                        </div>

                                        <div style={{marginBottom: -20, marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
                                            {count? <div> <span style={{fontSize: 12}}> <strong> {count} </strong>  </span> </div>: <div> <span style={{fontSize: 12}}> Calculando quantidade... </span></div> }
                                            <div> <Switch checked={resumeType.current === 'completos'} onChange={(e) => handleSwitch(e)} size={'small'}> </Switch> <span style={{color: "#616161"}}> {resumeType.current === 'pendentes'? 'Ver currículos com feedback' : 'Ver currículos pendentes'} </span> </div>
                                        </div>

                                        <Divider className={'primary-color-bg'}/>

                                        {resumesList.current.length > 0 && <List
                                            className='listAnt listAntOrange'
                                            style={{overflow: 'auto', width: '100%', minHeight: '600px'}}
                                            itemLayout="horizontal"
                                            dataSource={resumesList.current}
                                            loading={loading}
                                            renderItem={(item, index) => (
                                                <List.Item key={index} style={{padding: 0, position: 'relative', backgroundColor: 'white'}} >
                                                    <List.Item.Meta
                                                        onClick={() => {
                                                            handleViewProfile(item.id)
                                                        }}
                                                        style={{alignItems: 'center', padding: 10, cursor: 'pointer', position: 'relative'}}
                                                        avatar={
                                                            <Avatar style={{width: 64, height: 64, padding: 10, backgroundColor: '#f5f5f5'}} size='default' src={Images.lettersPack[item.name[0].toLowerCase()] || Images.user_place_holder_2 }/>
                                                        }
                                                        title={<div style={{display: 'flex', flexDirection: 'column'}}>
                                                            <span style={{fontWeight: "bold"}}>{item.name}  </span>
                                                            <div>

                                                                <Popover content={'Este currículo foi selecionado manualmente por você'}>
                                                                    <span style={{color: "darkgray", fontSize: 13, fontWeight: 400}}> Selecionado  <FaMousePointer style={{color: "darkgray"}}/> </span>
                                                                </Popover>

                                                            </div>
                                                        </div>}
                                                        description={<span> <img src={Images.resume.job_case} style={{marginTop: -4}} height={15} width={15}/> {item.occupation} </span>}
                                                    />


                                                    {item.status === 'pending' &&<div style={{display: 'flex', flexFlow: 'column', marginRight: 20, marginTop: 10}}>
                                                        <div
                                                            className={'likeDislikeCandidate'}
                                                            onClick={() => {
                                                                profile.current = item;
                                                                setShowFeedback(true);
                                                            }}
                                                            style={{backgroundColor: '#e9e9e94d', borderRadius: '50%', height: 45, width: 45, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#1680ce', boxShadow: '0px 1px 1px 1px #e0e0e0', fontSize: 15}}>
                                                            <FaThumbsUp style={{marginRight: 5, color: '#6bbe1e'}}/>
                                                            <FaThumbsDown  style={{color: '#ff4e4e', transform: 'rotateY(180deg)'}}/>
                                                        </div>
                                                    </div>}
                                                </List.Item>
                                            )}
                                        />}

                                        {pagination.current.nextPage && <div style={{width: '100%', marginTop: 25, paddingBottom: 10}}>
                                            <div className='loadMore2' onClick={() => getData(false, lastUsedFilters.current)}><Icon type={'reload'}/> Carregar Mais</div>
                                        </div>}
                                        {resumesList.current.length === 0 &&
                                        <div className={'empty-card'} style={{boxShadow: "unset"}}>
                                            <div style={{marginLeft: '20px', color: '#545a5d'}}>
                                                {resumeType.current === 'pendentes' ?
                                                    <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                                                        <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                                                        <span > Você não possui nenhum recrutamento pendente de feedback! </span>
                                                        <Button className={"ant-btn-primary"} style={{marginTop: 40}} onClick={() => history.push("/curriculos")}> Buscar currículos </Button>
                                                    </div>
                                                    :
                                                    <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                                                        <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                                                        <span> <span > Você não possui nenhum Feedback Enviado! </span> <br/> Os feedbacks são extremamente importantes e pedimos que os realize o quanto antes após entrevistar cada candidato.  </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </Col>
                            }
                        </Row>
                    </div>

                {showFeedback &&<FeedbackForm id={profile.current?.id} getData={() => getData(true)} showFeedback={showFeedback} setShowFeedback={(item) => setShowFeedback(item)} profile={profile.current}/>}
                <ResumeDrawer id={currentItemId} recruited={true} cboId={false} visible={showModal} setVisible={(item) => setShowModal(item)} />
                <ExportModal handleJsonDownload={(filter) => handleJsonDownload({...lastUsedFilters.current, ...filter}, 'completed')} loading={exportLoading} visible={showExport} setExportType={() => setShowExport(false)}/>
            </Col>
        </Row>
    );
};

MyRecruitmentsPage.propTypes = {};

export default MyRecruitmentsPage;
