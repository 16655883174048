import React, {memo, useEffect, useState} from 'react';
import Layout from "antd/lib/layout";
import {Map, Marker} from 'google-maps-react';
import {Button, Icon, Row} from "antd";
import InfoWindowEx from "./InfoWindowEx";
import useLocalStorage from "../../../../Hooks/Storage";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";
import {Images} from "../../../../Helpers/Images";

const Content = Layout;


const MapComponent = (props) => {
    const [activeMarker, setActiveMarker] = useState({});
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [data, setData] = useState([]);
    const [markers, setMarkers] = useState([]);
    const myLocation = useLocalStorage(StorageVariables.geolocation);

    const onMarkerClick = (props, marker, e) => {
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };

    const onClose = props => {
        if (showInfoWindow) {
            setShowInfoWindow(false);
            setActiveMarker(null);
        }
    };

    const displayMarkers = (data) => {
        return data.map((item, index) => {
            //console.log(index);
            return (
                <Marker
                    key={index}
                    position={{ lat: item.latitude, lng: item.longitude}}
                    onClick={(props,marker) => onMarkerClick(props,marker)}
                    icon={{
                        url: item.recruited? Images.pin: Images.checkedPin,
                        anchor: new window.google.maps.Point(16,16),
                        scaledSize: new window.google.maps.Size(32,32)
                    }}
                    name={ [<div>
                        {item.recruited && <Icon style={{color: "#299c26", position: "absolute", fontSize: 30, opacity: '0.3',bottom: 20, right: 10}} type="safety-certificate"/>}
                        <h1 style={{fontWeight: 'bold'}}> {item.profile_name || item.title || 'Profissional'} </h1>
                        <br/>
                        {item.description.replace(/(\r\n|\n\r|\r|\n)/g, "|").split("|")?.map(subItem =><>{subItem} <br/></>)}
                        <br/>
                        <div style={{textAlign: 'center'}}> <Button style={{backgroundColor: '#14b314', color: 'white', borderColor: 'white'}} icon={'user'} onClick={() => props.showDrawer(item.id, !!item.name)}> Ver Perfil </Button> </div>
                    </div>]}
                />
            )}
        )
    };


    useEffect(() => {
        setData(props.data);
        setMarkers(displayMarkers(props.data));
    },[props.data]);

    return (
        <Row className={'mapComponentDiv'}>
            <div style={{position: 'relative', height: '600px'}}>
                {(data.length > 0)&&
                <div style={{ background: '#fff', padding: 0}}>
                    <Map initialCenter={{
                        lat: data[0].latitude,
                        lng: data[0].longitude
                    }}
                         center={{
                             lat: data[0].latitude,
                             lng: data[0].longitude
                         }}
                         google={props.google}
                         zoom={13}
                         style={{width: 'auto', height: 'auto'}}
                    >
                        {/*{displayMarkers(data)}*/}
                        <Marker
                            position={{lat: myLocation.getObject()?.lat, lng: myLocation.getObject()?.long}}
                            onClick={(props,marker) => onMarkerClick(props,marker)}
                            name={ [<span> <h1> {myLocation.getObject()?.loc}</h1></span>]}
                        />
                        {markers}
                        <InfoWindowEx
                            marker={activeMarker}
                            visible={showInfoWindow}
                            onClose={() => onClose}
                        >
                            <div>
                                {activeMarker? <h4>{activeMarker.name}</h4> : null }
                            </div>
                        </InfoWindowEx>
                    </Map>
                    {props.nextPage&& <div style={{position: 'absolute', width: '100%', bottom: '10px', textAlign: 'center' }}>
                         <Button loading={props.loading}  onClick={() => props.loadMore()} style={{borderRadius: 20, boxShadow: '0px 0px 3px 2px #d9d9d9', borderColor: '#14b314',  backgroundColor: '#14b314', color: 'white'}}> Carregar Mais </Button>
                    </div> }

                </div>
                }

            </div>
        </Row>
    )

}

export default memo(MapComponent, (prevProps, nextProps) => {
    if(prevProps.data !== nextProps.data) return false;
    if(prevProps.loading !== nextProps.loading) return false;
    return true
});
