import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Icon, List, Skeleton, Spin} from "antd";
import moment from "moment";


const EstablishmentMain = (props) => {

    console.log(props.reviews);
    return (
        <div>
            <div style={{marginBottom: 40, borderRadius: 10}}>
                <img style={{width: '100%', borderRadius: 10}} src={props.coverPicture}/>
            </div>

            <span style={{fontSize: 22, color: '#333'}}> <Icon className={'primary-color'} type={'shop'}/> Sobre Nós </span>
            <div style={{marginTop: 20, marginBottom: 30, fontSize: 18}}>
                <span style={{whiteSpace: 'break-spaces'}}>
                    {props.bio}
                </span>
            </div>

            {(props.discounts.length > 0 || props.discountsLoading) &&
            <>
                <div style={{fontSize: 22, color: '#333', marginBottom: 20}}><span> <Icon className={'primary-color'} type={'dollar'}/> Descontos e Vantagens  </span>
                </div>
                {props.discounts?.map((item, index) =>
                    <div style={{fontSize: 18}} key={index}>
                        <List
                            className='alternateColorsList'
                            style={{overflow: 'auto'}}
                            itemLayout="horizontal"
                            dataSource={item.products}
                            header={<div style={{display: 'flex'}}><Avatar style={{width: 64, height: 64}}
                                                                           src={item.badge}/>
                                <div style={{paddingLeft: 10, paddingTop: 5, fontWeight: 'bold'}}><span
                                    style={{fontSize: 20}}> {item.title}/{item.abbrev} </span> <br/>
                                    <span> {item.description} </span></div>
                            </div>}
                            renderItem={subItem => (
                                <List.Item actions={subItem.discount !== null?[<div style={{color: 'white', background: 'limegreen', padding: 10, borderRadius: 7}}> <Icon type={'dollar'}/> Desconto de <strong> {' ' + subItem.discount}</strong> </div>] : [<div style={{color: 'limegreen', fontSize: 17}}> VANTAGEM </div>]}
                                           style={{padding: 20, paddingLeft: 40, borderRadius: 5}}>
                                    <Skeleton avatar title={false} loading={false} active>
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar shape="square" style={{width: 48, height: 48}} size='default'
                                                        src={subItem.picture.full}/>
                                            }
                                            title={<div><span
                                                style={{fontWeight: "bold", fontSize: 17}}>{subItem.title}  </span>
                                            </div>}
                                            description={<span> <span style={{
                                                fontSize: 16,
                                                color: '#5f5f6f'
                                            }}>{subItem.description}</span> </span>}
                                        />
                                    </Skeleton>
                                </List.Item>
                            )}
                        />
                        {props.discountsNextPage &&
                        <div className='loadMore' onClick={() => props.loadMoreDiscounts}> Carregar Mais </div>}
                    </div>
                )}
            </>
            }
            {props.discountsLoading&& <div style={{marginTop: 20, textAlign: 'center'}}> <Spin/> </div>}


            {(props.reviews.length > 0 || props.reviewsLoading) &&
            <>
                <div style={{fontSize: 22, color: '#333', marginBottom: 20, marginTop: 40}}><span> <Icon className={'primary-color'} type={'like'}/> Avaliações  </span>
                </div>
                <div style={{marginTop: 20, fontSize: 18}}>
                    <List
                        loading={props.reviewsLoading}
                        className='alternateColorsList'
                        style={{overflow: 'auto'}}
                        itemLayout="horizontal"
                        dataSource={props.reviews}
                        renderItem={item => (
                            <List.Item style={{padding: 20, borderRadius: 5}}>
                                <Skeleton avatar title={false} loading={false} active>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar style={{width: 64, height: 64}} size='default'
                                                    src={item.user.profile_picture.full}/>
                                        }
                                        title={<div
                                            style={{display: 'flex', marginTop: 5, justifyContent: 'space-between'}}>
                                            <span style={{fontWeight: "bold", fontSize: 17}}>{item.user.name}  </span>
                                            <div><span className={'primary-color'} style={{marginRight: 5}}> <Icon
                                                type='calendar'/> {moment(item.created_at).format('DD-MM-YYYY')} </span>
                                                <span style={{color: '#ffc53d'}}> <Icon type='star'
                                                                                        theme="filled"/> {item.rating} </span>
                                            </div>
                                        </div>}
                                        description={<span> <span
                                            style={{fontSize: 16, color: '#5f5f6f'}}>{item.review}</span> {item.reply &&
                                        <div><br/> Resposta
                                            em {moment(item.reply.created_at).format('DD-MM-YYYY')} - {item.reply.content}
                                        </div>} </span>}
                                    />
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                    {props.reviewsNextPage &&
                    <div className='loadMore' onClick={() => props.loadMoreReviews()}> Carregar Mais </div>}

                </div>
            </>
            }
        </div>
    );
};

EstablishmentMain.propTypes = {
    bio: PropTypes.any,
    reviewsLoading: PropTypes.any,
    reviewsNextPage: PropTypes.any,
    reviews: PropTypes.any,
    discounts: PropTypes.any,
    discountsNextPage: PropTypes.any,
    loadMoreDiscounts: PropTypes.any,
    loadMoreReviews: PropTypes.any,
    discountsLoading: PropTypes.any,

};

export default EstablishmentMain;
