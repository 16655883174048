import React, {useEffect, useState, useCallback} from 'react';
import {AutoComplete, Button, Checkbox, Col, Collapse, Icon, Input, Popover, Row, Select, Spin, Typography} from "antd";
import _, {debounce} from 'lodash';
import useApi from "../../../../Hooks/Api";
import {Fade} from "react-reveal";
import TextArea from "antd/es/input/TextArea";
import occupation_list from '../../../../occupation_list.json'

const horarios = ['Indiferente', 'Comercial', 'Manhã', 'Tarde', 'Noite', 'Madrugada', 'Manhã/Tarde', 'Manhã/Noite', 'Manhã/Madrugada', 'Manhã/Tarde/Noite', 'Manhã/Tarde/Madrugada', 'Manhã/Noite/Madrugada', 'Manhã/Tarde/Noite/Madrugada', 'Tarde/Noite', 'Tarde/Madrugada', 'Tarde/Noite/Madrugada', 'Noite/Madrugada'];
const deficiencias = ['Alguma', 'Surdez bilateral parcial', 'Surdez bilateral total', 'Amputação', 'Cadeirante', 'Membros Inferiores', 'Um membro Inferior', 'Membros Superiores', 'Um membro superior', 'Nanismo', 'Ostomia', 'Paralisia Cerebral', 'Parcial', 'Mental/Intelectual', 'Baixa Visão', 'Cegueira', 'Auditiva não informada', 'Física não informada', 'Mental não informada', 'Visual não informada', 'Múltipla não informada'];
const schooling = ['Analfabeto', 'Fundamental Incompleto', 'Fundamental Completo', 'Médio Incompleto', 'Médio Completo', 'Superior Incompleto', 'Superior Completo', 'Mestrado', 'Doutorado', 'Especialização'];
const driver_license = ['A', 'AB', 'AC', 'AD', 'AE', 'B', 'C', 'D', 'E'];
const experience = [{text: "6 Meses", value: '6'}, {text: "1 Ano", value: '12'}, {text: "2 Anos", value: '24'}, {text: "3 Anos", value: '36'}, {text: "4 Anos", value: '48'}, {text: "5 Anos", value: '60'}, {text: "6 Anos", value: '72'}, {text: "7 Anos", value: '84'}, {text: "8 Anos", value: '96'}, {text: "9 Anos", value: '108'}, {text: "10 Anos", value: '120'}];
const languages = ['Alemão', 'Espanhol', 'Francês', 'Inglês', 'Italiano'];
const vehicles = [ 'Bicicleta', 'Caminhão', 'Carro', 'Moto', 'Nenhum', 'Outro Veículo motorizado', 'Outro Veículo não motorizado', 'Veículo de Tração Animal'];

const SideBarFilter = props => {

    let activeFilters = window.queryStringToObject();
    const [data, setData] = useState([]);
    const autocompleteApi = useApi({loadingControl: true, customReturn: true});
    const [autoCompleteInput,setAutoCompleteInput] = useState();

    const onAutocompleteTextChange = useCallback(debounce(async (text) => {
        setAutoCompleteInput(text);
        let aux = []
        if(text.length === 0) {
            setData([]);
        } else {
            if(text.length > 3) {
                occupation_list.forEach((item) => {
                    if(item.occupation_synonym?.toLowerCase().match(text.toLowerCase()) || item.occupation_name.toLowerCase().match(text.toLowerCase())) {
                        aux.push(item)
                    }
                });
                aux.forEach((item, index) => {
                    if(item.occupation_name.toLowerCase().match(text.toLowerCase())) {
                        aux.slice(index, 1);
                        aux.unshift(item);
                    }
                })
                console.log(aux)
            }
        }
    },700), []);

    const onChange = (e) => {
        setAutoCompleteInput(e)
    }

    const onAutoCompleteChange = value => {
        let aux = value.split(',');
        props.filter("cbo_occupation_id", aux[0]);
        props.filter("cbo_name", aux[1]);
        props.search();
    }

    const autoCompleteSearch = useCallback(debounce(async (query) => {
        autocompleteApi.setLoading(true);
        let searchQuery = `?query=${query}&limit=25`;

        if (!autocompleteApi.loading) {
            autocompleteApi.get(`sine/cbo-occupations/autocomplete${searchQuery}`, (res) => {
                setData(res);
            }, (e) => {
                console.log(e);
            });
        }
    }, 700), []);

    useEffect(() => {
        if (!window.location.search) {
            setAutoCompleteInput('');
        }
    },[window.location.search]);

    useEffect(() => {
        if(props.cboName){
            setAutoCompleteInput(_.replace(props.cboName,/\+/g," "));
        }
    },[props.cboName]);


    return (
        <>
            <div style={{minHeight: '165px'}} >
                <Fade style={{cursor: 'pointer'}} unmountOnExit mountOnEnter duration={1000} when={activeFilters.cbo_occupation_id !== undefined}>
                    <Row onClick={() => props?.clearFilters()} className={'cleanFilters'}>
                        <Col span={24}>
                            <label><Icon type={'retweet'}/> Limpar Filtros </label>
                        </Col>
                    </Row>
                </Fade>

                <Row className='sidebarCol'>
                    <Col span={24}>
                        <label style={{fontSize: 16, fontWeight: 'bold'}}> Qual profissão você busca? </label>
                    </Col>
                    <Col span={24} style={{paddingTop: 10}}>
                        <AutoComplete
                            size="large"
                            className='height100Autocomplete'
                            value={autoCompleteInput}
                            style={{ width: '100%'}}
                            dataSource={data.map(item => {
                                return (
                                    <AutoComplete.Option key={item.occupation_id+","+item.name} text={item.name}>
                                        {item.name}
                                    </AutoComplete.Option>
                                )
                            })}
                            onChange={(e) => onChange(e)}
                            onSelect={onAutoCompleteChange}
                            onSearch={autoCompleteSearch}
                            optionLabelProp="text"
                        >
                            <TextArea autoSize={{ minRows: 1, maxRows: 2 }} style={{boxShadow: 'none'}} className='customTextArea borderBottomBlue'  size={'large'} placeholder={'Qual perfil você busca? Ex: Vendedor'}/>
                        </AutoComplete>
                        {autocompleteApi.loading &&<Icon style={{right: 5, top: 25, position: 'absolute'}} type={"loading"} />}
                    </Col>
                </Row>
                <Fade unmountOnExit mountOnEnter duration={1000} when={activeFilters.cbo_occupation_id !== undefined}>
                    <div className={'sidebarFilters'}>
                        <br/>
                        {/*<Divider />*/}
                        <Row style={{marginBottom: 20}}>
                            <Col span={24}>
                                <Collapse className='floatsCardsResume'  defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                        <div className="circle">
                                            <div className="horizontal"/>
                                            <div className="vertical"/>
                                        </div>
                                    </div> }>
                                    <Collapse.Panel header={<span style={{fontSize: 16, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}>Conhecimento e Experiência</span>} key="1">
                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Experiência Formal</Typography.Text>
                                            <Input.Group compact style={{display: "flex"}}>
                                                <Select disabled={props.loading} style={{flex: "3"}} value={activeFilters.formal_experience_min} placeholder="Todas" onChange={(value) => {if(value) props.filter("formal_experience_min", Number(value)); else props.filter("formal_experience_min", null); props.search()}}>
                                                    <Select.Option value={null}>Todos</Select.Option>
                                                    {experience.map((el, index) => <Select.Option key={index} value={el.value}>{el.text}</Select.Option>)}
                                                </Select>
                                            </Input.Group>
                                        </div>

                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15,marginBottom: 15}}>
                                            <Typography.Text>Experiência Informal</Typography.Text>

                                            <Input.Group compact style={{display: "flex"}}>
                                                <Select disabled={props.loading} style={{flex: "3"}} value={activeFilters.informal_experience_min} placeholder="Todas" onChange={(value) => {if(value) props.filter("informal_experience_min", Number(value)); else props.filter("informal_experience_min", null); props.search()}}>
                                                    <Select.Option value={null}>Todos</Select.Option>
                                                    {experience.map((el, index) => <Select.Option key={index} value={el.value}>{el.text}</Select.Option>)}
                                                </Select>
                                            </Input.Group>
                                        </div>
                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text >Escolaridade</Typography.Text>
                                            <Select
                                                disabled={props.loading}
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Todas"
                                                value={activeFilters.schooling?.replace(/\+/ig, " ").split(",")}
                                                onChange={(value) => {props.filter("schooling", _.isEmpty(value) ? null : value); props.search()}}
                                            >
                                                {schooling.map((el, index) => <Select.Option key={index} value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>

                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Outros Idiomas</Typography.Text>
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Indiferente"
                                                value={activeFilters.languages?.replace(/\+/ig, " ").split(",")}
                                                onChange={(value) => {props.filter("languages", _.isEmpty(value) ? null : value); props.search()}}
                                            >
                                                {languages.map((el, index) => <Select.Option key={index} value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>

                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>

                        <Row style={{marginBottom: 20}}>
                            <Col span={24}>
                                <Collapse className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                        <div className="circle">
                                            <div className="horizontal"/>
                                            <div className="vertical"/>
                                        </div>
                                    </div> }>
                                    <Collapse.Panel header={<span style={{fontSize: 16, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}>Dados Básicos</span>} key="1">
                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Gêneros</Typography.Text>
                                            <Select
                                                mode={'multiple'}
                                                value={activeFilters.gender?.replace(/\+/ig, " ").split(",")}
                                                onChange={(value) => {props.filter("gender", _.isEmpty(value) ? null : value); props.search()}}
                                                disabled={props.loading}  placeholder="Todos">
                                                <Select.Option value="">Todos</Select.Option>
                                                <Select.Option value={'male'}> Masculino </Select.Option>
                                                <Select.Option value={'female'}> Feminino </Select.Option>
                                                <Select.Option value={'transgender_male'}> Homem transgênero </Select.Option>
                                                <Select.Option value={'transgender_female'}> Mulher transgênero </Select.Option>
                                                <Select.Option value={'transsex_male'}> Homem Transexual </Select.Option>
                                                <Select.Option value={'transsex_female'}> Mulher Transexual </Select.Option>
                                                <Select.Option value={'unknown'}> Não sei responder </Select.Option>
                                                <Select.Option value={'not_anwswered'}> Prefiro não responder </Select.Option>
                                                <Select.Option value={'other'}> Outros </Select.Option>
                                            </Select>
                                        </div>


                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Idade</Typography.Text>
                                            <div style={{display: 'flex'}}>
                                                <Input type={'number'} style={{width: '100%', borderBottomRightRadius: 0, borderTopRightRadius: 0}} className={'numberInput'} disabled={props.loading}  value={activeFilters.age_min} placeholder="Mínimo" onChange={(e) => {if(Number(e.target.value) < 110 && (activeFilters.age_max? Number(e.target.value) < activeFilters.age_max : true )) props.filter("age_min", Number(e.target.value) === 0? null : Number(e.target.value));}} min={0}/>
                                                <Input type={'number'} style={{width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} className={'numberInput'} disabled={props.loading}  value={activeFilters.age_max} placeholder="Máximo" onChange={(e) => {if(Number(e.target.value) < 110) props.filter("age_max", Number(e.target.value) === 0? null : Number(e.target.value));}} min={0}/>
                                            </div>
                                        </div>

                                        <Checkbox style={{marginLeft: 8,fontSize: 16}} checked={activeFilters.is_student==="true"} onChange={(e) => {
                                            props.filter("is_student", e.target.checked ? true : null);
                                            props.search();
                                        }}>Estudante</Checkbox>
                                        <Checkbox style={{fontSize: 16}} checked={activeFilters.courses==="true"} onChange={(e) => {
                                            props.filter("courses", e.target.checked ? true : null);
                                            props.search();
                                        }}>Possui curso profissionalizante</Checkbox>

                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>

                        <Row style={{marginBottom: 20}}>
                            <Col span={24}>
                                <Collapse className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                        <div className="circle">
                                            <div className="horizontal"/>
                                            <div className="vertical"/>
                                        </div>
                                    </div> }>
                                    <Collapse.Panel header={<span style={{fontSize: 16, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}>Disponibilidade e Acessibilidade</span>} key="1">
                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Carteira de Habilitação</Typography.Text>
                                            <Select size={'large'} disabled={props.loading} value={activeFilters.driver_license} placeholder="Todas" onChange={(value) => {
                                                props.filter("driver_license", value);
                                                props.search();
                                            }}>
                                                <Select.Option value="">Todos</Select.Option>
                                                {driver_license.map((el, index) => <Select.Option key={index} value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>

                                        <div style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text >Deficiências</Typography.Text>
                                            <Select
                                                disabled={props.loading}
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Indiferente"
                                                value={activeFilters.disabilities?.replace(/\+/ig, " ").split(",")}
                                                onChange={(value) => {
                                                    props.filter("disabilities", _.isEmpty(value) ? null : value);
                                                    props.search();
                                                }}
                                            >
                                                <Select.Option value="Nenhuma">Nenhuma</Select.Option>
                                                {deficiencias.map((el, index) => <Select.Option key={index} value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>

                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Typography.Text>Possui Veículo</Typography.Text>
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Indiferente"
                                                value={activeFilters.vehicles?.replace(/\+/ig, " ").split(",")}
                                                onChange={(value) => {
                                                    props.filter("vehicles", _.isEmpty(value) ? null : value)
                                                    props.search();
                                                }}
                                            >
                                                {vehicles.map((el, index) => <Select.Option key={index} value={el}>{el}</Select.Option>)}
                                            </Select>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>


                        <Row>
                            <Col span={24}>
                                <Collapse className='floatsCardsResume' expandIcon={({ isActive }) =>
                                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                        <div className="circle">
                                            <div className="horizontal"/>
                                            <div className="vertical"/>
                                        </div>
                                    </div> }>
                                    <Collapse.Panel header={<span style={{fontSize: 17, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}>Outros Filtros </span>} key="1">
                                        <div  style={{display: 'flex', flexFlow: 'column', fontSize: 15, marginBottom: 15}}>
                                            <Checkbox style={{marginLeft: 8, fontSize: 16}} checked={activeFilters.internship==="true"}  onChange={(e) => {
                                                props.filter("internship", e.target.checked ? true : null);
                                                props.search();
                                            }}>Aceita estagiar</Checkbox>
                                            <Checkbox style={{fontSize: 16}} checked={activeFilters.grads==="true"} onChange={(e) => {
                                                props.filter("grads", e.target.checked ? true : null);
                                                props.search();
                                            }}>Possui graduação</Checkbox>
                                            <Checkbox style={{fontSize: 16}} checked={activeFilters.post_grads==="true"} onChange={(e) => {
                                                props.filter("post_grads", e.target.checked ? true : null);
                                                props.search();
                                            }}>Possui pós-graduação</Checkbox>
                                            <Checkbox style={{marginLeft: 8,fontSize: 16}} checked={activeFilters.available_travel==="true"} onChange={(e) => {
                                                props.filter("available_travel", e.target.checked ? true : null);
                                                props.search();
                                            }}>Pode viajar <Popover content={'Este filtro só realizara a filtragem dos Currículos cadastrados no SINE'}> <Icon type={'question-circle'}/> </Popover> </Checkbox>
                                            <Checkbox style={{fontSize: 16}} checked={activeFilters.available_sleep_duty==="true"} onChange={(e) => {
                                                props.filter("available_sleep_duty", e.target.checked ? true : null);
                                                props.search();
                                            }}>Pode dormir no emprego <Popover content={'Este filtro só realizara a filtragem dos Currículos cadastrados no SINE'}> <Icon type={'question-circle'}/> </Popover></Checkbox>
                                            <Checkbox style={{fontSize: 16}} checked={activeFilters.available_leave_home==="true"} onChange={(e) => {
                                                props.filter("available_leave_home", e.target.checked ? true : null);
                                                props.search();
                                            }}>Pode se ausentar do domicílio <Popover content={'Este filtro só realizara a filtragem dos Currículos cadastrados no SINE'}> <Icon type={'question-circle'}/> </Popover></Checkbox>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{marginTop: 15, display: "flex", flexDirection: "column", lineHeight: '25px'}}>
                                <Button style={{boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)'}} icon='search' className='searchButtonSubPage' onClick={() => props.search()}> Buscar </Button>
                            </Col>
                        </Row>
                    </div>
                </Fade>
            </div>
        </>
    );
};

SideBarFilter.propTypes = {};

export default SideBarFilter;
