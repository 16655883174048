import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Icon, Row} from "antd";
import {Images} from "../../../../Helpers/Images";
import {useHistory} from "react-router-dom";
import useVerifyServices from "../../../../Hooks/VerifyServices";
import {FaBriefcase, FaHandshake} from "react-icons/fa";
import {useDispatch} from "react-redux";
import {Popover} from "antd/es";
import {Texts} from "../../../../Helpers/Texts";
import useAuth from "../../../../Hooks/Auth";
import useApi from "../../../../Hooks/Api";
import {BsClockFill} from "react-icons/bs";
import {GoChecklist} from "react-icons/go";
import {RiFileCopy2Fill} from "react-icons/ri";
import manageResumes from "../../../../Img/manageResumes.png";



const ClientPanelComponent = () => {
    const history = useHistory();
    const {hasSineService, hasCraftyService,hasAll,hasOnlySine,  hasClubService, hasOnlyCrafty, hasOnlyClub} = useVerifyServices();


    return (<Row gutter={[24, 24]} style={{margin: 0, display: 'flex', flexWrap: 'wrap'}}>
        {/*<Col span={24} style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 3, paddingTop: 0}}>
                <h1 style={{margin: 0, paddingLeft: 10, fontSize: 25, color: "#757575"}} > Painel de Contratante </h1>
                <Popover placement={"bottom"}  title={'Painel Trabalhador'} content={Texts.popover_worker_panel}> <Button style={{borderRadius: 15}} className={'ant-btn-gray'} onClick={() => history.push('/painel/prestador')}>  Ver painel trabalhador <FaBriefcase className={'exportedIcons'}/> </Button> </Popover>
            </Col>


            <Col xs={24}  md={24} xl={12} >
                <div className={'profileCol borderBottomSecondary'} onClick={() => history.push('/painel/cliente/vagas')}>
                    <Row>
                        <Col span={14}>
                            <h1 style={{color: '#07941a'}}> Gerenciar vagas e currículos </h1>
                            <span> Divulgue vagas disponíveis em sua empresa, gerencie os currículos selecionados e entre em contato com o trabalhador.  </span> <br/> <br/>
                        </Col>
                        <Col span={10}>
                            <img src={Images.manageResumes}/>
                        </Col>
                    </Row>
                </div>
            </Col>*/}

            {hasCraftyService() && <Col xs={24}  md={24} xl={24} >
                <div className={'profileCol borderBottomSubPrimary'} onClick={() => history.push('/painel/cliente/contatos')}>
                    <Row>
                        <Col span={14}>
                            <h1 style={{color: '#1680ce'}}> Ver Lista de Contatos </h1>
                            <span> Veja aqui a lista de contatos dos prestadores de serviço que solicitou, e não se esqueça de avaliar! </span> <br/> <br/>
                        </Col>
                        <Col span={10}>
                            <img src={Images.panel.agenda_card}/>
                        </Col>
                    </Row>
                </div>
            </Col>}

            {hasClubService() && <Col xs={24} md={24} xl={hasOnlyClub()? 24: 12}>
                <Row className={'profileCol borderBottomFourth'} onClick={() => history.push('/painel/cliente/compras')}>
                    <Col span={12}>
                        <h1 style={{color: '#263238'}}> Ver cartões de desconto </h1>
                        <span> Fez comprar utilizando o serviço do crafty club? algum estabelecimento te ofereceu um cupom de desconto? cheque aqui! </span> <br/> <br/>
                    </Col>
                    <Col span={12}>
                        <img src={Images.panel.shop_card}/>
                    </Col>
                </Row>
            </Col>}

            <Col xs={24} md={12} xl={12}  >
                <Row className={'profileCol borderBottomPrimary'} onClick={() => {history.push('/painel/cliente/perfil')}} >
                    <Col span={12}>
                        <h1 className={'primary-color'} style={{fontWeight: 'bold'}}> Visualize seu perfil </h1>
                        <span> Edite as informações do seu perfil. Nos conte um pouco sobre você. </span> <br/> <br/>
                    </Col>
                    <Col span={12}>
                        <img src={Images.panel.profile_card}/>
                    </Col>
                </Row>
            </Col>

            {/*{hasCraftyService() && <Col xs={24} md={12} xl={8} >
                <Row className={'profileCol borderBottomPrimary'} onClick={() => {history.push('/painel/cliente/enderecos')}}>
                    <Col span={12}>
                        <h1 className={'primary-color'} style={{fontWeight: 'bold'}}> Meus endereços </h1>
                        <span> Adicione e organize aqui os endereços aonde deseja que sejam prestados os serviços solicitados por você. </span> <br/> <br/>
                    </Col>
                    <Col span={12}>
                        <img src={Images.panel.address_card}/>
                    </Col>
                </Row>
            </Col>}*/}

            <Col xs={24} md={12} xl={12} >
                <Row className={'profileCol borderBottomPrimary'} onClick={() => {history.push('/painel/cliente/notificacoes')}}>
                    <Col span={12}>
                        <h1 className={'primary-color'} style={{fontWeight: 'bold'}}> Notificações </h1>
                        <span> Manteremos você informado de tudo que for de seu interesse aqui! </span> <br/> <br/>
                    </Col>
                    <Col span={12}>
                        <img  src={Images.panel.notification_card}/>
                    </Col>
                </Row>
            </Col>
        </Row>)
};
export default ClientPanelComponent;
