import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    AutoComplete,
    Button,
    Checkbox,
    Col,
    Form,
    Icon,
    Input, InputNumber,
    Layout,
    message, Modal, Popover,
    Radio,
    Row,
    Select,
    Spin,
    Switch
} from "antd";
import moment from "moment";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";

import {FaCheck, FaForward, FaPlusSquare, FaTimes} from "react-icons/fa";
import useApi from "../../../../../Hooks/Api";
import {useValidationShorts} from "../../../../../Hooks/Helpers";
import useLocalStorage from "../../../../../Hooks/Storage";
import {StorageVariables} from "../../../../../Helpers/StoragesVariables";
import RegisterEnterpriseModal from "../../../../../Pages/RegisterEnterpriseComponent";
import TextArea from "antd/lib/input/TextArea";
import GenericCropImage from "../../../../Generic/GenericCropImage";
import {MdWork} from "react-icons/md";
import * as urlParser from "js-video-url-parser/lib/provider/youtube";
import {Images} from "../../../../../Helpers/Images";
import {debounce} from "lodash";
import {Env} from "../../../../../Helpers/Env";


const schoolingList = ['Analfabeto', 'Fundamental Incompleto', 'Fundamental Completo', 'Médio Incompleto', 'Médio Completo', 'Superior Incompleto', 'Superior Completo', 'Mestrado', 'Doutorado', 'Especialização'];
const languagesList = [{label: 'Alemão', value: 'Alemão'}, {label: 'Espanhol', value: 'Espanhol'}, {label: 'Francês', value: 'Francês'}, {label: 'Inglês', value: 'Inglês'}, {label: 'Italiano', value: 'Italiano'}];
const experience = [{text: "6 Meses", value: '6'}, {text: "1 Ano", value: '12'}, {text: "2 Anos", value: '24'}, {text: "3 Anos", value: '36'}, {text: "4 Anos", value: '48'}, {text: "5 Anos", value: '60'}, {text: "6 Anos", value: '72'}, {text: "7 Anos", value: '84'}, {text: "8 Anos", value: '96'}, {text: "9 Anos", value: '108'}, {text: "10 Anos", value: '120'}];
const incentivesList = ['Vale-transporte', 'Vale-alimentação', 'Plano de saúde', 'Plano Odontológico', 'Descontos na empresa', 'Auxílio-creche', 'Auxílio-educação', 'Planos de previdência privada', 'Viagens'];
const requisitesList = ['Saber lidar com Clientes', 'Conhecimento básico em Informática', 'Trabalhar em Equipe', 'Ser comunicativo', 'Estar disposto a serviços braçais', 'Conhecimento da ferramenta Excell', 'Saber dirigir'];

const workTypeList = ['Tempo integral', 'Meio período',
    'Autônomo', 'Freelance', 'Temporário', 'Estágio', 'Aprendiz'];

const VacancyFormComponent = (props) => {

    const {getFieldDecorator, getFieldsValue,setFieldsValue, validateFields, getFieldsError} = props.form;
    const api = useApi();
    const autocompleteApi = useApi();
    const user = useSelector((state) => state).authReducer;
    const {isValidDate, numMinWithMask} = useValidationShorts();
    const [basicData, setBasicData] = useState({});
    const [loading, setLoading] = useState(false);
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);

    const [showExperience, setShowExperience] = useState(false);
    const [showLanguages, setShowLanguages] = useState(false);
    const [showSchooling, setShowSchooling] = useState(false);
    const [cepLoading, setCepLoading] = useState(false);

    const [vacancyImg, setVacancyImg] = useState(undefined)
    const [autocompleteSelected, setAutoCompleteSelected] = useState(false);
    const [occupationsList, setOccupationsList] = useState();
    const [finishedModal, setFinishedModal] = useState(false);
    const [complianceModal, setComplianceModal] = useState(false);
    const [embedVideo, setEmbedVideo] = useState(undefined);
    const startDate = useRef();
    const [editObj,] = useState(props.vacancy);

    const handleSubmit = async () => {
        validateFields(async (error, values) => {
            if (error) { message.warning('Preencha os campos corretamente'); return 0;}
            if(vacancyImg) {
                values.job_image = {
                    filename: "img.jpg",
                    base64_picture: vacancyImg.slice(23)
                }
            }
            setLoading(true);

            message.loading('Anunciando Vaga...');
            if(!values.pay || values.pay === "") {
                delete values.pay;
            } else if(values.pay !== undefined) {
                values.pay = values.pay.replace(/\./g, "").replace(",", ".").replace('R$',"");
            }
            if(!showLanguages) values.languages = [];
            if(!showSchooling) values.schooling = undefined;

            if(values.schooling && !values.accept_proposal) {
                values.schooling_req = true;
            }
            if(values.disabilities) {
                values.disabilities = ["Alguma"];
                if(!values.accept_proposal) {
                    values.disabilities_opt = 'required';
                }
            } else {
                values.disabilities = [];
            }
            if(values.apply_message === "") delete values.apply_message;

            if(!props.edit) values.start_date = moment().format("YYYY-MM-DD");
            else values.start_date = startDate.current;

            values.job_type = [values.job_type];

            if(values.work_address?.postal_code) {
                values.work_address.postal_code = window.getNumbers(values.work_address.postal_code);
            }
            values.user_company_id = props.enterprise.id;
            values.application = Env.application_alias;

            if(values.job_description?.trim() === '') {
                values.job_description = undefined;
            }

            if(values.job_video) {
                if (urlParser.prototype.parseVideoUrl(values.job_video)) {
                    let auxEmbedVideo = urlParser.prototype.parseVideoUrl(values.job_video);
                    values.job_video = `https://www.youtube.com/embed/${auxEmbedVideo}`;
                } else {
                    message.error('Link do video inválido');
                    setLoading(false);
                    return 0;
                }
            } else {
                values.job_video = undefined;
            }

            if(props.edit) {
                if(values.job_image && (vacancyImg === props.vacancy.job_image)) {
                    delete values.job_image;
                }
                api.put(`sine/user/${user.user_id}/job-opportunity/${editObj.id}`,values, (res) => {
                    message.destroy();
                    message.success('Sucesso!');
                    setFinishedModal(false);
                    setComplianceModal(false);
                    props.changeMenu("sub");
                    setLoading(false);
                }, (e) => {
                    console.log(e);
                    setLoading(false);
                    message.error(e.message || 'Não foi possivel salvar, tente novamente');
                });
            }  else {
                api.post(`sine/user/${user.user_id}/job-opportunity`,values, (res) => {
                    message.destroy();
                    message.success('Sucesso!');
                    setFinishedModal(false);
                    setComplianceModal(false);
                    props.changeMenu("sub");
                    setLoading(false);
                },(e) => {
                    console.log(e);
                    setLoading(false);
                    message.error(e.message || 'Não foi possivel salvar, tente novamente');
                });
            }
        })
    };



    const onCepChange = async (e) => {
        const cep = window.getNumbers(e.target.value);
        if (cep?.length === 8) {
            setCepLoading(true);

            api.get(`search/postal-code?cep=${cep}`, (res) => {
                if(res) {
                    setFieldsValue({work_address: { district: res.district, street: res.street_name, city: res.city, state_abbrev: res.state}});
                }
                setCepLoading(false);
            },(e) => {
                message.warning('CEP inválido');
                setFieldsValue({work_address: {postal_code: undefined}});
                console.log(e);
                setCepLoading(false);
            });
        }
    }

    const autoCompleteSearch = useCallback(debounce(async (query) => {
        autocompleteApi.setLoading(true);
        let searchQuery = `?query=${query}&limit=25`;

        if (!autocompleteApi.loading) {
            autocompleteApi.get(`sine/cbo-occupations/autocomplete${searchQuery}`, (res) => {
                setOccupationsList(res);
            }, (e) => {
                console.log(e);
            });
        }
    }, 700), []);

    const handleSelect = (value, opt) => {
        setFieldsValue({occupation_id : opt.props.label});
        setAutoCompleteSelected(true);
    };

    const handleFinishModal = () => {
        validateFields(async (error, values) => {
            if (error) { message.warning('Preencha os campos corretamente'); return 0;}
            setFinishedModal(true)
        })
    };

    const clearAutocomplete = () => {
        setFieldsValue({occupation_id: undefined});
        setFieldsValue({occupation_name: undefined});
        setAutoCompleteSelected(false);
    };

    const removeZeros = () => {
        let aux = getFieldsValue().pay;
        if(aux) {
            let aux2 = aux.split(',');
            setFieldsValue({pay: aux2[0].concat(',00')});
        }
    }

    useEffect(() => {
        if(getFieldsValue().job_video) {
            if (urlParser.prototype.parseVideoUrl(getFieldsValue().job_video)) {
                let auxEmbedVideo = urlParser.prototype.parseVideoUrl(getFieldsValue().job_video);
                setEmbedVideo(`https://www.youtube.com/embed/${auxEmbedVideo}`);
            } else {
                setEmbedVideo(undefined);
            }
        }
    },[getFieldsValue().job_video]);

    useEffect(() => {
        if(props.edit) {
            startDate.current = editObj.start_date;
            let aux = editObj;
            if (aux.languages.length > 0) {
                setShowLanguages(true)
            }
            if(aux.schooling) {
                setShowSchooling(true)
            }
            if(aux.pay) {
                aux.pay = aux.pay.toString();
            }

            if(aux.disabilities.length > 0) {
                aux.disabilities = true;
            }

            if(aux.job_image) {
                setVacancyImg(aux.job_image)
            }

            aux.job_type = aux.job_type[0];

            setFieldsValue(aux);
        }
        window.scrollToElement('body');
    },[]);

    return (<div style={{minHeight: 500, backgroundColor: 'white', borderRadius: 4, boxShadow: "0 2px 8px rgb(0 0 0 / 8%)"}} >

        <Modal
            visible={finishedModal}
            footer={null}
            bodyStyle={{padding: 0}}
            width={'80%'}
            onCancel={() => {
                window.gtag&& window.gtag('event', 'cancelVacancyClick');
                setFinishedModal(false)}
            }
            className={'registerModalWidth'}
            destroyOnClose={true}
            style={{maxWidth: '800px'}}
        >
            <div style={{padding: 30}}>
                <div>
                    <div style={{textAlign: 'center', fontSize: 20}}>
                        <span style={{color: '#424242', fontSize: 20}}> Voce está prestes a {props.edit? 'editar' : 'divulgar'} <strong> {getFieldsValue().job_openings} </strong> vaga(s) para o cargo de - <strong> {getFieldsValue().occupation_name} </strong> </span>
                        <br/>
                        <span style={{color: '#424242', fontSize: 16}}> Revise os dados antes de {props.edit? 'editar' : 'publicar'}, essas informações serão disponibilizadas no APP do {Env.application_title}.  </span>
                        <br/>
                        <span style={{color: '#424242', fontSize: 16}}> Os candidatos que se aplicarem a sua vaga estarão disponíveis aqui, na área de candidatos.  </span>

                    </div>
                    <div style={{textAlign: 'center'}}>
                        <img style={{height: '100%', maxHeight: 320}} src={Images.vacancy_img}/>
                    </div>
                    <div style={{padding: 20, display: "flex", justifyContent: 'center', flexWrap: 'wrap'}}>
                        <Button style={{marginRight: 20}} onClick={() =>{
                            window.gtag&& window.gtag('event', 'cancelVacancyClick');
                            setFinishedModal(false)
                        }}> Cancelar </Button>
                        <Button loading={loading} onClick={() => {props.edit ? handleSubmit() : setComplianceModal(true)}}  className={'ant-btn-secondary'}> {props.edit? 'Editar Vaga' : 'Continuar'}</Button>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal
            visible={complianceModal}
            footer={null}
            bodyStyle={{padding: 0}}
            width={'80%'}
            onCancel={() => {setComplianceModal(false)}}
            className={'registerModalWidth'}
            destroyOnClose={true}
            style={{maxWidth: '800px'}}
        >
            <div style={{padding: 30}}>
                <div>
                    <div style={{textAlign: 'center', fontSize: 20}}>
                        <span style={{color: '#424242', fontSize: 20}}> Ao divulgar uma vaga você se <strong> compromete </strong> a nos informar se algum candidato foi contratado através de nossa plataforma. </span>
                        <br/>
                        <h4 style={{color: 'grey', fontSize: 15}}> É muito importante que este retorno seja nos dado! Isso aumenta a credibilidade de sua empresa na plataforma e ajuda no fomento à empregabilidade do estado do {Env.state_full}. </h4>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <img style={{height: '100%', maxHeight: 320}} src={Images.enterprise_compliance}/>
                    </div>
                    <div>
                        <span style={{fontSize: 11, color: 'grey'}}>
                            *Os Retornos fornecidos somente são utilizados para estatísticas de empregabilidade, não vinculando a empresa ou empregador a nenhum outro banco de dados público ou privado.
                        </span>
                    </div>
                    <div style={{padding: 20, display: "flex", justifyContent: 'center', flexWrap: 'wrap'}}>
                        <Button style={{marginRight: 20}} onClick={() =>{setComplianceModal(false)}}> Cancelar </Button>
                        <Button loading={loading} onClick={() => handleSubmit()} className={'ant-btn-primary'}> Divulgar vaga </Button>
                    </div>
                </div>
            </div>
        </Modal>

            <div style={{display: "flex" , justifyContent: 'space-between', padding: 10, borderBottom: "1px solid gainsboro", alignItems: "center"}}>
                <div style={{display: "flex",alignItems: "center"}}>
                    <FaPlusSquare style={{marginRight: 8, fontSize: 18, marginTop: -4}} className={"secondary-color"}/>
                    {!props.edit ? <span style={{fontSize: 18}}> Formulário de nova divulgação</span> : <span style={{fontSize: 18}}> Editando divulgação</span> }
                </div>
                <FaTimes style={{cursor: 'pointer'}} onClick={() => {props.hideEdit()}}/>
            </div>
            <Row gutter={[24,24]} style={{ margin: 0, padding: 15,}}>
                <Col xs={24} md={12} className={'formItem'}>
                    <div className='resumeLabel'><label><span>*</span> Cargo da vaga </label> </div>
                    {getFieldDecorator(`occupation_name`,
                        {
                            rules: [{ required: true, message: 'Ocupação é obrigatório'}],
                            initialValue: "",
                        }
                    )(
                        <AutoComplete
                            disabled={props.edit}
                            size="large"
                            onSelect={(value, option) => handleSelect(value, option)}
                            style={{ width: '100%', display: autocompleteSelected&& 'none'}}
                            dataSource={occupationsList?.map(item => {
                                return (<AutoComplete.Option key={item.name} label={item.occupation_id} value={item.name}>
                                        {item.name}
                                    </AutoComplete.Option>
                                )
                            })}
                            onSearch={(query) => autoCompleteSearch(query)}
                            optionLabelProp="text"
                        >
                            <Input suffix={autocompleteApi.loading ? <Icon type="loading"/> : <MdWork/> } size={'large'} placeholder={'Selecione a ocupação/cargo da vaga'} />
                        </AutoComplete>)}

                    <div className={'ant-input ant-input-lg'} style={{ width: '100%', display: !autocompleteSelected? 'none' : 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        {getFieldsValue().occupation_name}
                        <Icon className={'hoverIcon'} onClick={() => clearAutocomplete()} type={'close'}/>
                    </div>
                    <div style={{minHeight: 15, marginTop: 5, position: 'absolute'}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().occupation_id} </span> </div>

                    {getFieldDecorator(`occupation_id`,
                        {
                            initialValue: '',
                            rules: [{ required: true, message: 'Escolha um cargo na busca'}],
                        }
                    )(
                        <Input type={'hidden'}/>
                    )}
                </Col>

                <Col xs={24} lg={12} className={'formItem'}>
                    <div className='resumeLabel'> <label> Título da vaga a ser divulgado <Popover content={'Caso ache necessário, informe um título para a vaga, caso contrario será exibido o nome do cargo.'}> <Icon  style={{color: "gray"}}  type='question-circle'/> </Popover> </label> </div>
                    {getFieldDecorator('occupation_title',
                        {
                            rules: [{
                                required: false,
                                message: 'Nome é obrigatório'
                            }],
                            initialValue: undefined,
                        }
                    )(
                        <Input placeholder={'Título alternativo'} size={'large'}/>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().occupation_title} </span> </div>
                </Col>
                <Col xs={24} lg={8}  className={'formItem'}>
                    <div className='resumeLabel'><label> <span>*</span> Tipo de trabalho </label> </div>
                    {getFieldDecorator(`job_type`,
                        {
                            rules: [{required: true, message: 'Tipo de trabalho é obrigatório'}],
                            initialValue: undefined
                        }
                    )(
                        <Select
                            placeholder="Selecione o tipo de trabalho"
                            size={'large'}
                        >
                            {workTypeList.map((item, index) =>
                                <Select.Option key={index} value={item}>{item}</Select.Option>
                            )}
                        </Select>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().job_type} </span> </div>
                </Col>

                <Col xs={24} lg={8} className={'formItem'}>
                    <div className='resumeLabel'> <label> Número de vagas <Popover content={'Ao aprovar candidatos equivalente a quantidade de vagas, a divulgação será marcada como finalizada.'}> <Icon  style={{color: "gray"}}  type='question-circle'/> </Popover></label> </div>
                    {getFieldDecorator('job_openings',
                        {
                            rules: [{
                                required: false,
                                message: 'Nome é obrigatório'
                            }],
                            initialValue: 1,
                        }
                    )(
                        <InputNumber placeholder={'Quantidade de vagas em aberto'} style={{width: "100%"}} size={'large'} min={1}/>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().job_openings} </span> </div>
                </Col>

                <Col xs={24} lg={8} className={'formItem'}>
                    <div className='resumeLabel'> <label> Salário </label> </div>
                    {getFieldDecorator('pay',
                        {
                            rules: [],
                            initialValue: undefined,
                        }
                    )(
                        <NumberFormat onBlur={() => removeZeros()} maxLength={14} decimalScale={2} fixedDecimalScale={true} className='ant-input ant-input-lg' thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} style={{textAlign: "right"}} placeholder={'Salario da vaga'} size={'large'}/>
                    )}
                </Col>

                <Col xs={24} className={'formItem'}>
                    <div className='resumeLabel'> <label> Horário de Trabalho </label> </div>
                    {getFieldDecorator('schedule',
                        {
                            rules: [{
                                required: false,
                                message: 'Nome é obrigatório'
                            }],
                            initialValue: undefined,
                        }
                    )(
                        <TextArea style={{minHeight: 50}} placeholder={'Informe caso necessário os horários de trabalho, Ex: Das 07:00 às 18:00 de segunda a sexta.'} size={'large'}/>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().schedule} </span> </div>
                </Col>

                <Col xs={24} className={'formItem'}>
                    <div className='resumeLabel'> <label> Benefícios </label> </div>
                    {getFieldDecorator('incentives',
                        {
                            rules: [{
                                required: false,
                                message: 'Nome é obrigatório'
                            }],
                            initialValue: undefined,
                        }
                    )(
                        <Select
                        mode="tags"
                        notFoundContent={"Digite para inserir"}
                        size={'large'}
                        tokenSeparators={[',']}
                        className={"tagsInput"}
                        placeholder="Incentivos da vaga, Ex. 'Plano de saúde', 'Vale transporte', e etc..."
                        style={{ width: '100%' }}
                        >
                            {incentivesList.map((item) => <Select.Option key={item}>{item}</Select.Option>)}
                        </Select>
                    )}
                    <div style={{textAlign: 'right'}}> <span style={{color: '#ccddea', fontSize: 12}}>Se há um beneficio nao inserido na listagem, digite manualmente para inserir</span> </div>
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().incentives} </span> </div>
                </Col>


                <Col xs={24} className={'formItem'}>
                    <div className='resumeLabel'> <label> Descrição da Vaga </label> </div>
                    {getFieldDecorator('job_description',
                        {
                            rules: [{
                                required: false,
                                message: 'Nome é obrigatório'
                            }],
                            initialValue: undefined,
                        }
                    )(
                        <TextArea style={{minHeight: 200}} placeholder={'Descreva aqui sobre a vaga e o serviço que será realizado'} size={'large'}/>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().job_description} </span> </div>
                </Col>


                <Col xs={24} lg={24} style={{textAlign: 'center', display: "flex", flexFlow: "column", justifyContent: "center"}}>
                    <span style={{fontSize: 16, fontWeight: "bold", marginBottom: 10}}> Imagem destaque da vaga <Popover content={'A imagem de destaque aparecerá nos detalhes da vaga (formato banner)'}> <Icon  style={{color: "gray"}}  type='question-circle'/> </Popover> </span>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={Images.img_upload} style={{maxWidth: 200}}/>
                            <div style={{maxWidth: 200, textAlign: 'left'}}>
                                <h3>Insira uma imagem destaque</h3>
                                <span> Adicione uma imagem para ser exibida junto a sua publicação de vaga.</span>
                            </div>
                        </div>
                        <div style={{flex: 1, justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                            <GenericCropImage aspectRatio={2} banner={true} googleFaceImage={vacancyImg} image={vacancyImg}
                                              setCropedImg={(item) => setVacancyImg(item)} height={300} width={600}/>
                        </div>
                    </div>
                </Col>


                <Col xs={24} className={'formItem'}>
                    <div className='resumeLabel'> <label> Link de video <Popover content={'O vídeo aparecerá nos detalhes da vaga. (Link do youtube)'}> <Icon  style={{color: "gray"}}  type='question-circle'/> </Popover> </label> </div>
                    {getFieldDecorator('job_video',
                        {
                            rules: [{
                                required: false,
                                message: 'Nome é obrigatório'
                            }],
                            initialValue: undefined,
                        }
                    )(
                        <Input placeholder={'Cole aqui o link do video (Ex. https://youtube.com.br/812yu9ds8aid)'} size={'large'}/>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().job_video} </span> </div>
                </Col>

                <Col xs={24} className={'formItem'}>
                    {embedVideo && <div className='iframeDiv'>
                        <iframe src={embedVideo}
                                allow='autoplay; encrypted-media'
                                allowFullScreen
                                title='video'
                        />
                    </div>}
                </Col>

                <Col xs={24} className={'formItem'}>
                    <div className='resumeLabel'> <label> Mensagem aos candidatos (Opcional) </label> </div>
                    {getFieldDecorator('apply_message',
                        {
                            rules: [{
                                required: false,
                                message: 'Nome é obrigatório'
                            }],
                            initialValue: undefined,
                        }
                    )(
                        <TextArea style={{minHeight: 50}} placeholder={'Informe aqui caso deseja informar alguma informação adicional para os candidatos de sua vaga'} size={'large'}/>
                    )}

                </Col>

                <Col xs={24} style={{display: "flex", alignItems: "center"}}>
                    <Icon type={"user"} style={{fontSize: 18, marginRight: 5}}  className={"primary-color"}/>
                    <span style={{fontSize: 18, marginRight: 5}}> Requisitos ao candidato <Popover content={'Informe requisitos que deseja dos candidatos para preencher a vaga, você pode optar por permitir a inscrição de candidatos que não se aplicam ao seus requisitos.'}> <Icon  style={{color: "gray"}}  type='question-circle'/> </Popover> </span>
                    <div style={{height: 1, flex: 1, backgroundColor: "gainsboro"}}/>
                </Col>


                <Col xs={24} className={'formItem'}>
                    <div className='resumeLabel'> <label>  Habilidades ou Requisitos desejados </label> </div>
                    {getFieldDecorator('desired_skills',
                        {
                            rules: [{
                                required: false,
                                message: 'Nome é obrigatório'
                            }],
                            initialValue: undefined,
                        }
                    )(
                        <Select
                            mode="tags"
                            notFoundContent={"Digite para inserir"}
                            size={'large'}
                            tokenSeparators={[',']}
                            className={"tagsInput"}
                            placeholder="Habilidades desejadas para o cargo"
                            style={{ width: '100%' }}
                        >
                            {requisitesList.map((item) => <Select.Option key={item}>
                                {item}
                            </Select.Option>)}
                        </Select>
                    )}
                    <div style={{textAlign: 'right'}}> <span style={{color: '#ccddea', fontSize: 12}}>Se há uma habilidade ou requisto nao inserido na listagem, digite manualmente para inserir</span> </div>
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().desired_skills} </span> </div>
                </Col>

                <Col xs={24} style={{padding: 5}}>
                    <Row style={{borderBottom: '1px solid gainsboro', margin: 0}} gutter={[16,16]}>
                        <Col xs={24} lg={12} className={'formItem'} >
                            <div className='resumeLabel' style={{marginBottom: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Deve possuir escolaridade mínima? </label>
                                <div>
                                    <Radio.Group className={'radio'} name="languageRadio" value={showSchooling}>
                                        <Radio value={true} onChange={() => {setShowSchooling(true)}}>
                                            Sim
                                        </Radio>
                                        <Radio value={false} onChange={() => {setShowSchooling(false)}}>
                                            Não
                                        </Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            {getFieldDecorator('schooling',
                                {
                                    rules: [],
                                    initialValue: undefined,
                                }
                            )(
                                <Radio.Group
                                    className={"radioGroupCustom"}
                                    style={{display: !showSchooling&& "none"}}
                                    options={schoolingList}
                                />
                            )}
                        </Col>

                        <Col xs={24} lg={12} className={'formItem'}>
                            <div className='resumeLabel' style={{marginBottom: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Deve falar outro idioma? </label>
                                <div>
                                    <Radio.Group className={'radio'} name="languageRadio" value={showLanguages}>
                                        <Radio value={true} onChange={() => {setShowLanguages(true)}}>
                                            Sim
                                        </Radio>
                                        <Radio value={false} onChange={() => {setShowLanguages(false)}}>
                                            Não
                                        </Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            {getFieldDecorator('languages',
                                {
                                    rules: [],
                                    initialValue: undefined,
                                }
                            )(
                                <Checkbox.Group
                                    style={{display: !showLanguages&& "none",}}
                                    options={languagesList}
                                />
                            )}
                        </Col>
                    </Row>


                    <Row style={{borderBottom: '1px solid gainsboro', margin: 0}} gutter={[16,16]}>
                        <Col xs={24} lg={12} className={'formItem'} >
                            <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Deve possuir experiência na ocupação? </label>
                                <div>
                                    {getFieldDecorator('occupation_cbo_req',
                                        {
                                            rules: [],
                                            initialValue: false,
                                        }
                                    )(
                                        <Radio.Group className={'radio'} name="languageRadio">
                                            <Radio value={true}>
                                                Sim
                                            </Radio>
                                            <Radio value={false}>
                                                Não
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} lg={12} className={'formItem'} >
                            <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> As vagas são destinadas somente a PcD? </label>
                                <div>
                                    {getFieldDecorator('disabilities',
                                        {
                                            rules: [],
                                            initialValue: false,
                                        }
                                    )(
                                        <Radio.Group className={'radio'} name="languageRadio">
                                            <Radio value={true}>
                                                Sim
                                            </Radio>
                                            <Radio value={false}>
                                                Não
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Col>

                <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Candidato deve ter disponibilidade para realizar viagens? </label>
                        <div>
                            {getFieldDecorator('available_travel',
                                {
                                    rules: [],
                                    initialValue: false,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio" >
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                </Col>

                <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Candidato deve ter disponibilidade para dormir no local de trabalho? </label>
                        <div>
                            {getFieldDecorator('available_sleep_duty',
                                {
                                    rules: [],
                                    initialValue: false,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio" >
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                </Col>

                <Col xs={24} className={'formItem'} >
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Candidato deve ter disponibilidade para ausentar-se de seu domicílio por longos períodos? </label>
                        <div>
                            {getFieldDecorator('available_leave_home',
                                {
                                    rules: [],
                                    initialValue: false,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio" >
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                </Col>

                <Col xs={24} style={{borderRadius: 5, border: "1px solid gainsboro", }}>
                    {getFieldDecorator('accept_proposal',
                        {
                            rules: [],
                            initialValue: false,
                            valuePropName: "checked"
                        }
                    )(
                        <Checkbox style={{fontSize: 13}}>Permitir candidatos que não se encaixam nos requisitos a se candidatar à vaga mesmo assim</Checkbox>
                    )}
                </Col>

                <Col xs={24} style={{display: "flex", alignItems: "center", marginTop: 30}}>
                    <Icon type={"environment"} style={{fontSize: 18, marginRight: 5}}  className={"primary-color"}/>
                    <span style={{fontSize: 18, marginRight: 5}}> Endereço da vaga </span>
                    <div style={{height: 1, flex: 1, backgroundColor: "gainsboro"}}/>
                </Col>


                <Col xs={24}  className={'formItem'}>
                    <div className='resumeLabel'> <label> <span> * </span> Selecione o local da vaga </label> </div>
                    {getFieldDecorator('work_place',
                        {
                            rules: [{
                                required: true,
                                message: 'Local é obrigatório'
                            }],
                            initialValue: undefined
                        }
                    )(
                        <Select
                            showSearch
                            placeholder="Selecione o local"
                            size={'large'}
                        >
                            <Select.Option value={'same'}> Mesmo endereço da empresa previamente cadastrado </Select.Option>
                            <Select.Option value={'other'}> Outro endereço </Select.Option>
                            <Select.Option value={'mobile'}> Trabalho remoto / Home Office </Select.Option>
                            <Select.Option value={'itinerant'}> Sem endereço fixo (Ex. Porta a porta, itinerante etc) </Select.Option>
                        </Select>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_place} </span> </div>
                </Col>

                {getFieldsValue().work_place === "other" &&
                    <>
                        <Col xs={24} lg={24} className={'formItem'}>
                            <div className='resumeLabel'> <label><span> * </span> CEP </label> </div>
                            {getFieldDecorator('work_address.postal_code', {
                                rules: [
                                    numMinWithMask(),
                                    {
                                        required:  true,
                                        message: 'CEP Obrigatório',
                                    },
                                ],
                                initialValue: props.vacancy?.work_address?.postal_code || undefined,
                            })(
                                <NumberFormat type={"tel"} disabled={cepLoading} onChange={(e)=> onCepChange(e)} style={{width: '100%', textAlign: 'center'}} placeholder='Digite seu CEP para buscar' className='ant-input ant-input-lg' format={'## ###-###'} mask="_"/>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_address?.postal_code} </span> </div>


                        </Col>
                        <Col xs={24} lg={12} className={'formItem'}>
                            <div className='resumeLabel'> <label> <span> * </span> Cidade </label> </div>
                            {getFieldDecorator('work_address.city',
                                {
                                    rules: [{
                                        required: true,
                                        message: 'Cidade é obrigatório'
                                    }],
                                    initialValue:  props.vacancy?.work_address?.city || undefined,
                                }
                            )(
                                <Input prefix={cepLoading&& <Icon type="loading"/>} disabled={true} placeholder={'Sua cidade'} size={'large'}/>
                            )}
                        </Col>
                        <Col xs={24} lg={12} className={'formItem'}>
                            <div className='resumeLabel'> <label> <span> * </span> Estado </label> </div>
                            {getFieldDecorator('work_address.state_abbrev',
                                {
                                    rules: [{
                                        required: true,
                                        message: 'Sigla Estado é obrigatório'
                                    }],
                                    initialValue:  props.vacancy?.work_address?.state_abbrev || undefined,
                                }
                            )(
                                <Input prefix={cepLoading&& <Icon type="loading"/>} disabled={true} placeholder={'Informe seu estado'} size={'large'}/>
                            )}
                        </Col>

                        <Col xs={24} lg={12} className={'formItem'}>
                            <div className='resumeLabel'> <label> <span> * </span> Rua </label> </div>
                            {getFieldDecorator('work_address.street',
                                {
                                    rules: [{
                                        required: true,
                                        message: 'Rua é obrigatório'
                                    }],
                                    initialValue:  props.vacancy?.work_address?.street || undefined,
                                }
                            )(
                                <Input prefix={cepLoading&& <Icon type="loading"/>} placeholder={'Sua rua'} size={'large'}/>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_address?.street} </span> </div>

                        </Col>
                        <Col xs={24} lg={8} className={'formItem'}>
                            <div className='resumeLabel'> <label> Bairro </label> </div>
                            {getFieldDecorator('work_address.district',
                                {
                                    rules: [],
                                    initialValue:  props.vacancy?.work_address?.district || undefined,
                                }
                            )(
                                <Input prefix={cepLoading&& <Icon type="loading"/>} placeholder={'Seu bairro'} size={'large'}/>
                            )}
                        </Col>
                        <Col xs={24} lg={4} className={'formItem'}>
                            <div className='resumeLabel'>   <label>  <span> * </span> Número </label> </div>
                            {getFieldDecorator('work_address.street_number',
                                {
                                    rules: [{
                                        required: true,
                                        message: 'Número é obrigatório'
                                    }],
                                    initialValue:  props.vacancy?.work_address?.street_number || undefined,
                                }
                            )(
                                <Input type={'number'} placeholder={'Número da residência'} size={'large'}/>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_address?.street_number} </span> </div>

                        </Col>
                    </>
                }
            </Row>

            <Row style={{ padding: 20, textAlign: 'center'}}>
                <Button shape={'round'} loading={loading} disabled={loading} onClick={() => handleFinishModal()} className={'ant-btn-secondary'}> {props.edit ? 'Editar Vaga' : 'Divulgar Vaga'} </Button>
            </Row>
    </div>)
};
export default Form.create({name: 'vacancyForm'})(VacancyFormComponent);

