export const Env = {
    debug: process.env.NODE_ENV === 'production' ? false : true,
    ambience: process.env.NODE_ENV  === 'production' ? 'prod' : 'dev',
    api_url: process.env.NODE_ENV  === 'production'? 'https://prod-api.crafty.work' : 'https://dev-api.crafty.work',
    api_club_url: process.env.NODE_ENV === 'production' ? 'https://api-club.crafty.work' : 'https://apidev-club.crafty.work',
    api_bluetrix : 'https://api-white-label.bluetrix.com.br',
    header: {
        'X-Client-Device': 'web',
        Accept: 'application/json',
        'X-Version-Name': '2.0.0',
        'Content-Type': 'application/json',
        // 'X-App-Package': 'com.crafty.contrata-mais.web'
    },
    header_club: {
        Accept: 'application/json',
        'Content-Type': "application/json",
        "X-Requested-With": "XMLHttpRequest"
    },

    services_available: ['crafty'], // 'crafty', 'sine', 'club'
    phone_validation: false,
    version: '1.0.0',
    application_title: 'Crafty',
    application_name: 'com.crafty.web',
    application_alias: 'crafty',
    state_restriction: 'none',
    state_full: null,
    support_number: '',
    support_email: 'contact@crafty.work',
    appointment_id: '11',

    worker_play: 'https://play.google.com/store/apps/details?id=com.crafty.worker',
    worker_apple: 'https://apps.apple.com/us/app/crafty-pro/id1436058356',
    client_play: 'https://play.google.com/store/apps/details?id=com.crafty',
    client_apple: 'https://apps.apple.com/us/app/crafty/id1320101218',
    number: null,

    biz_apple_store: 'https://apps.apple.com/br/app/crafty-biz-para-lojistas/id1444677721',
    biz_play_store: 'https://play.google.com/store/apps/details?id=br.com.crafty.biz',
    instagram: '',
    facebook: '',
};
