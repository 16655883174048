import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Button, Card, Col, Icon, message, Modal, Row, Statistic} from "antd";

import moment from "moment";
import {Slide} from "react-reveal";
import {Link} from "react-router-dom";
import useApi from "../../../../Hooks/Api";
import Loading from "../../../Generic/Loading";
import {Images} from "../../../../Helpers/Images";
import {useHistory} from 'react-router-dom'

const CardsPointsPage = props => {
    const api = useApi();
    const [cards, setCards] = useState([]);
    const [loading,setLoading] = useState(true);
    const [loadingCard, setLoadingCard] = useState(false);
    const paginate = useRef({page: 0});
    const [viewCard,setViewCard] = useState(false);
    const [tutorial, setTutorial] = useState(false);
    const indexLoading = useRef();
    const history = useHistory();

    const getTutorial = async () => {
        try {
            const res = await api.get(`crafty-club/cards-tutorial`);
            setTutorial(res.description);
        } catch (e) {

        }
    }
    const getData = async () => {
        setLoading(true);
        try {
            const res = await api.get(`crafty-club/loyalty-cards?page=${paginate.current.page + 1}`);
            paginate.current = {nextPage: res.next_page, page: res.page};
            let auxCards = cards.concat(res.loyalty_cards);
            setCards(auxCards);
            setLoading(false);
        } catch {
            message.error('Ops, não foi possível recuperar cupoms de desconto, tente novamente')
            setLoading(false);
        }
    }

    const getCard = async (id,index) => {
        try {
            indexLoading.current = index;
            setLoadingCard(true);
            const res = await api.get(`crafty-club/loyalty-card/${id}`);
            setViewCard(res);
            setLoadingCard(false);
        } catch {
            message.error('Ops, não foi possível recuperar os dados do seu cupom, tente novamente')
            setLoadingCard(false);
        }
    }

    useEffect(() => {
        getData();
        getTutorial();
        window.scrollToElement('body');
    },[]);


    return (
        <Row gutter={[22,22]} className={'mainComponentPanel'}>
            <Row gutter={[24, 0]}>
                <Col xs={0} sm={0} md={8} lg={5} className={'sideBarPanelCards'}>
                    <div className={'back-side-button'} onClick={() => history.push('/painel')}>
                        <Icon className={'craftyIcon'} type={'arrow-left'}/>
                        <span> Voltar </span>
                    </div>


                    <div className={'divCardDefault selected-primary'} onClick={() => history.push('/painel/cliente/cartoes')}>
                        <Icon className={'craftyIcon'} type={'credit-card'}/>
                        <span> Cartões </span>
                        <div className={'arrowCard'}/>
                    </div>
                    <div className={'divCard primary-sub-color'} onClick={() => history.push('/painel/cliente/compras')}>
                        <Icon className={'craftyIcon'} type={'shopping'}/>
                        <span> Minhas compras  </span>
                        <div className={'roundCardThing primary-sub-color-bg'}/>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={16} lg={19}>
                    <Row style={{fontSize: 20, color: 'rgb(82, 196, 26)', paddingLeft: 5, marginBottom: 15}}>
                        <Icon type={'credit-card'}/>
                        <span > Seus cartões fidelidade </span> <br/>
                        <span style={{fontSize: 14, color: 'gray'}}> Cartões de desconto recebido por lojas parceiras </span>
                    </Row>
                    {loading?
                        <div style={{margin: "auto"}}> <Loading/> </div>
                        :
                        <Row gutter={[12,12]} style={{display: 'flex', flexWrap: 'wrap'}}>
                            {cards.map((item,index) =>
                                <Col span={8} key={index} style={{position: 'relative'}}>
                                    <div
                                        style={{ marginTop: 16 }}
                                        className={'cardBodyLoyaltyCard'}
                                        onClick={() => getCard(item.id, index)}
                                    >
                                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'space-between', height: '100%'}}>
                                            <div style={{display: 'flex'}}>
                                                <Avatar style={{height: 50, width: 50, marginRight: 10}} src={item.company_picture.thumbnail || '/img/craftyClub.png'}/>
                                                <div>
                                                    <div> <span style={{fontSize: 18, fontWeight: 'bold', color: "#52c41a"}}> {item.card_name}</span></div>
                                                    <span style={{fontSize: 13, color: '#9E9E9E'}}> {item.company_name} </span>
                                                </div>
                                            </div>

                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <Statistic
                                                    valueStyle={{color: '#2a8db7'}}
                                                    style={{textAlign: 'center'}}
                                                    prefix={<Icon type={'check'} style={{color: '#2a8db7'}}/>}
                                                    value={item.stamps_owned}
                                                    suffix={<span style={{color: '#52c41a'}}> / {item.stamps_target} </span>} />

                                                <Button style={{marginTop: 'auto', fontSize: 17}} loading={loadingCard && indexLoading.current === index} disabled={loadingCard} shape={'round'}> Ver </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}
                            {paginate.current.nextPage&& <div style={{width: '100%', marginTop: 25, paddingBottom: 10}}>  <div className='loadMore2' onClick={()=> getData()}> <Icon type={'reload'}/> Carregar Mais </div> </div>}

                            {cards.length === 0 ?
                                <div className={'empty-card empty-card-green'}>
                                    <div style={{display: 'flex'}}>
                                        <img src={'/img/man.svg'} height={60}/>
                                        <div style={{marginLeft: '20px', color: '#545a5d'}}>
                                   <span> <span style={{fontWeight: 'bold'}}> Você não possui nenhum cartão fidelidade! </span> <br/> Faça compras nos <Link to={'/estabelecimentos'}> estabelecimentos parceiros </Link> para acumular!
                                </span>
                                        </div>

                                    </div>
                                    <div style={{
                                        fontSize: 17,
                                        marginTop: 40,
                                        borderRadius: 5,
                                        color: 'green'
                                    }}><Icon type={'notification'}/> {tutorial} </div>
                                </div> :

                                <Slide bottom>
                                    <div style={{
                                        fontSize: 17,
                                        padding: 15,
                                        marginTop: 40,
                                        marginLeft: 20,
                                        marginRight: 20,
                                        backgroundColor: 'white',
                                        borderRadius: 5,
                                        boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)'
                                    }}><Icon type={'notification'}/> {tutorial} </div>
                                </Slide>
                            }
                        </Row>
                    }
                </Col>
            </Row>

            <Modal className={'modalStamps'} visible={viewCard} title={
                <div>
                    <strong style={{fontSize: 20}}> {viewCard.name} </strong>
                    <br/>
                    <div style={{display: 'flex', justifyContent: "space-between"}}>
                        <div style={{alignSelf: 'center'}}>
                            {viewCard.description}
                        </div>
                        <Statistic
                            valueStyle={{color: '#2a8db7'}}
                            style={{width: 'fit-content'}}
                            prefix={<Icon type={'check'} style={{color: '#2a8db7'}}/>}
                            value={viewCard.stamps_owned}
                            suffix={<span style={{color: '#52c41a'}}> / {viewCard.stamps_target} </span>} />
                    </div>
                </div>} width={'fit-content'} footer={null} onCancel={() => setViewCard(false)}>
                <Card bodyStyle={{padding: 0}} style={{ width: 690, border: 0}} >
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {viewCard.stamps?.map((item, index) =>
                            <div key={index} style={{fontSize: 13, margin: 10, borderRadius: 3, position: "relative", justifyContent: 'space-around', display: 'flex', width: 210, boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)', padding: 15}}>
                                <img src={Images.discount.stamp} style={{height: 50, position: "absolute", right: 5, opacity: 0.2}}/>
                                <Statistic valueStyle={{fontSize: 13, fontWeight: 'bold'}}  title={<span style={{color: 'rgb(82, 196, 26)', fontWeight: 'bold'}}> Criada em </span>}  value={moment.utc(item.created_at).local().format('DD/MM/YYYY')}/>
                                <Statistic valueStyle={{fontSize: 13, fontWeight: 'bold'}}  title={<span style={{color: 'red', fontWeight: 'bold'}}> Expira em </span>} value={moment.utc(item.expires_at).local().format('DD/MM/YYYY')}/>
                            </div>
                        )}
                    </div>
                </Card>
            </Modal>
        </Row>
    );
};

CardsPointsPage.propTypes = {};

export default CardsPointsPage;
