import React, {useEffect} from 'react';
import {Button, Col, Divider, Icon, Row} from "antd";
import {Fade} from "react-reveal";
import {useHistory} from "react-router-dom";
import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";

const HowToRecruit = (props) => {

    const history = useHistory();


    useEffect(() => {
        window.scrollToElement('body');
    },[]);

    return (<div>

        <Row style={{minHeight: '400px', marginTop: 60, fontSize: 15}}>
            {/*<div style={{width: '100%', position: 'absolute', height: '100%', backgroundSize: 'contain', zIndex: 10, backgroundImage: "url('/img/squareBg.png')"}}/>*/}

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <Col sm={20} lg={20} xl={19} xxl={16} className='headerHowTo' style={{padding: 5, textAlign: 'center'}}>
                    <img style={{filter: 'brightness(0.2)', width: 70, marginBottom: 30}} src={Images.home.resume_card_icon}/>

                    <h1 style={{position: 'relative'}} className={'title title-line'}> {Texts.howItWorksSine.mainTitle} </h1>
                </Col>
            </Row>


            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBanner'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 reverseBg2'}>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}>
                            <div>
                                <h1 style={{fontSize: 20, margin: 0}}>
                                    <strong style={{color: '#68bb23'}}> 1. </strong> {Texts.howItWorksSine.step1Title}
                                </h1>
                            </div>
                            <div className={'primary-sub-color-bg'} style={{height: 2, flex: 1, marginLeft: 10, alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} left cascade>
                                {Texts.howItWorksSine.step1}
                            </Fade>
                        </div>
                    </Col>
                    <Col md={24} lg={8}>
                        <Fade delay={300} right>
                            <img style={{width: '100%', borderRadius: 10 }} src={Images.how_to_resume_client.how_to_resume_client_1} />
                        </Fade>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBannerInvert'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2'}>
                    <Col md={24} lg={8}>
                        <Fade delay={300} left>
                            <img style={{width: '100%', borderRadius: 10}} src={Images.how_to_resume_client.how_to_resume_client_2} />
                        </Fade>
                    </Col>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}>
                            <div>
                                <h1 style={{fontSize: 20, margin: 0}}>
                                    <strong style={{color: '#68bb23'}}> 2. </strong>{Texts.howItWorksSine.step2Title}
                                </h1>
                            </div>
                            <div className={'primary-sub-color-bg'} style={{height: 2, width: '100%', flex: 1, marginLeft: 10, alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} right cascade>
                                {Texts.howItWorksSine.step2}
                            </Fade>

                            <Button onClick={() => history.push('/curriculos')} className={'ant-btn-secondary'} style={{marginLeft: 40}}> <Icon type='search' style={{margin: 0}}/> Buscar </Button>

                        </div>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBanner'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 reverseBg2'}>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}>
                            <div>
                                <h1 style={{fontSize: 20, margin: 0}}>
                                    <strong  style={{color: '#68bb23'}}> 3.
                                    </strong> {Texts.howItWorksSine.step3Title}
                                </h1>
                            </div>
                            <div className={'primary-sub-color-bg'} style={{height: 2, width: '100%', flex: 1, marginLeft: 10, alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} left cascade>
                                {Texts.howItWorksSine.step3}
                            </Fade>
                        </div>
                    </Col>
                    <Col md={24} lg={8}>
                        <Fade delay={300} right>
                            <img style={{width: '100%', borderRadius: 10}} src={Images.how_to_resume_client.how_to_resume_client_3} />
                        </Fade>
                    </Col>
                </Col>
            </Row>
        </Row>
    </div>)
};
export default HowToRecruit;
