import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, DatePicker, Form, Input, List, Menu, message, Modal, Popover, Select, Spin} from "antd";
import Loading from "../../../../Generic/Loading";
import useApi from "../../../../../Hooks/Api";
import {Images} from "../../../../../Helpers/Images";
import {FaICursor, FaMobile, FaMousePointer, FaStar, FaThumbsDown, FaUserFriends, FaUsers} from "react-icons/fa";
import {
    FaThumbsUp,
    FaUserAltSlash,
    FaUserCheck, IoMdBriefcase, IoMdCalendar,
    IoMdCellular, IoMdPin, MdGroup,
    MdNetworkCell,
    MdPhone, MdSearch, MdStar,
    MdStarBorder, MdStarHalf
} from "react-icons/all";
import ResumeDrawer from "../../../Public/SearchResumePage/ResumeDrawer";
import FeedbackForm from "../../RecruitmentsPage/FeedbackForm";
import {Badge} from "antd/es";
import Tag from "antd/es/tag";
import Icon from "antd/es/icon";
import _ from "lodash";
import moment from "moment";
import locale from "antd/es/date-picker/locale/pt_BR";
import {Env} from "../../../../../Helpers/Env";
import {CityList} from "../../../../../Helpers/CityList";

const newFeedbackType = [
    {type: 1, value: 'Trabalhador Contratado'},
    {type: 2, value: 'Não foi contatado', label: 'Não entrei em contato'},
    {type: 7, value: 'Não foi contratado, vaga preenchida de outra forma'},
    {type: 8, value: 'Não foi contratado, falta de interesse do trabalhador em ocupar o cargo'},
    {type: 11, value: 'Não foi contratado. Não possui as qualificações exigidas para a ocupação'},
]

Spin.setDefaultIndicator(<Loading/>);

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}

const CandidatesListComponent = (props) => {

    const [starHover, setStarHover] = useState(false);
    const api = useApi();
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const candidatesList = useRef([]);
    const [menuSelected, setMenuSelected] = useState("pending")
    const paginate = useRef({
        page: 1,
        status: ["pending"]
    });
    const nextPage = useRef(false);
    const [showCandidateDetails, setShowCandidateDetails] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const feedbackUser = useRef(null);
    const [summary, setSummary] = useState(null);
    const [smallLoading, setSmallLoading] = useState(false);
    const checkedList = useRef([]);
    const [selectAllToggle, setSelectAllToggle] = useState(false);
    const [checkedCount, setCheckedCount] = useState(0);
    const [selectedValue, setSelectedValue] = useState(undefined);
    const [observation, setObservation] = useState(undefined);
    const [qualifications, setQualifications] = useState(undefined);
    const [date_admission, setDateAdmission] = useState(moment());
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [searchText, setSearchText] = useState(undefined);
    const [cityListState, setCityListState] = useState(CityList);

    const getCandidates = async (more) => {
        more? setLoadingMore(true) : setLoading(true);

        if(more) {
            paginate.current.page = paginate.current.page + 1;
        }

        api.post(`sine/user/${props.user.user_id}/opportunity-interests/${props.vacancy}`, paginate.current, (res) => {
            res.next_page? nextPage.current = true : nextPage.current = false;
            setSummary(res.summary);
            if(more) {
                candidatesList.current = candidatesList.current.concat(res.candidates);
            } else {
                candidatesList.current = res.candidates;
            }
            more? setLoadingMore(false) : setLoading(false);
        }, (e) => {
            more? setLoadingMore(false) : setLoading(false);
        });
    }

    const handleShowCandidate = (item,index) => {
        setSmallLoading(true);
        feedbackUser.current = Object.assign({}, item);
        feedbackUser.current.index = index;
        candidatesList.current[index].viewed = true;
        setShowCandidateDetails(true);
        setTimeout(() => {
            setSmallLoading(false);
        },[1000])
    }

    const handleFavorite = (id, index) => {
        setSmallLoading(true);
        setStarHover(index);
        let safeGuard = Object.assign([], candidatesList.current); ;
        api.post(`sine/user/${props.user.user_id}/interest-favorite/${id}`, {favorite: !candidatesList.current[index].favorite}, (res) => {
            setStarHover(false);
            setSmallLoading(false);
        }, (err) => {
            setSmallLoading(false);
            setStarHover(index);
            candidatesList.current = safeGuard;
            setStarHover(false);
        })

        candidatesList.current[index].favorite = !candidatesList.current[index].favorite;
        if(candidatesList.current[index].favorite) {
            let aux = candidatesList.current[index];
            candidatesList.current.splice(index, 1);
            let listAux = [aux];
            candidatesList.current = listAux.concat(candidatesList.current);
        }

        setStarHover(false);
    }

    const handleChangeTab = (e) => {
        paginate.current.page = 1;
        checkedList.current = [];
        setCheckedCount(0);
        setSelectAllToggle(false);
        if(e === "all") {
            setMenuSelected(e);
            paginate.current.status = ["pending", "approved", "rejected"];
        } else {
            setMenuSelected(e);
            paginate.current.status = [e];
        }
        console.log(e)
        getCandidates();
    }

    const filter = (item) => {
        paginate.current.page = 1;
        paginate.current?.worker_name && delete paginate.current.worker_name;
        if(item === "") {
            if(paginate.current.favorite !== undefined) {
                delete paginate.current.favorite
            }
        } else {
            paginate.current.favorite = item;
        }
        getCandidates();
    }

    const filterCity = (item) => {
        paginate.current.page = 1;
        paginate.current?.worker_name && delete paginate.current.worker_name;
        if(item) {
            paginate.current.city = item
            paginate.current.state_abbrev = Env.state_restriction
        } else {
            paginate.current.city && delete paginate.current.city
            paginate.current.state_abbrev && delete paginate.current.state_abbrev
        }
        getCandidates();
    }

    const orderFilter = (item) => {
        paginate.current.page = 1;
        paginate.current?.worker_name && delete paginate.current.worker_name;
        paginate.current.order_by = item;
        getCandidates();
    }

    const handleSearchText = () => {
        paginate.current.page = 1;
        paginate.current.worker_name = searchText;
        getCandidates();
    }

    const disregardCurriculum = (item, index) => {
        setSmallLoading(true);
        let objToSend = {};
        objToSend.feedback_result = "Perda de interesse do parceiro";
        objToSend.last_event = 3;
        objToSend.observation = 'O trabalhador foi desconsiderado para o cargo';
        let safeGuard = Object.assign([], candidatesList.current);

        candidatesList.current.splice(index, 1);
        setShowCandidateDetails(false);

        api.post(`sine/user/${props.user.user_id}/interest-status/${item.interest_id}`, objToSend, (res) => {
            window.gtag&& window.gtag('event', 'fastFeedbackSuccess');
            setSummary({...summary, rejected: summary.rejected + 1});
            setSmallLoading(false);
        }, (e) => {
            candidatesList.current = safeGuard;
            setSmallLoading(false);
            message.destroy();
            if(e.message === 'Request failed') {
                message.error('Não foi possível enviar esse feedback para o SINE, tente novamente.');
            } else {
                message.error(e.message || 'Não foi possivel enviar o feedback deste profissional');
            }
            window.gtag&& window.gtag('event', 'fastFeedBackFail');
        });
    }

    const getMotive = (value) => {
        for(let i = 0; i < newFeedbackType.length; i++) {
            if(newFeedbackType[i].type === value) {
                return newFeedbackType[i].value;
            }
        }
        return '';
    }

    const sendMultipleFeedback = async () => {
        let objToSend = {};
        objToSend.last_event = selectedValue;
        objToSend.observation = observation;
        if(selectedValue === 11 && !qualifications) {
            message.warning('Informe a qualificação');
            return 0;
        }
        objToSend.qualifications = qualifications;
        objToSend.interests_ids = checkedList.current;
        if (selectedValue === 1 && !date_admission) {
            message.warning('Informe a data');
            return 0;
        } else if(selectedValue === 1 && date_admission) {
            objToSend.date_admission =  moment(date_admission).format('YYYY-MM-DD');
        }
        objToSend.feedback_result = getMotive(selectedValue);

        setLoading(true);
        message.loading('Enviando feedback');

        api.post(`sine/user/${props.user.user_id}/interest-batch-status/${props.vacancy}`, objToSend, (res) => {
            message.destroy();
            message.success('Seu feedback foi enviado! ');
            checkedList.current = [];
            setCheckedCount(0);
            setLoading(false);
            window.gtag&& window.gtag('event', 'multipleFeedBackSuccess');
            getCandidates();
        }, (e) => {
            message.destroy();
            if(e.message === 'Request failed') {
                message.error('Não foi possível enviar esse feedback para o SINE, tente novamente.');
            } else if(e.message === 'Você alcançou o limite de recrutamentos sem retorno. Conclua seus recrutamentos pendentes para voltar a consultar.') {
                message.error('Você alcançou o limite de recrutamentos sem retorno. Envie o feedback dos currículos em GERENCIAR VAGAS para continuar consultando.');
            } else {
                message.error(e.message || 'Não foi possivel enviar o feedback deste profissional');
            }
            setLoading(false);
            window.gtag&& window.gtag('event', 'multipleFeedBackFail');
        });
    }

    const handleSelectAll = () => {
        let aux = [];
        if(selectAllToggle) {
            checkedList.current = [];
            setCheckedCount(0);
        } else {
            candidatesList.current.forEach((item) => {
                if(item.age !== null) {
                    aux.push(item.interest_id);
                }
            });
            checkedList.current = aux;
            setTimeout(() => {
                setCheckedCount(aux.length);
            },[700])
        }
        setSelectAllToggle(!selectAllToggle);
    }

    const handleSingleCheck = (item) => {
        if(checkedList.current.indexOf(item) !== -1) {
            checkedList.current.splice(checkedList.current.indexOf(item), 1);
        } else {
            checkedList.current.push(item)
        }

        if(checkedList.current.length  === 0) {
            setSelectAllToggle(false)
        }

        setCheckedCount(checkedList.current.length);
    }

    const infiniteScroll = () => {
        let bodyAux = document.getElementById(`candidatesScrollList`);
        if(nextPage.current) {
            if (bodyAux.scrollTop + bodyAux.clientHeight >= bodyAux.scrollHeight - 20) {
                getCandidates(true);
            }
        }
    }

    useEffect(() => {
        if(document.getElementById(`candidatesScrollList`)) document.getElementById(`candidatesScrollList`).addEventListener('scroll', infiniteScroll)
        return () => {
            if(document.getElementById(`candidatesScrollList`)) document.getElementById(`candidatesScrollList`).removeEventListener(`scroll`, infiniteScroll);
        }
    },[])

    useEffect(() => {
        getCandidates()
    },[props.vacancy]);

    return (
        <div className={'mainComponentPanel'} style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 0, marginBottom: 10, borderRadius:5, backgroundColor: "white"}}>
            {showCandidateDetails &&<ResumeDrawer
                arrowShortCut={true}
                currentIndex={feedbackUser.current.index}
                candidatesList={candidatesList.current}
                updateFeedbackUser={(item) => handleShowCandidate(item, item.index)}
                handleFavorite={() => handleFavorite(feedbackUser.current.interest_id, feedbackUser.current.index)}
                favorite={feedbackUser.current.favorite}
                viewed={feedbackUser.current.viewed}
                getData={() => getCandidates()}
                vacancyFeedback={true}
                interest_id={feedbackUser.current.interest_id}
                id={feedbackUser.current.contact_id}
                disregardCurriculum={() => disregardCurriculum(feedbackUser.current, feedbackUser.current.index)}
                recruited={true}
                cboId={false}
                visible={showCandidateDetails}
                setVisible={(item) => setShowCandidateDetails(item)} />}
            {showFeedback &&<FeedbackForm profile={feedbackUser.current} vacancyFeedback={true} getData={() => getCandidates()} id={props.user.user_id} showFeedback={showFeedback} setShowFeedback={(item) => setShowFeedback(item)}/>}

            <Menu selectedKeys={[menuSelected]} onClick={(e) => handleChangeTab(e.key)} mode="horizontal" style={{display: "flex", marginBottom: 5, justifyContent: "space-between"}}>
                <Menu.Item key="all">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FaUsers style={{marginRight: 10}}/>
                        <span>Todos </span>
                        {summary && <Badge style={{marginLeft: 5}} count={summary.approved + summary.pending + summary.rejected}/>}
                    </div>
                </Menu.Item>
                <Menu.Item key="pending">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FaUserFriends style={{marginRight: 10}}/>
                        <span>Pendentes </span>
                        {summary && <Badge style={{marginLeft: 5}} count={summary.pending}/>}
                    </div>
                </Menu.Item>
                <Menu.Item key="approved">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FaUserCheck style={{marginRight: 10}}/>
                        <span>Aprovados </span>
                        {summary && <Badge style={{marginLeft: 5 }} count={summary.approved}/>}
                    </div>
                </Menu.Item>
                <Menu.Item key="rejected">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FaUserAltSlash style={{marginRight: 10}}/>
                        <span>Reprovados</span>
                        {summary && <Badge style={{marginLeft: 5}} count={summary.rejected}/>}
                    </div>

                </Menu.Item>
            </Menu>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10, paddingLeft: 5, paddingRight: 10, justifyContent: 'flex-end', marginRight: 5}} className={'selectionFilter'}>
                {menuSelected === 'pending' &&<div style={{flex: 1}}>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: 10}}>
                        <div onClick={() => handleSelectAll()} style={{borderRadius: 3, cursor: 'pointer', marginRight: 5, height: 16, width: 16, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: selectAllToggle ? '#1680ce' : 'white', border: selectAllToggle ? '1px solid #1680ce' : '1px solid #9e9e9e'}}>
                            {(selectAllToggle && candidatesList.current.length === checkedCount) ?<Icon style={{color: 'white' }} type="check" /> : (selectAllToggle) && <Icon style={{color: 'white' }} type="minus" />}
                        </div>
                        <span>{checkedCount > 0 ? `${checkedCount} Currículos selecionados` : 'Selecionar todos da página'}</span>
                    </div>
                    {selectAllToggle &&<div>
                        <span style={{fontSize: 12 ,paddingLeft: 9, color: '#9e9e9e'}}>Alguns currículos não podem ser selecionados, e devem receber o retorno manualmente</span>
                    </div>}
                </div>}
                {smallLoading &&<div>
                    <Icon style={{marginLeft: 10, marginTop: 5, color: '#1680ce'}} type={'loading'}/>
                </div>}


                <div style={{display: 'flex', marginRight: 10}}>
                    <Select
                        mode={'default'}
                        className={'dropdown200'}
                        onChange={(value) => {orderFilter(value)}}
                        placeholder={<span><MdGroup style={{marginBottom: -2}}/> Últimos Candidatos</span>}>
                        <Select.Option value="creation"><MdGroup style={{marginBottom: -2}}/> Últimos Candidatos</Select.Option>
                        <Select.Option value="total_experience"> <IoMdBriefcase style={{marginBottom: -2}}/> Maior experiência </Select.Option>
                        <Select.Option value="distance">  <IoMdPin style={{marginBottom: -2}}/> Distância</Select.Option>
                        <Select.Option value="age">  <IoMdCalendar style={{marginBottom: -2}}/> Idade</Select.Option>
                    </Select>
                </div>

                <div style={{display: 'flex'}}>
                    <Select
                        mode={'default'}
                        onChange={(value) => {filter(value)}}
                        placeholder={<span><MdStarHalf style={{marginBottom: -2}}/> Todos</span>}>
                        <Select.Option value=""><MdStarHalf style={{marginBottom: -2}}/> Todos</Select.Option>
                        <Select.Option value={true}><MdStar style={{marginBottom: -2}}/> Favoritos </Select.Option>
                        <Select.Option value={false}><MdStarBorder style={{marginBottom: -2}}/> Não Favoritos </Select.Option>
                    </Select>
                </div>

                <div style={{display: 'flex', marginLeft: 5}}>
                    <Select
                        mode={'default'}
                        className={'dropdown200'}
                        onChange={(value) => {filterCity(value)}}
                        allowClear={true}
                        showSearch
                        onSearch={(e) => setCityListState(CityList.filter((item) => item?.toLowerCase().search(e.toLowerCase()) !== -1 ))}
                        placeholder={<span><Icon type={'environment'}/> {Env.state_full} </span>}>
                        {cityListState.map((item, index) =>
                            <Select.Option key={index} value={item}><Icon type={'environment'}/> {item || Env.state_full}</Select.Option>
                        )}
                    </Select>
                </div>

            </div>

            <form style={{display: 'flex', alignItems: 'center', paddingLeft: 5, paddingRight: 10, marginBottom: 15}} onSubmit={(event) => {
                event.preventDefault();
                handleSearchText()
            }}>
                <Input  value={searchText} onChange={(e) => setSearchText(e.target.value)} style={{flex: 1, borderColor: 'whitesmoke', height: 35, marginRight: 4,}}  placeholder={'Busque pelo nome'} />
                <div onClick={() => handleSearchText()} style={{cursor: 'pointer', display: 'flex', backgroundColor: '#f5f5f5', marginLeft: 5, borderRadius: 20, padding: 5, color: '#bfbfbf'}}>
                    <MdSearch style={{fontSize: 23, color: '#9e9e9e'}}/>
                </div>
            </form>

            <div id={`candidatesScrollList`} style={{maxHeight: window.innerHeight - 270, overflowY: `scroll`, borderTop: `1px solid #f1f1f1`, padding: 5}}>
                {(candidatesList.current.length > 0) &&<List
                    className='listAnt listAntCandidates'
                    style={{overflow: 'auto', width: '100%'}}
                    itemLayout="horizontal"
                    dataSource={candidatesList.current}
                    loading={loading || loadingMore}
                    renderItem={(item, index) => (
                        <List.Item key={index} style={{padding: 0, position: 'relative', backgroundColor: 'white', border: checkedList.current.includes(item.interest_id)? '1px solid #1680ce' : 'unset'}} >
                            {(item.gender !== null && selectAllToggle) &&<div onClick={() => handleSingleCheck(item.interest_id)} style={{borderRadius: 3, cursor: 'pointer', marginLeft: 5, height: 18, width: 18, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: checkedList.current.includes(item.interest_id) ? '#1680ce' : 'white', border: checkedList.current.includes(item.interest_id) ? '1px solid #1680ce' : '1px solid #9e9e9e'}}>
                                {checkedList.current.includes(item.interest_id) &&<Icon style={{color: 'white' }} type="check" />}
                            </div>}
                            <List.Item.Meta
                                onClick={() => {
                                    handleShowCandidate(item, index)
                                }}
                                style={{alignItems: 'center', padding: 10, cursor: 'pointer', position: 'relative'}}
                                avatar={
                                    item.profile_picture?
                                    <img style={{width: 64, height: 64, borderRadius: '50%', objectFit: "cover", backgroundColor: '#f5f5f5'}} src={item.profile_picture}/>
                                    :
                                    <Avatar style={{width: 64, height: 64, padding: 10, backgroundColor: '#f5f5f5'}} size='default' src={Images.lettersPack[item.name[0].toLowerCase()] || Images.user_place_holder_2 }/>
                                }
                                title={<div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{fontWeight: "bold"}}>{item.name}  </span>
                                    <div>
                                        {item.age && <span style={{color: "darkgray", fontSize: 13, fontWeight: 400}}> {genders[item.gender]} - {item.age + ' Anos'} </span>}

                                        {item.recruitment === 'selected' ?
                                            <Popover content={'Este currículo foi selecionado manualmente por você para esta vaga'}>
                                                <span style={{color: "darkgray", fontSize: 13, fontWeight: 400, marginLeft: 20}}> Selecionado  <FaMousePointer style={{color: "darkgray"}}/> </span>
                                            </Popover>
                                            :
                                            <Popover content={'O trabalhador se candidatou a essa vaga pelo aplicativo'}>
                                                <span style={{color: "darkgray", fontSize: 13, fontWeight: 400, marginLeft: 20}}> Aplicou-se a vaga <FaMobile style={{color: "darkgray"}}/></span>
                                            </Popover>}

                                        {!item.viewed && <Popover content={'Você ainda não viu este currículo'}>
                                            <span style={{ fontSize: 13, fontWeight: 400, color: "darkgray"}}> - <span style={{color: "#6bbe1e"}}> Novo </span> </span>
                                        </Popover>}
                                    </div>
                                </div>}
                                description={<div> <span> <img src={Images.resume.job_case} style={{marginTop: -4}} height={15} width={15}/> {item.occupation} </span> <span style={{marginLeft: 10}}> <Icon type={'environment'}/> {item.city} </span>  </div>}
                            />


                            {item.status === 'pending' &&<div style={{display: 'flex', flexFlow: 'column', marginRight: 20, marginTop: 10}}>
                                <div
                                    className={'likeDislikeCandidate'}
                                    onClick={() => {
                                        feedbackUser.current = item;
                                        setShowFeedback(true);
                                    }}
                                    style={{backgroundColor: '#e9e9e94d', borderRadius: '50%', height: 45, width: 45, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#1680ce', boxShadow: '0px 1px 1px 1px #e0e0e0', fontSize: 15}}>
                                    <FaThumbsUp style={{marginRight: 5, color: '#6bbe1e'}}/>
                                    <FaThumbsDown  style={{color: '#ff4e4e', transform: 'rotateY(180deg)'}}/>
                                </div>
                            </div>}

                            <div onClick={() => handleFavorite(item.interest_id, index)} style={{fontSize: 20, alignItems: 'center', display: 'flex', position: "absolute", top: 5, right: 5}} onMouseLeave={() => setStarHover(false)} onMouseEnter={() => setStarHover(index)} >
                                <Popover placement={"topRight"} content={'Os currículos favoritados aparecem no topo.'} title={'Favorite este currículo'}>
                                    {(starHover === index || item.favorite) ? <MdStar style={{color: "gold"}} className={'candidateStar'}/> : <MdStarBorder style={{color: 'gainsboro'}}/>}
                                </Popover>
                            </div>
                        </List.Item>
                    )}
                />}
            </div>

            {(candidatesList.current.length === 0 && !loading) &&
            <div className={'empty-card'} style={{boxShadow: "unset"}}>
                <div style={{marginLeft: '20px', color: '#545a5d'}}>
                    {menuSelected === 'pending' ?
                        <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                            <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                            <span style={{fontWeight: 'bold'}}> Você não possuí nenhum candidato nesse anúncio sem Feedback </span>
                        </div>
                        : (menuSelected === 'all')?
                        <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                            <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                            <span> <span style={{fontWeight: 'bold'}}> Você não possui nenhum candidato para este anúncio </span>
                                <br/> Nenhum candidato se aplicou para esta vaga  </span>
                        </div>
                            :(menuSelected === 'approved')?
                                <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                                    <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                                    <span> <span style={{fontWeight: 'bold'}}> Você não possui nenhum candidato aprovado </span>
                                <br/> Envie o feedback de candidatos pendentes  </span>
                                </div>
                                    :
                                    <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                                        <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                                        <span> <span style={{fontWeight: 'bold'}}> Você não possui nenhum candidato reprovado </span>
                                        <br/> Envie o feedback de candidatos pendentes  </span>
                                    </div>
                    }
                </div>
            </div>
            }
            {checkedCount > 0 &&<div style={{padding: 20, boxShadow: '-1px -1px 4px #eeeeee', position: 'fixed', bottom: 10, right: 10, maxWidth: 500, borderRadius: 5, backgroundColor: 'white'}}>
                {!showConfirmation ? <>
                        <h3 style={{borderBottom: '1px solid', color: '#005c9f'}}>Informe a situação
                            de {checkedCount} currículo(s)</h3>
                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {newFeedbackType.map((item, index) =>
                                <div style={{
                                    padding: 5,
                                    cursor: 'pointer',
                                    fontWeight: selectedValue === item.type ? 'bold' : 'normal',
                                    backgroundColor: selectedValue === item.type ? 'aliceblue' : 'white',
                                    color: selectedValue === item.type ? '#005c9f' : '#757575',
                                    borderRadius: 5,
                                    border: selectedValue === item.type ? '1px solid #005c9f' : '1px solid gainsboro',
                                    margin: 5,
                                    paddingRight: 10,
                                    paddingLeft: 10
                                }} key={index}
                                     onClick={() => {
                                         setSelectedValue(item.type)
                                     }}>{item.label || item.value}
                                </div>
                            )}
                        </div>

                        {selectedValue === 11 &&
                        <Form.Item required={false} style={{padding: 5}}
                                   label="Selecione a qualificação que não foi atendida">
                            <Select style={{width: '100%'}} value={qualifications} onChange={(e) => setQualifications(e)}
                                    placeholder="Selecione aqui">
                                <Select.Option value={'Capacitação'}>Capacitação</Select.Option>
                                <Select.Option value={'Distância entre residência e local de trabalho'}>Distância entre
                                    residência e local de trabalho</Select.Option>
                                <Select.Option value={'Apresentação pessoal'}>Apresentação pessoal</Select.Option>
                            </Select>
                        </Form.Item>
                        }

                        {selectedValue === 1 &&
                        <Form.Item style={{padding: 5}} label="Data de admissão" required={false}>
                            <DatePicker value={date_admission} onChange={(e) => setDateAdmission(e)} locale={locale}
                                        style={{width: "100%"}} format={"DD/MM/YYYY"}/>
                        </Form.Item>
                        }

                        {(selectedValue && selectedValue !== 1) &&
                        <Form.Item style={{padding: 5}} label="Descreva com mais detalhes o motivo se necessário">
                            <Input.TextArea value={observation} onChange={(e) => setObservation(e.target.value)} rows={3}/>
                        </Form.Item>}

                        {selectedValue && <div style={{textAlign: 'center'}}>
                            <Button type={"primary"} onClick={(e) => setShowConfirmation(true)}> Enviar Retorno </Button>
                        </div>}
                    </>
                    :
                    <>
                        <div style={{textAlign: 'center', fontSize: 20}}>
                            <span style={{color: '#424242', fontSize: 20}}> Voce está prestes a enviar o retorno de <strong> {checkedCount} </strong> currículo(s). </span>
                            <br/>
                            <span style={{color: '#424242', fontSize: 16}}> <strong>Motivo - </strong> {getMotive(selectedValue)} </span>

                            <div style={{display: 'flex', marginTop: 15, justifyContent: "space-around"}}>
                                <Button type={"default"} onClick={(e) => setShowConfirmation(false)}> Voltar </Button>
                                <Button type={"primary"} onClick={(e) => sendMultipleFeedback()}> Enviar Retorno </Button>
                            </div>
                        </div>
                    </>
                }
            </div>}
            {nextPage.current&& (loadingMore ? <div style={{display: `flex`, padding: 8}}> <Icon style={{margin: "auto", fontSize: 20}} type={'loading'}/> </div> : <div className='loadMore' onClick={() => getCandidates(true)}>Ver Mais</div>)}
        </div>
    );
};

CandidatesListComponent.propTypes = {
    loading: PropTypes.any,
    resumesList: PropTypes.any,
    cboId: PropTypes.any,
    showDrawer: PropTypes.any
};

export default CandidatesListComponent;
