import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Collapse, Icon, Row} from "antd";

const EstablishmentExtras = (props) => {
    const [occupationsArray,setOccupationsArray] = useState([]);

    const initMap = () => {
        let myLatLng = {lat: props.address.latitude, lng: props.address.longitude};

        let map = new window.google.maps.Map(document.getElementById('map'), {
            zoom: 16,
            center: myLatLng
        });

        var marker = new window.google.maps.Marker({
            position: myLatLng,
            map: map,
            title: 'Estamos Aqui!'
        });
    }

    useEffect(() => {
        initMap()
    });

    useEffect(() => {
        if(props.occupations) {
            setOccupationsArray(props.occupations.split(','))
        }

    },[props.occupations]);

    return (
        <Row gutter={[0,32]}>
            <Col span={24}>
                <Collapse defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                        <div className="circle">
                            <div className="horizontal"/>
                            <div className="vertical"/>
                        </div>
                    </div> }>
                    <Collapse.Panel header="Áreas de Atuação " key="1" style={{display: 'flex', flexFlow: 'column', fontSize: 19}}>
                        {occupationsArray.map((item, index) =>
                            <div key={index} style={{margin: 5, marginLeft: 0, fontSize: 17}}>
                                <Icon type={'check'}/>  {item}
                            </div>
                        )}

                    </Collapse.Panel>
                </Collapse>
            </Col>

            <Col span={24}>
                <Collapse defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                        <div className="circle">
                            <div className="horizontal"/>
                            <div className="vertical"/>
                        </div>
                    </div> }>
                    <Collapse.Panel header="Endereço" style={{display: 'flex', flexFlow: 'column', fontSize: 19}} key="1">

                        <div style={{
                            margin: 5,
                            marginLeft: 0,
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'space-between',
                            fontSize: 17
                        }}>
                            <div>  <span style={{color: 'black'}}> Rua - </span> <span> {props.address.street_name}</span> </div>
                            <div>  <span style={{color: 'black'}}> Nº - </span> <span>{props.address.street_number}</span> </div>
                            <div>  <span style={{color: 'black'}}> Bairro - </span> <span> {props.address.district}</span> </div>
                            <div>  <span style={{color: 'black'}}> Cidade - </span> <span> {props.address.city}</span> </div>
                            <div>  <span style={{color: 'black'}}> CEP - </span>  <span> {props.address.cep}</span> </div>
                        </div>

                    </Collapse.Panel>
                </Collapse>
            </Col>

            <Col span={24}>
                <div className="ant-collapse ant-collapse-icon-position-left">
                    <div className="ant-collapse-item ant-collapse-item-active">
                        <div className="ant-collapse-header" role="button" tabIndex="0" style={{fontSize: 19}}  aria-expanded="true">
                            <div className="closed ant-collapse-arrow marginLeftNeg" style={{fontSize: 19, marginLeft: '-5px'}}>
                                <Icon className={'primary-color'} type={'flag'}/>
                            </div>
                           Localização
                        </div>
                        <div className="ant-collapse-content ant-collapse-content-active">
                            <div className="ant-collapse-content-box" style={{padding: 0}}>
                                <div id='map' style={{width: '100%', height: 250, borderRadius: 5}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

EstablishmentExtras.propTypes = {
    address: PropTypes.any,
    occupations: PropTypes.any
};

export default EstablishmentExtras;
