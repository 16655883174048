import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Checkbox, Col, Form, Icon, Input, message, Radio, Row, Select, Spin, Switch} from "antd";
import moment from "moment";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";
import useApi from "../../../../../Hooks/Api";
import {useValidationShorts} from "../../../../../Hooks/Helpers";
import useLocalStorage from "../../../../../Hooks/Storage";
import {FaForward} from "react-icons/fa";
import {StorageVariables} from "../../../../../Helpers/StoragesVariables";
import {ethnicityList} from "../../../../../ApplicationAssets/indigenousMS"
import {countries} from "../../../../../ApplicationAssets/Nationalities";

const hoursList = ['Indiferente', 'Comercial', 'Manhã', 'Tarde', 'Noite', 'Madrugada', 'Manhã/Tarde', 'Manhã/Noite', 'Manhã/Madrugada', 'Manhã/Tarde/Noite', 'Manhã/Tarde/Madrugada', 'Manhã/Noite/Madrugada', 'Manhã/Tarde/Noite/Madrugada', 'Tarde/Noite', 'Tarde/Madrugada', 'Tarde/Noite/Madrugada', 'Noite/Madrugada'];
const disabilitiesList = [{label: 'Surdez bilateral parcial', value: 'Surdez bilateral parcial'}, {label: 'Surdez bilateral total', value: 'Surdez bilateral total'}, {label: 'Amputação', value: 'Amputação'}, {label: 'Cadeirante', value: 'Cadeirante'}, {label: 'Membros Inferiores', value: 'Membros Inferiores'}, {label: 'Um membro Inferior', value: 'Um membro Inferior'}, {label: 'Membros Superiores', value: 'Membros Superiores'}, {label: 'Um membro superior', value: 'Um membro superior'}, {label: 'Nanismo', value: 'Nanismo'}, {label: 'Ostomia', value: 'Ostomia'}, {label: 'Paralisia Cerebral', value: 'Paralisia Cerebral'}, {label: 'Parcial', value: 'Parcial'}, {label: 'Mental/Intelectual', value: 'Mental/Intelectual'}, {label: 'Baixa Visão', value: 'Baixa Visão'}, {label: 'Cegueira', value: 'Cegueira'}, {label: 'Auditiva não informada', value: 'Auditiva não informada'}, {label: 'Física não informada', value: 'Física não informada'}, {label: 'Mental não informada', value: 'Mental não informada'}, {label: 'Visual não informada', value: 'Visual não informada'}, {label: 'Múltipla não informada', value: 'Múltipla não informada'}];
const schoolingList = ['Analfabeto', 'Fundamental Incompleto', 'Fundamental Completo', 'Médio Incompleto', 'Médio Completo', 'Superior Incompleto', 'Superior Completo', 'Mestrado', 'Doutorado', 'Especialização'];
const driversLicenseList = ['A', 'AB', 'AC', 'AD', 'AE', 'B', 'C', 'D', 'E'];
const languagesList = [{label: 'Alemão', value: 'Alemão'}, {label: 'Espanhol', value: 'Espanhol'}, {label: 'Francês', value: 'Francês'}, {label: 'Inglês', value: 'Inglês'}, {label: 'Italiano', value: 'Italiano'}];
const coursesList = ['Atendimento, Gestão e Venda', 'Agricultura e Cuidado Animal', 'Atendimento e Vendas', 'Beleza, Saúde e Bem Estar', 'Casa e Construção', 'Cozinha e Gastronomia', 'Indústria', 'Mecânica e Veículos', 'Produção Cultural, Arte e Design', 'Tecnologia e Comunicação', 'Têxtil e Moda', 'Turismo, Hospitalidade e Lazer'];

const vehiclesList = [ {label: 'Bicicleta', value: 'Bicicleta'},{label: 'Caminhão', value: 'Caminhão'}, {label: 'Carro', value: 'Carro'}, {label: 'Moto', value: 'Moto'},{label: 'Outro Veículo motorizado', value: 'Outro Veículo motorizado'}, {label: 'Outro Veículo não motorizado', value: 'Outro Veículo não motorizado'}, {label: 'Veículo de Tração Animal', value: 'Veículo de Tração Animal'}];
const experience = [{text: "6 Meses", value: '6'}, {text: "1 Ano", value: '12'}, {text: "2 Anos", value: '24'}, {text: "3 Anos", value: '36'}, {text: "4 Anos", value: '48'}, {text: "5 Anos", value: '60'}, {text: "6 Anos", value: '72'}, {text: "7 Anos", value: '84'}, {text: "8 Anos", value: '96'}, {text: "9 Anos", value: '108'}, {text: "10 Anos", value: '120'}];

const AboutComponent = (props) => {

    const {getFieldDecorator, getFieldsValue,setFieldsValue, validateFields, getFieldsError} = props.form;
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const {isValidDate, numMinWithMask} = useValidationShorts();
    const [initialLoading, setInitialLoading] = useState(true);
    const [basicData, setBasicData] = useState({});
    const [loading, setLoading] = useState(false);
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);

    const [showPcd, setShowPcd] = useState(false);
    const [showVehicles, setShowVehicles] = useState(false);
    const [showLanguages, setShowLanguages] = useState(false);
    const [showCourses, setShowCourses] = useState(false);
    const [showCadunico, setShowCadunico] = useState(false);
    const [showIndigenous, setShowIndigenous] = useState(false);
    const [showImmigrant, setShowImmigrant] = useState(false);
    const [cepLoading, setCepLoading] = useState(false);
    const [autocompleteSelected, setAutoCompleteSelected] = useState(false);
    const [countriesList, setCountriesList] = useState(countries);
    const [nationality, setNationality] = useState("Brasil");

    const getFullData = async () => {
        api.get(`sine/user/${user?.user_id}/full`, (res) => {
            if(res.user_id !== null) {
                props.setHasProfile(true);

                if(res.profile.vehicles.length > 0) setShowVehicles(true);
                if(res.profile.disabilities.length > 0) setShowPcd(true);
                if(res.profile.languages.length > 0) setShowLanguages(true);
                if(res.profile.training_interests.length > 0) setShowCourses(true);
                if(res.profile.indigenous_person) setShowIndigenous(true);
                if(res.profile.has_cadunico) setShowCadunico(true);
                //if(res.profile.is_immigrant) setShowImmigrant(true);
                //if(res.profile.nationality) setNationality(res.profile.nationality)

                setBasicData(res.profile);
            }
            setInitialLoading(false);
        },(e) => {
            console.log(e);
        });
    };

    const registerContacts = async () => {
        api.post(`sine/user/${user.user_id}/phone-number`, {number: user.phone, primary: true}, (res) => {}, (e) => {});
        api.post(`sine/user/${user.user_id}/email`, {email: user.email, primary: true},(res) => {}, (e) => {});
        window.gtag&& window.gtag('event', 'initialResumeCreated');
    };

    const handleSubmit = async () => {
        validateFields(async (error, values) => {
            if (error) { message.warning('Preencha os campos corretamente'); return 0;}
            setLoading(true);

            //values.nationality = nationality;
            //values.is_immigrant = showImmigrant;

            message.loading('Adicionando...');
            if(values.wage_claim === "") {
                delete values.wage_claim;
            } else if(values.wage_claim !== undefined) {
                values.wage_claim = values.wage_claim.replace(/\./g, "").replace(",", ".").replace('R$',"");
            }
            if(!showVehicles) values.vehicles = [];
            if(!showLanguages) values.languages = [];
            if(!showPcd) values.disabilities = [];
            if(!showCourses) values.training_interests = [];
            if(!showIndigenous) {
                values.ethnicity = undefined;
                values.native_village = undefined;
            } else {
                if(!values.ethnicity || !values.native_village) {
                    message.destroy();
                    message.warning('Informe sua Aldeia e Etnia');
                    setLoading(false);
                    return;
                }
                values.indigenous_person = true;
            }
            if(showCadunico) {values.has_cadunico = true;} else {values.has_cadunico = false; values.cadunico_number = undefined}

            values.address.postal_code = window.getNumbers(values.address.postal_code);

            if(moment().diff(moment(values.birthday, 'DD/MM/YYYY'), 'years') <= 13) {
                window.gtag&& window.gtag('event', 'wrongDate');
                message.destroy();
                message.warning('Preencha sua data de nascimento corretamente/ Você deve ter no mínimo 14 anos de idade');
                setLoading(false);
                return;
            }

            values.birthday = moment(values.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');
            values.about_me = basicData?.about_me || undefined;
            api.post(`sine/user/${user.user_id}/profile`,values, (res) => {
                message.destroy();
                message.success('Sucesso!');
                if(!props.hasProfile) {
                    props.setHasProfile(true);
                    registerContacts();
                    hasProfileStorage.setPrimitive(true);
                }
                props.handleChangeStep(1);
                setLoading(false);
            }, (e) => {
                message.error('Não foi possivel adicionar seus dados, tente novamente');
                setLoading(false);
            });
        })
    };

    const onCepChange = async (e) => {
        const cep = window.getNumbers(e.target.value);
        if (cep?.length === 8) {
            setCepLoading(true);
            api.get(`search/postal-code?cep=${cep}`, (res) => {
                if(res) {
                    setFieldsValue({address: { district: res.district, street: res.street_name, city: res.city, state_abbrev: res.state}});
                }
                setCepLoading(false);
            }, (e) => {
                message.warning('CEP inválido');
                setFieldsValue({address: {postal_code: undefined}});
                console.log(e);
                setCepLoading(false);
            });
        }
    }

    const autoCompleteSearch = (query) => {
        const aux = []
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].value.toLowerCase().includes(query.toLowerCase())) {
                aux.push(countries[i])
            }
        }
        setCountriesList(aux)

    }


    useEffect(() => {
        getFullData();
        window.scrollToElement('body');

    },[]);


    return (<div style={{minHeight: 500}} className={'aboutCss'}>
        {initialLoading&&<div className={'loadingResume'}> <Spin/> </div>}

        {!initialLoading && <>
        <Row className={'formTitle'}> Dados pessoais </Row>
        <Row gutter={[24,24]} className={'formRow'} style={{backgroundColor: 'white', margin: 0}}>
            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label> <span> * </span> Nome  </label> </div>
                {getFieldDecorator('name',
                    {
                        rules: [{
                            required: true,
                            message: 'Nome é obrigatório'
                        }],
                        initialValue: basicData.name || user.name || '',
                    }
                )(
                    <Input placeholder={'Seu nome'} size={'large'}/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().name} </span> </div>
            </Col>
            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label> <span> * </span> Gênero </label> </div>
                {getFieldDecorator('gender',
                    {
                        rules: [{
                            required: true,
                            message: 'Gênero é obrigatório'
                        }],
                        initialValue: basicData.gender || user.gender || undefined
                    }
                )(
                    <Select
                        showSearch
                        placeholder="Selecione seu gênero"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        size={'large'}
                    >
                        <Select.Option value={'male'}> Masculino </Select.Option>
                        <Select.Option value={'female'}> Feminino </Select.Option>
                        <Select.Option value={'transgender_male'}> Homem transgênero </Select.Option>
                        <Select.Option value={'transgender_female'}> Mulher transgênero </Select.Option>
                        <Select.Option value={'transsex_male'}> Homem Transexual </Select.Option>
                        <Select.Option value={'transsex_female'}> Mulher Transexual </Select.Option>
                        <Select.Option value={'unknown'}> Não sei responder </Select.Option>
                        <Select.Option value={'not_anwswered'}> Prefiro não responder </Select.Option>
                        <Select.Option value={'other'}> Outros </Select.Option>
                    </Select>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().gender} </span> </div>

            </Col>

            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label> <span> * </span>Data de nascimento </label> </div>
                {getFieldDecorator('birthday',
                    {
                        rules: [{required: true, message: 'Data de nascimento é obrigatório',}, isValidDate()],
                        initialValue: basicData.birthday? (moment(basicData.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY') || undefined) : (user.birthday? moment(user.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined)
                    }
                )(
                    <NumberFormat type={"tel"} style={{textAlign: 'center'}} className={'ant-input ant-input-lg'} format="##/##/####" placeholder="DD/MM/YYYY"/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().birthday} </span> </div>

            </Col>

            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label> Pretensão Salarial </label> </div>
                {getFieldDecorator('wage_claim',
                    {
                        rules: [],
                        initialValue: basicData.wage_claim&& basicData.wage_claim.replace('.', ",") || undefined,
                    }
                )(
                    <NumberFormat maxLength={14} decimalScale={2} fixedDecimalScale={true} className='ant-input ant-input-lg' thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} style={{textAlign: "right"}} placeholder={'Valor pretendido'} size={'large'}/>
                )}
            </Col>

            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label><span> * </span>Qual sua escolaridade ?</label> </div>
                {getFieldDecorator('schooling',
                    {
                        rules: [{required: true, message: 'Escolaridade é obrigatório'}],
                        initialValue: basicData.schooling || undefined,
                    }
                )(
                    <Select
                        placeholder="Selecione seu grau de escolaridade"
                        size={'large'}
                    >
                        {schoolingList.map((item, index) =>
                            <Select.Option value={item} key={index}>{item}</Select.Option>
                        )}
                    </Select>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().schooling} </span> </div>

            </Col>


            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label> Possui CNH ?</label> </div>
                {getFieldDecorator('driver_license',
                    {
                        rules: [],
                        initialValue: basicData.driver_license || undefined,

                    }
                )(
                    <Select
                        placeholder="Selecione a carteira de motorista que possui"
                        size={'large'}
                        allowClear={true}
                    >
                        {driversLicenseList.map((item, index) =>
                            <Select.Option key={index} value={item}>{item}</Select.Option>
                        )}
                    </Select>
                )}
            </Col>
            {/*<Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span> Qual sua nacionalidade ?</label></div>
                <AutoComplete
                    disabled={props.edit}
                    size="large"
                    onSelect={(value, option) => setNationality(value)}
                    style={{width: '100%', display: autocompleteSelected && 'none'}}
                    dataSource={countriesList?.map(item => {
                        return (<AutoComplete.Option key={item.value} label={item.label} value={item.value}>
                                {item.label}
                            </AutoComplete.Option>
                        )
                    })}
                    onSearch={(query) => autoCompleteSearch(query)}
                    optionLabelProp="text"
                >
                    <Input size={'large'} placeholder={nationality ? nationality : 'Selecione a nacionalidade'}/>
                </AutoComplete>
            </Col>*/}
           {/* <Col  xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>

                <div className='resumeLabel'
                     style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}><label> Você é imigrante? </label>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showImmigrant}>
                            <Radio value={true} onChange={() => {
                                setShowImmigrant(true)
                            }}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {
                                setShowImmigrant(false)
                            }}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
            </Col>*/}

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Possuí CADUNICO? </label>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showCadunico}>
                            <Radio value={true} onChange={() => {setShowCadunico(true)}}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {setShowCadunico(false)}}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                {getFieldDecorator('cadunico_number',
                    {
                        rules: [],
                        initialValue: basicData.cadunico_number || undefined,
                    }
                )(

                    <Input style={{display: !showCadunico&& "none"}} placeholder={'Informe o Número'} size={'large'}/>
                )}
            </Col>

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Você é indígena? </label>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showIndigenous}>
                            <Radio value={true} onChange={() => {setShowIndigenous(true)}}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {setShowIndigenous(false)}}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, paddingRight: 20, display: !showIndigenous&& "none"}}>
                        <div className='resumeLabel'> <label><span> * </span>Qual sua Etnia?</label> </div>
                        {getFieldDecorator('ethnicity',
                            {
                                rules: [],
                                initialValue: basicData.ethnicity || undefined,
                            }
                        )(
                            <Select
                                placeholder="Informe sua Etnia"
                                size={'large'}
                                allowClear={true}
                            >
                                {Object.keys(ethnicityList).map((item, index) =>
                                    <Select.Option key={index} value={item}>{item}</Select.Option>
                                )}
                            </Select>
                        )}
                    </div>
                    <div style={{flex: 1, display: (!getFieldsValue()?.ethnicity || !showIndigenous)&& "none"}}>
                        <div className='resumeLabel'> <label><span> * </span>Selecione sua Aldeia</label> </div>

                        {getFieldDecorator('native_village',
                            {
                                rules: [],
                                initialValue: basicData.native_village || undefined,
                            }
                        )(

                            <Select
                                placeholder="Selecione sua Aldeia"
                                size={'large'}
                                allowClear={true}
                            >
                                {getFieldsValue()?.ethnicity&& ethnicityList[getFieldsValue()?.ethnicity] ? ethnicityList[getFieldsValue()?.ethnicity]?.map((item, index) =>
                                    <Select.Option key={index} value={item}>{item}</Select.Option>
                                ) : []}
                            </Select>
                        )}
                    </div>
                </div>
            </Col>

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Fala outro idioma? </label>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showLanguages}>
                            <Radio value={true} onChange={() => {setShowLanguages(true)}}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {setShowLanguages(false)}}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                {getFieldDecorator('languages',
                    {
                        rules: [],
                        initialValue: basicData.languages || undefined,
                    }
                )(
                    <Checkbox.Group
                        style={{display: !showLanguages&& "none"}}
                        options={languagesList}
                    />
                )}
            </Col>

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Possui algum veículo ? </label>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showVehicles}>
                            <Radio value={true} onChange={() => {setShowVehicles(true)}}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {setShowVehicles(false)}}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>

                {getFieldDecorator('vehicles',
                    {
                        rules: [],
                        initialValue: basicData.vehicles || undefined,
                    }
                )(
                    <Checkbox.Group
                        style={{display: !showVehicles&& "none"}}
                        options={vehiclesList}
                    />
                )}
            </Col>
            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> É PcD ? (Pessoa com deficiência) </label>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showPcd}>
                            <Radio value={true} onChange={() => {setShowPcd(true)}}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {setShowPcd(false)}}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                {getFieldDecorator('disabilities',
                    {
                        rules: [],
                        initialValue: basicData.disabilities || undefined,
                    }
                )(
                    <Checkbox.Group
                        style={{display: !showPcd&& "none"}}
                        className={'disabilitiesGroup'}
                        options={disabilitiesList }
                    />
                )}
            </Col>

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> É estudante? </label>
                    <div>
                        {getFieldDecorator('is_student',
                            {
                                rules: [],
                                initialValue: basicData.is_student || false,
                            }
                        )(
                        <Radio.Group className={'radio'} name="languageRadio" >
                            <Radio value={true}>
                                Sim
                            </Radio>
                            <Radio value={false}>
                                Não
                            </Radio>
                        </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Possui interesse em realizar cursos grátis? </label>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showCourses}>
                            <Radio value={true} onChange={() => {setShowCourses(true)}}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {setShowCourses(false)}}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                {showCourses && <div> <label style={{color: '#616161', fontSize: 16}}> Escolha quais: </label> </div>}
                {getFieldDecorator('training_interests',
                    {
                        rules: [],
                        initialValue: basicData.training_interests || undefined,
                    }
                )(
                    <Checkbox.Group
                        style={{display: !showCourses&& "none"}}
                        className={'trainingGroup'}
                        options={coursesList }
                    />
                )}
            </Col>

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Você é o responsável financeiro em sua residência? </label>
                    <div>
                        {getFieldDecorator('financial_responsible',
                            {
                                rules: [],
                                initialValue: basicData.financial_responsible || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="languageRadio" >
                                <Radio value={true}>
                                    Sim
                                </Radio>
                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Tem interesse em participar de grupo de empreendedores ou economia solidária?</label>
                    <div>
                        {getFieldDecorator('entrepreneur_interest',
                            {
                                rules: [],
                                initialValue: basicData.entrepreneur_interest || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="languageRadio" >
                                <Radio value={true}>
                                    Sim
                                </Radio>
                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Disponibilidade para realizar viagens? </label>
                    <div>
                        {getFieldDecorator('available_travel',
                            {
                                rules: [],
                                initialValue: basicData.available_travel || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="languageRadio" >
                                <Radio value={true}>
                                    Sim
                                </Radio>
                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>

            <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Disponibilidade para dormir no local de trabalho? </label>
                    <div>
                        {getFieldDecorator('available_sleep_duty',
                            {
                                rules: [],
                                initialValue: basicData.available_sleep_duty || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="languageRadio" >
                                <Radio value={true}>
                                    Sim
                                </Radio>
                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>

            <Col xs={24} className={'formItem'} >
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Disponibilidade para ausentar-se de seu domicílio por longos períodos? </label>
                    <div>
                        {getFieldDecorator('available_leave_home',
                            {
                                rules: [],
                                initialValue: basicData.available_leave_home || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="languageRadio" >
                                <Radio value={true}>
                                    Sim
                                </Radio>
                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>
        </Row>

        <Row className={'formTitle'}> Endereço residencial </Row>
        <Row gutter={[24,24]} className={'formRow'} style={{backgroundColor: 'white', margin: 0}}>
            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label><span> * </span> CEP </label> </div>
                {getFieldDecorator('address.postal_code', {
                    rules: [
                        numMinWithMask(),
                        {
                            required:  true,
                            message: 'CEP Obrigatório',
                        },
                    ],
                    initialValue: basicData?.address?.postal_code || '',
                })(
                    <NumberFormat type={"tel"} disabled={cepLoading} onChange={(e)=> onCepChange(e)} style={{width: '100%', textAlign: 'center'}} placeholder='Digite seu CEP para buscar' className='ant-input ant-input-lg' format={'## ###-###'} mask="_"/>
                )}

            </Col>
            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label> <span> * </span> Estado </label> </div>
                {getFieldDecorator('address.state_abbrev',
                    {
                        rules: [{
                            required: true,
                            message: 'Sigla Estado é obrigatório'
                        }],
                        initialValue: basicData?.address?.state_abbrev || '',
                    }
                )(
                    <Input prefix={cepLoading&& <Icon type="loading"/>} disabled={true} placeholder={'Informe seu estado'} size={'large'}/>
                )}
            </Col>
            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label> <span> * </span> Cidade </label> </div>
                {getFieldDecorator('address.city',
                    {
                        rules: [{
                            required: true,
                            message: 'Cidade é obrigatório'
                        }],
                        initialValue: basicData?.address?.city || '',
                    }
                )(
                    <Input prefix={cepLoading&& <Icon type="loading"/>} disabled={true} placeholder={'Sua cidade'} size={'large'}/>
                )}
            </Col>
            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label> <span> * </span> Rua </label> </div>
                {getFieldDecorator('address.street',
                    {
                        rules: [{
                            required: true,
                            message: 'Rua é obrigatório'
                        }],
                        initialValue: basicData?.address?.street || '',
                    }
                )(
                    <Input prefix={cepLoading&& <Icon type="loading"/>} placeholder={'Sua rua'} size={'large'}/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().address?.street} </span> </div>

            </Col>
            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'> <label> Bairro </label> </div>
                {getFieldDecorator('address.district',
                    {
                        rules: [],
                        initialValue: basicData?.address?.district || '',
                    }
                )(
                    <Input prefix={cepLoading&& <Icon type="loading"/>} placeholder={'Seu bairro'} size={'large'}/>
                )}
            </Col>
            <Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'>   <label>  <span> * </span> Número </label> </div>
                {getFieldDecorator('address.street_number',
                    {
                        rules: [{
                            required: true,
                            message: 'Número é obrigatório'
                        }],
                        initialValue: basicData?.address?.street_number || '',
                    }
                )(
                    <Input type={'number'} placeholder={'Número da residência'} size={'large'}/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().address?.street_number} </span> </div>

            </Col>
        </Row>

        <Row justify={'end'} style={{marginBottom: 20, textAlign: 'right', marginTop: 20}}>
            <Button shape={'round'} loading={loading} disabled={loading} onClick={() => handleSubmit()} className={'ant-btn-sub-primary'}> Próximo <FaForward className={'exportedIcons'}/>  </Button>
        </Row> </>}
    </div>)
};
export default Form.create({name: 'aboutForm'})(AboutComponent);

