import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import "./custom.less";
import * as Sentry from "@sentry/react";
import {Env} from "./Helpers/Env";

process.env.NODE_ENV  === 'production' &&Sentry.init({
    dsn: "https://fd17593953dc44dd897ca430d4b1bfac@o516750.ingest.sentry.io/6295553",
    debug: !process.env.NODE_ENV  === 'production',
    environment: process.env.NODE_ENV  === 'production'? 'production' : 'development',
    release: Env.application_alias + '-web@' + Env.version,
    beforeBreadcrumb(breadcrumb, hint) {
        return breadcrumb.category === "console" ? null : breadcrumb;
    },
});

ReactDOM.render(

    <App />
,
  document.getElementById('root')
);

// If you want to start m
// easuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*reportWebVitals();*/
