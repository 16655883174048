import React from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Icon, Input, Row} from "antd";
import GenericInput from "../../../../Generic/GenericInput";
import {useValidationShorts} from "../../../../../Hooks/Helpers";
import TextArea from "antd/es/input/TextArea";


const ProfileContactForm = props => {
    const {getFieldDecorator, getFieldError, getFieldsValue} = props.form;
    const {numMinWithMask, required, isEmail} = useValidationShorts();
    return (
        <>
            <Row className={'primary-sub-color'} style={{fontSize: 17, marginBottom: 20}}>
                <Icon type={'phone'}/>
                <span > Dados de Contato </span>
            </Row>
            <Row style={{padding: 20, borderRadius: 10, boxShadow: '0 2px 8px rgba(0,0,0,.08)', backgroundColor: 'white',}}>
                <Col span={24}>
                    <Row gutter={[24,24]}>
                        <Col xs={24} md={12}>
                            <GenericInput initialValue={props.user.email} rules={[isEmail(), required()]} suffix={<Icon type="mail" className={'primary-sub-color'} style={{fontSize: 16}}/>} required={true} form={props.form} error={getFieldError('email')} name='email' label='Email' placeholder='Digite seu email' valueForMemo={getFieldsValue().email} />
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                className={'hideDots'}
                                label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}><strong > Telefone </strong> </span>}>
                                <Input suffix={<Icon type="phone" className={'primary-sub-color'} style={{fontSize: 16}}/>} disabled={true} value={props.user.cellphone_number} size={"large"} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

ProfileContactForm.propTypes = {
    user: PropTypes.any
};

export default ProfileContactForm;
