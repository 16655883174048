import Col from "antd/es/grid/col";
import Form from "antd/es/form";
import React from "react";
import Icon from "antd/es/icon";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import useApi from "../../Hooks/Api";
import {showNotification, useValidationShorts} from "../../Hooks/Helpers";
import {Env} from "../../Helpers/Env";
import {message} from 'antd';


export function CepInput(props) {

    const api = useApi({initialLoading: false});
    const {getFieldDecorator, getFieldsValue, setFieldsValue, getFieldError} = props.form;
    const {numMinWithMask} = useValidationShorts();

    const onCepChange = async (e) => {
        const cep = window.getNumbers(e.target.value);
        if(!api.loading) {
            if (cep?.length === 8) {
                api.get(`search/postal-code?cep=${cep}`, (res) => {
                    if (res) {
                        if (Env.state_restriction !== 'none' && !props.ignoreRestriction) {
                            if(res.state === Env.state_restriction) {
                                props.setAddressVisible(true);
                            } else {
                                showNotification();
                            }
                        } else {
                            props.setAddressVisible(true);
                        }
                        props.checkCepInfoAvailability(res);
                    }
                }, (e) => {
                    message.warning("CEP INVALIDO");
                    console.log(e);
                });
            }
        }
    }

    return (
        <Col sm={props.sm || 24} md={props.md || 24} lg={props.lg || 24}  className='formItens' style={{display: 'block', padding: 0 }}>
            <Form.Item validateStatus={getFieldError(props.name)&& 'error'} help={getFieldError(props.name)} required={props.required || true} label={<span style={{fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}> <Icon  style={{marginRight: 5, marginLeft: 0}} type={api.loading? 'loading': 'environment'}/>{props.label}</span>}>
                {getFieldDecorator(props.name, {
                    rules: [
                        numMinWithMask(),
                        {
                            required: props.required? props.required: true,
                            message: props.message || 'Campo Obrigatório',
                        },
                    ],
                    initialValue: props.initialValue || ''
                })(
                    <NumberFormat disabled={props.disabled || api.loading} onChange={(e)=> onCepChange(e)} style={{width: '100%', marginTop: 5}} placeholder='Digite seu CEP para buscar' className='ant-input ant-input-lg cellPhone' format={'## ###-###'} mask="_"/>
                )}
            </Form.Item>
        </Col>
    )
}

CepInput.propTypes = {
    nome: PropTypes.any,
    form: PropTypes.any,
    icon: PropTypes.any,
    label: PropTypes.any,
    pattern: PropTypes.any,
    message: PropTypes.any,
    sm: PropTypes.any,
    md: PropTypes.any,
    lg: PropTypes.any,
    size: PropTypes.any,
    initialValue: PropTypes.any,
    checkCepInfoAvailability: PropTypes.any,
    ignoreRestriction: PropTypes.any
};
export default CepInput;
