import React, {useState} from 'react';
import Header from "./Default/Header";
import Footer from "./Default/Footer";
import {Drawer, Layout} from 'antd';
import Sidebar from "./Default/Sidebar";

const { Content } = Layout;

const DefaultLayout = props => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [showChat, setShowChat] = useState(false);

    return (
        <Layout>

            <Sidebar collapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />

            <Layout className="layout">

                <Header
                    setSidebarCollapsed={setSidebarCollapsed}
                    sidebarCollapsed={sidebarCollapsed}
                />

                <Content style={{marginTop: '66px', position: 'relative', zIndex: 2}}>
                    {props.children}
                </Content>

                <Footer />

            </Layout>
        </Layout>

    );
};

DefaultLayout.propTypes = {};

export default DefaultLayout;
