import React, {useEffect} from 'react';
import {Button, Col, Divider, Icon, Row} from "antd";
import {Fade} from "react-reveal";
import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";
import {useHistory} from "react-router-dom";

const HowToHireServices = (props) => {
    const history = useHistory();

    useEffect(() => {
        window.scrollToElement('body');
    },[]);

    return (<div>

        <Row style={{minHeight: '400px', marginTop: 60}}>
            {/*<div style={{width: '100%', position: 'absolute', height: '100%', backgroundSize: 'contain', zIndex: 10, backgroundImage: "url('/img/squareBg.png')"}}/>*/}
            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <Col sm={20} lg={20} xl={19} xxl={16} className='headerHowTo' style={{padding: 5, textAlign: 'center'}}>
                    <img style={{filter: 'brightness(0.2)', width: 70}} src={Images.home.service_icon}/>
                    <h1 style={{position: 'relative', marginBottom: 100, marginTop: 30}} className={'title title-line title-line2'}> {Texts.howItWorksCrafty.mainTitle} </h1>
                    {Texts.howItWorksCrafty.mainText}
                </Col>
            </Row>


            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBanner'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 reverseBg2 homeBg2Purple'}>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}>
                            <div>
                                <h1 style={{fontSize: 24, margin: 0}}>
                                    <strong style={{color: '#8720c5'}}> 1. </strong> {Texts.howItWorksCrafty.step1Title}
                                </h1>
                            </div>
                            <div className={'primary-sub-color-bg'} style={{height: 2, flex: 1, marginLeft: 20, alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} left cascade>
                                {Texts.howItWorksCrafty.step1}
                            </Fade>
                        </div>
                    </Col>
                    <Col md={24} lg={8}>
                        <Fade delay={300} right>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.services.reg_img_step1} />
                        </Fade>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBannerInvert'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 homeBg2Purple'}>
                    <Col md={24} lg={8}>
                        <Fade delay={300} left>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.services.search_img_step2} />
                        </Fade>
                    </Col>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}>
                            <div>
                                <h1 style={{fontSize: 24, margin: 0}}>
                                    <strong style={{color: '#8720c5'}}> 2. </strong>{Texts.howItWorksCrafty.step2Title}
                                </h1>
                            </div>
                            <div className={'primary-sub-color-bg'} style={{height: 2, flex: 1, marginLeft: 20, alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} right cascade>
                                {Texts.howItWorksCrafty.step2}
                            </Fade>

                            <Button onClick={() => history.push('/curriculos')} className={'ant-btn-purple'} style={{marginLeft: 40}}> <Icon type='search' style={{margin: 0}}/> Buscar </Button>

                        </div>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBanner'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 reverseBg2 homeBg2Purple'}>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}>
                            <div>
                                <h1 style={{fontSize: 24, margin: 0}}>
                                    <strong  style={{color: '#8720c5'}}> 3. </strong> {Texts.howItWorksCrafty.step3Title}
                                </h1>
                            </div>
                            <div className={'primary-sub-color-bg'} style={{height: 2, flex: 1, marginLeft: 20, alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} left cascade>
                                {Texts.howItWorksCrafty.step3}
                            </Fade>
                        </div>
                    </Col>
                    <Col md={24} lg={8}>
                        <Fade delay={300} right>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.services.confirm_img_step3}/>
                        </Fade>
                    </Col>
                </Col>
            </Row>
            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBannerInvert'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 homeBg2Purple'}>
                    <Col md={24} lg={8}>
                        <Fade delay={300} left>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.services.await_img_step4} />
                        </Fade>
                    </Col>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}>
                            <div>
                                <h1 style={{fontSize: 24, margin: 0}}>
                                    <strong style={{color: '#8720c5'}}> 4. </strong> {Texts.howItWorksCrafty.step4Title}
                                </h1>
                            </div>
                            <div className={'primary-sub-color-bg'} style={{height: 2, flex: 1, marginLeft: 20, alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} right cascade>
                                {Texts.howItWorksCrafty.step4}
                            </Fade>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBanner'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 reverseBg2 homeBg2Purple'}>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}>
                            <div>
                                <h1 style={{fontSize: 24, margin: 0}}>
                                    <strong  style={{color: '#8720c5'}}> 5. </strong> {Texts.howItWorksCrafty.step5Title}
                                </h1>
                            </div>
                            <div className={'primary-sub-color-bg'} style={{height: 2, flex: 1, marginLeft: 20, alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} left cascade>
                                {Texts.howItWorksCrafty.step5}
                            </Fade>
                        </div>
                    </Col>
                    <Col md={24} lg={8}>
                        <Fade delay={300} right>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.services.rate_img_step5}/>
                        </Fade>
                    </Col>
                </Col>
            </Row>
        </Row>
    </div>)
};
export default HowToHireServices;
