import React from 'react';
import {Carousel, Col, Row} from "antd";
import {Fade} from "react-reveal";
import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";

const SearchEstablishmentsBanner = () => {

    return (
        <Carousel effect="fade" autoplay={true} autoplaySpeed={5000}>
            <Row className='bannerSubPages' style={{display: 'flex', justifyContent: 'center'}}>
                <div className='banner' style={{backgroundImage: `url(${Images.establishment.establishment_banner1})`}}/>
                <div className='bannerAppsBackground'/>
                <Col span={16} style={{alignItems: 'center', display: 'flex'}}>
                    <span style={{fontSize: '30px'}}> <Fade  duration={1000} mountOnEnter> <strong style={{color: '#055265',padding: '0 10px 0 10px', backgroundColor: '#ffc53dcf'}}> {Texts.searchEstablishmentsBanner.title1} </strong> </Fade> <Fade delay={1000} duration={2500} mountOnEnter><span style={{color: 'white', padding: '0 10px 0 10px', backgroundColor: 'rgba(106, 196, 216, 0.8)'}}> {Texts.searchEstablishmentsBanner.subTitle1} </span></Fade> </span>
                </Col>
            </Row>
            <Row className='bannerSubPages' style={{display: 'flex', justifyContent: 'center'}}>
                <div className='banner' style={{backgroundPosition: 'center', backgroundImage: `url(${Images.establishment.establishment_banner2})`}}/>
                <div className='bannerAppsBackground'/>
                <Col span={16} style={{alignItems: 'center', display: 'flex'}}>
                    <span style={{fontSize: '30px'}}> <Fade  duration={1000} mountOnEnter> <strong style={{color: '#055265',padding: '0 10px 0 10px', backgroundColor: '#ffc53dcf'}}> {Texts.searchEstablishmentsBanner.title2} </strong> </Fade> <Fade delay={1000} duration={2500} mountOnEnter><span style={{color: 'white', padding: '0 10px 0 10px', backgroundColor: 'rgba(106, 196, 216, 0.8)'}}> {Texts.searchEstablishmentsBanner.subTitle2} </span></Fade> </span>
                </Col>
            </Row>
        </Carousel>
    );
};

SearchEstablishmentsBanner.propTypes = {};

export default SearchEstablishmentsBanner;
