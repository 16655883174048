import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "antd";
import useLocalStorage from "../Hooks/Storage";
import useVerifyServices from "../Hooks/VerifyServices";
import ProfilePage from "../Components/PageComponents/Restrict/ClientPanelPage/ProfilePage";
import ShopListPage from "./ShopListPage";
import AddressesPage from "../Components/PageComponents/Restrict/ClientPanelPage/AddressesPage";
import CardsPointsPage from "../Components/PageComponents/Restrict/ClientPanelPage/CardsPointsPage";
import ClientPanelComponent from "../Components/PageComponents/Restrict/ClientPanelPage/ClientPanelComponent";
import {PrivateRoute} from "../Helpers/PrivateRoute";
import NotificationPage from "../Components/PageComponents/Restrict/ClientPanelPage/NotificationPage";
import {Redirect} from "react-router-dom";
import {Images} from "../Helpers/Images";
import MyRecruitmentsPage from "../Components/PageComponents/Restrict/ClientPanelPage/MyRecruitmentsPage";
import {useDispatch} from "react-redux";
import {StorageVariables} from "../Helpers/StoragesVariables";
import VacancyManagePage from "../Components/PageComponents/Restrict/ClientPanelPage/VacancyPage/VacancyManagePage";
import MyContactsManage from "../Components/PageComponents/Restrict/ClientPanelPage/CraftyPage/MyContactsManage";

const ClientPanelPage = (props) => {

    const {hasSineService, hasCraftyService, hasClubService} = useVerifyServices();
    const panelStorage = useLocalStorage(StorageVariables.lastPanel);
    const dispatch = useDispatch();


    useEffect(() => {
        window.scrollToElement('body');
        panelStorage.setPrimitive('client');
        dispatch({type: 'panel_profile', data: 'client'});
    },[]);

    return (<Row style={{display: 'flex', justifyContent: 'center', backgroundSize: '100%', backgroundRepeat: 'repeat-y', background: `url(${Images.background_4})`}}>
        <div style={{position: 'absolute', height: '100%', objectFit:'cover', width: '100%', zIndex: 0, background: 'linear-gradient(180deg, #e6e9ef2b 80%, white)'}}/>

        <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17} style={{paddingTop: 20}}>
            <PrivateRoute exact path={'/painel/cliente'} component={ClientPanelComponent}/>
            <PrivateRoute exact path={'/painel/cliente/perfil'} component={ProfilePage}/>
            <PrivateRoute exact path={'/painel/cliente/notificacoes'} component={NotificationPage}/>
            {hasClubService() && <PrivateRoute exact path={'/painel/cliente/cartoes'} component={CardsPointsPage}/>}
            {hasClubService() && <PrivateRoute exact path={'/painel/cliente/compras'} component={ShopListPage}/>}
            {hasSineService() && <PrivateRoute exact path={'/painel/cliente/curriculos/:type'} component={MyRecruitmentsPage}/>}
            {hasSineService() && <PrivateRoute exact path={'/painel/cliente/vagas'} component={VacancyManagePage}/>}
            {hasCraftyService() && <PrivateRoute exact path={'/painel/cliente/contatos'} component={MyContactsManage}/>}

        </Col>

    </Row>)
};
export default ClientPanelPage;
