import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Icon, Input, message, Row, Select, Spin} from "antd";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";
import moment from "moment";
import useApi from "../../../../../Hooks/Api";
import {FaBackward, FaEdit, FaForward, FaPlus, FaTrashAlt, FaUniversity} from "react-icons/fa";
import {MdWork} from "react-icons/md";

const durationList = [{value: 1, label: '1 Mês'},{value: 2, label: '2 Meses'},{value: 3, label: '3 Meses'},{value: 6, label: '6 Meses'},{value: 12, label: '1 Ano'},
    {value: 24, label: '2 Anos'},{value: 3, label: '3 Anos'},{value: 4, label: '4 Anos'}];

const CoursesComponent = (props) => {

    const coursesList = useRef([]);
    const [courseSelected, setCourseSelected] = useState(null);
    const {getFieldDecorator, getFieldsValue, setFieldsValue,getFieldsError, validateFields} = props.form;
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    const getCourses = async () => {
        setInitialLoading(true);
        api.get(`sine/user/${user.user_id}/courses`,(res) => {
            if(res.user_id !== null) {
                props.setHasProfile(true);
                if (res.courses?.length > 0) {
                    coursesList.current = res.courses;
                } else {
                    coursesList.current = [];
                }
                setInitialLoading(false);
            }
        }, (e) => {
            console.log(e);
            setInitialLoading(false);
        });
        window.scrollToElement('body')
    }

    const validAndFormatDate = (date) => {
        if(date) {
            if(moment(date, 'MM/YYYY').isValid()) {
                return moment(date, 'MM/YYYY').format('YYYY-MM');
            } else {
                console.log('nao eh valido');
                return false;
            }
        }
    }

    const handleAddCourse = () => {
        setCourseSelected({});
    };
    const handleSaveNewCourse = async (index) => {
        validateFields(async (error, values) => {
            if(error) return 0;
            setLoading(true);
            let aux = validAndFormatDate(values.end_date);
            if(aux !== false) values.end_date = aux;
            else {
                message.warning('Informe a data final corretamente (Dia/Mês)');
                setLoading(false);
                return 0;
            }

            message.loading('Adicionando...');
            api.post(`sine/user/${user.user_id}/course`, values, (res) => {
                setCourseSelected(null);
                message.destroy();
                message.success('Sucesso!');
                getCourses();
            }, (e) => {
                message.warning(e.message || 'Não foi possível salvar, tente novamente!');
                console.log(e);
            });

            setLoading(false);
        });
    };

    const handleEditCourse = async (id) => {
        validateFields(async (error, values) => {
            if(error) return 0;
            setLoading(true);

            let aux = validAndFormatDate(values.end_date);
            if(aux !== false) values.end_date = aux;
            else {
                message.warning('Informe a data final corretamente (Dia/Mês)');
                setLoading(false);
                return 0;
            }

            message.loading('Editando...');
            api.put(`sine/user/${user.user_id}/course/${id}`, values, (res) => {
                setCourseSelected(null);
                message.destroy();
                message.success('Sucesso!');
                getCourses();
                setLoading(false);
            },(e) => {
                message.warning(e.message || 'Não foi possível salvar, tente novamente!');
                setLoading(false);
                console.log(e);
            });
        });

    };

    const handleDeleteCourse = async (id) => {
        if(!loading) {
            setLoading(true);
            message.loading('Removendo...');
            api.del(`sine/user/${user.user_id}/course/${id}`, null,(res)=> {
                setCourseSelected(null);
                getCourses();
                message.destroy();
                message.success('Sucesso!');
                setLoading(false);
            },(e) => {
                message.warning(e.message || 'Não foi possível deletar, tente novamente!');
                console.log(e);
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        getCourses();
        window.scrollToElement('body')

    },[]);

    return (<div style={{marginBottom: 200}} className={'experienceForm aboutCss'}>

        <div style={{ paddingBottom: 35, borderBottom: '1px solid gainsboro',textAlign: 'center', marginTop: 50 }}>
            <h1>
                <strong style={{color: '#242424', fontSize: 25}}> Cursos realizados </strong>
            </h1>
            <span  style={{ fontSize: 20,color: '#9E9E9E'}}>   Adicione aqui caso tenha realizados cursos técnicos </span> <br/>
            <span  style={{ fontSize: 16,color: '#1890ff9e'}}>   Clique no botão adicionar, e adicione todos os cursos realizados por você </span> <br/>
        </div>


        <Row className={'formTitle'} style={{display: 'flex', marginBottom: 35, justifyContent: 'flex-end'}}>
           <Button className={'ant-btn-green'}  style={{visibility: courseSelected !== null && 'hidden', display: 'flex', fontSize: 16, alignItems: 'center'}} onClick={() => handleAddCourse()}> Adicionar  <FaPlus style={{color: 'white', fontSize: 17, marginLeft: 5}}/> </Button>
        </Row>

        {courseSelected && <Row  gutter={[24,24]} style={{marginBottom: 30, backgroundColor: 'white'}} className={'formRow'}>
            <Col xs={24} md={12} className={'formItem'}>
                <div className='resumeLabel'><label> <span>*</span> Nome </label> </div>
                {getFieldDecorator(`name`,
                    {
                        rules: [{required: true, message: 'O nome do curso é obrigatório'}],
                        initialValue: courseSelected.name? courseSelected.name : ''
                    }
                )(
                    <Input placeholder={'Nome do curso'} size={'large'}/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().name} </span> </div>

            </Col>
            <Col xs={24} md={12}  className={'formItem'}>
                <div className='resumeLabel'><label> <span>*</span> Instituição </label> </div>
                {getFieldDecorator(`institution`,
                    {
                        rules: [{required: true, message: 'O nome da instituição é obrigatório'}],
                        initialValue: courseSelected.institution? courseSelected.institution : ''
                    }
                )(
                    <Input placeholder={'Insitutição em que foi realizado'} size={'large'}/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().institution} </span> </div>

            </Col>
            <Col  xs={24} md={8}  className={'formItem'}>
                <div className='resumeLabel'><label> Duração </label> </div>
                {getFieldDecorator(`duration`,
                    {
                        rules: [],
                        initialValue: courseSelected.duration? (courseSelected.duration || undefined) : undefined
                    }
                )(
                    <Select
                        showSearch
                        placeholder="Selecione a duração do curso"
                        size={'large'}
                    >
                        {durationList.map((item, index) =>
                            <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                        )}
                    </Select>
                )}
                <small style={{color: 'gray'}}> *Se seu curso foi em horas, selecione 1 Mês </small>

            </Col>
            <Col xs={24} md={4}  className={'formItem'}>
                <div className='resumeLabel'><label> Fim </label> </div>
                {getFieldDecorator(`end_date`,
                    {
                        rules: [],
                        initialValue: courseSelected.end_date? (moment(courseSelected.end_date, 'YYYY-MM').format('MM/YYYY') || undefined) : undefined
                    }
                )(
                    <NumberFormat type={"tel"} className={'ant-input ant-input-lg'} format="##/####" placeholder="Mês/Ano"/>
                )}
            </Col>

            <Col className={'listButtons'} span={24}>
                {!courseSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-secondary'} onClick={() => handleSaveNewCourse()}> Salvar </Button>}
                {courseSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-secondary editButton'} onClick={() => handleEditCourse(courseSelected.id)}> Salvar  </Button>}
                {courseSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-red'} onClick={() => handleDeleteCourse(courseSelected.id)}> Remover </Button>}
            </Col>
        </Row>}

        {coursesList.current?.map((item, index) =>
            courseSelected?.id !== item.id&& <Row key={index} gutter={[24,24]} style={{marginBottom: 30, display: 'flex', backgroundColor: 'white'}} className={'formRow'}>
                <Col xs={10} md={10}  className={'listView'}>
                    <div> <MdWork/></div>
                    <span> {coursesList.current[index]?.name? coursesList.current[index]?.name : <Icon type="loading"/>} </span>
                </Col>
                <Col xs={10} md={10}  className={'listView'}>
                    <div> <FaUniversity/> </div>
                    <span> {coursesList.current[index]?.institution? coursesList.current[index]?.institution : <Icon type="loading"/>} </span>
                </Col>
                <Col xs={4} md={4} style={{justifyContent: 'flex-end', padding: 0, fontSize: 20, display: 'flex', alignItems: 'center'}}>
                    <div> <FaEdit className={'editButtonIcon'} onClick={() => setCourseSelected(item)}/> </div>
                    <div> <FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}} onClick={() => handleDeleteCourse(item.id)}/> </div>
                </Col>
            </Row>
        )}
        {(initialLoading && coursesList.current.length === 0)? <div className={'loadingResume'}> <Spin/> </div>
            :
            <div className={'buttonRow'} justify={'center'}>
                <Button shape={'round'} onClick={() => props.handleChangeStep(2)} type={'default'}> <FaBackward className={'exportedIconsLeft'}/> Voltar  </Button>
                <Button shape={'round'} onClick={() => props.handleChangeStep(4)} className={'ant-btn-sub-primary'}> Próximo <FaForward className={'exportedIcons'}/> </Button>
            </div>
        }

    </div>)
};
export default Form.create({name: 'coursesForm'})(CoursesComponent);
