import React from "react";
import {Icon} from "antd";

const Loading = () => {
    return (
        <div className="no-freeze-spinner" style={{margin: 'auto'}}>
        <div id="no-freeze-spinner">
            <div>
                <Icon type='idcard' className="material-icons"/>
                <Icon type='team' className="material-icons"/>
                <Icon type='shop' className="material-icons"/>
                <div></div>
            </div>
        </div>
        </div>
    );
};

Loading.propTypes = {};

export default Loading;
