import {combineReducers, createStore} from 'redux'
import authReducer from "./reducers/authReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import chatNotificationsReducer from "./reducers/chatNotificationsReducer";
import pushNotificationRequestReducer from "./reducers/pushNotificationRequestReducer";
import preferencesReducer from "./reducers/preferencesReducer";

const rootReducer = combineReducers({
    authReducer,
    chatNotificationsReducer,
    notificationsReducer,
    pushNotificationRequestReducer,
    preferencesReducer
});

const store = createStore(rootReducer);

export default store;

