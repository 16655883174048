import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Col, Rate, Row} from "antd";


const EstablishmentHeader = (props) => {

    return (
        <div style={{height: 200, display: 'flex', marginBottom: 50}}>
            <div className='serviceProviderHomeBanner'/>
            <Row className={'establishmentHeader'}>
                <Col xs={24} sm={16} span={16} style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{textAlign: 'center', marginRight: '40px'}}>
                        <Avatar style={{width: 130, height: 130}} size='default' src={props.avatar}/>
                    </div>
                    <div>
                        <div>
                            <span style={{fontSize: '25px', fontWeight: 'bold', color: '#333'}}> {props.name} </span>
                        </div>
                        <div>
                            <span style={{fontSize: '20px', color: 'gray'}}>{props.occupations} </span>
                        </div>
                        <div>
                            <span><Rate disabled allowHalf={true} defaultValue={props.userRating} /> ({props.userRatingCount}) </span>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

EstablishmentHeader.propTypes = {
    userRatingCount: PropTypes.any,
    userRating: PropTypes.any,
    name: PropTypes.any,
    avatar: PropTypes.any,
    occupations: PropTypes.any
};

export default EstablishmentHeader;
