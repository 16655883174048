import React, {useEffect, useState} from 'react'
import {Row, Col} from "antd";
import {Images} from "../Helpers/Images";
import {Texts} from "../Helpers/Texts";
import { useHistory, browserHistory} from "react-router-dom";
import useApi from "../Hooks/Api";
import Loading from "../Components/Generic/Loading";
import GenericCommonQuestions from "./Components/GenericCommonQuestions";
import {Fade} from "react-reveal";

const CommonQuestions = () => {
    let history = useHistory()
    let baseURL = `/perguntas-frequentes`

    const [loading, setLoading] = useState(false);
    const [activeCard, setActiveCard] = useState(null);
    const [titleActiveCard, setTitleActiveCard] = useState()
    const [visible, setVisible] = useState(false)

    const [cardsQuestions, setCardsQuestions] = useState([{
        title: `Como divulgar uma vaga`,
        description: `lalalalal l laalla lal la la lal alall`,
        questions: [
            {
                question: `Como registro minhas perguntas?`,
                answer: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.!`,
                link: null
            },
            {
                question: `Eu não sei o que estou fazendo?`,
                answer: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.`,
                link: `www.google.com.br`
            },
            {
                question: `Eu já quis alugar uma casa, mas não tem uma?`,
                answer: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using `,
                link: null
            },
            {
                question: `Au au o leão vai te morder?`,
                answer: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.`,
                link: null
            },
            {
                question: `Jà pensou se o sol explode?`,
                answer: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.`,
                link: null
            },
        ]
    },
        {
            title: `Como encontrar um emprego pelo MS CONTRATA+`,
            description: `lalalalal l laalla lal la la lal alall`,
            questions: [
                {
                    question: `First question?`,
                    answer: `First Answer!`,
                    link: null
                },
                {
                    question: `Second question?`,
                    answer: `Second Answer!`,
                    link: `www.google.com.br`
                },
                {
                    question: `Third question?`,
                    answer: `Third Answer!`,
                    link: null
                },
                {
                    question: `Fourth question?`,
                    answer: `Fourth Answer!`,
                    link: null
                },
                {
                    question: `First question?`,
                    answer: `First Answer!`,
                    link: null
                },
                {
                    question: `First question?`,
                    answer: `First Answer!`,
                    link: null
                },
            ]
        },
        {
            title: `Como Divulgar meus serviços de prestador`,
            description: `lalalalal l laalla lal la la lal alall`,
            questions: [
                {
                    question: `First question?`,
                    answer: `First Answer!`,
                    link: null
                },
                {
                    question: `Second question?`,
                    answer: `Second Answer!`,
                    link: `www.google.com.br`
                },
                {
                    question: `Third question?`,
                    answer: `Third Answer!`,
                    link: null
                },
                {
                    question: `Fourth question?`,
                    answer: `Fourth Answer!`,
                    link: null
                },
                {
                    question: `First question?`,
                    answer: `First Answer!`,
                    link: null
                },
            ]
        }
        , {
            title: `Como encontrar prestadores de serviço`,
            description: `lalalalal l laalla lal la la lal alall`,
            questions: [
                {
                    question: `First question?`,
                    answer: `First Answer!`,
                    link: null
                },
                {
                    question: `Second question?`,
                    answer: `Second Answer!`,
                    link: `www.google.com.br`
                },
                {
                    question: `Third question?`,
                    answer: `Third Answer!`,
                    link: null
                },
                {
                    question: `Fourth question?`,
                    answer: `Fourth Answer!`,
                    link: null
                },
                {
                    question: `First question?`,
                    answer: `First Answer!`,
                    link: null
                },
            ]
        }]);

    let filterTitleURL

    const handleActiveCard = (questionObj, cardTitle) => {
        let filterTitle = cardTitle.toLowerCase().replace(/\s+/g, '-')
        filterTitleURL = filterTitle
        setTitleActiveCard(cardTitle)
        setActiveCard(questionObj)
        history.push(`${baseURL + '/' + filterTitleURL}`)
        setVisible(true)
    }

    const handleBack = (value) => {
        history.push(baseURL)
        setVisible(value)
    }

    useEffect(() => {
        if (window.location.pathname === baseURL) {
            setVisible(false)
        } else if (window.location.pathname === `${baseURL + '/' + filterTitleURL}`) {
            setVisible(true)
        }
        window.scrollToElement('body');
    }, [window.location.pathname]);

    return (
        <Row style={{display: "flex", justifyContent: "center", minHeight: 400, marginTop: 32}}>

            {visible === false ? <Col xs={23} lg={22} xl={18} xxl={15} style={{
                    textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around"
                }}><Fade delay={400}>

                    <Row style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <Col sm={20} lg={20} xl={19} xxl={16} className='headerHowTo'
                             style={{padding: 5, textAlign: 'center'}}>
                            <img style={{width: 100, marginBottom: 16}} src={Images.commonQuestions}/>
                            <h1 style={{position: 'relative'}} className={'title title-line'}>Perguntas frequentes </h1>
                        </Col>
                    </Row>
                    {loading ?
                        <Loading/>
                        :
                        <Row gutter={[32, 32]}
                             style={{display: "flex", width: "100%", flexFlow: "wrap row", alignItems: "stretch"}}>
                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-around"
                            }}>
                                {cardsQuestions.map((question) => {
                                    return (
                                        <Col xs={24} md={12} lg={8}>
                                            <div onClick={() => {handleActiveCard(question.questions, question.title);}} className="commons-questions card-questions-blue">
                                                <div>
                                                    <img
                                                        style={{filter: 'brightness(0.2)', width: 50, marginBottom: 16}}
                                                        src={Images.home.resume_card_icon}/>
                                                </div>
                                                <div>
                                                    <h1> {question.title} </h1>
                                                </div>
                                                <div>
                                                    <span>{question.description}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </div>
                        </Row>
                    }
                </Fade>
                </Col> :
                <GenericCommonQuestions activeCard={activeCard} titleActiveCard={titleActiveCard} handleBack={handleBack}/>
            }
        </Row>
    )
}

export default CommonQuestions
