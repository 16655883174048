import React from 'react';
import {Env} from "../Helpers/Env";

const useVerifyServices = () => {

    const hasSineService = () => {
        return Env.services_available.indexOf('sine') !== -1;
    };

    const hasClubService = () => {
        return Env.services_available.indexOf('club') !== -1;
    };

    const hasCraftyService = () => {
        return Env.services_available.indexOf('crafty') !== -1;
    };

    const hasAll = () => {
        return Env.services_available.length === 3;
    };

    const hasTwo = () => {
        return Env.services_available.length === 2;
    };

    const hasAtLeastTwo = () => {
        return Env.services_available.length >= 2;
    };

    const hasOnlyOne = () => {
        return Env.services_available.length === 1;
    };

    const hasOnlyCrafty = () => {
        return hasOnlyOne() && hasCraftyService();
    };

    const hasOnlySine = () => {
        return hasOnlyOne() && hasSineService();
    };

    const hasOnlyClub = () => {
        return hasOnlyOne() && hasClubService();
    };

    return {hasClubService, hasCraftyService, hasSineService, hasAll, hasTwo, hasAtLeastTwo, hasOnlyOne, hasOnlyClub, hasOnlyCrafty, hasOnlySine}
};

export default useVerifyServices;
