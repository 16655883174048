import React, {useEffect, useState} from 'react';

import Loading from "../Components/Generic/Loading";
import CreateAccountBanner from "../Components/PageComponents/Public/HomePage/CreateAccountBanner";
import BannerApps from "../Components/PageComponents/Public/HomePage/BannerAps";
import TopComponent from "../Components/PageComponents/Public/WorkHomePage/TopComponent";
import HowToWorkerComponent from "../Components/PageComponents/Public/WorkHomePage/HowToWorker";
import {Images} from "../Helpers/Images";
import {Row} from "antd";


const WorkHomePage = props => {

    const [loading, setLoading] = useState(true);

    const handleLoading = () => {
        setTimeout(() => {
            setLoading(false);
        },[500]);
    };

    useEffect(() => {
        window.scrollToElement('body');
        handleLoading();
    },[]);

    return (
        <div style={{backgroundSize: '100%', backgroundRepeat: 'repeat-y', background: `url(${Images.background_2})`}}>
            <div style={{position: 'absolute', height: '100%', objectFit:'cover', width: '100%', zIndex: 0, background: 'linear-gradient(180deg, transparent 80%, white)'}}/>

            <div style={{position: 'absolute', width: '100%', transition: '500ms', marginTop: '20%', display: 'flex', opacity: !loading? 0: 1}}> <div style={{margin: 'auto'}}> <Loading/>  </div> </div>
            <div id={'mainComponentId2'} style={{opacity: loading? 0: 1, transition: '500ms'}}>
                <TopComponent/>
                <HowToWorkerComponent/>
               {/* <CreateAccountBanner/>*/}
                <BannerApps/>
            </div>
        </div>
    );
};

WorkHomePage.propTypes = {};

export default WorkHomePage;
