import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Input} from "antd";
import {Fade} from "react-reveal";
import {Images} from "../../../../Helpers/Images";


const ForgotForm = props => {
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');

    return (
        <div  style={{paddingTop: '70px'}}>
            <Fade mountOnEnter>
                <div>
                    <div className='loginFormMobile' style={{maxWidth: '100%', margin: "auto", padding: '30px', borderRadius: '10px'}}>
                        <>
                            <div className='logo' style={{marginBottom: 50, textAlign: 'center'}}>
                                <img style={{maxWidth: 288}} src={Images.logo} />
                            </div>
                            <div style={{textAlign: "center", margin: 20, fontSize: 20}}>
                                <span> Digite seu CPF e e-mail para recuperar sua senha. </span>
                            </div>
                            <div>
                                <label style={{fontSize: 17, fontWeight: 'bold'}}> CPF </label>
                                <Input className='defaultInput' size={'large'}
                                       placeholder={'Seu CPF'} value={cpf} onChange={(e) => setCpf(e.target.value)} suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}/>
                            </div>

                            <div>
                                <label style={{fontSize: 17, fontWeight: 'bold'}}> E-mail  </label>
                                <Input placeholder={'Seu Email'} className='defaultInput' size={'large'}
                                       value={email} onChange={(e) => setEmail(e.target.value)} suffix={<Icon type="mail" className={'primary-sub-color'} style={{ fontSize: '17px'}}/>}/>
                            </div>


                            <Button loading={props.loading} style={{width: '100%', marginTop: 25}} size={'large'} onClick={() => props.doForgotPassword(cpf,email)}> Recuperar Senha </Button>
                        </>
                    </div>
                </div>
            </Fade>
        </div>
    );
};

ForgotForm.propTypes = {
    doForgotPassword: PropTypes.any,
    setForgot: PropTypes.any
};

export default ForgotForm;
