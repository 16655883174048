import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, message, Row, Select, Spin} from "antd";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";
import Popover from "antd/es/popover";
import useApi from "../../../../../Hooks/Api";
import {FaCheckCircle, FaCircle, FaPhone, FaPlus, FaTrashAlt} from "react-icons/fa";
import {maskPhone} from "../../../../../Helpers/Functions";

const PhonesComponent = (props) => {

    const phonesList = useRef([]);
    const [phoneSelected, setPhoneSelected] = useState(undefined);
    const {getFieldDecorator, getFieldsValue, getFieldsError, validateFields} = props.form;
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    const getPhones = async () => {
        setInitialLoading(true);

        api.get(`sine/user/${user.user_id}/profile`, (res) => {
            if(res.profile.phone_numbers?.length > 0) {
                phonesList.current = res.profile.phone_numbers;
            } else {
                phonesList.current = [];
                handleAddPhone();
            }
            setInitialLoading(false);
        }, (e) => {
            console.log(e);
            setInitialLoading(false);
        });
    }


    const handleAddPhone = () => {
        let aux = phonesList.current.concat(0);
        phonesList.current = aux;
        setPhoneSelected(aux.length - 1);
    };

    const validatePhone = (phone) => {
        let auxPhone = phone.replace(/\D/ig, '');

        if(auxPhone.length !== 11 || !["6","7","8","9"].includes(auxPhone[2])) {
            message.warning('Digite um Número valido');
            return false;
        } else {
            return true;
        }
    };

    const handleSaveNewPhone = async (index) => {
        validateFields([`phones[${index}].number`], async (error, values) => {
            if(error) return 0;
            let aux = validatePhone(values.phones[index].number);
            if(aux) {
                values.phones[index].number = values.phones[index].number.replace(/\D/ig, '');
            } else {
                return 0;
            }
            setLoading(true);

            message.loading('Adicionando...');
            api.post(`sine/user/${user.user_id}/phone-number`, {number: values.phones[index].number, primary: false}, (res) => {
                setPhoneSelected(undefined);
                message.destroy();
                message.success('Sucesso!');
                getPhones();
                setLoading(false);
            }, (e) => {
                setLoading(false);
                console.log(e);
            });
        });
    };

    const handleDeletePhone = async (index) => {
        if(!loading) {
            setLoading(true);
            message.loading('Removendo...');

            api.del(`sine/user/${user.user_id}/phone-number/${phonesList.current[index].id}`, null,(res) => {
                setPhoneSelected(undefined);
                getPhones();
                message.destroy();
                message.success('Sucesso!');
                setLoading(false);
            }, (e) => {
                console.log(e);
                setLoading(false);
            });
        }
    };

    const handlePrimary = async (number) => {
        if (!loading) {
            setLoading(true);
            message.loading('Editando...');
            api.put(`sine/user/${user.user_id}/phone-number`, {number: number, primary: true}, (res) => {
                setPhoneSelected(undefined);
                message.destroy();
                message.success('Sucesso!');
                getPhones();
            }, (e) => {
                console.log(e);
                setLoading(false);
            });
        }
    };


    useEffect(() => {
        getPhones();
    },[]);

    return (<div style={{marginBottom: 50}}>

        <div className={'formTitle'} style={{display: 'flex', justifyContent: 'space-between'}}>
            <span style={{fontSize: 20}}> Telefones </span>
            {phonesList.current.indexOf(0) === -1 &&<Button shape={'circle'} className={'ant-btn-green'}  style={{padding: 5}} onClick={() => handleAddPhone()}> <FaPlus style={{color: 'white', fontSize: 17, marginLeft: 1, marginTop: 1}}/> </Button>}
        </div>

        {phonesList.current?.map((item, index) =>
            <Row key={index} gutter={[0,24]} style={{marginBottom: 30, marginLeft: 0, backgroundColor: 'white'}} className={'formRow'}>
                {phoneSelected !== index&& <>
                    <Col span={20} className={'listView'}>
                       <FaPhone/>
                        <span> {phonesList.current && maskPhone(phonesList.current[index]?.number)} </span>
                    </Col>
                    <Col span={4} style={{justifyContent: 'flex-end', padding: 0, fontSize: 20, display: 'flex', alignItems: 'center'}}>
                        {phonesList.current[index]?.primary ? <Popover content={'Número principal'}> <FaCheckCircle style={{color: 'limegreen', verticalAlign: 'middle'}}/> </Popover> :
                            <Popover content={'Tornar principal'}> <FaCircle style={{color: 'gainsboro', verticalAlign: 'middle'}} onClick={() => handlePrimary(phonesList.current[index]?.number)}/> </Popover>
                        }

                        <FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}} onClick={() => handleDeletePhone(index)}/>
                    </Col>
                </>}
                <Col style={{display: phoneSelected !== index&& 'none'}} xs={24} md={24} className={'formItem'}>
                    <div className='resumeLabel'><label> Telefone </label> </div>
                    {getFieldDecorator(`phones[${index}].number`,
                        {
                            rules: [{required: true}],
                            initialValue: item? (item.number || '') : ''
                        }
                    )(
                        <NumberFormat
                            type={"tel"}
                            placeholder={'(99) 99999-9999'}
                            className='ant-input ant-input-lg'
                            format={'(##) #####-####'} mask="_"/>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().phones[index].number} </span> </div>

                </Col>

                {index === phoneSelected &&<Col className={'listButtons'} span={24}>
                    {!item.id && <Button loading={loading} disabled={loading} className={'greenSaveButton'} onClick={() => handleSaveNewPhone(index)} type={'primary'}> Salvar </Button>}
                    {item.id && <Button loading={loading} disabled={loading} className={'removeButton'} onClick={() => handleDeletePhone(index)} type={'primary'}> Remover </Button>}
                </Col>}
            </Row>

        )}
        {(initialLoading && phonesList.current.length === 0)&&<div className={'loadingResume'}> <Spin/> </div>}
    </div>)
};
export default Form.create({name: 'phonesForm'})(PhonesComponent);

