import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Divider, Form, Icon, Input, message, Modal, Radio, Rate} from "antd";
import useApi from "../../../../../Hooks/Api";
import {useValidationShorts} from "../../../../../Hooks/Helpers";
import moment from "moment";
import {Fade} from "react-reveal";
import {Images} from "../../../../../Helpers/Images";

const RateOrderModal = props => {
    const [message, setMessage] = useState("");
    const [rating, setRating] = useState(1);
    const api = useApi({navigation: props.navigation});
    const [step, setStep] = useState('initial');
    const refToast = useRef();

    const handleSubmit = async () => {
        if(rating === 0) {
            message.error('Avalie com uma ou cinco estrelas para continuar');
        } else {
            setStep('loading');
            api.post(`worker-contact/${props.profile?.id}/review`, {review: message === ""? null : message, rating: rating, price: 1 }, (res) => {
                setStep('sent');
            }, (e) => {
                message.error(e.message || 'Não foi realizar esta ação, tente novamente');
                setStep('initial');
                console.log(e);
            });
        }
    };

    const handleNoRate = () => {
        setStep('loading');
        api.post(`worker-contact/${props.profile?.id}/dismiss-review`, null, (res) => {
            props.handleAfterFeedback();
        }, (e) => {
            message.error(e.message || 'Não foi realizar esta ação, tente novamente');
            setStep('initial');
            console.log(e);
        });
    }

    const {required} = useValidationShorts();

    return (
        <Modal
            className={'defaultModal defaultModalSecondary'}
            destroyOnClose
            style={{textAlign: 'center'}}
            visible={true}
            onCancel={() => {props.hide(false);}}
            footer={[
                step === `initial`&&<div style={{marginBottom: 20, textAlign: 'center', position: "relative", zIndex: 2}}>
                    <Button  disabled={step === `loading`} onClick={() => handleSubmit()} key="submit" className={'ant-btn-secondary'} loading={step === `loading`} >
                        <span style={{marginRight: 5}}>
                            Enviar Avaliação
                        </span>
                        <img height={16} width={16} src={Images.agenda.requested}/>
                    </Button>
                    <div style={{marginTop: 10, cursor: `pointer`}} onClick={() => handleNoRate()}>
                        <span style={{color: '#9e9e9e'}}>
                            Não quero avaliar
                        </span>
                    </div>
                </div>,
                <img src={Images.bottom_waves} style={{objectFit: 'cover',position: 'absolute', width: '100%', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, left: 0, height: 60, bottom: 0}}/>
            ]}>
            {step === `initial` ? <div style={{overflow: 'hidden'}}>
                    <Fade mountOnEnter unmountOnExit>
                        <div style={{display: 'flex', flexFlow: `column`, justifyContent: `center`, alignItems: `center`}}>
                            <div>
                                <Avatar style={{height: 65, width: 65, marginRight: 10}}
                                        src={props.profile.worker.profile_picture || Images.user_place_holder}/>
                            </div>
                            <div style={{textAlign: 'center', display: `flex`, flexFlow: `column`, justifyContent: `center`}}>
                                <div>
                                    <span style={{
                                    fontSize: 17,
                                    fontWeight: 'bold',
                                    color: "black"
                                }}> {props.profile.worker?.name}</span>
                                </div>
                                <div>
                                    <span style={{
                                    fontSize: 15,
                                    color: '#9e9e9e'
                                }}> {props.profile.occupation?.name} </span>
                                </div>
                            </div>
                        </div>
                        <Divider/>
                        <Form style={{textAlign: 'center'}}>
                            <div style={{marginBottom: 30}}>
                                <span style={{fontSize: 17}}> Nota do atendimento </span> <br/> <br/>
                                <Rate style={{display: `flex`, margin: `auto`, justifyContent: `space-between`, width: `100%`, maxWidth: 400, fontSize: 27}} defaultValue={rating} onChange={(e) => setRating(e)} value={rating} />
                            </div>
                            <div>
                                <span style={{fontSize: 17}}> Escreva aqui sua avaliação, caso ache necessária </span> <br/><br/>
                                <Input.TextArea rows={3} value={message} onChange={(e) => setMessage(e.target.value)}/>
                            </div>
                        </Form>
                    </Fade>
                </div>
                :
                <Fade mountOnEnter unmountOnExit>
                    <div>
                        <div style={{marginBottom: 20, fontSize: 25}}>
                            <span> {step === `loading` ? 'Enviando' : 'Avaliação Enviada!'} </span></div>
                        <div className={'modalLoader'}>
                            <div className={step === `loading` ? "circle-loader" : "circle-loader load-complete"}>
                                <div style={{display: step === `sent` ? 'block' : 'none'}} className="checkmark draw"/>
                            </div>
                            <Fade when={step === `sent`}>
                                <Button icon={'check'} style={{zIndex: 2000}} onClick={() => {
                                    props.handleAfterFeedback();
                                    props.hide(false);
                                }} className={'ant-btn-primary2'} type={"primary"}> Ok </Button>
                            </Fade>
                        </div>
                    </div>
                </Fade>
            }
        </Modal>

    );
};

RateOrderModal.propTypes = {
    itemToFeedback: PropTypes.any,
    hide: PropTypes.any,
    setShowRateModal: PropTypes.any
};

export default RateOrderModal;
