import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Input, message, Row, Select, Spin} from "antd";
import {createForm} from "rc-form";
import {useSelector} from "react-redux";
import Popover from "antd/es/popover";
import {useValidationShorts} from "../../../../../Hooks/Helpers";
import useApi from "../../../../../Hooks/Api";
import {FaCheckCircle, FaCircle, FaMailBulk, FaPlus, FaTrashAlt} from "react-icons/fa";

const EmailsComponent = (props) => {

    const emailsList = useRef([]);
    const [emailSelected, setEmailSelected] = useState(undefined);
    const {getFieldDecorator, getFieldsValue, getFieldsError, validateFields} = props.form;
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const {isEmail} = useValidationShorts();

    const getEmails = async () => {
        setInitialLoading(true);
        api.get(`sine/user/${user.user_id}/profile`, (res) => {
            if(res.user_id !== null) {
                props.setHasProfile(true);
                if (res.profile.emails?.length > 0) {
                    emailsList.current = res.profile.emails;
                } else {
                    emailsList.current = [];
                    handleAddEmail();
                }
                setInitialLoading(false);
            }
        }, (e) => {
            console.log(e);
            setInitialLoading(false);
        });
    };


    const handleAddEmail = () => {
        let aux = emailsList.current.concat(0);
        emailsList.current = aux;
        setEmailSelected(aux.length - 1);
    };

    const handleSaveNewEmail = async (index) => {
        validateFields([`emails[${index}].email`], async (error, values) => {
            if(error) return 0;
            setLoading(true);
            message.loading('Adicionando...');
            api.post(`sine/user/${user.user_id}/email`, {email: values.emails[index].email ,primary: false}, (res) => {
                setEmailSelected(undefined);
                message.destroy();
                message.success('Sucesso!');
                getEmails();
                setLoading(false);
            },(e) => {
                console.log(e);
                message.error(e.message || 'Não foi possível seu email tente novamente!');
                setLoading(false);
            });
        });
    };

    const handlePrimary = async (email) => {
        if (!loading) {
            setLoading(true);
            message.loading('Editando...');
            api.put(`sine/user/${user.user_id}/email`, {email: email, primary: true}, (res) => {
                setEmailSelected(undefined);
                message.destroy();
                message.success('Sucesso!');
                getEmails();
                setLoading(false);
            }, (e) => {
                message.warning(e.message || 'Não foi possível salvar, tente novamente!');
                console.log(e);
                setLoading(false);
            });
        }
    };

    const handleDeleteEmail = async (index) => {
        if(!loading) {
            setLoading(true);
            message.loading('Removendo...');
            api.del(`sine/user/${user.user_id}/email/${emailsList.current[index].id}`, null,(res) => {
                setEmailSelected(undefined);
                getEmails();
                message.destroy();
                message.success('Sucesso!');
                setLoading(false);
            },(e) => {
                message.warning(e.message || 'Não foi possível deletar, tente novamente!');
                console.log(e);
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        getEmails();
    },[]);

    return (<div style={{marginBottom: 50}}>

        <div className={'formTitle'} style={{display: 'flex', justifyContent: 'space-between'}}>
            <span style={{fontSize: 20}}> Emails </span>
            {emailsList.current.indexOf(0) === -1 &&<Button className={'ant-btn-green'} shape={"circle"} style={{padding: 5}} onClick={() => handleAddEmail()}>  <FaPlus style={{color: 'white', fontSize: 17, marginLeft: 1, marginTop: 1}}/> </Button>}
        </div>

        {emailsList.current?.map((item, index) =>
            <Row key={index} gutter={[0,24]} style={{marginBottom: 30, backgroundColor: 'white', marginLeft: 0}} className={'formRow'}>
                {emailSelected !== index&& <>
                    <Col span={20} className={'listView'}>
                        <FaMailBulk/>
                        <span> {emailsList.current[index]?.email} </span>
                    </Col>
                    <Col span={4} style={{justifyContent: 'flex-end', padding: 0, fontSize: 20, display: 'flex', alignItems: 'center'}}>
                       {emailsList.current[index]?.primary ? <Popover content={'Email principal'}> <FaCheckCircle style={{color: 'limegreen', verticalAlign: 'middle'}}/> </Popover> :
                            <Popover content={'Tornar principal'}> <FaCircle style={{color: 'gainsboro', verticalAlign: 'middle'}} onClick={() => handlePrimary(emailsList.current[index]?.email)}/> </Popover>
                        }
                        <FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}} onClick={() => handleDeleteEmail(index)}/>
                    </Col>
                </>}
                <Col style={{display: emailSelected !== index&& 'none'}} xs={24} md={24}  className={'formItem'}>
                    <div className='resumeLabel'><label> Email </label> </div>
                    {getFieldDecorator(`emails[${index}].email`,
                        {
                            rules: [{required: true, message: '- Email é obrigatório'}, isEmail()],
                            initialValue: item? (item.email || '') : ''
                        }
                    )(
                        <Input placeholder={'Seu email'} size={'large'}/>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().emails[index].email} </span> </div>
                </Col>

                {index === emailSelected &&<Col className={'listButtons'} span={24}>
                    {!item.id && <Button loading={loading} disabled={loading} className={'greenSaveButton'} onClick={() => handleSaveNewEmail(index)} type={'primary'}> Salvar  </Button>}
                    {item.id && <Button loading={loading} disabled={loading} className={'removeButton'} onClick={() => handleDeleteEmail(index)} type={'primary'}> Remover </Button>}
                </Col>}
            </Row>

        )}
        {(initialLoading && emailsList.current.length === 0)&&<div className={'loadingResume'}> <Spin/> </div>}
    </div>)
};
export default Form.create({name: 'emailsForm'})(EmailsComponent);
