import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Button, Form, Icon, List, message, Modal, Popconfirm, Row, Skeleton} from "antd";
import useApi from "../../../../Hooks/Api";
import useAuth from "../../../../Hooks/Auth";
import Loading from "../../../Generic/Loading";
import {Images} from "../../../../Helpers/Images";
import NotFound from "../../../Generic/NotFound";
import AddressCardForm from "./AddressesPage/AddressCardForm";
import {useSelector} from "react-redux";



const AddressesPage = props => {
    const type = {apartment: 'Apartamento', house: 'Casa', condominium: 'Condomínio', commercial: 'Ímovel Comercial', farmhouse: 'Chácara'};
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const [addresses, setAddresses] = useState({});
    const [loading,setLoading] = useState(true);
    const {validateFields} = props.form;
    const [addAddress, setAddAddress] = useState(false);
    const addressToEdit = useRef();

    const getData = async () => {
        setLoading(true);
        api.get(`user/${user.user_id}/addresses`, (res) => {
            setAddresses(res.addresses);
            setLoading(false);
        }, (e) => {
            message.error('Ops, não foi possível recuperar seus endereços, tente novamente')
            setLoading(false);
        });
    }

    const handleSave = () => {
        validateFields(async (error, values) => {
            if(error) return 0;

            values.street_number = Number(values.street_number);
            values.cep = window.getNumbers(values.cep);
            values.is_primary = false;
            values.is_public = false;
            if(addressToEdit.current) {
                api.post(`user/${user.user_id}/address/${addressToEdit.current.address_id}`, values, (res) => {
                    message.success('Seu endereço foi Editado!');
                    setAddAddress(false);
                    getData();
                }, (e) => {
                    console.log(e);
                    message.warning(e.message || 'Não foi possível salvar, tente novamente!');
                });
            }
            else {
                api.put(`user/${user.user_id}/address`, values, (res) => {
                    message.success('Endereço adicionado!');
                    setAddAddress(false);
                    getData();
                }, (e) => {
                    console.log(e);
                    message.warning(e.message || 'Não foi possível salvar, tente novamente!');
                });
            }
        })
    }

    const handleDelete = async (id) => {
        message.loading('Removendo Endereço');
        api.del(`user/${user.user_id}/address/${id}`, null, (res) => {
            message.destroy();
            message.success('Endereço Removido');
            getData();
        }, (e) => {
            message.error(e.message || 'Ops, não foi possível Remover seu endereço')
        });
    }

    useEffect(() => {
        getData()
    },[]);


    return (
        <Row gutter={[22,22]} className={'mainComponentPanel'}>
            <Row className={'primary-sub-color'} style={{fontSize: 20, marginBottom: 15}}>
                <div>
                    <Icon type={'home'}/>
                    <span > Seus endereços </span>
                    <Button style={{float: 'right', marginRight: 7}} icon={'plus'} className={'ant-btn-primary2'} type={'primary'} onClick={() => {addressToEdit.current = ''; setAddAddress(true)}}> Adicionar endereço </Button>
                </div>
            </Row>
            {loading?
                <div style={{margin: "auto"}}> <Loading/> </div>
                :(addresses.length > 0)?
                <Row gutter={[10,20]}>
                    <List
                        className='listAnt listAntAddresses'
                        style={{overflow: 'auto', minHeight: '600px'}}
                        itemLayout="horizontal"
                        dataSource={addresses}
                        loading={api.loading}
                        renderItem={(item,index) => (
                            <List.Item key={index} className={'addressItem'} actions={[
                                    <Button type="primary" className={'ant-btn-primary2'} onClick={() => {addressToEdit.current = item; setAddAddress(true)}} icon="edit"/>, api.loading ? <Icon type="loading" key="setting" />
                                    :
                                    <Popconfirm
                                        title="Você tem certeza que deseja excluir este endereço?"
                                        onConfirm={() => handleDelete(item.address_id)}
                                        onCancel={() => {}}
                                        okText="Sim"
                                        cancelText="Não"
                                        placement={"bottomRight"}
                                    >
                                        <Button icon={'delete'} style={{color: "red", borderColor: 'red'}}/>
                                    </Popconfirm>
                            ]}>

                                <Skeleton avatar title={false} loading={false} active>
                                    <List.Item.Meta
                                        style={{alignItems: 'center'}}
                                        onClick={() => {addressToEdit.current = item; setAddAddress(true)}}
                                        avatar={
                                            <Avatar style={{width: 64, height: 64}} size='default' src={
                                                (item.address_type === 'house')? Images.addresses.house :
                                                (item.address_type === 'apartment')? Images.addresses.apartment :
                                                (item.address_type === 'condominium')? Images.addresses.condominium  :
                                                (item.address_type === 'commercial')? Images.addresses.commercial  :
                                                (item.address_type === 'farmhouse')&& Images.addresses.farm
                                            }/>
                                        }
                                        title={<span> <span style={{fontWeight: "bold"}}>{item.name}  </span> </span>}
                                        description={<span>
                                            <span style={{fontSize: 15}}> {item.street_name}, <strong> Nº </strong> {item.street_number} </span> <br/>
                                            <span style={{fontSize: 15}}> {item.district}  </span> <br/>
                                            <span style={{fontWeight: 'bold'}}>  {item.city} - {item.state} </span> </span> }
                                    />
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </Row>
                    :
                    <NotFound mb={-30} title={<span style={{ fontSize: 25, fontWeight: 'bold'}}> Você não possui nenhum endereço cadastrado! </span>}
                        subTitle={'Cadastre aqui seus endereços, para poder solicitar serviços para onde quiser.'}
                        img={Images.address_not_found}
                    />
            }
            <Modal
                className={'defaultModal defaultModalDarkBlue'}
                destroyOnClose
                title={<span style={{fontSize: 22, color: 'white'}}><Icon type={'home'}/> Novo Endereço </span>} width={650}
                visible={addAddress}
                onCancel={() => setAddAddress(false)}
                footer={[
                    <div style={{paddingRight: 25, position: "relative", zIndex: 2}}>
                        <Button icon={'check'} disabled={api.loading} onClick={() => handleSave()} key="submit" type="primary" className={'ant-btn-sub-primary2'} loading={api.loading} >
                            {addressToEdit.current? 'Editar' : 'Adicionar'}
                        </Button>
                    </div>,
                    <img src={Images.bottom_waves} style={{objectFit: 'cover', position: 'absolute', width: '100%', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, left: 0, height: 60, bottom: 0}}/>
                ]}>
                <AddressCardForm addressToEdit={addressToEdit.current} handleSave={() => handleSave()} getList={() => getData()} form={props.form}/>
            </Modal>

        </Row>
    );
};

AddressesPage.propTypes = {};

export default Form.create({name: 'addresses_profile'})(AddressesPage);
