import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Collapse, Icon, notification, Row} from "antd";
import useAuth from "../../../../Hooks/Auth";
import {useHistory} from "react-router-dom";
import {Images} from "../../../../Helpers/Images";
import RegisterPageModal from "../../../../Pages/RegisterPageModal";
import Spin from "antd/lib/spin";


const daysPT = {
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sabado',
    sunday: 'Domingo'
};

const ServiceProviderExtras = (props) => {
    const {isLogged} = useAuth();
    const history = useHistory();
    const [registerModal, setRegisterModal] = useState(false);

    const handleHire = () => {
        if(isLogged()) {
            props.requestContact(true);
        }
        else {
            setRegisterModal('login');
        }
    };

    return (
        <Row gutter={[0,32]}>
            <Col span={24}>
                {props.requestContactLoading ?
                    <div style={{width: '100%', display: `flex`, alignItems: "center", justifyContent: "center", height: 46, fontSize: 20, borderRadius: 4, border: '1px solid #41d141'}}>
                        <Icon style={{color: `#41d141`}} type={`loading`}/>
                    </div>
                    : props.phoneNumber ?
                        <div style={{width: '100%', display: `flex`, color: "#41d141", alignItems: "center", justifyContent: "center", height: 46, fontSize: 20, borderRadius: 4, border: '1px solid #41d141'}}>
                            <Icon style={{color: "#41d141", marginRight: 10}} type={'phone'}/>
                            <span>{window.maskViewPhone(props.phoneNumber)}</span>
                        </div>
                        :
                        <Button className='greenHoverButton' onClick={() => handleHire()} size={"large"}
                                style={{width: '100%', height: 46, fontSize: 20}}>
                            <Icon type={'phone'}/> Ver Contato
                        </Button>
                }
            </Col>
            {(props.actualOccupation.extra_fields?.daily_price || props.actualOccupation.extra_fields?.half_daily_price) &&
            <Col span={24}>
                <Collapse defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                        <div className="circle">
                            <div className="horizontal"/>
                            <div className="vertical"/>
                        </div>
                    </div> }>
                    <Collapse.Panel header="Diárias " key="1" style={{display: 'flex', flexFlow: 'column', fontSize: 19}}>
                        {props.actualOccupation.extra_fields.daily_price && <div style={{margin: 5, marginLeft: 0, fontSize: 17}}>
                            <Icon type={'schedule'}/>  Diária -  <strong style={{color: 'limegreen'}}> R$ {props.actualOccupation.extra_fields.daily_price} </strong>
                        </div>}
                        {props.actualOccupation.extra_fields.half_daily_price && <div style={{margin: 5, marginLeft: 0, fontSize: 17}}>
                            <Icon type={'schedule'}/>  Meia Diária -  <strong  style={{color: 'limegreen'}}> R$ {props.actualOccupation.extra_fields.half_daily_price} </strong>
                        </div>}
                    </Collapse.Panel>
                </Collapse>
            </Col>}

            <Col span={24}>
                <Collapse defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                    <div className="circle">
                        <div className="horizontal"/>
                        <div className="vertical"/>
                    </div>
                </div> }>
                    <Collapse.Panel header="Ocupações " key="1" style={{display: 'flex', flexFlow: 'column', fontSize: 19}}>
                        {props.occupations?.map((item,index) => (
                            <div key={index} style={{margin: 5, marginLeft: 0, fontSize: 17}}>
                                <Icon type={'check'}/>  {item.name}
                            </div>
                        ))}
                    </Collapse.Panel>
                </Collapse>
            </Col>

            <Col span={24}>
                <Collapse defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                    <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                    <div className="circle">
                        <div className="horizontal"/>
                        <div className="vertical"/>
                    </div>
                </div> }>
                    <Collapse.Panel header="Horários Diponiveis " style={{display: 'flex', flexFlow: 'column', fontSize: 19}} key="1">
                        {props.actualOccupation?.work_schedule?.map((item, index) =>
                            <div key={index} style={{
                                alignItems: 'center',
                                margin: 5,
                                marginLeft: 0,
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontSize: 17
                            }}>
                                <div> <span><Icon type={'clock-circle'} style={{color: 'green'}}/>  {daysPT[item.dow]} </span> </div>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span> {item.start1} às {item.end1} </span>
                                    {item.start2&& <span> {item.start2} às {item.end2}</span>}
                                </div>
                            </div>)
                        }
                    </Collapse.Panel>
                </Collapse>
            </Col>

            <RegisterPageModal profile={'client'} visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />

        </Row>
    );
};

ServiceProviderExtras.propTypes = {
    actualOccupation: PropTypes.any,
    occupations: PropTypes.any,
    setShowModal: PropTypes.any,
    showModal: PropTypes.any
};

export default ServiceProviderExtras;
