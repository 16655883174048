import React, {useEffect, useRef, useState} from 'react';
import {Carousel, Col, Collapse, Form, Icon, message, Row} from "antd";

import useApi from "../Hooks/Api";
import {useHistory} from "react-router-dom";

import useLocalStorage from "../Hooks/Storage";
import SideBarFilter from "../Components/PageComponents/Public/SearchResumePage/SideBarFilter";
import ListHeader from "../Components/PageComponents/Public/SearchResumePage/ListHeader";
import MainListComponent from "../Components/PageComponents/Public/SearchResumePage/MainListComponent";
import {Fade} from "react-reveal";
import MapComponent from "../Components/PageComponents/Public/SearchResumePage/MapComponent";
import ResumeDrawer from "../Components/PageComponents/Public/SearchResumePage/ResumeDrawer";
import {useDispatch} from "react-redux";
import {Images} from "../Helpers/Images";
import {StorageVariables} from "../Helpers/StoragesVariables";

const google = window.google;

const SearchResumePage = (props) => {
    const history = useHistory();
    const carouselRef = useRef();
    const paginate = useRef({page: 1});
    const [loading, setLoading] = useState(false);
    const resumesList = useRef([]);
    const storage = useLocalStorage(StorageVariables.geolocation);
    let filtersQuery = window.queryStringToObject();
    const [toggle, setToggle] = useState(false);
    const lastSearch = useLocalStorage(StorageVariables.lastCurriculumParam);
    const [count, setCount] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const actualUserId = useRef('');
    const sawMapStorage = useLocalStorage(StorageVariables.sawMap);
    const dispatch = useDispatch();
    const shrinkState = useRef(false);
    const [smallLoading, setSmallLoading] = useState(false);

    const api = useApi({customReturn: true, loadingControl: true});

    const getData = async (loadMore) => {

        window.gtag&& window.gtag('event', 'searchSuccess');
        setLoading(true);
        const filters = {};
        if(filtersQuery.cbo_occupation_id) filters.cbo_occupation_id = filtersQuery.cbo_occupation_id; else {setLoading(false); return 1;}
        if(filtersQuery.lat) filters.latitude = filtersQuery.lat; else {setLoading(false); return 2;}
        if(filtersQuery.long) filters.longitude = filtersQuery.long; else {setLoading(false); return 2;}
        filters.page = paginate.current.page;
        filters.filters = formatValues();

        api.post('sine/search', filters, (res) => {
            setCount(undefined)
            paginate.current = {nextPage: res.next_page, page: res.page};
            let aux;
            if (res.page === 2) {
                aux = resumesList.current.concat(res.workers);
            } else {
                aux = res.workers;
            }

            lastSearch.setObject({cbo_name: filtersQuery.cbo_name, cbo_occupation_id: filtersQuery.cbo_occupation_id});

            resumesList.current = aux;
            setLoading(false);

            api.post('sine/search', {...filters, count_only: true},(countRes) => {
                setCount(countRes.total_count);
            },(e) => {});

        },(e) => {
            console.log(e);
            setLoading(false);
        });

        !loadMore&& window.scrollToElement('body')
    };

    const handleRecruited = () => {
        setLoading(true);
        let aux = resumesList.current;
        if((actualUserId.current?.index !== undefined && actualUserId.current?.index !== null) && aux[actualUserId.current.index]) {
            aux[actualUserId.current.index].has_feedback = true;
        }
        resumesList.current = aux;
        setLoading(false);
    }

    const handleShowDrawer = (id,recruited, index) => {
        setSmallLoading(true);
        actualUserId.current = {id: id, recruited: recruited, index: index};
        setShowModal(true);
        setTimeout(() => {
            setSmallLoading(false);
        },[500])
    };

    const addToFilter = (name,value) => {
        if (value === null) {
            delete filtersQuery[name];
        } else {
            filtersQuery[name] = value;
        }
        const urlParams = new URLSearchParams(filtersQuery);
        history.push(`/curriculos/?${urlParams}`);
    }

    const clearFilters = () => {
        let auxStorage = storage.getObject();
        if(auxStorage) {
            delete auxStorage.userLat;

            filtersQuery = {...auxStorage, ...lastSearch.getObject()};
            const urlParams = new URLSearchParams(filtersQuery);
            history.push(`/curriculos/?${urlParams}`);
            getData();
        }
    }

    const addLocToFilter = () => {
        let aux = window.queryStringToObject();
        let auxStorage = storage.getObject();
        delete auxStorage.userLat;

        filtersQuery = {...aux, ...auxStorage, ...lastSearch.getObject()};
        const urlParams = new URLSearchParams(filtersQuery);
        history.push(`/curriculos/?${urlParams}`);
        getData();
    };

    const loadMore = () => {
        paginate.current.page = paginate.current.page + 1;
        getData(true).then((item) => {
            if (item === 1) {
                message.info({icon: <Icon type={'exclamation'}/>, content: 'Escolha uma Ocupação para buscar'})
            }
            else if (item === 2) {
                message.info({icon: <Icon type={'exclamation'}/>, content: 'Escolha um Endereço para buscar'})
            }
        });
    };

    const handleToggle = () => {
        sawMapStorage.setPrimitive(true);
        if(toggle) {
            setToggle(!toggle);
            carouselRef.current.prev();
        } else {
            setToggle(!toggle);
            carouselRef.current.next()
        }
    };

    const formatValues = () => {
        let formattedFilters = {};
        filtersQuery = window.queryStringToObject();
        if(filtersQuery.internship) formattedFilters.internship = true;
        if(filtersQuery.is_student) formattedFilters.is_student = true;
        if(filtersQuery.courses) formattedFilters.courses = true;
        if(filtersQuery.grads) formattedFilters.grads = true;
        if(filtersQuery.post_grads) formattedFilters.post_grads = true;
        if(filtersQuery.available_sleep_duty) formattedFilters.available_sleep_duty = true;
        if(filtersQuery.available_leave_home) formattedFilters.available_leave_home = true;
        if(filtersQuery.available_travel) formattedFilters.available_travel = true;
        if(filtersQuery.exclude_imported) formattedFilters.exclude_imported = false; else formattedFilters.exclude_imported = true;

        if(filtersQuery.formal_experience_min) formattedFilters.formal_experience_min = Number(filtersQuery.formal_experience_min);
        if(filtersQuery.formal_experience_max) formattedFilters.formal_experience_max = Number(filtersQuery.formal_experience_max);
        if(filtersQuery.informal_experience_min) formattedFilters.informal_experience_min = Number(filtersQuery.informal_experience_min);
        if(filtersQuery.informal_experience_max) formattedFilters.informal_experience_max = Number(filtersQuery.informal_experience_max);
        if(filtersQuery.age_min) formattedFilters.age_min = Number(filtersQuery.age_min);
        if(filtersQuery.age_max) formattedFilters.age_max = Number(filtersQuery.age_max);

        if(filtersQuery.schooling) formattedFilters.schooling = filtersQuery.schooling.replace(/\+/ig, " ").split(",");
        if(filtersQuery.disabilities) formattedFilters.disabilities = filtersQuery.disabilities.replace(/\+/ig, " ").split(",");
        if(filtersQuery.vehicles) formattedFilters.vehicles = filtersQuery.vehicles.replace(/\+/ig, " ").split(",");
        if(filtersQuery.languages) formattedFilters.languages = filtersQuery.languages.replace(/\+/ig, " ").split(",");
        if(filtersQuery.driver_license) formattedFilters.driver_license = filtersQuery.driver_license;
        if(filtersQuery.availability) formattedFilters.availability = filtersQuery.availability.replace(/\+/ig, " ").split(",");
        if(filtersQuery.gender) formattedFilters.gender = filtersQuery.gender.replace(/\+/ig, " ").split(",");
        return formattedFilters;
    }

    const handleScroll = (e) => {
        if (!shrinkState.current && window.scrollY > 200) {
            shrinkState.current = true;
            dispatch({type: 'shrink_scroll'})
        } else if(shrinkState.current && window.scrollY <= 200) {
            shrinkState.current = false;
            dispatch({type: 'expand_scroll'});
        }
    };


    useEffect(() => {
        if(window.location.search) {
            if (filtersQuery.lat && filtersQuery.long && filtersQuery.loc && filtersQuery.cbo_occupation_id) {
                getData();
            }
        } else if(storage.getObject()?.lat && storage.getObject()?.long) {
            addLocToFilter();
        }

        if(window.innerWidth > 768) window.addEventListener('scroll', handleScroll, { passive: true });

        if(window.innerWidth > 768) return () => {window.removeEventListener('scroll', handleScroll);  dispatch({type: 'expand_scroll'});}

    }, []);

    useEffect(() => {
        if(!window.location.search) {
            setLoading(true);
            resumesList.current = [];
            setLoading(false);
        }
    },[window.location.search]);

    return (
        <div style={{ backgroundSize: '100%', backgroundRepeat: 'repeat-y', background: `url(${Images.background_1})`}}>
            <div style={{position: 'absolute', height: '100%', objectFit:'cover', width: '100%', zIndex: 0, background: 'linear-gradient(180deg, transparent 80%, white)'}}/>

            <Row style={{display: 'flex', marginTop: 35, justifyContent: 'center'}}>
                <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
                    <Row>
                        <Col  xs={0} sm={0} md={8} lg={6} style={{paddingRight: 10, paddingLeft: 10}}>
                            <SideBarFilter clearFilters={() => clearFilters()} cboName={filtersQuery.cbo_name} search={() => getData()} loading={api.loading} filter={(name,value) => addToFilter(name,value)} form={props.form}/>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={18} className={'listHeaderFilters'}>
                            {window.innerWidth < 768 ?
                                <Collapse>
                                    <Collapse.Panel header={<div style={{display: 'flex', justifyContent: 'space-between'}}> <span> Filtros </span> <Fade bottom when={count !== undefined}> <div>  <span> <strong style={{fontSize: 15}}> {count} </strong> resultados encontrados. </span> </div></Fade> </div>} key="1">
                                        <ListHeader clearFilters={() => clearFilters()} count={count} toggle={toggle} setToggle={() => handleToggle()} addLocToFilter={() => addLocToFilter()} location={filtersQuery.loc} form={props.form} search={() => getData()} loading={api.loading} filter={(name,value) => addToFilter(name,value)}/>
                                    </Collapse.Panel>
                                </Collapse>
                                :
                                <ListHeader clearFilters={() => clearFilters()} count={count} toggle={toggle} setToggle={() => handleToggle()} addLocToFilter={() => addLocToFilter()} location={filtersQuery.loc} form={props.form} search={() => getData()} loading={api.loading} filter={(name,value) => addToFilter(name,value)}/>
                            }
                            {(resumesList.current.length === 0 && !loading)&&
                            <Fade>
                                <div className={'notFoundComponent'} style={{marginTop: 30}}>
                                    <div style={{marginBottom: 0}}>
                                        <span style={{fontSize: 25}}>Não há profissionais disponíveis para essa pesquisa </span> <br/>
                                        <span style={{fontSize: 22}}>Busque por outro cargo ou busque por profissionais em outra região </span>
                                    </div>
                                    <div className={'imgDiv'}>
                                        <img src={Images.resume_not_found}/>
                                    </div>
                                </div>
                            </Fade>}
                            <Carousel ref={carouselRef}>
                                <div>
                                    <MainListComponent showDrawer={(id,recruited,index) => handleShowDrawer(id,recruited,index)} nextPage={paginate.current.nextPage} loadMore={() => loadMore()} cboId={filtersQuery.cbo_occupation_id} loading={loading} resumesList={resumesList.current}/>
                                </div>
                                <div>
                                    {toggle&& <MapComponent showDrawer={(id,recruited) => handleShowDrawer(id,recruited)} loading={loading} nextPage={paginate.current.nextPage} cboId={filtersQuery.cbo_occupation_id} loadMore={() => loadMore()}  google={google} data={resumesList.current}/>}
                                </div>
                            </Carousel>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <ResumeDrawer
                arrowShortCut={true}
                currentIndex={actualUserId.current?.index}
                candidatesList={resumesList.current}
                updateFeedbackUser={(item) => handleShowDrawer(item.id, !!item.name, item.index)}
                handleRecruited={() => handleRecruited()} id={actualUserId.current.id} recruited={actualUserId.current.recruited} cboId={filtersQuery.cbo_occupation_id} visible={showModal} setVisible={(item) => setShowModal(item)} />
        </div>
    );
};

SearchResumePage.propTypes = {};

export default Form.create({name: 'searchResumePage'})(SearchResumePage);
