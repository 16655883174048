import React, {useEffect, useState} from 'react';

import {Avatar, Button, Col, Collapse, Form, Icon, message, Row} from "antd";
import GenericInput from "../Components/Generic/GenericInput";
import NumberFormat from "react-number-format";
import TextArea from "antd/es/input/TextArea";
import {useValidationShorts} from "../Hooks/Helpers";
import useApi from "../Hooks/Api";
import {Env} from "../Helpers/Env";

const HelpPage = props => {
    const {getFieldDecorator, getFieldError, getFieldsValue, setFieldsValue, validateFields} = props.form;
    const {isEmail} = useValidationShorts();
    const api = useApi();
    const [step, setStep] = useState(0);

    const sendContact = async () => {
        validateFields(async (err, values) => {
            if(err) return 0;

            values.application_name = Env.application_name;
            setStep(1);
            api.post('access/contact-mail', values, (res) => {
                message.success('Email enviado!');
                setStep(2);
                setFieldsValue({});
            },(e) => {
                message.warning('Não foi possivel enviar o e-mail, tente novamente mais tarde.')
                setStep(0);
            });
        })
    };


    useEffect(() => {
        window.scrollToElement('body');
    },[]);

    return (
        <>
            {/*<Row type={'flex'} justify={'center'} className={'helpPage'}>
                <Col  xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
                    <div style={{marginBottom: 20, display: 'flex', alignSelf: 'center', alignItems: 'center'}}>
                        <div style={{marginRight: 10}}>
                            <Avatar style={{height: 40, width: 40}} src={'/img/social.svg'}/>
                        </div>
                        <div>
                            <span style={{fontSize: 22, fontWeight: "bold"}}> Perguntas Frequentes - Contratante </span> <br/>
                            <span style={{fontSize: 15}}> Tentamos nos adiantar, respondendo aqui, as perguntas mais frequentes feitas pelos usuários do Crafty, mas se ainda ficou com alguma dúvida, use o fale conosco ao final desta página. </span>
                        </div>
                        </div>
                    <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <div className={!isActive? "circle-plus closed": "circle-plus opened" }>
                            <div className="circle">
                                <div className="horizontal"/>
                                <div className="vertical"/>
                            </div>
                        </div>}
                    >
                        <Collapse.Panel header="Quanto custa para usar o Crafty?" key="1">
                            <p>R.  Nada! Você só tem o custo da serviço cobrado pelo prestador!. O Contrata
                                MG é uma ferramenta completamente gratuita que ajuda você a encontrar
                                prestadores de serviços credenciados e qualificados. Inicialmente a base conta
                                com 115 profissões, como pedreiros, eletricistas, diaristas, cozinheiras, mas
                                muito em breve você também terá à disposição muitos outros profissionais
                                importantes no nosso cotidiano.  </p> <br/>
                        </Collapse.Panel>
                        <Collapse.Panel header="O Crafty é confiável?" key="2">
                            <p>R.  Esta é uma das perguntas que mais ouvimos. O Crafty, antes de
                                tudo, é uma ferramenta para que o profissional autônomo seja valorizado e
                                reconhecido. Todos os prestadores de serviços passam por um processo de
                                credenciamento onde enviam comprovante dos seus documentos pessoais e de
                                residência. Aí só fica ao seu critério escolher as qualificações que cada
                                profissional possui e agendar com confiança.
                            </p> <br/>

                        </Collapse.Panel>
                        <Collapse.Panel header="Então o Crafty é uma agência de prestadores de serviços?" key="3">
                            <p>R. Não. Muito longe disso. Em agências, além de intermediar a contratação e cobrar por
                                isso, dificilmente se preocupam com a satisfação e empoderamento de seus
                                profissionais. No Crafty você contrata e negocia diretamente com o profissional
                                autônomo que você selecionou, e o avalia e testemunha sua experiência.
                            </p> <br/>
                             </Collapse.Panel>
                        <Collapse.Panel header="E se minha experiência não for a que eu esperava?" key="4">
                            <p>R. Poxa, quando isso acontecer, é muito importante avaliar este profissional e
                                testemunhar como foi sua experiência com ele. Cada profissional no Contrata
                                MG trabalha por conta própria, e se busca sucesso e oportunidades de
                                trabalho melhores, usará sua crítica para reavaliar os serviços prestados.</p> <br/>
                        </Collapse.Panel>
                    </Collapse>
                </Col>

                <Col   xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
                    <div style={{marginBottom: 20, display: 'flex', alignSelf: 'center', alignItems: 'center'}}>
                        <div style={{marginRight: 10}}>
                            <Avatar style={{height: 40, width: 40}} src={'/img/worker.svg'}/>
                        </div>
                        <div>
                            <span style={{fontSize: 22, fontWeight: "bold"}}> Perguntas Frequentes - Prestador </span> <br/>
                            <span style={{fontSize: 15}}> Tentamos nos adiantar, respondendo aqui, as perguntas mais frequentes feitas pelos usuários do Crafty, mas se ainda ficou com alguma dúvida, use o fale conosco ao final desta página. </span>
                        </div>
                    </div>
                    <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <div className={!isActive? "circle-plus closed": "circle-plus opened" }>
                            <div className="circle">
                                <div className="horizontal"/>
                                <div className="vertical"/>
                            </div>
                        </div>}
                    >
                        <Collapse.Panel header="O Crafty cobra alguma coisa pela sua utilização?" key="1">
                            <p>R. Nada. O Crafty é uma ferramenta completamente gratuita que ajuda você a encontrar seus contratantes. Mas não para por aí. Temos uma vontade gigantesca de vermos os prestadores de serviços sendo cada dia mais valorizados. Inconformados com a quantidade de recursos financeiros, físicos e humanos desperdiçados dia a dia em praticamente todas as nossas atividades, decidimos demonstrar que é possível remunerar melhor quem trabalha, sem transferir custos adicionais para quem contrata ou compra. </p> <br/>
                        </Collapse.Panel>
                        <Collapse.Panel header="Mas vem cá, então o Crafty é uma agência de trabalhadores?" key="2">
                            <p>R. Não. Muito longe disso. Em agências, além de intermediar a contratação e cobrar por
                                isso, dificilmente se preocupam com a satisfação e valorização equilibrada de seus
                                profissionais. No Crafty você é contratado diretamente por contratantes
                                interessados em seus serviços, e você negocia diretamente com eles! Sem a interferência
                                de ninguém! Aqui, você é quem cuida das suas atividades de forma simples e prática! :)
                            </p> <br/>
                        </Collapse.Panel>
                        <Collapse.Panel header="O Crafty define qual valor eu tenho que cobrar pelo meu serviço?" key="3">
                            <p>R. De jeito nenhum. Os prestadores de serviços dentro do Crafty
                                cobram o valor de seus serviços pelas suas qualificações e experiência.
                                Lembrando que em qualquer profissão, os mais qualificados sempre ganham
                                melhor. Por isso o Crafty apoia todos aqueles que se capacitam e
                                comprovam suas qualificações.</p> <br/>
                        </Collapse.Panel>
                        <Collapse.Panel header="Baixei o aplicativo e me cadastrei, já posso receber serviços?" key="4">
                            <p>R. Parabéns, você saiu na frente! Agora você precisa enviar fotos dos seus comprovantes de
                                dados pessoais e residência para ter seu cadastro aprovado na plataforma e poder receber
                                serviços (o atestado de bons antecedentes e os certificados são opcionais, mas orientamos
                                anexá-los para seu perfil ficar atraente e confiável para o contratante!). Mantenha o
                                aplicativo instalado e nos siga em nossas redes sociais para ficar por dentro de todas as
                                novidades.</p> <br/>
                        </Collapse.Panel>
                        <Collapse.Panel header="Porque preciso tirar fotos dos meus documentos?" key="5">
                            <p>R.  Normalmente as pessoas buscam prestadores de serviços através de
                                indicações, não é mesmo? Quando o cliente te encontra através do aplicativo
                                Crafty, além da sua qualidade o cliente precisa confiar plenamente em
                                você, mesmo sem conhecê-lo. As fotos dos documentos são analisadas e
                                auxiliam em um cadastro mais completo e seguro. Mas fique tranquilo(a),
                                somente o Crafty possui acesso a estes documentos.</p> <br/>
                        </Collapse.Panel>
                        <Collapse.Panel header="Não encontrei minha profissão. E agora, o que eu faço?" key="6">
                            <p>R.  Inicialmente o Crafty estará disponível para 115 profissões distribuídas nas categorias: Beleza e Moda, Casa e Serviços Gerais, Construção e Reformas, Festas e Eventos e Serviços Domésticos.
                                As profissões autônomas atendidas inicialmente no Crafty serão: Barbeiro, Cabeleireiro, Depilação, Esteticista, Manicure / Pedicure, Maquiador, Chaveiro, Dedetizador, Desentupidor, Jardineiro, Marceneiro, Marido de Aluguel, Eletricista, Encanador, Pedreiro, Pintor, Serralheiro / Soldador, Vidraceiro, Churrasqueiro, Confeiteiro, Cozinheiro, Garçom, Recepcionista, Segurança, Babá, Cuidador de Idosos, Diarista, Lavadeira, Passadeira e Pet Shop.
                                Se você não encontrou sua profissão, não se preocupe, em breve estaremos adicionando muito mais profissões e com certeza sua profissão será incluída. </p> <br/>
                        </Collapse.Panel>
                        <Collapse.Panel header="Posso usar meu perfil do Facebook ou Google como minha conta no Crafty?" key="7">
                            <p>R.  Claro que sim, mas é preciso complementar sua conta dentro do aplicativo com informações importantes. Muito importante lembrar que seu perfil no Crafty precisa ser bem claro e completo, afinal, seus contratantes vão pesquisar e escolher o profissional pelas informações que eles encontram ali. Embora o Crafty tenha um formato de rede social, preencha seu perfil sempre com informações importantes e relacionadas sobre o seu trabalho.</p> <br/>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row> */}
            <Row type={'flex'} justify={'center'} style={{marginTop: 40}} >
                {step !== 2 ? <Col    xs={24} sm={24} md={24} lg={22} xl={20} xxl={17} style={{boxShadow: '0 0px 9px rgba(0, 0, 0, 0.1)' , borderRadius: 10, padding: 20}}>
                    <div style={{marginBottom: 20, display: 'flex', alignSelf: 'center', alignItems: 'center'}}>
                        {/*<div style={{marginRight: 10}}>
                            <Avatar style={{height: 40, width: 40}} src={'/img/helps.svg'}/>
                        </div>*/}
                        <div>
                            <span style={{fontSize: 22, fontWeight: "bold"}}> Fale Conosco </span> <br/>
                        </div>
                    </div>

                    <div>
                        <Row gutter={24}>
                            <Col span={8}>
                                <GenericInput required={true} form={props.form} error={getFieldError('name')} name='name' label='Nome'  placeholder='Seu Nome' valueForMemo={getFieldsValue().name}/>
                            </Col>
                            <Col span={8}>
                                <GenericInput rules={[isEmail()]} required={true} form={props.form} error={getFieldError('email')} name='email' label='Email'  placeholder='Email' valueForMemo={getFieldsValue().email}/>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    required={false}
                                    validateStatus={getFieldError('phone_number ')}
                                    className={'hideDots'}
                                    label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 17}}><strong > Telefone </strong> </span>}
                                >
                                    {getFieldDecorator('phone_number', {})
                                    (<NumberFormat
                                        placeholder={'(99) 99999-9999'}
                                        className='ant-input ant-input-lg '
                                        suffix={<Icon className={'primary-sub-color'} type="phone" style={{fontSize: '24px'}}/>}
                                        format={'(##) #####-####'} mask="_"/>)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <GenericInput required={true} form={props.form} error={getFieldError('subject')} name='subject' label='Assunto'  placeholder='Assunto' valueForMemo={getFieldsValue().subject}/>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    required={true}
                                    validateStatus={getFieldError('text')}
                                    className={'hideDots'}
                                    label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 17}}><strong > Mensagem </strong> </span>}
                                >

                                    {getFieldDecorator('message', {
                                        rules: props.rules || [
                                            {
                                                required: true,
                                                message: 'Campo Obrigatório',
                                            }
                                        ],
                                    })(<TextArea autoSize={{minRows:5}}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{textAlign: 'center'}}>
                                <Button loading={step === 1} disabled={step === 1} onClick={() => sendContact()} type={"primary"} icon={'mail'}> Enviar </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                    :
                <Col    xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}  style={{boxShadow: '0 0px 9px rgba(0, 0, 0, 0.1)' , textAlign: 'center', borderRadius: 10, padding: 20}} className='successAnimationCss'>
                    <div style={{marginBottom: 40}}>
                        <span style={{fontSize: 25, fontWeight: 'bold', color: 'limegreen'}}>Tudo Certo!, Email enviado! </span><br/>
                        <span style={{fontSize: 20}}>
                              Obrigado por entrar em contato!, tentaremos te ajudar o mais rápido possível <br/>
                        </span>
                    </div>
                    <div>
                         <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="170"
                                              height="170" viewBox="0 0 70 70">
                            <path id="successAnimationResult" fill="#D8D8D8"
                                  d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2"
                                    stroke-linecap="round" fill="transparent"/>
                            <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2"
                                      points="23 34 34 43 47 27" fill="transparent"/>
                        </svg>
                    </div>

                </Col>}
            </Row>
        </>
    );
};

HelpPage.propTypes = {};

export default  Form.create({name: 'contact_us'})(HelpPage);
