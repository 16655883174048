import React, {useEffect,useRef, useState} from 'react';
import {
    Avatar, Button,
    Col,
    Icon, List,
    Menu,
    Modal, Popover, Rate,
    Row,
} from "antd";
import {useSelector} from "react-redux";

import {
    FaBriefcase,
    FaClipboardCheck,
    FaClipboardList,
    FaForward, FaMobile,
    FaMousePointer,
    FaPlusSquare,
    FaRegEdit,
    FaStoreAlt, FaThumbsDown,
    FaUsers
} from "react-icons/fa";
import useApi from "../../../../../Hooks/Api";
import Loading from "../../../../Generic/Loading";
import RateOrderModal from "./RateOrderModal";
import moment from "moment";
import {Images} from "../../../../../Helpers/Images";
import {FaThumbsUp, MdStar, MdStarBorder} from "react-icons/all";
import {useHistory} from "react-router-dom";

const MyContactsManage = (props) => {

    const api = useApi();
    const contacts_worker = useRef([]);
    const [loading, setLoading] = useState(true);
    const typeSelected = useRef('requested');
    const refToast = useRef();
    const [contactWorkerReview, setContactWorkerReview] = useState(false);
    const activeWorker = useRef(null);
    const [smallLoading, setSmallLoading] = useState(false);
    const paginate1 = useRef({ page: 1 });
    const paginate2 = useRef({ page: 1 });
    const user = useSelector((state) => state).authReducer;
    const history = useHistory();

    const getData = (filter, loadMore) => {
        typeSelected.current = filter;
        setLoading(true);
        if(filter === `requested`) {
            const filter = {
                "order": "desc",
                "status": ["requested"],
                "page": paginate1.current?.page,
                "end": moment().format("YYYY-MM-DD"),
                "with_review": false,
                "profile": "customer"};

            api.post(`worker-contact/contacts`, filter, (res) => {
                if(res.next_page) {
                    paginate1.current.page = paginate1.current.page + 1;
                }
                paginate1.current.next_page = res.next_page;
                let aux = [];
                if (loadMore) {
                    aux = contacts_worker.current.concat(res.worker_contacts);
                } else {
                    aux = res.worker_contacts;
                }
                contacts_worker.current = aux;
                setLoading(false);
            }, (res) => {})
        } else {
            api.get(`user/${user.user_id}/contact-reviews?page=${paginate2.current?.page}&order=desc&profile=customer&type=submitted`, (res) => {
                if(res.next_page) {
                    paginate2.current.page = paginate2.current.page + 1;
                }
                paginate2.current.next_page = res.next_page;
                let aux = [];
                if (loadMore) {
                    aux = contacts_worker.current.concat(res.reviews);
                } else {
                    aux = res.reviews;
                }
                contacts_worker.current = aux;
                setLoading(false);
            }, (e) => {
                console.log(e)
            });
        }
    }

    const afterFeedback = () => {
        let aux = contacts_worker.current;
        aux.splice(activeWorker.current.index, 1)
        contacts_worker.current = aux;
        setContactWorkerReview(false);
        //refToast.current.showToast('success', 'Retorno enviado com sucesso!')
    }

    const handleNoService = (id, index) => {
        setSmallLoading(true);
        let safeAux = Object.assign([], contacts_worker.current);
        let aux = contacts_worker.current;
        aux.splice(index, 1)
        contacts_worker.current = aux;
        setSmallLoading(false);

        api.post(`worker-contact/${id}/cancel`, null, (res) => {

        }, (e) => {
            setSmallLoading(true);
            contacts_worker.current = safeAux;
            refToast.current.showToast('warning', 'Ops algo deu errado!')
            setSmallLoading(false);
            console.log(e);
        })
    }

    useEffect(() => {
        getData(typeSelected.current);
        window.scrollToElement('body');
    },[]);


    return (<Row gutter={[16,16]} style={{minHeight: 500}} className={'aboutCss'}>

        {contactWorkerReview &&
            <RateOrderModal hide={() => setContactWorkerReview(false)} profile={activeWorker.current} handleAfterFeedback={() => afterFeedback()}/>
        }
        <Col xs={24} sm={7}>
            <div style={{boxShadow: "0 2px 8px rgb(0 0 0 / 8%)", borderRadius: 5, backgroundColor: "white"}}>
                <div style={{width: '100%', textAlign: 'left', paddingLeft: 20, paddingTop: 20}}>
                    <Icon  onClick={() => history.push('/painel')} style={{fontSize: 17, cursor: 'pointer'}} className={'craftyIcon'} type={'arrow-left'}/>
                </div>

                <div style={{justifyContent: `center`, display: `flex`, flexFlow: `column`, padding: 20, borderBottom: `1px solid gainsboro`, marginBottom: 20, alignItems: `center`}}>
                    <img style={{maxWidth: `100%`, width: 200}} src={Images.menuWorker}/>
                    <div style={{textAlign: `center`, paddingTop: 20}}>
                        <span style={{fontWeight: `bold`, color: `#263238`}}>Não se esqueça de informar que ocorreu a prestação de serviço!</span> <br/>
                        <span>Isso ajuda muito o trabalhador!</span>
                    </div>
                </div>

                <div>
                    <Menu
                        mode="inline"
                        className={`workerMenu`}
                        selectedKeys={typeSelected.current === 'requested' ? ['2'] : ['1']}
                    >
                        <Menu.Item key='1' onClick={() => getData('fulfilled')} className={'workerMenuItem'} style={{display: 'flex', alignItems: 'center'}}>
                            <FaPlusSquare style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/>Contatos que prestaram serviço
                        </Menu.Item>
                        <Menu.Item key='2' onClick={() => getData('requested')}  className={'workerMenuItem'} style={{display: 'flex', alignItems: 'center'}}>
                            <FaClipboardCheck style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/>Contatos Solicitados
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
        </Col>
        <Col xs={24} sm={17}>
            <div className={'mainComponentPanel'} style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 5, width: '100%', marginBottom: 10, borderRadius:5, backgroundColor: "white"}}>

                <div style={{display: "flex" ,padding: 10, borderBottom: "1px solid gainsboro", alignItems: "center", marginBottom: 10}}>
                    {typeSelected.current === `requested` ?
                        <FaClipboardCheck type={"file-done"} style={{marginRight: 8, fontSize: 18, marginTop: -4}} className={"primary-color"}/>
                        :
                        <FaClipboardList style={{marginRight: 8, fontSize: 18, marginTop: -4}} className={"primary-color"}/>
                    }
                    <span style={{fontSize: 18}}>{typeSelected.current === `requested`? 'Contatos solicitados' : 'Contatos que prestaram serviço'}</span>

                    {loading && <div style={{flex: 1, justifyContent: 'flex-end', textAlign: 'right'}}>
                        <Icon type={"loading"} style={{ color: '#067893' }} />
                    </div>}
                </div>
                {loading?
                    <div style={{paddingTop: 50}}>
                        <Loading/>
                    </div>
                    :
                    <div>
                        {typeSelected.current === `requested`? <Row gutter={[16,16]} style={{margin: 0}}>
                                {contacts_worker.current.map((item, index) =>
                                    <Col xs={24} xl={12} style={{padding: 5}}>
                                        <div  style={{backgroundColor: 'white', cursor: `pointer`, borderRadius: 5, position: 'relative', display: 'flex', flexDirection: 'column',boxShadow: '0 0px 3px 0px rgb(0 0 0 / 20%)', padding: 10}}>
                                            <div style={{textAlign: "right", color: '#bfbfbf', fontSize: 12,position: 'absolute', top: 5, right: 6}}>
                                                <span> {moment(item.created_at).format(`DD/MM/YYYY`)} </span>
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center'}} onClick={() => history.push('/prestador-de-servico/' + item.worker.id + '/' + item.occupation.id)}>
                                                <div style={{marginRight: 10, position: 'relative'}}>
                                                    <img style={{height: 60, width: 60, objectFit: 'cover', borderRadius: 45}} src={item.worker?.profile_picture? item.worker.profile_picture : Images.case_img }/>
                                                </div>
                                                <div>
                                                    <div>
                                                        <span style={{color: 'black', fontWeight: 'bold'}}>{item.worker?.name}</span>
                                                    </div>
                                                    <div style={{marginBottom: 3}}>
                                                        <span style={{color: 'gray', fontSize: 12}}>{item.occupation?.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{borderTop: '1px solid #eeeeee', marginTop: 10, paddingTop: 10, display: 'flex'}}>
                                                <div style={{flex: 1}}>
                                                    <span style={{fontSize: 13, color: 'gray'}}>O trabalhador prestou o serviço para você</span>
                                                </div>
                                                <div style={{display:  'flex'}}>
                                                    <Button onClick={() => {activeWorker.current = item; setContactWorkerReview(true)}} style={{borderRadius: 20, alignItems: 'center', display: 'flex', marginRight: 5}} className={'ant-btn-secondary'} size={'small'} type={'primary'}>
                                                        <Icon style={{marginRight: 3}} type={'check'}/> <span> Sim </span>
                                                    </Button>
                                                    <Button onClick={() => handleNoService(item.id, index)} style={{borderRadius: 20, display: 'flex', alignItems: 'center'}} size={'small'}  type={'default'}>
                                                        <Icon style={{marginRight: 3}} type={'close'}/> <span> Não </span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>)}
                            </Row>
                            :
                            <Row gutter={[16,16]} style={{margin: 0, display: `flex`, flexWrap: `wrap`}}>
                                {contacts_worker.current.map((item, index) =>
                                    <Col xs={24} xl={12} style={{padding: 5}}>
                                        <div onClick={() => history.push('/prestador-de-servico/' + item.user.id + '/' + item.occupation.id)} style={{backgroundColor: 'white', cursor: `pointer`, borderRadius: 5, position: 'relative', display: 'flex', flexDirection: 'column',boxShadow: '0 0px 3px 0px rgb(0 0 0 / 20%)', padding: 10}}>
                                            <div style={{textAlign: "right", color: '#bfbfbf', fontSize: 12,position: 'absolute', top: 5, right: 6}}>
                                                <span> {moment(item.created_at).format(`DD/MM/YYYY`)} </span>
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{marginRight: 10, position: 'relative'}}>
                                                    <img style={{height: 60, width: 60, objectFit: 'cover', borderRadius: 45}} src={item.user?.profile_picture?.full? item.user.profile_picture.full : Images.case_img }/>
                                                </div>
                                                <div>
                                                    <div>
                                                        <span style={{color: 'black', fontWeight: 'bold'}}>{item.user?.name}</span>
                                                    </div>
                                                    <div style={{marginBottom: 3}}>
                                                        <span style={{color: 'gray', fontSize: 12}}>{item.occupation?.name}</span>
                                                    </div>
                                                    {item.rating &&<div style={{display: 'flex'}}>
                                                        <Rate style={{fontSize: 12}} disabled defaultValue={item.rating} />
                                                        <span style={{color: 'gray', fontSize: 13, marginLeft: 4}}> ({item.rating})</span>
                                                    </div>}
                                                </div>
                                            </div>
                                            {item.review &&<div style={{borderTop: '1px solid #eeeeee', marginTop: 10, paddingTop: 10}}>
                                                <span style={{fontSize: 13, color: 'gray'}}>{item.review}</span>
                                            </div>}
                                        </div>
                                    </Col>)}
                            </Row> }


                        {!loading && contacts_worker.current.length === 0 && <div  style={{backgroundColor: "white"}}>
                            <div style={{color: '#545a5d'}}>
                                <div style={{textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                                    {typeSelected.current === `requested` ? <span style={{fontSize: 19}}> Você nao possuí nenhum contato pendente solicitado </span> : <span style={{fontSize: 19}}> Você nao possuí nenhum contato em que informou que ocorreu a prestação de serviço </span>}
                                    <span> Busque agora por profissionais autônomos cadastrados em todo o estado </span>
                                    <Button className={"ant-btn-primary"} style={{marginTop: 40, width: "fit-content"}} onClick={() => console.log('ad')}> Buscar Prestadores </Button>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                }
            </div>
        </Col>
    </Row>)
};
export default MyContactsManage;

