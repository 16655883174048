import React, {useEffect} from 'react';
import {Col, Divider, Row} from "antd";
import {Fade} from "react-reveal";
import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";

const HowToDiscounts = (props) => {

    useEffect(() => {
        window.scrollToElement('body');
    },[]);

    return (<div>

        <Row style={{minHeight: '400px', marginTop: 80}}>
            {/*<div style={{width: '100%', position: 'absolute', height: '100%', backgroundSize: 'contain', zIndex: 10, backgroundImage: "url('/img/squareBg.png')"}}/>*/}
            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <Col sm={20} lg={20} xl={19} xxl={16} className='headerHowTo' style={{padding: 5, textAlign: 'center'}}>
                    <img style={{filter: 'brightness(0.2)', width: 70}} src={Images.home.service_icon}/>
                    <h1 style={{position: 'relative', marginBottom: 100}} className={'title title-line'}> {Texts.howItWorksCrafty.mainTitle} </h1>
                    {Texts.howItWorksCrafty.mainText}
                </Col>
            </Row>


            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBanner'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 reverseBg2'}>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}> <div style={{minWidth: 170}}> <h1 style={{fontSize: 24, margin: 0}}> <strong style={{color: '#8720c5'}}> 1. </strong> {Texts.howItWorksCrafty.step1Title} </h1> </div> <div className={'primary-sub-color-bg'} style={{height: 2, width: '100%', alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} left cascade>
                                {Texts.howItWorksCrafty.step1}
                            </Fade>
                        </div>
                    </Col>
                    <Col md={24} lg={8}>
                        <Fade delay={300} right>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.discount.img_1} />
                        </Fade>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBannerInvert'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2'}>
                    <Col md={24} lg={8}>
                        <Fade delay={300} left>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.discount.img_2} />
                        </Fade>
                    </Col>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}> <div style={{minWidth: 255}}> <h1 style={{fontSize: 24, margin: 0}}> <strong style={{color: '#8720c5'}}> 2. </strong>{Texts.howItWorksCrafty.step2Title}</h1> </div> <div className={'primary-sub-color-bg'} style={{height: 2, width: '100%', alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} right cascade>
                                {Texts.howItWorksCrafty.step2}
                            </Fade>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBanner'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2 reverseBg2'}>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}> <div style={{minWidth: 230}}> <h1 style={{fontSize: 24, margin: 0}}> <strong  style={{color: '#8720c5'}}> 3. </strong> {Texts.howItWorksCrafty.step3Title} </h1> </div> <div className={'primary-sub-color-bg'} style={{height: 2, width: '100%', alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} left cascade>
                                {Texts.howItWorksCrafty.step3}
                            </Fade>
                        </div>
                    </Col>
                    <Col md={24} lg={8}>
                        <Fade delay={300} right>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.discount.img_3} />
                        </Fade>
                    </Col>
                </Col>
            </Row>
            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className={'howItWorksBannerInvert'}/>
                <Col sm={20} lg={20} xl={15} className={'homeBg2'}>
                    <Col md={24} lg={8}>
                        <Fade delay={300} left>
                            <img style={{width: '100%', borderRadius: 10, marginRight: 30 }} src={Images.discount.img_1} />
                        </Fade>
                    </Col>
                    <Col md={24} lg={16} style={{textAlign: 'left'}}>
                        <div style={{display: 'flex', marginBottom: 20}}> <div style={{minWidth: 255}}> <h1 style={{fontSize: 24, margin: 0}}> <strong style={{color: '#8720c5'}}> 4. </strong> {Texts.howItWorksCrafty.step4Title} </h1> </div> <div className={'primary-sub-color-bg'} style={{height: 2, width: '100%', alignSelf: 'center', marginTop: 10}} /> </div>
                        <div>
                            <Fade delay={300} right cascade>
                                {Texts.howItWorksCrafty.step4}
                            </Fade>
                        </div>
                    </Col>
                </Col>
            </Row>
            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <Col sm={20} lg={20} xl={15} style={{marginTop: 100}}>
                    <Fade delay={1000}>
                        <div style={{display: 'flex', marginBottom: 20}}> <Divider> <h1 style={{fontSize: 27, color: '#8720c5', margin: 0}}> Viu como é fácil?</h1> </Divider>  </div>
                    </Fade>
                </Col>
            </Row>
        </Row>
    </div>)
};
export default HowToDiscounts;
