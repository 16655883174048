import React, {Suspense, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import "react-image-crop/dist/ReactCrop.css";
import {useMergeState} from "../../Hooks/Helpers";
import * as _ from "lodash";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import ButtonGroup from "antd/es/button/button-group";
import {message, Modal} from "antd";
import {Images} from "../../Helpers/Images";

const ReactCrop = React.lazy(() => import("react-image-crop"));


const GenericCropImage = props => {
    const [uncropedImg, setUncropedImg] = useState(null);
    const [cropedImg, setCropedImg] = useState(props.image || null);
    const [imageObj, setImageObj] = useState(null);
    const GlobalAspectRatio = useRef(props.aspectRatio || 1);
    const [cropObj, setCropObj] = useMergeState({unit: 'px'});
    const uploadFieldRef = useRef();
    const [showDimmer, setShowDimmer] = useState(false);

    const handleFileChange = (e) => {
        const files = e.target.files;

        const reader = new FileReader();

        reader.onload = function(e) {
            setUncropedImg(e.target.result);
        }
        ;
        reader.readAsDataURL(files[0]);
    }

    const onImageLoaded = image => {
        const aspectRatio = GlobalAspectRatio.current;
        const cropedSizes = window.getCropSizeBasedOnImage(image, aspectRatio);
        setCropObj({ x: 0, y: 0, width: cropedSizes.width, height: cropedSizes.height, aspect: aspectRatio});
        setImageObj(image);
        return false;
    }

    const doCrop = () => {
        if(cropObj?.width === 0 || cropObj?.height === 0) {
            message.warning("Ooops...", "Você não selecionou nada para cortar", "error");
            const cropedSizes = window.getCropSizeBasedOnImage(imageObj, GlobalAspectRatio.current);
            setCropObj({ x: 0, y: 0, width: cropedSizes.width, height: cropedSizes.height, aspect: GlobalAspectRatio.current});
            return;
        }
        if(imageObj) {
            const imgB64 = window.cropImageAndGetBase64(imageObj, cropObj, props.width, props.height);

            setCropedImg(imgB64);
            !!props.setCropedImg && props.setCropedImg(imgB64);
        } else {
            message.warning('Escolha uma imagem nos seguintes formatos .png .jpg .jpeg')
        }

        clean();
    }

    const clean = () => {
        uploadFieldRef.current.value = null;
        setImageObj(null);
        setUncropedImg(null);
        setCropObj({unit: 'px'});
    }

    useEffect(() => {
        if(!_.isUndefined(props.image)) setCropedImg(props.image);
    }, [props.image]);

    return (
        <div style={{width: 'fit-content', alignSelf: 'center'}}>
            {props.loading?
            <div>
                <div  className="text-center" style={{textAlign: 'center'}}>
                    <div className={'dimmerFather'} style={{borderRadius: 64}}>
                        <Icon style={{fontSize: 100}} type={'loading'}/>
                    </div>
                </div>
            </div>
            :
                <div>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        style={{display: "none"}}
                        ref={uploadFieldRef}
                    />
                    {!!uncropedImg ?
                        <Modal visible={!!uncropedImg} footer={null} onCancel={() => clean()} >
                            <div className="box-upload" style={{textAlign: 'center'}}>
                                <Suspense fallback={<div>Carregando...</div>}>
                                    <ReactCrop
                                        src={uncropedImg}
                                        crop={cropObj}
                                        onChange={(crop) => setCropObj(crop)}
                                        onImageLoaded={onImageLoaded}
                                        crossorigin={null}
                                        circularCrop={props.circularCrop}
                                    />
                                </Suspense>
                                <div className="ui horizontal divider" style={{marginTop: '20px'}}/>
                                <ButtonGroup>
                                    <Button onClick={doCrop}>
                                        <Icon type="check" />
                                        Recortar e Salvar
                                    </Button>
                                    <Button onClick={clean} type="danger">
                                        Cancelar
                                        <Icon type="close" />
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Modal>
                        :
                        (
                            !cropedImg ?
                                <div onClick={() => {uploadFieldRef.current.click()}} className="text-center" style={{textAlign: 'center'}}>
                                    <div className={'dimmerFather'} style={{borderRadius: props.circularCrop ? 64 : 0}}>
                                        <img style={{cursor: 'pointer', border: "2px solid gainsboro",  width: props.banner? "100%" : 128, objectFit: "contain",  height: 128, ...(props.circularCrop ? {borderRadius: 64} : {borderRadius: 20} : {})}} src={Images.user_place_holder} className="imagePreviewCrop" />
                                        {props.banner? <Button style={{marginTop: 10}} type="primary">
                                            Adicionar
                                            <Icon type="plus" />
                                        </Button> :  <div className={'dimmerImage'}> Trocar </div>}
                                    </div>
                                </div>
                                :
                                <div className="text-center pt-10" style={{textAlign: 'center'}}>
                                    <div onClick={() => {uploadFieldRef.current.click()}} className={'dimmerFather'} style={{borderRadius: props.circularCrop ? 64 : 0}}>
                                        <img style={props.circularCrop ? {borderRadius: 64, height: 128, width: 128} : {height: 128, width: "100%", objectFit: "contain"}} src={props.googleFaceImage || cropedImg} className="cropped-image-shadow imagePreviewCrop"/>
                                        {props.banner? <Button style={{marginTop: 10}} type="primary">
                                            Trocar
                                        </Button> :  <div className={'dimmerImage'}> Trocar </div>}
                                    </div>
                                </div>
                        )
                    }
                </div>
            }
        </div>
    );
};

GenericCropImage.propTypes = {
    setCropedImg: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    image: PropTypes.string,
    aspectRatio: PropTypes.number,
    label: PropTypes.any,
    circularCrop: PropTypes.bool,
    googleFaceImage: PropTypes.any
};

export default GenericCropImage;
