import React, {useEffect, useRef, useState} from 'react';
import HireServicesBanner from "../Components/PageComponents/Public/HireServicesPage/HireServicesBanner";
import {Affix, Col, Form, Row} from "antd";
import SideBarFilter from "../Components/PageComponents/Public/HireServicesPage/SideBarFilter";
import ListHeader from "../Components/PageComponents/Public/HireServicesPage/ListHeader";
import MainListComponent from "../Components/PageComponents/Public/HireServicesPage/MainListComponent";
import useApi from "../Hooks/Api";
import {useHistory} from "react-router-dom";

import _ from 'lodash';
import useLocalStorage from "../Hooks/Storage";
import {StorageVariables} from "../Helpers/StoragesVariables";
import {Images} from "../Helpers/Images";
import {encodeWorkerCategories} from "../Helpers/Functions";
import CardListComponent from "../Components/PageComponents/Public/HireServicesPage/CardListComponent";
import Loading from "../Components/Generic/Loading";
import InfiniteScroll from "./Components/InfiniteScroll";

const HireServicesPage = (props) => {
    const history = useHistory();
    const filters = useRef({});
    const paginate = useRef({page: 1});

    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] =useState(false)
    const [servicesList, setServicesList] = useState([]);
    const [servicesTreeList, setServicesTreeList] = useState([]);

    const api = useApi({customReturn: true, loadingControl: false});
    const {validateFields} = props.form;
    const storage = useLocalStorage(StorageVariables.geolocation);
    const lastWorkerSearch = useLocalStorage('lastWorkerSearch');

    let filtersQuery = window.queryStringToObject();

    const getData = async (more) => {
        more? setLoadingMore(true) : setLoading(true);
        const filters = {};

        if(storage.getObject().lat) filters.latitude = storage.getObject().lat; else return 0;
        if(storage.getObject().long) filters.longitude = storage.getObject().long; else return 0;
        if(filtersQuery.occupation_id) filters.occupation_id = filtersQuery.occupation_id; else return 0;
        if(filtersQuery.worker) filters.worker = filtersQuery.worker;
        filters.page = paginate.current.page;

        api.post('search', filters, (res) => {
            paginate.current = {nextPage: res.next_page, page: res.page};
            let aux;
            if (more) {
                aux = servicesList.concat(res.workers)
            } else {
                aux = res.workers;
            }
            setServicesList(aux);
            more? setLoadingMore(false) : setLoading(false);
        },(e) => {
            more? setLoadingMore(false) : setLoading(false);
            console.log(e)
        });
    }
    const getServices = async () => {
        api.get('search/occupations', (res) => {
            let treeData = [];

            res = encodeWorkerCategories(res);
            res.forEach((item) => {
                if(item.occupations.length > 0){
                    treeData.push({
                        value: 'p' + item.group_occupation_id,
                        name: <div style={{display: 'flex', alignItems: 'center'}}> <img src={item.group_occupation_image?.web} style={{height: 20, width: 20, marginRight: 5}}/>  <span> {item.group_occupation_name} </span> </div>,
                        occupations: item.occupations.map(subItem => {
                            return {...subItem, ...{name_phonetic: subItem.name_phonetic}}
                        })
                    })
                }
            });
            setServicesTreeList(treeData);
        }, (e) => {
            console.log(e)
        });

    };
    const infiniteScroll = () => {
        if (paginate.current.nextPage === true) {
            paginate.current.page = paginate.current.page +1
            getData(true)
        }
    }

    const handleSearch = () => {
        validateFields(async (error, values) => {
            paginate.current.page = 1
            if (error) return;
            if(!values.occupation_id) {
                if(lastWorkerSearch.getPrimitive()) {
                    values.occupation_id = lastWorkerSearch.getPrimitive();
                } else {
                    values.occupation_id = 27;
                }
            }
            const objToFilter = {...values, ...storage.getObject()};
            delete objToFilter.userLat;
            filtersQuery = _.pickBy(objToFilter, _.identity);
            const urlParams = new URLSearchParams(filtersQuery);
            history.push(`/quero-contratar/?${urlParams}`);
            getData();
        })
    };

    /* Inciializa os serviços e ve se n tem nenhum parametro na busca, ai faz o default que é com diarista*/
    useEffect(() => {
        getServices().then(() =>{ if(!window.location.search && storage.getObject()?.lat && storage.getObject()?.long) handleSearch();});
    },[]);

    /* Verifica se já tem os parametros na url ai já busca*/
    useEffect(() => {
        if(window.location.search) {
            if (filtersQuery.lat && filtersQuery.long && filtersQuery.loc) {
                storage.setObject({lat: filtersQuery.lat, long: filtersQuery.long, loc: filtersQuery.loc, userLat: storage.getObject()?.userLat});
            }
            getData();
        }
    }, []);

    return (
        <div>
            <HireServicesBanner/>
            <Row style={{display: 'flex', justifyContent: 'center', backgroundSize: '100%', backgroundRepeat: 'repeat-y', background: `url(${Images.background_2})`}}>
                <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17} style={{marginTop: 20}}>
                    <Row>
                        <Col style={{display:"flex", justifyContent:"center"}}>
                            <ListHeader worker={filtersQuery.worker} occupation_id={filtersQuery.occupation_id} location={storage.getObject()?.loc} servicesTreeList={servicesTreeList} form={props.form} searchAction={() => handleSearch()}/>
                            {/*<SideBarFilter worker={filtersQuery.worker} servicesTreeList={servicesTreeList} form={props.form} occupation_id={filtersQuery.occupation_id} searchAction={() => handleSearch()}/>*/}
                        </Col>
                        <Col>
                            {loading === true ?
                                <div style={{marginTop:16}}>
                                    <Loading/>
                                </div>
                                :
                                <CardListComponent infiniteScroll={() => infiniteScroll()} loadingMore={loadingMore} paginate={paginate.current} occupation_id={filtersQuery.occupation_id} loading={loading} servicesList={servicesList}/>
                            }
                            {(!loading && servicesList.length > 0) && (
                                <InfiniteScroll paginate={paginate.current} fetchMore={()=> infiniteScroll()}/>
                            )}
                            {loadingMore === true && (<Loading/>)}
                            {(paginate.current.nextPage === false && !loading) && (
                                <div style={{display: "flex", justifyContent:"center",marginTop:48}}>
                                    <div style={{fontSize:20}}>
                                        {servicesList.length === 0 && !loading ? <span/> : <span>Não há mais resultados de busca.</span>}
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

HireServicesPage.propTypes = {};

export default Form.create({name: 'HireServicesPageForm'})(HireServicesPage);
