export const cities = [
    {
        label: "Água Clara",
        value: "Água Clara",
    }, {
        label: "Alcinópolis",
        value: "Alcinópolis",
    }, {
        label: "Amambaí",
        value: "Amambaí",
    }, {
        label: "Anastácio",
        value: "Anastácio",
    }, {
        label: "Anaurilândia",
        value: "Anaurilândia",
    }, {
        label: "Angélica",
        value: "Angélica",
    }, {
        label: "Antônio João",
        value: "Antônio João",
    }, {
        label: "Aparecida do Taboado",
        value: "Aparecida do Taboado",
    }, {
        label: "Aquidauana",
        value: "Aquidauana",
    }, {
        label: "Aral Moreira",
        value: "Aral Moreira",
    }, {
        label: "Bandeirantes",
        value: "Bandeirantes",
    }, {
        label: "Bataguassu",
        value: "Bataguassu",
    }, {
        label: "Bataiporã",
        value: "Bataiporã",
    }, {
        label: "Bela Vista",
        value: "Bela Vista",
    }, {
        label: "Bodoquena",
        value: "Bodoquena",
    }, {
        label: "Bonito",
        value: "Bonito",
    }, {
        label: "Brasilândia",
        value: "Brasilândia",
    }, {
        label: "Caarapó",
        value: "Caarapó",
    }, {
        label: "Camapuã",
        value: "Camapuã",
    }, {
        label: "Campo Grande",
        value: "Campo Grande",
    }, {
        label: "Caracol",
        value: "Caracol",
    }, {
        label: "Cassilândia",
        value: "Cassilândia",
    }, {
        label: "Chapadão do Sul",
        value: "Chapadão do Sul",
    }, {
        label: "Corguinho",
        value: "Corguinho",
    }, {
        label: "Coronel Sapucaia",
        value: "Coronel Sapucaia",
    }, {
        label: "Corumbá",
        value: "Corumbá",
    }, {
        label: "Costa Rica",
        value: "Costa Rica",
    }, {
        label: "Coxim",
        value: "Coxim",
    }, {
        label: "Deodápolis",
        value: "Deodápolis",
    }, {
        label: "Dois Irmãos do Buriti",
        value: "Dois Irmãos do Buriti",
    }, {
        label: "Douradina",
        value: "Douradina",
    }, {
        label: "Dourados",
        value: "Dourados",
    }, {
        label: "Eldorado",
        value: "Eldorado",
    }, {
        label: "Fátima do Sul",
        value: "Fátima do Sul",
    }, {
        label: "Glória de Dourados",
        value: "Glória de Dourados",
    }, {
        label: "Guia Lopes da Laguna",
        value: "Guia Lopes da Laguna",
    }, {
        label: "Iguatemi",
        value: "Iguatemi",
    }, {
        label: "Inocência",
        value: "Inocência",
    }, {
        label: "Itaporã",
        value: "Itaporã",
    }, {
        label: "Itaquiraí",
        value: "Itaquiraí",
    }, {
        label: "Ivinhema",
        value: "Ivinhema",
    }, {
        label: "Japorã",
        value: "Japorã",
    }, {
        label: "Jaraguari",
        value: "Jaraguari",
    }, {
        label: "Jardim",
        value: "Jardim",
    }, {
        label: "Jateí",
        value: "Jateí",
    }, {
        label: "Juti",
        value: "Juti",
    }, {
        label: "Ladário",
        value: "Ladário",
    }, {
        label: "Laguna Carapã",
        value: "Laguna Carapã",
    }, {
        label: "Maracaju",
        value: "Maracaju",
    }, {
        label: "Miranda",
        value: "Miranda",
    }, {
        label: "Mundo Novo",
        value: "Mundo Novo",
    }, {
        label: "Naviraí",
        value: "Naviraí",
    }, {
        label: "Nioaque",
        value: "Nioaque",
    }, {
        label: "Nova Alvorada do Sul",
        value: "Nova Alvorada do Sul",
    }, {
        label: "Nova Andradina",
        value: "Nova Andradina",
    },
    {
        label: "Novo Horizonte do Sul",
        value: "Novo Horizonte do Sul",
    }, {
        label: "Paranaíba",
        value: "Paranaíba",
    },
    {
        label: "Paranhos",
        value: "Paranhos",
    },
    {
        label: "Pedro Gomes",
        value: "Pedro Gomes",
    },
    {
        label: "Ponta Porã",
        value: "Ponta Porã",
    },
    {
        label: "Porto Murtinho",
        value: "Porto Murtinho",
    },
    {
        label: "Ribas do Rio Pardo",
        value: "Ribas do Rio Pardo",
    }, {
        label: "Rio Brilhante",
        value: "Rio Brilhante",
    }, {
        label: "Rio Negro",
        value: "Rio Negro",
    }, {
        label: "Rio Verde de Mato Grosso",
        value: "Rio Verde de Mato Grosso",
    }, {
        label: "Rochedo",
        value: "Rochedo",
    }, {
        label: "Santa Rita do Pardo",
        value: "Santa Rita do Pardo",
    }, {
        label: "São Gabriel do Oeste",
        value: "São Gabriel do Oeste",
    }, {
        label: "Selvíria",
        value: "Selvíria",
    }, {
        label: "Sete Quedas",
        value: "Sete Quedas",
    }, {
        label: "Sidrolândia",
        value: "Sidrolândia",
    }, {
        label: "Sonora",
        value: "Sonora",
    }, {
        label: "Tacuru",
        value: "Tacuru",
    }, {
        label: "Taquarussu",
        value: "Taquarussu",
    }, {
        label: "Terenos",
        value: "Terenos",
    }, {
        label: "Três Lagoas",
        value: "Três Lagoas",
    }, {
        label: "Vicentina",
        value: "Vicentina",
    },
]
