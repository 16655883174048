import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Button, Col, Icon, message, Modal, Row} from "antd";
import moment from "moment";
import Paragraph from "antd/lib/typography/Paragraph";
import StackGrid from "react-stack-grid";
import {Divider} from "antd/es";
import {useDispatch} from "react-redux";
import Loading from "../../../Generic/Loading";
import NotFound from "../../../Generic/NotFound";
import {useHistory} from "react-router-dom";

import useApi from "../../../../Hooks/Api";
import {Images} from "../../../../Helpers/Images";

const type = {
    work_order: {borderTop: '3px solid #00a5d4 '},
    full: {borderTop: '3px solid #00a5d4 '},
    express: {borderTop: '3px solid #00a5d4 '},
    crafty_club : {borderTop: '3px solid limegreen'},
    chat: {borderTop: '3px solid #00a5d4 '},
    rating: {borderTop: '3px solid gold'},
    reply: {borderTop: '3px solid gold'},
}

const classButton = {
    work_order: 'ant-btn-darkBlue',
    full: 'ant-btn-primary2',
    express: 'ant-btn-primary2',
    crafty_club : 'ant-btn-green',
    chat: 'ant-btn-primary2',
    rating: 'ant-btn-gold',
    reply: 'ant-btn-gold',
}

const NotificationPage = props => {
    const history = useHistory();
    const api = useApi();
    const notifications = useRef([]);
    const [loading,setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const paginate = useRef({page: 0});
    const [viewNotification,setViewNotification] = useState(false);
    const myGrid = useRef();
    const dispatch = useDispatch();

    const getData = async () => {
        setLoading(true);
        api.get(`notifications/list?page=${paginate.current.page + 1}&order=desc`, (res) => {
            paginate.current = {nextPage: res.next_page, page: res.page};
            notifications.current = notifications.current.concat(res.notifications);
            setLoading(false);
        }, (e) => {
            message.error('Ops, não foi possível recuperar seus endereços, tente novamente');
            setLoading(false);
        });
    }

    const handleDelete = async (id, index) => {
        setDeleteLoading(true);
        message.loading('Removendo Notificação');
        api.del(`notifications/${id}`, null,(res) => {
            message.destroy();
            message.success('Notificação Removida!');
            dispatch({type: 'remove_notification'});

            let notificationsAux = [...notifications.current];
            notificationsAux.splice(index,1);
            notifications.current = notificationsAux;

            setDeleteLoading(false);
            setTimeout(() => {
                    updateGrid();
                },[500]
            )
        }, (e) => {
            setDeleteLoading(false);
            message.error(e.message || 'Ops, não foi possível Remover esta notificação!')
        });
    }

    const updateGrid = () => {
        myGrid.current.updateLayout();
    };

    useEffect(() => {
        getData()
    },[]);

    return (
        <Row gutter={[22,22]} style={{display: 'flex', justifyContent: 'center'}} className={'mainComponentPanel'}>
            <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={18}>
                <Row className={'primary-sub-color'} style={{fontSize: 20, marginBottom: 20, marginTop: 20}}>
                    <Icon type={'bell'}/>
                    <span> Suas notificações </span>
                </Row>
                {loading?
                    <div style={{margin: "auto"}}> <Loading/> </div>
                    :
                    <div>
                        <StackGrid gridRef={grid => myGrid.current = grid} duration={0} gutterWidth={15} gutterHeight={15} monitorImagesLoaded={true}
                            columnWidth={window.innerWidth < 768 ? '100%' : '50%'}
                        >
                        {notifications.current.map((item,index) =>

                            <div
                                key={index}
                                className={'cardBodyCardNotification'}
                                style={ (item.type === 'work_order' && item.image)? {borderTop: '3px solid #7b1fa2'} : type[item.type]}
                            >
                                <div onClick={() => setViewNotification(item)}>
                                    <div style={{textAlign: 'center', marginBottom: 30}}>
                                    <strong style={{fontSize: 18}}> {item.title || 'Notificação'} </strong>  <br/> </div>
                                    {(item.image && item.type !== 'work_order')&& <img src={item.image} style={{width: '100%', marginBottom: 20}}/>}
                                    <div style={{marginBottom: 30}}>
                                        <Paragraph style={{fontSize: 14}} ellipsis={{ rows: 3 }}>  {item.message} </Paragraph>
                                    </div>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    {(item.link || item.work_order_id || item.message.match('há mais de 10 dias')) && <Button className={(item.type === 'work_order' && item.image)? 'ant-btn-purple' : classButton[item.type]} icon={item.link? 'eye' : 'login'}  type={"primary"} style={{width: '48%'}} onClick={() => {item.work_order_id? history.push('/ordem-de-servico/'+item.work_order_id): setViewNotification(item) }}> {item.link?  'Ver mais' : 'Confira'} </Button>}
                                    <Button onClick={() => handleDelete(item.notification_id, index)} style={{width:(item.link || item.work_order_id || item.message.match('há mais de 10 dias'))? '48%' : '100%'}}> Entendi </Button>
                                </div>

                                <div style={{position: 'absolute', right: 15, top: 15, color: '#d9d9d9'}}> { moment.utc(item.created_at).local().fromNow()}</div>
                            </div>

                        )}
                        </StackGrid>
                        {paginate.current.nextPage&& <div style={{width: '100%', marginTop: 25, paddingBottom: 10}}>  <div className='loadMore2' onClick={()=> getData()}> <Icon type={'reload'}/> Carregar Mais </div> </div>}

                        {notifications.current.length === 0 &&
                        <NotFound title={<span style={{fontWeight: 'bold'}}> Você não possui nenhuma notificação! </span>} subTitle={'Aqui aparece o status de todos os seus agendamentos com prestadores de serviços, além de promoções e notícias'}
                        img={Images.no_notification_found}/>
                        }
                    </div>
                }
            </Col>
            <Modal  style={(viewNotification.type === 'work_order' && viewNotification.image)? {borderTop: '3px solid #7b1fa2'} : type[viewNotification.type]} className={viewNotification.type === 'full'&& 'bodyNotificationModalFull'} title={<span style={{fontSize: 20, fontWeight: 'bold'}}> {viewNotification.title || 'Notificação'} </span>} visible={viewNotification} footer={null} onCancel={() => setViewNotification(false)}>
                <div>
                    <div>

                          {/*  <div>
                                <iframe style={{width: '100%', border: 0, height: 330, borderRadius: 10, marginBottom: 20}} src={viewNotification.link.replace('watch?v=','embed/')}
                                        allow='autoplay; encrypted-media'
                                        allowFullScreen
                                        title='video'
                                />
                                <Divider/>
                            </div>*/}

                        {(viewNotification.image && viewNotification.type !== 'work_order') &&
                            <div> <img src={viewNotification.image} style={{width: '100%', marginBottom: 20}}/>   <Divider/> </div> }


                        <div style={{marginBottom: 30}}>
                            <span style={{fontSize: 15}}>
                               {viewNotification?.message?.split('\n').map((item, key) => {
                                   return <Fragment key={key}>{item}<br/></Fragment>
                               })}
                            </span>
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {(viewNotification.work_order_id) && <Button className={(viewNotification.type === 'work_order' && viewNotification.image)? 'ant-btn-purple' : classButton[viewNotification.type]} icon={'login'}  type={"primary"} style={{width: '100%'}} onClick={() => {window.open(viewNotification.link)}}> Confira </Button>}
                        {(viewNotification.type === 'full' && viewNotification.link) && <Button className={'ant-btn-purple'} style={{margin: 'auto'}} icon={'login'}  type={"primary"}  onClick={() => {window.open(viewNotification.link)}}> Confira </Button>}
                      </div>

                </div>
            </Modal>
        </Row>
    );
};

NotificationPage.propTypes = {};

export default NotificationPage;
