import React, {useEffect, useRef, useState} from 'react';
import PropTypes         from 'prop-types';
import {Button, Carousel, Checkbox, Col, Dropdown, Form, Icon, Input, Menu, Popover, Row, Switch, TreeSelect} from "antd";
import useLocalStorage from "../../../../Hooks/Storage";
import _ from "lodash";
import SideBarFilter from "./SideBarFilter";
import {Fade} from "react-reveal";
import {checkStateRestriction, showNotification} from "../../../../Hooks/Helpers";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";

const ListHeader = (props) => {
    const {getFieldDecorator, getFieldsValue, getFieldError, setFieldsValue} = props.form;
    const [popUp, setPopUp] = useState(true);
    const [geoLocation, setGeoLocation] = useState('');
    const geocode = useRef();
    const [spinIcon, setSpinIcon] = useState(0);
    const storage = useLocalStorage(StorageVariables.geolocation);
    const sawMapStorage = useLocalStorage(StorageVariables.sawMap);
    let activeFilters = window.queryStringToObject();

    const setAddress = (item) => {
        storage.setObject({lat: item.lat, long: item.long, loc: item.name, userLat: storage.getObject()?.userLat});
        setGeoLocation(item.name);
        setFieldsValue({locFilled: 'yes'});
        props.addLocToFilter();
    }

    useEffect(() => {
        if (window.innerWidth < 768) {
            if (props.category_id) {
                setFieldsValue({category_id: props.category_id?.toString()})
            }
        }
        if (props.location) {
            setFieldsValue({locFilled: 'yes'});
            if(props.location){
                setGeoLocation(_.replace(props.location,/\+/g," "));
                setPopUp(false);
                setSpinIcon(2);
            }
        }
    },[props.category_id, props.location]);

    useEffect(() => {
        window.scrollToElement('body');
        geocode.current = new window.google.maps.places.Autocomplete(document.querySelector('#geocodeField'), {types: ['geocode']});
        geocode.current.addListener('place_changed', getGeolocation);
        geocode.current.setComponentRestrictions({'country': ['br']});
        geocode.current.setFields(['formatted_address', 'geometry', 'address_components']);
    },[]);


    const getGeo = () => {
        setSpinIcon(1);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(item => {
                let geocoder = new window.google.maps.Geocoder;
                geocoder.geocode({'location': {lat: item.coords.latitude, lng: item.coords.longitude }}, function(results, status) {
                    if(checkStateRestriction(results[0])) {
                        setGeoLocation(results[0].formatted_address);
                        storage.setObject({lat: item.coords.latitude, long: item.coords.longitude, loc: results[0].formatted_address, userLat: storage.getObject()?.userLat});
                        setFieldsValue({locFilled: 'yes'});
                        setSpinIcon(2);
                        props.addLocToFilter();
                    } else {
                        setSpinIcon(0);
                        showNotification();
                        setGeoLocation('');
                    }
                })
            });
        }
    };

    const getGeolocation = () => {
        setTimeout(() => {
            const place = geocode.current.getPlace();
            if(checkStateRestriction(place)){
                storage.setObject({lat: place.geometry.location.lat(), long: place.geometry.location.lng(), loc: place.formatted_address, userLat: storage.getObject()?.userLat});
                setFieldsValue({locFilled: 'yes'});
                setGeoLocation(place.formatted_address);
                props.addLocToFilter();
            } else {
                showNotification();
                setGeoLocation('');
            }
        },[200]);
    }

    return (
        <Row className='listHeaderFather'>

            <div className={'listHeaderSearchRecruits'} style={{paddingBottom: 7, overflow: 'hidden'}}>
                <label style={{fontSize: 16, fontWeight: 'bold'}}> Para qual localidade você precisa contratar? </label>

                <Form.Item style={{marginBottom: -4}} validateStatus={getFieldError('locFilled')&& 'error'} help={getFieldError('locFilled')}>
                    <Input className='paddingGeoSubPage' style={{fontSize: 16, marginBottom: 20, marginTop: 20}} id='geocodeField' type='text' onChange={(e) => setGeoLocation(e.target.value)} value={geoLocation} placeholder='Digite um Endereço'
                           prefix={
                               <Popover
                                   placement='bottomLeft'
                                   content="Clique aqui para usar minha localização Atual!"
                                   trigger="click"
                                   visible={popUp}
                                   onVisibleChange={() => setPopUp(!popUp)}
                               >
                                   <Dropdown overlay={
                                       <Menu>
                                           <Menu.Item onClick={() => getGeo()} key="default"> Usar minha localização Atual </Menu.Item>
                                           {storage.getObject()?.userLat?.map((item, index) =>
                                               <Menu.Item onClick={() => setAddress(item)} key={index}> {item.name} </Menu.Item>
                                           )}
                                       </Menu>
                                   }>
                                       <Icon type={spinIcon === 0? "environment": spinIcon === 1? "loading": "check"} style={{ color: '#067893' }} />
                                   </Dropdown>
                               </Popover>}
                           size='large' />
                </Form.Item>

                <Form.Item  required={true} help={''} style={{margin: 0}}>
                    {getFieldDecorator('locFilled', {
                        rules: [
                            {
                                required: true,
                                message:  'Escolha um Local',
                            },
                        ]
                    })( <Input style={{display: "none"}}/>)}
                </Form.Item>

                {window.innerWidth < 768 &&
                <div style={{paddingBottom: 15}}>
                    <SideBarFilter clearFilters={() => props.clearFilters()} search={() => props.search()} loading={props.loading} filter={(name,value) => props.filter(name,value)}/>
                </div>
                }

                <div style={{display: 'flex', justifyContent: "space-between", flexWrap: 'wrap'}}>
                    <Fade bottom when={props.count !== undefined}>
                        <div>
                        <span>
                            <strong style={{fontSize: 15}}> {props.count} </strong> resultados encontrados.
                        </span>
                            <Popover content={'Quantidade de currículos que ainda não foram selecionados com estes filtros selecionados'}>
                                <Icon type={'question-circle'}/>
                            </Popover>
                        </div>
                    </Fade>

                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div>
                            <Checkbox checked={activeFilters.exclude_imported} onChange={(e) => {props.filter("exclude_imported", e.target.checked ? true : null); props.search()}} />
                            <span>
                                &nbsp; Incluir currículos do SINE &nbsp;
                                <Popover content={'Selecione para retornar também os currículos da base nacional de emprego(Nestes currículos as informações são bem mais simplificadas)'}>
                                    <Icon type={'question-circle'}/>
                                </Popover>
                            </span>
                        </div>

                        <div  style={{display: 'flex', marginLeft: 20}}>
                            <label style={{fontSize: 14, marginRight: 5 }}> {!props.toggle? 'Lista' : 'Mapa'} </label>
                            <div className={'customSwitch'} style={{display: 'flex'}}>
                                <Popover style={{zIndex: 1}} content={'Clique aqui para ver o mapa'} title={'Mapa'} visible={!sawMapStorage.getPrimitive()}>
                                    <Switch style={{margin: 'auto'}}
                                            size={"small"}
                                            checkedChildren={ <Icon type={'environment'}/>}
                                            unCheckedChildren={<Icon type={'unordered-list'}/>}
                                            value={props.toggle}
                                            onChange={() => props.setToggle()}
                                    />
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Row>
    );
};

ListHeader.propTypes = {
    location: PropTypes.any,
    categoriesTreeList: PropTypes.any,
    searchAction: PropTypes.any,
    toggle: PropTypes.any,
    addLocToFilter: PropTypes.any
};

export default ListHeader;
