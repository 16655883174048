import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Icon, Row, Select} from "antd";
import GenericInput from "../../../../Generic/GenericInput";
import CepInput from "../../../../Generic/CepInput";
import {Slide} from "react-reveal";
import useApi from "../../../../../Hooks/Api";
import {checkStateRestriction, showNotification} from "../../../../../Hooks/Helpers";


const AddressCardForm = props => {
    const {getFieldDecorator, getFieldError, getFieldsValue, setFieldsValue, validateFields} = props.form;
    const [addressVisible, setAddressVisible] = useState(false);
    const [spinIcon, setSpinIcon] = useState(0);
    const api = useApi();
    const disableDistrict = useRef(true);
    const disableStreet = useRef(true);

    const checkCepInfoAvailability = (res) => {
        disableDistrict.current = true;
        disableStreet.current = true;
        const auxObj = {};
        if(res.district) {
            if(res.district === res.street_name) {
                disableDistrict.current = false;
            }
            auxObj.district = res.district;
        } else {
            auxObj.district = "";
            disableDistrict.current = false;
        }
        if(res.street_name) {
            if(res.district === res.street_name) {
                disableStreet.current = false;
            }
            auxObj.street_name = res.street_name;
        } else {
            auxObj.street_name = "";
            disableStreet.current = false;
        }
        setAddressVisible(true);
        setFieldsValue({
            district: auxObj.district,
            street_name: auxObj.street_name,
            city: res.city,
            state: res.state,
            country: res.country
        });
    };

    const getGeo = () => {
        setSpinIcon(1);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(item => {
                let geocoder = new window.google.maps.Geocoder;
                geocoder.geocode({'location': {lat: item.coords.latitude, lng: item.coords.longitude }}, async function (results, status) {
                    if(checkStateRestriction(results[0])) {
                        for (let i = 0; i < results[0].address_components.length; i++) {
                            for (let j = 0; j < results[0].address_components[i].types.length; j++) {
                                if (results[0].address_components[i].types[j] === 'postal_code') {
                                    let auxCep = results[0].address_components[i].long_name;
                                    setFieldsValue({cep: auxCep});
                                    api.get(`search/postal-code?cep=${auxCep}`, (res) => {
                                        if (res) {
                                            checkCepInfoAvailability(res)
                                        }
                                    }, (e) => {

                                    });
                                }
                            }
                        }
                        setSpinIcon(2);
                    } else {
                        showNotification();
                        setSpinIcon(0);
                    }
                })
            });
        }
    };

    useEffect(() => {
        if(props.addressToEdit) {
            setAddressVisible(true)
        }
    },[props.addressToEdit]);

    return (
        <div style={{overflow: 'hidden'}}>
            <Row  gutter={[20]}>
                <Col xs={24} sm={24} md={16}>
                    <GenericInput initialValue={props.addressToEdit?.name || ''}  required={true} form={props.form} error={getFieldError('name')} name='name' label='Nome'  placeholder='Digite o nome do endereço' valueForMemo={getFieldsValue().name}/>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item required={true} className='hideDots' validateStatus={getFieldError('address_type')&& 'error'} help={getFieldError('address_type')} label={<span style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> Tipo do Endereço</span>}>
                        {getFieldDecorator('address_type', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Campo Obrigatório',
                                },
                            ],
                            initialValue: props.addressToEdit?.address_type&& props.addressToEdit.address_type ,
                        })(<Select
                            mode="default"
                            style={{ width: '100%' }}
                            placeholder={"Escolha um Tipo"}
                            allowClear={false}
                            size={'large'}
                            suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: '16'}}/>}
                        >
                            <Select.Option value={'house'}> Casa </Select.Option>
                            <Select.Option value={'apartment'}> Apartamento </Select.Option>
                            <Select.Option value={'condominium'}> Condomínio </Select.Option>
                            <Select.Option value={'commercial'}> Imóvel Comercial </Select.Option>
                            <Select.Option value={'farmhouse'}> Chácara </Select.Option>
                        </Select>)}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={props.addressToEdit? 24: 12}>
                    <CepInput checkCepInfoAvailability={(item) => checkCepInfoAvailability(item)} disabled={props.addressToEdit} initialValue={props.addressToEdit.cep} setAddressVisible={(e) => setAddressVisible(e)} required={true} form={props.form} name='cep' label='CEP'/>
                </Col>
                {!props.addressToEdit &&<Col xs={24} sm={24} md={12} style={{ textAlign: 'center', marginTop: 38}}>
                   <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 16, marginRight: 10}}><strong> Use minha localização Atual </strong> </span>
                   <Button onClick={() => getGeo()} shape={'circle'} style={{marginTop: -2}} size={'large'}> <Icon className={'primary-sub-color'} type={spinIcon === 0? "environment": spinIcon === 1? "loading": "check"}/> </Button>
               </Col>}

            </Row>
            <Slide mountOnEnter unmountOnExit when={addressVisible} right>
                <>
                <Row gutter={12}>
                    <Col xs={24} sm={12} md={10}>
                        <GenericInput  initialValue={props.addressToEdit.street_name} disabled={disableStreet.current} form={props.form} error={getFieldError('street_name')} name='street_name' label='Rua'  placeholder='Digite a Rua' valueForMemo={getFieldsValue().street_name}/>
                    </Col>
                    <Col xs={24} sm={12} md={10}>
                        <GenericInput initialValue={props.addressToEdit.district} disabled={disableDistrict.current} form={props.form} error={getFieldError('district')} name='district' label='Bairro'  placeholder='Digite o Bairro' valueForMemo={getFieldsValue().district}/>
                    </Col>
                    <Col xs={24} sm={12} md={10}>
                        <GenericInput initialValue={props.addressToEdit.street_number} required={true} form={props.form} error={getFieldError('street_number')} name='street_number' type='number' label='Nº' placeholder='Nº' valueForMemo={getFieldsValue().street_number}/>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col xs={24} sm={14} md={12}>
                        <GenericInput initialValue={props.addressToEdit.city} disabled={true} form={props.form} error={getFieldError('city')} name='city' label='Cidade'  placeholder='Digite a Cidade' valueForMemo={getFieldsValue().city}/>
                    </Col>
                    <Col xs={24} sm={16} md={7}>
                        <GenericInput initialValue={props.addressToEdit.state} disabled={true} form={props.form} error={getFieldError('state')} name='state' label='Estado'  placeholder='Digite o Estado' valueForMemo={getFieldsValue().state}/>
                    </Col>
                    <Col xs={24} sm={5} md={5}>
                        <GenericInput initialValue={props.addressToEdit.country} disabled={true}  form={props.form} error={getFieldError('country')} name='country' label='País'  placeholder='Digite o País' valueForMemo={getFieldsValue().country}/>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <GenericInput initialValue={props.addressToEdit.complement} form={props.form} error={getFieldError('complement')} name='complement' label='Complemento'  placeholder='Complemento adicional do endereço' valueForMemo={getFieldsValue().complement}/>
                    </Col>
                </Row>
                    </>
            </Slide>
        </div>
    );
};

AddressCardForm.propTypes = {
    user: PropTypes.any,
    handleSave: PropTypes.any,
    addressToEdit: PropTypes.any
};

export default AddressCardForm;
