import React, {useEffect} from 'react';
import HowToHireServices from "../Components/PageComponents/Public/HowItWorksPage/HowToHireServices";
import HowToRecruit from "../Components/PageComponents/Public/HowItWorksPage/HowToRecruit";
import HowToDiscounts from "../Components/PageComponents/Public/HowItWorksPage/HowToDiscounts";
import HowToClub from "./HowToClub";

const HowItWorksPage = (props) => {

    useEffect(() => {
        window.scrollToElement('body');
    },[]);

    return (<div>
        {props.match.params.type === 'contratar'? <HowToHireServices/> : (props.match.params.type === 'curriculos')? <HowToRecruit/> : (props.match.params.type === 'estabelecimentos')? <HowToDiscounts/> : <HowToClub/>}
    </div>)
};
export default HowItWorksPage;
