import React from 'react';
import {Button, Col, Row} from 'antd';
import {Fade} from "react-reveal";
import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";
import {Env} from "../../../../Helpers/Env";

const BannerApps = () => {

    return (

        <Row style={{display: 'flex', justifyContent: 'center', overflow: 'hidden', marginTop: '100px', backgroundSize: 'cover'}}>
            <Col xs={23} lg={22} xl={18} xxl={15} style={{display: 'flex', flexFlow: 'wrap'}}>
                <Col span={24} style={{textAlign: 'center'}} className='headerHowTo'>
                    <h1 className={'title title-line'}>  {Texts.home_page.app.title1}   </h1>
                    <div style={{maxWidth:  600, margin: 'auto'}}> <span> <Fade delay={600}> {Texts.home_page.app.subTitle1} </Fade> </span></div>
                </Col>
                <Col xs={24} md={10} style={{zIndex: 200, margin: 'auto'}}>
                    <div style={{textAlign: 'center'}}>
                        <div> <img style={{maxHeight: 60, marginBottom: 10}} src={Images.storeIcon3}/> </div>
                        <h2 style={{color: '#595959'}}> Quero encontrar o profissional ideal </h2>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={() => window.redirectWorker(Env.client_play)} className={'ant-btn-sub-primary'} style={{height: 45, marginRight: 10, boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 3px 2px'}}>
                                <div>
                                    <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.gplay_icon}/> Disponível no <strong> Google Play </strong>
                                </div>
                            </Button>

                            <Button onClick={() => window.redirectWorker(Env.client_apple)} className={'ant-btn-sub-primary'} style={{height: 45, backgroundColor: '#3a81b6', color: 'white', borderColor: '#3a81b6', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 3px 2px'}}>
                                <div>
                                    <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.apple_icon}/> Disponível na <strong> App Store </strong>
                                </div>
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={10} style={{margin: 'auto'}}>
                    <div style={{textAlign: 'center'}}>
                        <div> <img style={{maxHeight: 60, marginBottom: 10}} src={Images.storeIcon1}/> </div>
                        <h2 style={{color: '#595959'}}> Quero divulgar meu trabalho </h2>

                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={() => window.redirectWorker(Env.worker_play)} className={'ant-btn-primary'} style={{height: 45, marginRight: 10, boxShadow: '0 2px 0 rgba(0, 0, 0, 0.06)'}}>
                                <div>
                                    <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.gplay_icon}/> Disponível no <strong> Google Play </strong>
                                </div>
                            </Button>

                            <Button onClick={() => window.redirectWorker(Env.worker_apple)} className={'ant-btn-primary'} style={{height: 45, color: 'white',  boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 3px 2px'}}>
                                <div>
                                    <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.apple_icon}/> Disponível na <strong> App Store </strong>
                                </div>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Col>
        </Row>
    );
};

BannerApps.propTypes = {};

export default BannerApps;
