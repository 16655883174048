import React, {useRef, useState} from 'react';
import {Button, Col, Layout, Row} from 'antd';
import {useHistory} from "react-router-dom";

import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";
import RegisterPageModal from "../../../../Pages/RegisterPageModal";

const CreateAccountBanner = () => {
    const history = useHistory();
    const [registerModal, setRegisterModal] = useState(false);
    const profileType = useRef('');
    return (

        <Row style={{minHeight: '480px', marginTop: 50, justifyContent: 'center', display: 'flex'}}>
            <Col xs={23} lg={22} xl={18} xxl={15} style={{textAlign: 'center', justifyContent: 'space-around'}}>
                <Row className='headerHowTo'>
                    <h1 className={'title title-line'}>  {Texts.home_page.register.title1} </h1>
                    <span> {Texts.home_page.register.subTitle1} </span>
                </Row>
                <Row gutter={[32,44]} style={{margin: 0, width: '100%', justifyContent: 'center', display: 'flex', flexWrap: 'wrap', alignItems: 'stretch'}}>
                    <Col xs={24} md={24} lg={12} >
                        <div className='createAccountBannersCard'>
                            <div style={{width: '100%'}}>
                                <img style={{maxHeight: '200px', maxWidth: '100%', marginBottom: 20}} src={Images.client}/>
                            </div>

                            <div style={{textAlign: 'left', display: 'flex', flexFlow: 'column', marginBottom: 20, marginTop: 10}}>
                                <div>
                                    <h2 className={'primary-sub-color'} style={{textAlign: 'center'}}> {Texts.home_page.register.client_title} </h2>
                                </div>
                                <Button className={'ant-btn-sub-primary'} style={{width: '210px', marginTop: 20, height: '40px'}} onClick={() =>{profileType.current = 'client'; setRegisterModal('regNumber')}}> <span style={{color: 'white'}}> Registrar </span> </Button>
                            </div>
                          </div>
                    </Col>
                    {/*<Col xs={24} md={24} lg={12}>
                        <div className='createAccountBannersCard'>
                            <div style={{width: '100%'}}>
                                <img style={{maxHeight: '200px', maxWidth: '100%',  marginBottom: 20}} src={Images.worker}/>
                            </div>
                            <div style={{textAlign: 'left', display: 'flex', flexFlow: 'column', marginBottom: 20, marginTop: 10}}>
                                <div>
                                    <h2 style={{textAlign: 'center'}}>{Texts.home_page.register.worker_title}  </h2>
                                </div>
                                <span>
                                    Amplie suas possibilidades de contratação gratuitamente pelo app, acesse agora mesmo!
                                </span>
                                <Button className={"ant-btn-green"}  onClick={() => {profileType.current = 'worker'; setRegisterModal('regNumber')}} style={{width: '210px',marginTop: 20, height: '40px'}}> <span style={{color: 'white'}}> Registrar </span>  </Button>
                            </div>
                          </div>
                    </Col>*/}

                </Row>
            </Col>
            <RegisterPageModal profile={profileType.current} visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />

        </Row>
    );
};

CreateAccountBanner.propTypes = {};

export default CreateAccountBanner;
