import React from 'react';
import {Carousel, Col, Row} from "antd";
import {Fade} from "react-reveal";
import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";

const HireServicesBanner = () => {

    return (
        <Carousel effect="fade" autoplay={true} autoplaySpeed={5000}>
            <Row className='bannerSubPages' style={{display: 'flex', justifyContent: 'center'}}>
                <div className='banner' style={{backgroundImage: `url(${Images.services.services_banner1})`}}/>
                <div className='bannerAppsBackground'/>
                <Col xs={22} sm={16} style={{alignItems: 'center', display: 'flex'}}>
                    <span className={'bannerText'}>
                        <Fade  duration={1000} mountOnEnter>
                            <strong style={{color: '#0c2766',padding: '0 10px 0 10px', backgroundColor: 'rgb(238 244 249)'}}>{Texts.hireServicesBanner.title1} </strong>
                        </Fade>
                        <br/>
                        <Fade delay={1000} duration={2500} mountOnEnter>
                            <span style={{color: 'white', padding: '0 10px 0 10px', backgroundColor: 'rgba(106, 196, 216, 0.8)'}}>{Texts.hireServicesBanner.subTitle1} </span>
                        </Fade>
                    </span>
                </Col>
            </Row>
            <Row className='bannerSubPages' style={{display: 'flex', justifyContent: 'center'}}>
                <div className='banner' style={{backgroundImage: `url(${Images.services.services_banner2})`}}/>
                <div className='bannerAppsBackground'/>
                <Col xs={22} sm={16} style={{alignItems: 'center', display: 'flex'}}>
                    <span className={'bannerText'}>
                        <Fade  duration={1000} mountOnEnter>

                            <strong style={{color: '#0c2766',padding: '0 10px 0 10px', backgroundColor: 'rgb(238 244 249)'}}>{Texts.hireServicesBanner.title2} </strong>
                        </Fade>
                        <br/>
                        <Fade delay={1000} duration={2500} mountOnEnter>
                            <span style={{color: 'white', padding: '0 10px 0 10px', backgroundColor: 'rgba(106, 196, 216, 0.8)'}}>{Texts.hireServicesBanner.subTitle2} </span>
                        </Fade>
                    </span>
                </Col>
            </Row>
            <Row className='bannerSubPages' style={{display: 'flex', justifyContent: 'center'}}>
                <div className='banner' style={{backgroundPosition: 'center', backgroundImage: `url(${Images.services.services_banner3})`}}/>
                <div className='bannerAppsBackground'/>
                <Col xs={22} sm={16} style={{alignItems: 'center', display: 'flex'}}>
                    <span className={'bannerText'}>
                        <Fade  duration={1000} mountOnEnter>
                            <strong style={{color: '#0c2766',padding: '0 10px 0 10px', backgroundColor: 'rgb(238 244 249)'}}>{Texts.hireServicesBanner.title3} </strong>
                        </Fade>
                        <br/>
                        <Fade delay={1000} duration={2500} mountOnEnter>
                            <span style={{color: 'white', padding: '0 10px 0 10px', backgroundColor: 'rgba(106, 196, 216, 0.8)'}}>{Texts.hireServicesBanner.subTitle3} </span>
                        </Fade>
                    </span>
                </Col>
            </Row>
        </Carousel>
    );
};

HireServicesBanner.propTypes = {};

export default HireServicesBanner;
