import React, {memo, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Col, Icon, List, Result, Row, Skeleton, Spin} from "antd";
import Link from "react-router-dom/es/Link";
import Loading from "../../../../Generic/Loading";
import {maskReal} from "../../../../../Helpers/Functions";
import {Images} from "../../../../../Helpers/Images";
import moment from "moment";
import useApi from "../../../../../Hooks/Api";
import {useSelector} from "react-redux";
import user_place_holder from '../../../../../Img/jobSearch.png'
import {FaCalendar, FaCalendarCheck, FaClipboardCheck, FaClipboardList, FaUserAltSlash, FaUserCheck, FaUsers} from "react-icons/fa";

Spin.setDefaultIndicator(<Loading/>);

const VacancyListComponent = (props) => {

    const nextPageRef = useRef(props.nextPage);

    const infiniteScroll = () => {
        let bodyAux = document.getElementById(`vacancyScroll`);
        if(nextPageRef.current) {
            if (bodyAux.scrollTop + bodyAux.clientHeight >= bodyAux.scrollHeight) {
                props.loadMore();
            }
        }
    }

    useEffect(() => {
        nextPageRef.current = props.nextPage;
    },[props.nextPage])

    useEffect(() => {
        if(document.getElementById(`vacancyScroll`)) document.getElementById(`vacancyScroll`).addEventListener('scroll', infiniteScroll)
        return () => {
            if(document.getElementById(`vacancyScroll`)) document.getElementById(`vacancyScroll`).removeEventListener(`scroll`, infiniteScroll);
        }
    },[])

    return (
        <div  className={'mainComponentPanel'} style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 0, borderRadius:5, backgroundColor: "white"}}>
            <Row>
                <Col xs={24} style={{display: "flex" ,padding: 10, borderBottom: "1px solid gainsboro", alignItems: "center"}}>
                    {props.ended ?
                        <FaClipboardCheck type={"file-done"} style={{marginRight: 8, fontSize: 18, marginTop: -4}} className={"secondary-color"}/>
                        :
                        <FaClipboardList style={{marginRight: 8, fontSize: 18, marginTop: -4}} className={"secondary-color"}/>
                    }
                    <span style={{fontSize: 18}}>{props.ended? 'Suas divulgações encerradas' : 'Suas divulgações em andamento'}</span>
                </Col>

                <Col xs={24} style={{padding: 5, minHeight: 500, maxHeight: window.innerHeight - 170, overflowY: `scroll`}} id={`vacancyScroll`}>
                    {(props.vacancies?.length > 0) &&<List
                        className='listAnt'
                        style={{overflow: 'hidden'}}
                        itemLayout="horizontal"
                        dataSource={props.vacancies}
                        loading={props.loading}
                        renderItem={item => (
                            <List.Item onClick={() => props.handleVacancySelect(item)} style={{position: 'relative', cursor: "pointer", padding: 0, backgroundColor: "white"}}>
                                <Skeleton avatar title={false} loading={false} active>
                                    <div style={{width: "100%"}}>
                                        <div style={{display: "flex", padding: 15}}>
                                            <Avatar style={{width: 60, height: 60}} size='default' src={item.job_avatar || user_place_holder}> </Avatar>
                                            <div style={{marginLeft: 10}}>
                                                <div>
                                                    <span style={{fontSize: '18px'}}>{item.occupation_title? item.occupation_title : item.occupation_name}  </span> <br/>
                                                    <span style={{fontSize: 13, color: "#9e9e9e"}}> {item.occupation_title? (item.occupation_name + " / " + item.job_type[0]) : item.job_type[0] }</span>
                                                </div>
                                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                                    <div style={{marginRight: 10, display: 'flex', borderRadius: 5, paddingLeft: 5, paddingRight: 5, color: 'rgb(10, 121, 202)', alignItems: 'center'}}>
                                                        <FaUsers style={{marginBottom: 2}}/>
                                                        <strong style={{marginRight: 4, marginLeft: 2}}>
                                                            {item.summary.pending}
                                                        </strong>
                                                        <span style={{fontSize: 14}}> Pendente(s) </span>
                                                    </div>
                                                    <div style={{margin: 5, marginRight: 10, display: 'flex', borderRadius: 5, paddingLeft: 5, paddingRight: 5, color: 'rgb(84, 183, 35)', alignItems: 'center'}}>
                                                        <FaUserCheck style={{marginBottom: 2}}/>
                                                        <strong style={{marginRight: 4, marginLeft: 2}}>
                                                            {item.summary.approved}
                                                        </strong>
                                                        <span style={{fontSize: 14}}> Aprovado(s) </span>
                                                    </div>
                                                    <div style={{margin: 5, marginRight: 10, display: 'flex', borderRadius: 5,paddingLeft: 5, paddingRight: 5, color: 'rgb(239, 28, 28)', alignItems: 'center'}}>
                                                        <FaUserAltSlash style={{marginBottom: 2}}/>
                                                        <strong style={{marginRight: 4, marginLeft: 2}}>
                                                            {item.summary.rejected}
                                                        </strong>
                                                        <span style={{fontSize: 14}}> Reprovado(s) </span>
                                                    </div>

                                                </div>
                                             </div>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "space-between", padding: 10, borderTop: "1px solid gainsboro"}}>
                                            <div>
                                                <Icon type={"environment"}/>
                                                <span style={{color: "#757575"}}>{item.work_place === 'mobile'? "Trabalho remoto / Home office" : (item.work_place === 'itinerant' ? "Sem endereço fixo" : (item.work_address?  item.work_address.street + " / " + item.work_address.city + ' - ' +  item.work_address.postal_code : null))} </span>
                                            </div>

                                            <div style={{color: "#9e9e9e", display: 'flex'}}>
                                                <div style={{marginRight: 10}}>
                                                    <FaCalendar/>
                                                    <span> {moment(item.start_date).format("DD/MM/YYYY")}</span>
                                                </div>

                                                {item.closed_at &&<div style={{color: '#d48c8c'}}>
                                                    <FaCalendarCheck/>
                                                    <span> {moment(item.closed_at).format("DD/MM/YYYY")}</span>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </Skeleton>
                            </List.Item>
                        )}
                    /> }
                    {props.vacancies?.length === 0 && !props.loading &&
                    <div  style={{backgroundColor: "white"}}>
                        <div style={{color: '#545a5d'}}>
                            <div style={{textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                                {props.ended ? <span style={{fontSize: 19}}> Você não possui nenhuma divulgação em andamento </span> : <span style={{fontSize: 19}}>Você não possui nenhuma divulgação encerrada</span>}
                                    <span> Gostaria de divulgar uma vaga em sua empresa? divulgue agora! </span>
                                <Button className={"ant-btn-primary"} style={{marginTop: 40, width: "fit-content"}} onClick={() => props.changeMenu("2")}> Divulgar vaga </Button>
                            </div>
                        </div>
                    </div>

                    }
                    {props.nextPage&&
                        <div style={{textAlign: 'right', padding: 5}}>
                            <Button className={"ant-btn-primary"} style={{width: "fit-content"}} onClick={() => props.loadMore()}> Mostrar mais </Button>
                        </div>
                    }
                </Col>
            </Row>
        </div>
    );
};

VacancyListComponent.propTypes = {
    establishments: PropTypes.any,
    loading: PropTypes.any
};

export default VacancyListComponent;
