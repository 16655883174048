import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Dropdown, Form, Icon, Input, Menu, message, Popover, Row, TreeSelect} from "antd";
import useLocalStorage from "../../../../Hooks/Storage";
import _ from "lodash";
import useAuth from "../../../../Hooks/Auth";
import {Env} from "../../../../Helpers/Env";
import {checkStateRestriction, showNotification} from "../../../../Hooks/Helpers";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";
import SideBarFilter from "./SideBarFilter";
import SubMenu from "antd/es/menu/SubMenu";
import {filterWorkerSearch} from "../../../../Helpers/Functions";

const ListHeader = (props) => {
    const {getFieldDecorator, getFieldsValue, getFieldError, setFieldsValue} = props.form;
    const [geoLocation, setGeoLocation] = useState('');
    const geocode = useRef();
    const [spinIcon, setSpinIcon] = useState(0);
    const storage = useLocalStorage(StorageVariables.geolocation);

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const lastWorkerSearch = useLocalStorage('lastWorkerSearch');
    const [value, setValue] = useState(props.occupation_id? props.occupation_id : (lastWorkerSearch.getPrimitive()? lastWorkerSearch.getPrimitive() : 27));
    const [textValue, setTextValue] = useState("")

    const onSelectService = (value, text) => {
        setTextValue(text);
        setFieldsValue({occupation_id: value});
        lastWorkerSearch.setPrimitive(value);
    };

    const getOccupationName = () => {
        let id = props.occupation_id? props.occupation_id : (lastWorkerSearch.getPrimitive()? lastWorkerSearch.getPrimitive() : 27);
        props.servicesTreeList.forEach((item) => {
            item.occupations.forEach((subItem) => {
                if(subItem.id === Number(id)) {
                    setTextValue(subItem.name)
                }
            })
        });
    }

    const handleSearch = () => {
        return filterWorkerSearch(textValue, props.servicesTreeList);
    }

    const onExpand = (expandedKeys,  expanded, node ) => {
        setExpandedKeys(expandedKeys);
    };

    const onSelect = (value, node) => {
        if (node.isLeaf()) {
            setFieldsValue({occupation_id: value});
            lastWorkerSearch.setPrimitive(value);
            setValue(value);
            setIsDropdownVisible(false);
        } else {
            setIsDropdownVisible(true);
            setExpandedKeys(node.props.expanded
                ? expandedKeys.filter(k => k !== node.props.eventKey)
                : expandedKeys.concat(node.props.eventKey));

        }
    };


    const onDropdownVisibleChange = (visible) => {
        setIsDropdownVisible(visible)
    };

    const setAddress = (item) => {
        storage.setObject({lat: item.lat, long: item.long, loc: item.name, userLat: storage.getObject()?.userLat});
        setGeoLocation(item.name);
        setFieldsValue({locFilled: 'yes'});
        props.searchAction()
    }

    useEffect(() => {
        if (window.innerWidth < 768) {
            if (props.occupation_id) {
                setFieldsValue({occupation_id: props.occupation_id?.toString()})
            }
        }
        if (props.location) {
            setFieldsValue({locFilled: 'yes'});
            if(props.location){
                setGeoLocation(_.replace(props.location,/\+/g," "));
                setSpinIcon(2);
            }
        }
    },[props.occupation_id, props.location]);

    useEffect(() => {
        window.scrollToElement('body');
        geocode.current = new window.google.maps.places.Autocomplete(document.querySelector('#geocodeField'), {types: ['geocode']});
        geocode.current.addListener('place_changed', getGeolocation);
        geocode.current.setComponentRestrictions({'country': ['br']});
    },[]);

    useEffect(() => {
        getOccupationName()
    },[props.servicesTreeList])


    const getGeo = () => {
        setSpinIcon(1);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(item => {
                let geocoder = new window.google.maps.Geocoder;
                geocoder.geocode({'location': {lat: item.coords.latitude, lng: item.coords.longitude }}, function(results, status) {
                    if (results && checkStateRestriction(results[0])) {
                        setGeoLocation(results[0].formatted_address);
                        storage.setObject({lat: item.coords.latitude, long: item.coords.longitude, loc: results[0].formatted_address, userLat: storage.getObject()?.userLat});
                        setFieldsValue({locFilled: 'yes'});
                        setSpinIcon(2);
                        props.searchAction();
                    } else {
                        setSpinIcon(0);
                        showNotification();
                        setGeoLocation('');
                    }
                })
            }, () =>
            {
                message.info('Parece que seu navegador está bloqueando o acesso a sua localização! ', 5);
                setSpinIcon(0)
            });
        }
    };

    const getGeolocation = () => {
        setTimeout(() => {
            const place = geocode.current.getPlace();
            if(checkStateRestriction(place)) {
                storage.setObject({lat: place.geometry.location.lat(), long: place.geometry.location.lng(), loc: place.formatted_address, userLat: storage.getObject()?.userLat});
                setFieldsValue({locFilled: 'yes'});
                setGeoLocation(place.formatted_address);
                props.searchAction()
            } else {
                showNotification();
                setGeoLocation('');
            }

        },[200]);
    }

    return (
        <Col xs={20} sm={16} md={18} lg={15} style={{display:"flex", justifyContent:"center", flexDirection:"column"}} className='header-provider'>
            <Row gutter={[16,16]}>
                <Col xs={24} md={12} lg={12}>
                    <label style={{fontSize: 18}}> Qual serviço está buscando? </label>
                    <Form.Item required={true} help={getFieldError('occupation_id')}>
                        <Dropdown trigger={'click'}
                                  overlay={
                            <Menu className={'searchWorkerMenu'}>
                              {textValue !== "" ?
                                  handleSearch().map((item, index) =>
                                      <Menu.Item onClick={() => onSelectService(item.id, item.name)} key={index}>{item.name}</Menu.Item>
                                  )
                                  :
                                  props.servicesTreeList.map((item, index) =>
                                      <SubMenu key={index} title={item.name}>
                                          {item.occupations.map((subItem, subIndex) =>
                                              <Menu.Item onClick={() => onSelectService(subItem.id, subItem.name)} key={subIndex}>{subItem.name}</Menu.Item>
                                          )}
                                      </SubMenu>
                                  )
                              }
                            </Menu>}>

                            <Input value={textValue}
                                   style={{fontSize: 16, marginTop:12}}
                                   onChange={(e) => setTextValue(e.target.value)}
                                   placeholder={'Selecione o serviço desejado'}/>
                        </Dropdown>
                    </Form.Item>

                    <div style={{display: 'none'}}>
                        {getFieldDecorator('occupation_id', {
                            initialValue: props.occupation_id? props.occupation_id : (lastWorkerSearch.getPrimitive()? lastWorkerSearch.getPrimitive() : 27),
                            rules: [
                                {
                                    required: true,
                                    message:  'Escolha um serviço',
                                },
                            ]
                        })(<Input/>)}
                    </div>
                </Col>

                <Col xs={24} md={12} lg={12}>
                    <label style={{fontSize: 18}}> Tem um nome em mente? </label>
                    <Form.Item>
                        {getFieldDecorator('worker', {
                            initialValue: props.worker&& props.worker,
                            rules: []
                        })( <Input style={{fontSize: 16, marginTop:12}} size={'large'} placeholder={'Nome'} suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: '16px' }} />}/>)}
                    </Form.Item>
                </Col>
            </Row>

            <Col lg={24}>
                <label style={{fontSize: 18,}}> Para onde você precisa do {Env.application_title}? </label>
                <Form.Item style={{marginBottom: 0}} validateStatus={getFieldError('locFilled')&& 'error'} help={getFieldError('locFilled')}>
                    <Input className='paddingGeoSubPage'
                           style={{ fontSize: 16, marginBottom: 20, marginTop: 12}}
                           size='large'
                           id='geocodeField' type='text' onChange={(e) => setGeoLocation(e.target.value)} value={geoLocation}
                           placeholder='Digite um Endereço'
                           prefix={
                               <Popover
                                   placement='bottomLeft'
                                   content={<span> {storage.getObject()?.userLat? 'Selecione aqui seus endereços cadastrados' : 'Clique aqui para usar minha localização Atual!' }  </span>}
                                   trigger={"hover"}
                                   defaultVisible={storage.getObject()?.loc? false : true}
                               >
                                   <Dropdown overlay={
                                       <Menu>
                                           <Menu.Item onClick={() => getGeo()} key="default"> Usar minha localização Atual </Menu.Item>
                                           {storage.getObject()?.userLat?.map((item, index) =>
                                               <Menu.Item onClick={() => setAddress(item)} key={index}> {item.name} </Menu.Item>
                                           )}
                                       </Menu>
                                   }>
                                       <Icon  onClick={() => getGeo()} type={spinIcon === 0? "environment": spinIcon === 1? "loading": "check"} className={'primary-sub-color'} />
                                   </Dropdown>
                               </Popover>}
                    />
                </Form.Item>

                <Form.Item  required={true} help={''} style={{margin: 0}}>
                    {getFieldDecorator('locFilled', {
                        rules: [
                            {
                                required: true,
                                message:  'É necessário escolher um local',
                            },
                        ]
                    })( <Input style={{display: "none"}}/>)}
                </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6} style={{ margin:"auto"}}>
                <Button icon={'search'} className='searchButtonSubPage' onClick={() => props.searchAction()}> Buscar </Button>
            </Col>
        </Col>
    );
};

ListHeader.propTypes = {
    location: PropTypes.any,
    servicesTreeList: PropTypes.any,
    worker: PropTypes.any,
    searchAction: PropTypes.any,
    occupation_id: PropTypes.any,

};

export default ListHeader;
