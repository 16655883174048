import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Modal, Select} from "antd";
import NumberFormat from "react-number-format";


const ExportModal = props => {
    const [completed_min, setCompletedMin] = useState();
    const [completed_max, setCompletedMax] = useState();
    const [feedback_type, setFeedbackType] = useState();


    return (
        <Modal className={'feedbackModal'}
               visible={props.visible}
               title={'Exportar currículos'}
               confirmLoading={props.loading}
               onOk={() => props.handleJsonDownload({completed_min, completed_max, feedback_type})}
               onCancel={() => props.setExportType(false)}
               okText={'Exportar'}>
            <React.Fragment>

                <Form.Item label="Escolha quais currículos deseja exportar">
                    <Select
                        size={'large'}
                        style={{width: '100%'}}
                        value={feedback_type}
                        onChange={(value) => {setFeedbackType(value)}}
                        disabled={props.loading}  placeholder="Todos">
                        <Select.Option value={'contrato'}> Contratados (Currículos que tiveram um feedback positivo) </Select.Option>
                        <Select.Option value={'entrevista'}> Não contratados (Currículos que tiveram um feedback negativo) </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Escolha os currículos de qual período gostaria de exportar">
                    <div style={{display: 'flex'}}>
                        <NumberFormat className={'ant-input ant-input-lg'} format="##/##/####" placeholder="DD/MM/AAAA" value={completed_min}  onChange={(e) => {setCompletedMin(e.target.value)}}/>
                        <div style={{width: 'fit-content', marginTop: 'auto', paddingLeft: 5, paddingRight: 5}}> Até </div>
                        <NumberFormat className={'ant-input ant-input-lg'} format="##/##/####" placeholder="DD/MM/AAAA" value={completed_max}  onChange={(e) => {setCompletedMax(e.target.value)}}/>
                    </div>
                </Form.Item>
            </React.Fragment>
        </Modal>

    );
};

ExportModal.propTypes = {
    addressToEdit: PropTypes.any,
    exportType: PropTypes.any,
    setExportType: PropTypes.any
};

export default ExportModal;
