import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Col, Icon, List, Result, Row, Skeleton, Spin} from "antd";
import Loading from "../../../Generic/Loading";
import Link from "react-router-dom/es/Link";

Spin.setDefaultIndicator(<Loading/>);

const MainListComponent = (props) => {
    return (
        <Row>
            <Col>
                {(props.establishments?.length > 0 || props.loading) &&<List
                    className='listAnt listAntEstablishmentsRibbon'
                    style={{overflow: 'hidden', minHeight: '600px'}}
                    itemLayout="horizontal"
                    dataSource={props.establishments}
                    loading={props.loading}
                    renderItem={item => (
                        <List.Item style={{padding: 20, paddingBottom: 20, position: 'relative'}} actions={[<Link to={'/estabelecimento/' + item.id}><Button type="primary" style={{height: 36}} icon="dollar" size='default'> Ver Ofertas </Button></Link>]}>
                            <Skeleton avatar title={false} loading={false} active>
                                <List.Item.Meta
                                    className={'listAntEstablishments'}
                                    avatar={
                                        <div> <Avatar style={{width: 80, height: 80}} size='default' src={item.profile_picture.thumbnail || '/img/craftyClub.png'}> </Avatar> </div>
                                    }
                                    title={<span style={{fontSize: '15px'}}> <span style={{fontWeight: "bold"}}>{item.name}  </span>  <br/> {item.group} </span>}
                                    description={<span> <span style={{ color: item.ratings_avg === 0? 'lightgray' : '#ffc53d', left: item.ratings_avg === 0&& '34px'}}> <Icon type='star' theme="filled"/>  { item.ratings_avg === 0? 'Novo' : item.ratings_avg.toFixed(1)} </span>
                                    <br/> <Icon className={'primary-sub-color'} type='environment'/> {window.getDistance(item.distance)} </span>}
                                />
                                {item.discount &&<div className="wrap">
                                    <span className="ribbon6">Desconto de <strong> {item.discount} </strong></span>
                                </div>}
                               </Skeleton>
                        </List.Item>
                    )}
                /> }
                {props.establishments.length === 0 && !props.loading &&
                <Result
                    status="404"
                    title="Oops, não há prestadores de Serviço para essa Pesquisa"
                    subTitle="Tente buscar com outros filtros"
                />
                }
            </Col>
        </Row>
    );
};

MainListComponent.propTypes = {
    establishments: PropTypes.any,
    loading: PropTypes.any
};

export default memo(MainListComponent, (prevProps, nextProps) => {
    if(prevProps.establishments !== nextProps.establishments) return false;
    if(prevProps.loading !== nextProps.loading) return false;
    return true
});
