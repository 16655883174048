import Route from "react-router-dom/es/Route";
import React from "react";
import Redirect from "react-router-dom/es/Redirect";
import useAuth from "../Hooks/Auth";

export const PrivateRoute = ({component: Component, ...obj}) => {

    const {isLogged} = useAuth();

    return (
        <Route {...obj}
           render={props => isLogged() ?
               (<Component {...props} />)
               :
               (<Redirect to='/notFound'/>)
           }/>)
};