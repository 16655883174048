import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Icon, Input, message, Row, Select, Spin} from "antd";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";
import moment from "moment";
import useApi from "../../../../../Hooks/Api";
import {FaBackward, FaEdit, FaForward, FaPlus, FaTrashAlt, FaUniversity} from "react-icons/fa";
import {MdWork} from "react-icons/md";

const schooling = ['Superior Incompleto', 'Superior Completo', 'Mestrado', 'Doutorado', 'Especialização'];


const GradComponent = (props) => {

    const gradsList = useRef([]);
    const [gradSelected, setGradSelected] = useState(null);
    const {getFieldDecorator, getFieldsValue, setFieldsValue, validateFields, getFieldsError} = props.form;
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    const getGrads = async () => {
        setInitialLoading(true);
        api.get(`sine/user/${user.user_id}/academics`, (res) => {
            if(res.user_id !== null) {
                props.setHasProfile(true);
                if (res.academic?.length > 0) {
                    gradsList.current = res.academic;
                } else {
                    gradsList.current = [];
                }
                setInitialLoading(false);
            }
        },(e) => {
            console.log(e);
            setInitialLoading(false);
        });

        window.scrollToElement('body')
    }

    const validAndFormatDate = (date) => {
        if(date) {
            if(moment(date, 'MM/YYYY').isValid()) {
                return moment(date, 'MM/YYYY').format('YYYY-MM');
            } else {
                console.log('nao eh valido');
                return false;
            }
        }
    }

    const handleAddGrad = () => {
        setGradSelected({});
    };

    const handleSaveNewGrad = async (index) => {
        validateFields(async (error, values) => {
            if(error) return 0;
            setLoading(true);

            let aux = validAndFormatDate(values.end_date);
            if(aux !== false) values.end_date = aux;
            else {
                message.warning('Informe a data final corretamente (Dia/Mês)');
                setLoading(false);
                return 0;
            }

            aux = validAndFormatDate(values.start_date);
            if(aux !== false) values.start_date = aux;
            else {
                message.warning('Informe a data inicial corretamente (Dia/Mês)');
                setLoading(false);
                return 0;
            }
            message.loading('Adicionando...');
            api.post(`sine/user/${user.user_id}/academic`, values, (res) => {
                setGradSelected(undefined);
                message.destroy();
                message.success('Sucesso!');
                getGrads();
                setLoading(false);
            }, (e) => {
                message.warning(e.message || 'Não foi possível salvar, tente novamente!');
                console.log(e);
                setLoading(false);
            });
        });
    };

    const handleEditGrad = async (id) => {
        validateFields(async (error, values) => {
            if(error) return 0;
            setLoading(true);

            let aux = validAndFormatDate(values.end_date);
            if(aux !== false) values.end_date = aux;
            else {
                message.warning('Informe a data final corretamente (Dia/Mês)');
                setLoading(false);
                return 0;
            }

            aux = validAndFormatDate(values.start_date);
            if(aux !== false) values.start_date = aux;
            else {
                message.warning('Informe a data inicial corretamente (Dia/Mês)');
                setLoading(false);
                return 0
            }

            message.loading('Editando...');
            api.put(`sine/user/${user.user_id}/academic/${id}`, values, (res) => {
                setGradSelected(null);
                message.destroy();
                message.success('Sucesso!');
                setLoading(false);
                getGrads();
            },(e) => {
                console.log(e);
                message.warning(e.message || 'Não foi possível salvar, tente novamente!');
                setLoading(false);
            });
        });
    };

    const handleDeleteGrad = async (id) => {
        if(!loading) {
            setLoading(true);
            message.loading('Removendo...');
            api.del(`sine/user/${user.user_id}/academic/${id}`, null, (res) => {
                setGradSelected(null);
                message.destroy();
                message.success('Sucesso!');
                getGrads();
                setLoading(false);
            }, (e) => {
                console.log(e);
                message.warning(e.message || 'Não foi possível deletar, tente novamente!');
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        getGrads();
        window.scrollToElement('body')
    },[]);

    return (<div style={{marginBottom: 200}} className={'experienceForm aboutCss'}>


        <div style={{ paddingBottom: 35, borderBottom: '1px solid gainsboro',textAlign: 'center', marginTop: 50 }}>
            <h1>
                <strong style={{color: '#242424', fontSize: 25}}> Suas graduações </strong>
            </h1>
            <span  style={{ fontSize: 20,color: '#9E9E9E'}}>   Adicione aqui caso tenha realizados algum curso de nível superior </span> <br/>
            <span  style={{ fontSize: 16,color: '#1890ff9e'}}>   Clique no botão adicionar, e adicione todos os seus cursos de nível superior/graduações/faculdades </span> <br/>
        </div>


        <Row className={'formTitle'} style={{display: 'flex', marginBottom: 35, justifyContent: 'flex-end'}}>
            <Button className={'ant-btn-green'}  style={{visibility: gradSelected !== null && 'hidden', display: 'flex', fontSize: 16, alignItems: 'center'}} onClick={() => handleAddGrad()}>Adicionar <FaPlus style={{color: 'white', fontSize: 17, marginLeft: 5}}/> </Button>
        </Row>
        {gradSelected&& <Row gutter={[24,24]} style={{marginBottom: 30, backgroundColor: 'white'}} className={'formRow'}>
            <Col xs={24} md={12}  className={'formItem'}>
                <div className='resumeLabel'><label> <span>*</span> Universidade </label> </div>
                {getFieldDecorator(`university`,
                    {
                        rules: [{required: true, message: 'Universidade é obrigatório'}],
                        initialValue: gradSelected? (gradSelected.university || '') : ''
                    }
                )(
                    <Input placeholder={'Nome da Universidade'} size={'large'}/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().university} </span> </div>

            </Col>
            <Col xs={24} md={12}  className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span>  Curso  </label> </div>
                {getFieldDecorator(`course`,
                    {
                        rules: [{required: true, message: 'Curso é obrigatório'}],
                        initialValue: gradSelected? (gradSelected.course || '') : ''
                    }
                )(
                    <Input placeholder={'Curso realizado'} size={'large'}/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().course} </span> </div>

            </Col>
            <Col xs={24} md={16}  className={'formItem'}>
                <div className='resumeLabel'><label> <span>*</span>  Grau acadêmico </label> </div>
                {getFieldDecorator(`academic_degree`,
                    {
                        rules: [{required: true, message: 'Grau acadêmico é obrigatório'}],
                        initialValue: gradSelected? (gradSelected.academic_degree || undefined) : undefined
                    }
                )(
                    <Select
                        placeholder="Selecione o grau do curso"
                        size={'large'}
                    >
                        {schooling.map((item, index) =>
                            <Select.Option key={index} value={item}>{item}</Select.Option>
                        )}
                    </Select>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().academic_degree} </span> </div>


            </Col>
            <Col  xs={12} md={4}  className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span>  Início </label> </div>
                {getFieldDecorator(`start_date`,
                    {
                        rules: [{required: true, message: 'Data inicial é obrigatório'}],
                        initialValue: gradSelected.start_date? (moment(gradSelected.start_date, 'YYYY-MM').format('MM/YYYY') || undefined) : undefined
                    }
                )(
                    <NumberFormat type={"tel"} style={{textAlign: 'center'}} className={'ant-input ant-input-lg'} format="##/####" placeholder="Mês/Ano"/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().start_date} </span> </div>

            </Col>
            <Col xs={12} md={4}  className={'formItem'}>
                <div className='resumeLabel'><label> Fim </label> </div>
                {getFieldDecorator(`end_date`,
                    {
                        rules: [],
                        initialValue: gradSelected.end_date? (moment(gradSelected.end_date, 'YYYY-MM').format('MM/YYYY') || undefined) : undefined
                    }
                )(
                    <NumberFormat type={"tel"} style={{textAlign: 'center'}} className={'ant-input ant-input-lg'} format="##/####" placeholder="Mês/Ano"/>
                )}
            </Col>

            <Col style={{alignItems: 'flex-end'}} className={'listButtons'} span={24}>
                {!gradSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-secondary'} onClick={() => handleSaveNewGrad()}> Salvar </Button>}
                {gradSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-secondary editButton'} onClick={() => handleEditGrad(gradSelected.id)}> Salvar  </Button>}
                {gradSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-red'} onClick={() => handleDeleteGrad(gradSelected.id)}> Remover </Button>}
            </Col>
        </Row>}

        {gradsList.current?.map((item, index) =>
            gradSelected?.id !== item.id && <Row key={index} gutter={[24,24]} style={{marginBottom: 30, display: 'flex', backgroundColor: 'white'}} className={'formRow'}>
                <Col  xs={10} md={10}  className={'listView'}>
                    <div> <FaUniversity/> </div>
                    <span> {gradsList.current[index]?.university? gradsList.current[index]?.university : <Icon type="loading"/> } </span>
                </Col>
                <Col xs={10} md={10}  className={'listView'}>
                    <div> <MdWork/> </div>
                    <span> {gradsList.current[index]?.course? gradsList.current[index]?.course : <Icon type="loading"/>} </span>
                </Col>
                <Col xs={4} md={4}  style={{justifyContent: 'flex-end', padding: 0, fontSize: 20, display: 'flex', alignItems: 'center'}}>
                    <div><FaEdit className={'editButtonIcon'} onClick={() => setGradSelected(item)}/></div>
                    <div><FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}} onClick={() => handleDeleteGrad(item.id)}/></div>
                </Col>
            </Row>
        )}
        {(initialLoading && gradsList.current.length === 0) ? <div className={'loadingResume'}><Spin/></div>
            :
            <div className={'buttonRow'} justify={'center'}>
                <Button shape={'round'} onClick={() => props.handleChangeStep(1)} type={'default'}>  <FaBackward className={'exportedIconsLeft'}/> Voltar </Button>
                <Button shape={'round'} onClick={() => props.handleChangeStep(3)} className={'ant-btn-sub-primary'}> Próximo <FaForward className={'exportedIcons'}/> </Button>
            </div>
        }
    </div>)
};
export default Form.create({name: 'gradsForm'})(GradComponent);
