import React, {useEffect,useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    Col,
    Form,
    Icon,
    Input,
    Layout,
    Menu,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch
} from "antd";
import moment from "moment";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";

import {FaBriefcase, FaClipboardCheck, FaClipboardList, FaForward, FaPlusSquare, FaRegEdit, FaStoreAlt, FaUsers} from "react-icons/fa";
import useApi from "../../../../../Hooks/Api";
import {useValidationShorts} from "../../../../../Hooks/Helpers";
import useLocalStorage from "../../../../../Hooks/Storage";
import {StorageVariables} from "../../../../../Helpers/StoragesVariables";
import RegisterEnterpriseModal from "../../../../../Pages/RegisterEnterpriseComponent";
import {Images} from "../../../../../Helpers/Images";
import Loading from "../../../../Generic/Loading";
import SubMenu from "antd/es/menu/SubMenu";
import VacancyFormPage from "./VacancyFormComponent";
import VacancyListComponent from "./VacancyListComponent";
import {maskCnpj} from "../../../../../Helpers/Functions";
import VacancyDetailsComponent from "./VacancyDetailsComponent";
import CandidatesListComponent from "./CandidatesListComponent";
import RegisterEnterpriseComponent from "../../../../../Pages/RegisterEnterpriseComponent";
import MyRecruitmentsPage from "../MyRecruitmentsPage";
import {useHistory} from 'react-router-dom'
import useAuth from "../../../../../Hooks/Auth";
import Popover from "antd/es/popover";
import UserAccessPage from "../UserAccessPage";

const VacancyManagePage = (props) => {

    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const [loading, setLoading] = useState(false);
    const [rightLoading, setRightLoading] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const enterprise = useRef(null)
    const vacancyList = useRef([]);
    const [menuSelected, setMenuSelected] = useState("sub")
    const [subExpanded, setSubExpanded] = useState([]);
    const vacancy = useRef(null);
    const endedVacancies = useRef([]);
    const [loadingVacancy, setLoadingVacancy] = useState(false);
    const history = useHistory();
    const {storeEnterpriseOpportunities, storeEnterprise} = useAuth();
    const paginate = useRef({
        page: 1,
    });
    const nextPage = useRef(false);

    const getVacancies = async (status, more) => {
        if(more) {
            setLoadingVacancy(true)
            paginate.current.page = paginate.current.page + 1;
        } else {
            setRightLoading(true);
            paginate.current.page = 1;
        }
        api.post(`sine/user/${user.user_id}/job-opportunities`, {
            "order": "desc",
            "page": paginate.current.page,
            "status": status
        },(res) => {
            res.next_page? nextPage.current = true : nextPage.current = false;
            if(status === 'open') {
                vacancyList.current = (more? vacancyList.current.concat(res.job_opportunities) : res.job_opportunities);
                storeEnterpriseOpportunities(res.job_opportunities);
            } else {
                endedVacancies.current = (more? vacancyList.current.concat(res.job_opportunities) : res.job_opportunities);
            }
            setRightLoading(false);
            setLoadingVacancy(false);
        },(e) => {
            setLoadingVacancy(false);
            setRightLoading(false);
            console.log(e);
        });
    };

    const getEnterprise = async () => {
        setLoading(true)

        api.post(`sine/user/${user.user_id}/companies`,{
            "page": 1,
            "order": "desc"
        },(res) => {
            if(res.entries === 0) {
                setShowRegisterModal(true);
                setLoading(false);
            } else {
                enterprise.current = res.user_companies[0];
                storeEnterprise(res.user_companies[0]);
                setLoading(false);
                getVacancies('open');
            }
        },(e) => {
            setLoading(false);
        });
    }


    const handleSubMenus = (e) => {
        const aux = subExpanded.findIndex((item) => item === e);
        const listAux = subExpanded;

        if(aux !== -1) {
            listAux.splice(aux, 1);
            setSubExpanded(listAux);
        } else {
            listAux.push(e);
            setSubExpanded(listAux);
        }
    }

    const getVacancy = async (e) => {
        setMenuSelected(e);
        setRightLoading(true);
        api.get(`sine/user/${user.user_id}/job-opportunity/${e.slice(10)}`, (res) => {
            vacancy.current = res;
            vacancy.current.id = e.slice(10);
            setRightLoading(false)
        },(e) => {
            setRightLoading(false);
        });
    }

    const changeTab = (e) => {
        if(enterprise.current) {
            window.scrollToElement('body');
            if(e === "sub") {
                handleSubMenus(e)
                setMenuSelected(e);
            } else if(e.includes("subdetails")) {
                getVacancy(e);
            }
            else if(e.includes("subcandidates")) {
                if(vacancy.current) {
                    vacancy.current.id = e.slice(13);
                } else {
                    vacancy.current = {id : e.slice(13) }
                }
                setMenuSelected(e)
            }
            else if(e.includes("sub")) {
                handleSubMenus(e);
            }
            else {
                if(e === '5') {
                    getVacancies('closed')
                }
                setMenuSelected(e)
            }
        } else {
            if(e === "4") {
                setMenuSelected(e)
            } else {
                setShowRegisterModal(true);
            }
        }
    }

    useEffect(() => {
        getEnterprise();
        window.scrollToElement('body');
    },[]);


    return (<Row gutter={[16,16]} style={{minHeight: 500}} className={'aboutCss'}>

        {showRegisterModal &&
        <Modal
            visible={showRegisterModal}
            footer={null}
            bodyStyle={{padding: 0}}
            width={'80%'}
            onCancel={() => {setShowRegisterModal(false)}}
            className={'registerModalWidth'}
            destroyOnClose={true}
            style={{maxWidth: '800px'}}
        >
            <RegisterEnterpriseComponent changeMenu={(e) => changeTab(e)} getData={() => getEnterprise()} setVisible={(e) => setShowRegisterModal(e)}/>
        </Modal>
        }
        <Col xs={24} sm={7}>
            {(loading) ? <div style={{height: 400, display: "flex"}}>
                {rightLoading &&<Loading/>}
            </div>
                :
                <div style={{boxShadow: "0 2px 8px rgb(0 0 0 / 8%)", borderRadius: 5, backgroundColor: "white"}}>
                    <div onClick={() => enterprise.current&& changeTab('3')} style={{
                        alignItems: "center",
                        textAlign: "center",
                        marginBottom: 5,
                        borderRadius: 5,
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        borderBottom: "1px solid gainsboro"
                    }}>
                        <div style={{width: '100%', textAlign: 'left'}}>
                            <Icon  onClick={() => history.push('/painel')} style={{fontSize: 17, cursor: 'pointer'}} className={'craftyIcon'} type={'arrow-left'}/>
                        </div>
                        {enterprise.current? <>
                        <img src={enterprise.current?.company_logo? enterprise.current?.company_logo : Images.user_place_holder}
                             style={{height: 80, cursor: 'pointer', width: 80, borderRadius: 40}}/>
                        <span style={{color: "#212020", fontSize: 20}}> {enterprise.current?.business_name} </span>
                        <span style={{color: "#9e9e9e"}}> {maskCnpj(enterprise.current?.cnpj)}</span>
                        </>
                        :
                        <>
                            <img src={Images.logo_icon}
                                 style={{height: 80, width: 80, borderRadius: 40, marginBottom: 10}}/>
                            <span style={{color: "#212020", fontSize: 20}}> Gerenciar currículos e vagas </span>
                            <span style={{color: "#9e9e9e"}}> Os currículos selecionados manualmente, estão no menu "Currículos selecionados"</span>
                        </>
                        }
                    </div>

                    <div>
                        <Menu
                            onClick={(e) => changeTab(e.key)}
                            selectedKeys={[menuSelected]}
                            openKeys={subExpanded}
                            mode="inline"
                        >
                            <Menu.ItemGroup key="g1" title="Minha empresa">
                                {enterprise.current && <SubMenu
                                    className={'vacancyMenuItem'}
                                    key="sub"
                                    onTitleClick={() => changeTab('sub')}
                                    title={
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                      <FaClipboardList style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/>
                                      <span>Vagas publicadas</span>
                                    </div>
                                    }
                                >
                                    {vacancyList.current.map((item, index) =>
                                        <SubMenu
                                            key={"sub"+item.id}
                                            onTitleClick={() => changeTab("sub"+item.id)}
                                            title={
                                                <span>
                                                    <Icon type="caret-right"/>
                                                  <span>{item.occupation_title? item.occupation_title : item.occupation_name}</span>
                                                </span>
                                            }
                                        >
                                            <Menu.Item key={"subdetails"+item.id}><Icon type="edit"/>Detalhes</Menu.Item>
                                            <Menu.Item key={"subcandidates"+item.id}><Icon type="team"/>Candidatos</Menu.Item>
                                        </SubMenu>
                                    )}
                                </SubMenu>}

                                {enterprise.current && <Menu.Item className={'vacancyMenuItem'} key="5" style={{display: 'flex', alignItems: 'center'}}> <FaClipboardCheck style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/>Vagas encerradas</Menu.Item>}
                                <Menu.Item key="2" className={'vacancyMenuItem'} style={{display: 'flex', alignItems: 'center'}}> <FaPlusSquare style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/>Publicar vaga</Menu.Item>
                                {enterprise.current && <Menu.Item className={'vacancyMenuItem'} key="3" style={{display: 'flex', alignItems: 'center'}}> <FaStoreAlt style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/> Empresa</Menu.Item>}
                                {/*<Menu.Item key="6" className={'vacancyMenuItem'} style={{display: 'flex', alignItems: 'center'}}> <FaPlusSquare style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/>Acessos de usuários</Menu.Item>*/}

                            </Menu.ItemGroup>
                            <Menu.ItemGroup key="g2" title={<span> Currículos <Popover content={'Currículos que você selecionou que não foram vinculados a vagas'}> <Icon  style={{color: "#9e9e9e"}}  type='question-circle'/> </Popover> </span>}>
                                <Menu.Item className={'vacancyMenuItem'} key="4" style={{display: 'flex', alignItems: 'center'}}>
                                    <FaUsers style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/> Currículos selecionados
                                </Menu.Item>
                            </Menu.ItemGroup>
                        </Menu>
                    </div>

                </div>
            }
        </Col>
        <Col xs={24} sm={17}>
            {(enterprise.current !== null && !enterprise.current.company_logo) &&
            <div onClick={() => enterprise.current&& changeTab('3')} style={{padding: 20, backgroundColor: '#fff4f4', marginBottom: 20, borderRadius: 5, display: 'flex', border: '1px solid #ec1414'}}>
                <img src={Images.noPhotoRed} style={{height: 50, marginRight: 20, width: 50}} />
                <div style={{flex: 1}}>
                    <h3 style={{color: '#ec1414'}}>Você ainda não inseriu uma Imagem para sua Empresa </h3>
                    <span>Coloque uma imagem para sua empresa, a vaga gera mais credibilidade e mais engajamento por parte dos candidatos!</span> <br/>
                    <span style={{color: 'gray', fontWeight: 'bold', cursor: 'pointer'}}>Clique aqui para adicionar.</span>
                </div>
            </div>
            }
            {(rightLoading || loading) ? <div style={{height: 400, display: "flex"}}>
                    <Loading/>
                </div>
                :
                <>
                    {menuSelected === "sub" && <VacancyListComponent loading={loadingVacancy} nextPage={nextPage.current} loadMore={() => getVacancies('open', true)} handleVacancySelect={(e) => {
                        (subExpanded.findIndex((item) => item === "sub") === -1 && handleSubMenus("sub"))
                        handleSubMenus("sub"+e.id);
                        getVacancy("subdetails" + e.id);
                    }} changeMenu={(e) => changeTab(e)} vacancies={vacancyList.current} enterprise={enterprise.current}/>}
                    {menuSelected === "2" && <VacancyFormPage changeMenu={(e) => {
                        changeTab(e);
                        getVacancies('open');
                    }} enterprise={enterprise.current}/>}
                    {menuSelected === "3" &&
                    <div className={'mainComponentPanel'} style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 5, borderRadius:5, backgroundColor: "white"}}>
                        <RegisterEnterpriseComponent getData={() => getEnterprise()} enterprise={enterprise.current} edit={true} setVisible={(e) => setShowRegisterModal(e)}/>
                    </div>}
                    {(menuSelected.includes("subdetails"))  && <VacancyDetailsComponent changeMenu={(e) => {changeTab(e); getVacancies('open');}} vacancy={vacancy.current} user={user} enterprise={enterprise.current}/>}
                    {(menuSelected.includes("subcandidates"))  && <CandidatesListComponent vacancy={vacancy.current.id} user={user} enterprise={enterprise.current}/>}
                    {menuSelected === "4" && <MyRecruitmentsPage/>}
                    {menuSelected === "5" && <VacancyListComponent loading={loadingVacancy} loadMore={() => getVacancies('closed', true)} handleVacancySelect={(e) => {
                        getVacancy("subdetails" + e.id);
                    }} changeMenu={(e) => changeTab(e)} vacancies={endedVacancies.current} ended={true} enterprise={enterprise.current}/>}
                    {menuSelected === "6" && <UserAccessPage/>}

                </>
            }
        </Col>

    </Row>)
};
export default VacancyManagePage;

