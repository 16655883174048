import React, {useEffect, useState} from 'react';
import useLocalStorage from "../Hooks/Storage";
import {useSelector} from "react-redux";
import Loading from "../Components/Generic/Loading";
import {useHistory} from "react-router-dom";
import {StorageVariables} from "../Helpers/StoragesVariables";


const PanelPage = (props) => {

    const preferences = useSelector(state => state).preferencesReducer;
    const panelStorage = useLocalStorage(StorageVariables.lastPanel);
    const history = useHistory();

    useEffect(() => {
        {/*if(preferences?.panel_profile === 'client') {
            history.push('/painel/cliente')
        } else {
            history.push('/painel/prestador')
        }*/}
        history.push('/painel/cliente')
    },[]);

    return (<> <Loading/> </>)
};
export default PanelPage;
