import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Icon, Input, message, Modal, Popover, Row, Select} from "antd";
import {Fade, Slide} from "react-reveal";
import NumberFormat from "react-number-format";
import useApi from "../Hooks/Api";
import useAuth from "../Hooks/Auth";
import useLocalStorage from "../Hooks/Storage";
import {Texts} from "../Helpers/Texts";
import {useHistory} from "react-router-dom";

import {Env} from "../Helpers/Env";
import RegisterFinalForm from "../Components/PageComponents/Public/RegisterPage/RegisterFinalForm";
import {Images} from "../Helpers/Images";
import {StorageVariables} from "../Helpers/StoragesVariables";
import GenericCropImage from "../Components/Generic/GenericCropImage";
import GenericInput from "../Components/Generic/GenericInput";
import {useValidationShorts} from "../Hooks/Helpers";
import AddressCardForm from "../Components/PageComponents/Restrict/ClientPanelPage/AddressesPage/AddressCardForm";
import CepInput from "../Components/Generic/CepInput";
import {useSelector} from "react-redux";
import vacancy_img from "../Img/vacancy_img.png";


const RegisterEnterpriseComponent = (props) => {
    const history = useHistory();
    const [imageVisible, setImageVisible] = useState(undefined)
    const [step, setStep] = useState('registerForm'); // login, regNumber, regNumberAgain, regCode, regFinal
    const api = useApi();
    const imageApi = useApi();
    const [loading, setLoading] = useState(false);
    const lastProfileAux = useRef(undefined);
    const {getFieldError, getFieldsValue, setFieldsValue, getFieldDecorator, validateFields} = props.form;
    const {numMinWithMask, isEmail, required, passwordStrength, isValidDate} = useValidationShorts();
    const disableDistrict = useRef(true);
    const disableStreet = useRef(true);
    const [addressVisible, setAddressVisible] = useState(false);
    const user = useSelector(state => state).authReducer;
    const [alertLogo, setAlertLogo] = useState(false);

    const doRegister = async (e) => {
        if(e) e.preventDefault();
        window.scrollToElement('body');
        setAlertLogo(false);
        validateFields(async (error, values) => {
            if(error) return;
            setLoading(true);
            let objToSend = values;
            objToSend.address.postal_code = objToSend.address.postal_code.replace(/\D/ig, '');

            if(objToSend.cnpj) {
                objToSend.cnpj = objToSend.cnpj.replace(/\D/ig, '');
            }

            objToSend.employer_type = "legal_entity";
            if(imageVisible) {
                if(props.edit && imageVisible !== props.enterprise.company_logo) {
                    objToSend.company_logo = {
                        base64_picture: imageVisible.slice(23),
                        filename: "logo.png"
                    }
                } else if(!props.edit) {
                    objToSend.company_logo = {
                        base64_picture: imageVisible.slice(23),
                        filename: "logo.png"
                    }
                }
            }
            objToSend.application = Env.application_alias;

            if(props.edit) {
                message.loading("Atualizando Empresa...");
                api.put(`sine/user/${user.user_id}/company/${props.enterprise.id}`, objToSend, (res) => {
                    message.success("Dados Atualizados com sucesso");
                    props.getData();
                    setLoading(false);
                }, (e) => {
                    message.error(e.message || 'Não foi possível criar a empresa, tente novamente');
                    setLoading(false);
                });

            } else {
                message.loading("Cadastrando...");
                api.post(`sine/user/${user.user_id}/company`, objToSend, (res) => {
                    setStep('regEnd');
                    props.getData();
                    setLoading(false);
                },(e) => {
                    message.error(e.message || 'Não foi possível criar a empresa, tente novamente');
                    setLoading(false);
                });

            }
        })
    }

    const checkCepInfoAvailability = (res) => {
        disableDistrict.current = true;
        disableStreet.current = true;
        const auxObj = {};
        if(res.district) {
            if(res.district === res.street_name) {
                disableDistrict.current = false;
            }
            auxObj.district = res.district;
        } else {
            auxObj.district = "";
            disableDistrict.current = false;
        }
        if(res.street_name) {
            if(res.district === res.street_name) {
                disableStreet.current = false;
            }
            auxObj.street_name = res.street_name;
        } else {
            auxObj.street_name = "";
            disableStreet.current = false;
        }
        setFieldsValue({address: {
                district: auxObj.district,
                street: auxObj.street_name,
                city: res.city,
                state_abbrev: res.state,
                country: res.country
            }
        });
    };

    useEffect(() => {
        if(props.edit) {
            setAddressVisible(true);
            setFieldsValue(props.enterprise);
            setImageVisible(props.enterprise.company_logo);
        }
    },[]);

    return (<div>
        <Modal
            visible={alertLogo}
            footer={null}
            bodyStyle={{padding: 0}}
            width={'80%'}
            onCancel={() => {setAlertLogo(false)}}
            className={'registerModalWidth'}
            destroyOnClose={true}
            style={{maxWidth: '800px'}}
        >
            <div style={{padding: 30}}>
                <div>
                    <div style={{textAlign: 'center', fontSize: 20}}>
                        <span style={{color: imageVisible ? '#68bb23' :'#424242', fontSize: 20, fontWeight: 'bold'}}> {imageVisible ? 'Logo Adicionada!' : 'Vimos que você não inseriu uma Logo para sua Empresa'} </span>
                        <br/>
                        <span style={{color: '#424242', fontSize: 16}}> Ter uma logo é muito importante, pois aumenta o destaque de suas vagas publicadas no aplicativo além de dar maior credibilidade.  </span>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                        {imageApi.loading ?
                            <Icon type={'loading'} style={{fontSize: 100}}/>
                            :
                            <GenericCropImage googleFaceImage={imageVisible} image={imageVisible}
                                              setCropedImg={(item) => setImageVisible(item)} height={360} width={360}
                                              circularCrop={true}/>
                        }
                    </div>
                    <div style={{padding: 20, paddingBottom: 0, display: "flex", justifyContent: 'center', flexWrap: 'wrap'}}>
                        <Button loading={loading} onClick={() => doRegister()} className={'ant-btn-secondary'}>{!imageVisible? 'Continuar Cadastro mesmo assim' : 'Continuar Cadastro'} </Button>
                    </div>
                </div>
            </div>
        </Modal>
            <Row style={{display: 'flex', alignItems: 'stretch', flexWrap: 'wrap'}}>
                {!props.edit &&<Col xs={24} className={'sideLogin'} style={{backgroundColor: "#315dc3"}} >
                    <div style={{padding: 20}}>
                        <Fade left spy={step}>
                            {step === "registerForm" ?
                                <div className={'logTitle'} style={{fontSize: 18}}> {Texts.auth_page.register_enterprise_title}
                                    <div className={'line'} style={{backgroundColor: "#173277", marginBottom: 10, marginTop: 10}}/>
                                    <span style={{fontSize: 16}}> Caso deseje publicar vagas é necessário realizar o cadastro de sua empresa</span>
                                </div>
                                :
                                <div className={'logTitle'}> {Texts.auth_page.register_enterprise_end}
                                    <div className={'line'} style={{backgroundColor: "#173277"}}/>
                                </div>
                            }
                        </Fade>
                    </div>
                </Col>}
                <Col xs={24} style={{padding: 30, paddingBottom: 0, transition: '400ms'}}>
                    {step === 'registerForm'&&
                    <div>
                        <Row gutter={16} style={{ borderBottom: "1px solid gainsboro", paddingBottom: 20, marginBottom: 20, display: 'flex', alignItems: 'stretch', flexWrap: 'wrap'}}>
                            <Col xs={24} lg={9} style={{textAlign: 'center', display: "flex", flexFlow: "column", justifyContent: "center"}}>
                                <span style={{fontSize: 16, fontWeight: "bold"}}> Logo da empresa  </span>
                                <span style={{fontSize: 12, lineHeight: '12px', marginBottom: 10}}> O logo da empresa aparecerá tanto na listagem de vagas como na página da vaga </span>
                                {imageApi.loading ?
                                    <Icon type={'loading'} style={{fontSize: 100}}/>
                                    :
                                    <GenericCropImage googleFaceImage={imageVisible} image={imageVisible}
                                                      setCropedImg={(item) => setImageVisible(item)} height={360} width={360}
                                                      circularCrop={true}/>
                                }
                            </Col>
                            <Col span={15} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                                <GenericInput suffix={<Icon type="shop" className={'primary-sub-color'} style={{fontSize: '17px'}}/>} required={true} form={props.form} error={getFieldError('business_name')} name='business_name' label='Nome da empresa' placeholder='Nome fantasia' valueForMemo={getFieldsValue().business_name} />
                                <Col xs={24} style={{padding: 0, marginTop: 10}} className={'genericInput'}>
                                    <Form.Item required={true} className='hideDots' validateStatus={getFieldError('cnpj')&& 'error'} help={getFieldError('cnpj')} label={<span style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}>CNPJ</span>}>
                                        {getFieldDecorator(`cnpj`,
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: 'Campo Obrigatório',}],
                                                initialValue: ''
                                            }
                                        )(
                                            <NumberFormat
                                                placeholder={'Informe o CNPJ da empresa'}
                                                className='ant-input ant-input-lg'
                                                format={'##.###.###/####-##'} mask="_"/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Col>
                        </Row>
                        <Row  gutter={[16,16]}>
                            <Col span={24}>
                                <GenericInput suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: '17px'}}/>} required={true} form={props.form} error={getFieldError('employer_name')} name='employer_name' label='Nome do empregador / Razão Social' placeholder='Nome do empregador' valueForMemo={getFieldsValue().employer_name} />
                            </Col>

                            <Col xs={24}>
                                <GenericInput rules={[isEmail(), required()]} suffix={<Icon type="mail" className={'primary-sub-color'} style={{fontSize: '17px'}}/>} required={true} form={props.form} error={getFieldError('emails[0]')} name='emails[0]' label='Email empresarial' placeholder='Digite o Email empresarial' valueForMemo={getFieldsValue().emails}/>
                            </Col>

                            <Col xs={24}  className={'genericInput'}>
                                <Form.Item required={true} className='hideDots' validateStatus={getFieldError('phone_numbers[0]')&& 'error'} help={getFieldError('phone_numbers[0]')} label={<span style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}>Telefone empresarial</span>}>
                                    {getFieldDecorator(`phone_numbers[0]`,
                                        {
                                            rules: [{
                                                required: true,
                                                message: 'Campo Obrigatório'}],
                                            initialValue: ''
                                        }
                                    )(
                                        <NumberFormat
                                            type={"tel"}
                                            placeholder={'Informe o telefone empresarial'}
                                            className='ant-input ant-input-lg'
                                            format={'(##) ####-#####'} mask="_"/>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col xs={24} style={{display: "flex", alignItems: "center", marginBottom: 15, marginTop: 20}}>
                                <span style={{fontSize: 20, color: "#315dc3"}}> Endereço da empresa </span>
                                <div style={{height:1, flex: 1, backgroundColor: "gainsboro", marginLeft: 10}}/>
                            </Col>
                            <Col xs={24}>

                                <Row>
                                    <CepInput ignoreRestriction={true} checkCepInfoAvailability={(item) => checkCepInfoAvailability(item)}  setAddressVisible={(e) => setAddressVisible(true)} required={true} form={props.form} name='address.postal_code' label='CEP'/>
                                    <Col span={24} style={{marginTop: '-20px', textAlign: 'right', width: '100%'}}>
                                        <a href={'http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm'} target={'_blank'}> Não sei meu CEP </a>
                                    </Col>
                                </Row>

                                <Row gutter={[16,16]} style={{display: addressVisible? "block" : "none"}}>
                                    <Col xs={24} sm={12} md={24}>
                                        <GenericInput  disabled={disableStreet.current} form={props.form} error={getFieldError('address.street')} name='address.street' label='Rua'  placeholder='Digite a Rua' valueForMemo={getFieldsValue().address?.street}/>
                                    </Col>
                                    <Col xs={24} sm={12} md={16}>
                                        <GenericInput  disabled={disableDistrict.current} form={props.form} error={getFieldError('address.district')} name='address.district' label='Bairro'  placeholder='Digite o Bairro' valueForMemo={getFieldsValue().address?.district}/>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                        <GenericInput  required={true} form={props.form} error={getFieldError('address.street_number')} name='address.street_number' type='number' label='Nº' placeholder='Nº' valueForMemo={getFieldsValue().address?.street_number}/>
                                    </Col>
                                </Row>

                                <Row gutter={[16,16]} style={{display: addressVisible? "block" : "none"}}>
                                    <Col xs={24} sm={14} md={12}>
                                        <GenericInput  disabled={true} form={props.form} error={getFieldError('address.city')} name='address.city' label='Cidade'  placeholder='Cidade' valueForMemo={getFieldsValue().address?.city}/>
                                    </Col>
                                    <Col xs={24} sm={16} md={12}>
                                        <GenericInput  disabled={true} form={props.form} error={getFieldError('address.state_abbrev')} name='address.state_abbrev' label='Estado'  placeholder='Estado' valueForMemo={getFieldsValue().address?.state_abbrev}/>
                                    </Col>
                                    <Col span={24}>
                                        <GenericInput  form={props.form} error={getFieldError('address.complement')} name='address.complement' label='Complemento'  placeholder='Complemento adicional do endereço' valueForMemo={getFieldsValue().address?.complement}/>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={24} style={{padding: 15, marginTop: 10}}>
                                <Button loading={loading} style={{width: "100%"}} className={"ant-btn-primary"} onClick={() => imageVisible? doRegister() : setAlertLogo(true)}> {props.edit? "Atualizar Dados" : "Cadastrar"} </Button>
                            </Col>

                        </Row>
                    </div>}

                    {step === 'regEnd'&&<div style={{minHeight: 350}}>
                        <div>
                            <div style={{textAlign: 'center', fontSize: 20}}>
                                <span style={{color: '#424242', fontSize: 22}}> Gostaria de divulgar agora uma vaga? </span>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <img style={{height: '100%', maxHeight: 320}} src={Images.vacancy_img}/>
                            </div>
                            <div style={{padding: 20, display: "flex", justifyContent: 'center', flexWrap: 'wrap'}}>
                                <Button style={{marginRight: 20, borderColor: 'lightgray'}} onClick={() =>{ props.setVisible(false);}}> Depois </Button>
                                <Button onClick={() => {props.changeMenu("2"); props.setVisible(false);}} className={'ant-btn-secondary'}> Divulgar vaga </Button>
                            </div>
                        </div>
                    </div>}
                </Col>
            </Row>
    </div>)
};

export default Form.create({name: 'regEnterpriseForm'})(RegisterEnterpriseComponent);


