import React, {useEffect, useRef, useState} from 'react';
import {AutoComplete, Button, Col, Form, Icon, Input, message, Row, Select, Spin} from "antd";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";
import moment from "moment";
import Modal from "antd/lib/modal";
import TextArea from "antd/lib/input/TextArea";
import useApi from "../../../../../Hooks/Api";
import {FaBackward, FaEdit, FaForward, FaPlus, FaTrashAlt, FaUniversity} from "react-icons/fa";
import {MdWork} from "react-icons/md";
import {BsBookmarkFill} from "react-icons/bs";
import {FaGraduationCap} from "react-icons/all";

const workTypeList = ['Tempo integral', 'Meio período',
    'Autônomo', 'Freelance', 'Temporário', 'Estágio', 'Aprendiz'];


const ExperienceComponent = (props) => {

    const experiencesList = useRef([]);
    const [experienceSelected, setExperienceSelected] = useState(null);
    const {getFieldDecorator, getFieldsValue, setFieldsValue, validateFields, getFieldsError} = props.form;
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const autocompleteApi = useApi();
    const [occupationsList, setOccupationsList] = useState();
    const [showModal, setShowModal] = useState(false);
    const [pretenseJob, setPretenseJob] = useState('');
    const [pretenseJobId, setPretenseJobId] = useState('');
    const [autocompleteSelected, setAutoCompleteSelected] = useState(false);
    const [autocompletePretensionSelected, setAutoCompletePretensionSelected] = useState(false);

    const getExperiences = async () => {
        setInitialLoading(true);
        api.get(`sine/user/${user.user_id}/work-experiences`, (res) => {
            if(res.user_id !== null) {
                props.setHasProfile(true);
                if (res.work_experience?.length > 0) {
                    experiencesList.current = res.work_experience;
                } else {
                    experiencesList.current = [];
                }
                setInitialLoading(false);
            }
        }, (e) => {
            console.log(e);
            setInitialLoading(false);
        });
        window.scrollToElement('body')
    }

    const validAndFormatDate = (date) => {
        if(date) {
            if(moment(date, 'MM/YYYY').isValid()) {
                return moment(date, 'MM/YYYY').format('YYYY-MM');
            } else {
                console.log('nao eh valido');
                return false;
            }
        }
    }

    const handleAddExperience = () => {
        setAutoCompleteSelected(false);
        setExperienceSelected({});
    };

    const handleSavePretense = async () => {
        if(pretenseJobId && pretenseJob) {
            setLoading(true);
            let obj = {
                occupation_id: pretenseJobId,
                occupation: pretenseJob,
                company: 'n/a',
                location: 'n/a',
                start_date: '2000-01',
                end_date: '2000-01',
                work_type: getFieldsValue().work_type
            };
            message.loading('Adicionando...', 0);

            api.post(`sine/user/${user.user_id}/work-experience`, obj, (res) => {
                message.destroy();
                message.success('Pretensão adicionada!');
                setExperienceSelected(undefined);
                setShowModal(false);
                getExperiences();
                setLoading(false);
            }, (e) => {
                message.destroy();
                message.error(e.message || 'Não foi possível adicionar sua pretensão');
                setLoading(false);
            });
        }
    }

    const handleSaveNewExperience = async () => {
        validateFields(async (error, values) => {
            if(error) return 0;
            setLoading(true);

            let aux = validAndFormatDate(values.end_date);
            if(aux !== false) values.end_date = aux;
            else {
                message.warning('Informe a data final corretamente (Dia/Mês)');
                setLoading(false);
                return 0;
            }

            aux = validAndFormatDate(values.start_date);
            if(aux !== false) values.start_date = aux;
            else {
                message.warning('Informe a data inicial corretamente (Dia/Mês)');
                setLoading(false);
                return 0;
            }

            if(values.last_wage === "") {
                delete values.wage_claim;
            } else if(values.last_wage !== undefined) values.last_wage = values.last_wage.replace(/\./g, "").replace(",", ".").replace('R$',"");

            message.loading('Adicionando...');
            api.post(`sine/user/${user.user_id}/work-experience`, values, (res) => {
                setExperienceSelected(null);
                message.destroy();
                message.success('Sucesso!');
                setLoading(false);
                getExperiences();
            }, (e) => {
                message.warning(e.message || 'Não foi possível salvar, tenha certeza que inseriu um cargo válido!');
                console.log(e);
                setLoading(false);
            });

        });
    };

    const handleEditExperience = async (id) => {
        validateFields(async (error, values) => {
            if(error) return 0;
            setLoading(true);

            let aux = validAndFormatDate(values.end_date);
            if(aux !== false) values.end_date = aux;
            else {
                message.warning('Informe a data final corretamente (Dia/Mês)');
                setLoading(false);
                return 0;
            }

            aux = validAndFormatDate(values.start_date);
            if(aux !== false) values.start_date = aux;
            else {
                message.warning('Informe a data inicial corretamente (Dia/Mês)');
                setLoading(false);
                return 0
            }

            if(values.last_wage) values.last_wage = Number(values.last_wage);


            message.loading('Editando...');
            api.put(`sine/user/${user.user_id}/work-experience/${id}`, values, (res) => {
                setExperienceSelected(null);
                message.destroy();
                message.success('Sucesso!');
                getExperiences();
                setLoading(false);
            }, (e) => {
                message.warning(e.message || 'Não foi possível salvar, tenha certeza que inseriu um cargo válido!');
                setLoading(false);
                console.log(e);
            });
        });
    };

    const handleDeleteExperience = async (id) => {
        if(!loading) {
            setLoading(true);

            message.loading('Removendo...');
            api.del(`sine/user/${user.user_id}/work-experience/${id}`, null,(res) => {
                setExperienceSelected(null);
                message.destroy();
                message.success('Sucesso!');
                getExperiences();
                setLoading(false)
            }, (e) => {
                message.warning(e.message || 'Não foi possível deletar, tente novamente!');
                console.log(e);
                setLoading(false)
            });
        }
    };

    const autoCompleteSearch = async (query, isExp) => {
        if(isExp !== false) {
            if(getFieldsValue().occupation_id) {
                setFieldsValue({occupation_id: undefined});
            }
        } else {
            setPretenseJobId(undefined)
        }

        if(query?.length > 3) {
            autocompleteApi.setLoading(true);
            let searchQuery = `?query=${query}&limit=20`;

           // autocompleteApi.setDebounce(300);
            autocompleteApi.get(`sine/cbo-occupations/autocomplete${searchQuery}`, (res) => {
                setOccupationsList(res);
            }, (e) => {});
        }
    }


    const handleSelect = (value, opt) => {
        setFieldsValue({occupation_id : opt.props.label});
        setAutoCompleteSelected(true);
    };

    const clearAutocomplete = () => {
        setFieldsValue({occupation_id: undefined});
        setFieldsValue({occupation: undefined});
        setAutoCompleteSelected(false);
    };

    useEffect(() => {
        getExperiences();
        window.scrollToElement('body')
    },[]);

    return (<div style={{marginBottom: 200}} className={'experienceForm aboutCss'}>

        <div style={{ paddingBottom: 35, borderBottom: '1px solid gainsboro',textAlign: 'center', marginTop: 50 }}>
            <h1>
                <strong style={{color: '#242424', fontSize: 25}}> Suas experiências e pretensões profissionais </strong>
            </h1>
            <span  style={{ fontSize: 20,color: '#9E9E9E'}}>   Adicione aqui suas experiências profissionais, caso não possua experiência ou busca ser empregado em outro cargo ou estágio, adicione uma pretensão </span> <br/>
            <span  style={{ fontSize: 16,color: '#1890ff9e'}}>   Os cargos escolhidos em suas experiências já são adicionados como sendo de sua pretensão! </span> <br/>
        </div>

        <Row className={'formTitle'} style={{display: 'flex', marginBottom: 35, justifyContent: 'flex-end'}}>
            <Button  className={'ant-btn-green'}  style={{visibility: experienceSelected !== null && 'hidden', display: 'flex', fontSize: 16, alignItems: 'center'}} onClick={() => handleAddExperience()}> <span className={'hideMobile500'}> Adicionar  </span>  <span>&nbsp;</span> Experiência <FaPlus style={{color: 'white', fontSize: 17, marginLeft: 5}}/> </Button>
            <Button  className={'ant-btn-sub-primary'} style={{marginLeft: 10}} onClick={() => setShowModal(true)}> Adicionar Pretensão </Button>
        </Row>

        {experienceSelected && <Row gutter={[22,22]} style={{marginBottom: 30, backgroundColor: 'white'}} className={'formRow'}>
            <Col xs={24}  className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span>  Nome da empresa </label> </div>
                {getFieldDecorator(`company`,
                    {
                        rules: [{ required: true, message: 'Nome da empresa é obrigatório'}],
                        initialValue: experienceSelected? (experienceSelected.company || '') : ''
                    }
                )(
                    <Input placeholder={'Nome da empresa'} size={'large'}/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().company} </span> </div>

            </Col>

            <Col xs={24} className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span>  Endereço  </label> </div>
                {getFieldDecorator(`location`,
                    {
                        rules: [{ required: true, message: 'Endereço é obrigatório'}],
                        initialValue: experienceSelected? (experienceSelected.location || '') : ''
                    }
                )(
                    <Input placeholder={'Endereço completo da empresa'} size={'large'}/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().location} </span> </div>

            </Col>

            <Col xs={24} md={10} className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span> Busque pelo seu Cargo/Ocupação </label> </div>
                {getFieldDecorator(`occupation`,
                    {
                        rules: [{ required: true, message: 'Ocupação é obrigatório'}],
                        initialValue: experienceSelected?.occupation&&  experienceSelected?.occupation,
                    }
                )(
                    <AutoComplete
                        size="large"
                        onSelect={(value, option) => handleSelect(value, option)}
                        style={{ width: '100%', display: autocompleteSelected&& 'none'}}
                        dataSource={occupationsList?.map(item => {
                            return (<AutoComplete.Option key={item.name} label={item.occupation_id} value={item.name}>
                                    {item.name}
                                </AutoComplete.Option>
                            )
                        })}
                        onSearch={(query) => autoCompleteSearch(query, true)}
                        optionLabelProp="text"
                    >
                        <Input suffix={autocompleteApi.loading ? <Icon type="loading"/> : <MdWork/> } size={'large'} placeholder={'Selecione sua ocupação/cargo'} />
                    </AutoComplete>)}

                <div className={'ant-input ant-input-lg'} style={{ width: '100%', display: !autocompleteSelected? 'none' : 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {getFieldsValue().occupation}
                    <Icon className={'hoverIcon'} onClick={() => clearAutocomplete()} type={'close'}/>
                </div>
                <div style={{minHeight: 15, marginTop: 5, position: 'absolute'}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().occupation_id} </span> </div>

                {getFieldDecorator(`occupation_id`,
                    {
                        initialValue: experienceSelected? (experienceSelected.occupation_id || '') : '',
                        rules: [{ required: true, message: 'Escolha um cargo na busca'}],
                    }
                )(
                    <Input type={'hidden'}/>
                )}
            </Col>
            <Col xs={24} md={7}  className={'formItem'}>
                <div className='resumeLabel'><label> <span>*</span> Tipo/Período de trabalho </label> </div>
                {getFieldDecorator(`work_type`,
                    {
                        rules: [{required: true, message: 'Período de trabalho é obrigatório'}],
                        initialValue: experienceSelected? (experienceSelected.work_type || undefined) : undefined
                    }
                )(
                    <Select
                        placeholder="Selecione o período de trabalho"
                        size={'large'}
                    >
                        {workTypeList.map((item, index) =>
                            <Select.Option key={index} value={item}>{item}</Select.Option>
                        )}
                    </Select>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_type} </span> </div>
            </Col>

            <Col xs={24} md={7}  className={'formItem'}>
                <div className='resumeLabel'><label>  Último salário  </label> </div>
                {getFieldDecorator(`last_wage`,
                    {
                        rules: [{required: false, message: 'Último salario é obrigatório'}],
                        initialValue: experienceSelected.last_wage&& experienceSelected.last_wage.replace('.', ",") || undefined,
                    }
                )(
                    <NumberFormat maxLength={14} decimalScale={2} fixedDecimalScale={true} className='ant-input ant-input-lg' thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} style={{textAlign: "right"}} placeholder={'Valor do último salário recebido'} size={'large'}/>
                )}

                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().last_wage} </span> </div>

            </Col>

            <Col  xs={12} md={4}  className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span> Início </label> </div>
                {getFieldDecorator(`start_date`,
                    {
                        rules: [{required: true, message: 'Início é obrigatório'}],
                        initialValue: experienceSelected.start_date? (moment(experienceSelected.start_date, 'YYYY-MM').format('MM/YYYY') || undefined) : undefined
                    }
                )(
                    <NumberFormat type={"tel"} style={{textAlign: 'center'}} className={'ant-input ant-input-lg'} format="##/####" placeholder="Mês/Ano"/>
                )}
                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().start_date} </span> </div>

            </Col>
            <Col  xs={12} md={4}  className={'formItem'}>
                <div className='resumeLabel'><label> Fim </label> </div>
                {getFieldDecorator(`end_date`,
                    {
                        rules: [],
                        initialValue: experienceSelected.end_date? (moment(experienceSelected.end_date, 'YYYY-MM').format('MM/YYYY') || undefined) : undefined
                    }
                )(
                    <NumberFormat type={"tel"} style={{textAlign: 'center'}} className={'ant-input ant-input-lg'} format="##/####" placeholder="Mês/Ano"/>
                )}
            </Col>
            <Col xs={24} md={16} className={'formItem'}>
                <div className='resumeLabel'><label> Descrição das atribuições na empresa (Opcional)  </label> </div>
                {getFieldDecorator(`description`,
                    {
                        rules: [{required: false}],
                        initialValue: experienceSelected? (experienceSelected.description || '') : ''
                    }
                )(
                    <TextArea placeholder={'Descrição das atribuições na empresa'} size={'large'}/>
                )}
            </Col>
            <Col style={{alignItems: 'flex-end'}} className={'listButtons'} span={24}>
                {!experienceSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-secondary'} onClick={() => handleSaveNewExperience()}> Salvar </Button>}
                {experienceSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-secondary editButton'} onClick={() => handleEditExperience(experienceSelected.id)}> Salvar </Button>}
                {experienceSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-red'} onClick={() => handleDeleteExperience(experienceSelected.id)}> Remover </Button>}
            </Col>
        </Row>}

        {experiencesList.current?.map((item, index) =>
            experienceSelected?.id !== item.id&& <>
                {experiencesList.current[index].start_date === experiencesList.current[index].end_date ?
                    <Row key={index} gutter={[24,24]} style={{marginBottom: 30, display: 'flex', borderLeftColor: '#2196f3', backgroundColor: 'white'}} className={'formRow'}>
                        <Col xs={20} md={20} className={'listView'}>
                            {experiencesList.current[index].work_type === 'Estágio' ? <FaGraduationCap style={{color: '#2196f3'}}/> :  <BsBookmarkFill style={{color: '#2196f3'}}/>}
                            <span style={{color: '#2196f3'}}> {experiencesList.current[index]?.occupation?<span> {experiencesList.current[index]?.occupation }  <small style={{color: 'gray'}}> (Pretensão) </small> </span> :  <Icon type="loading"/>} </span>
                        </Col>
                        <Col xs={4} md={4} style={{justifyContent: 'flex-end', padding: 0, fontSize: 20, display: 'flex', alignItems: 'center'}}>
                            <FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}} onClick={() => handleDeleteExperience(item.id)}/>
                        </Col>
                    </Row>
                        :
                    <Row key={index} gutter={[24,24]} style={{marginBottom: 30, display: 'flex', backgroundColor: 'white'}} className={'formRow'}>
                        <Col xs={10} md={10} className={'listView'}>
                            <FaUniversity/>
                            <span> {experiencesList.current[index]?.company? experiencesList.current[index]?.company :  <Icon type="loading"/>} </span>
                        </Col>
                        <Col xs={10} md={10} className={'listView'}>
                            <MdWork/>
                            <span> {experiencesList.current[index]?.occupation? experiencesList.current[index]?.occupation: <Icon type="loading"/>} </span>
                        </Col>
                        <Col xs={4} md={4} style={{justifyContent: 'flex-end', padding: 0, fontSize: 20, display: 'flex', alignItems: 'center'}}>
                            <div><FaEdit className={'editButtonIcon'} onClick={() => {setAutoCompleteSelected(true); setExperienceSelected(item)}}/></div>
                            <div><FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}} onClick={() => handleDeleteExperience(item.id)}/></div>
                        </Col>
                    </Row>
                }
            </>
        )}

        {(initialLoading && experiencesList.current.length === 0) ? <div className={'loadingResume'}><Spin/></div> :
            <div style={{marginTop: 20}} className={'buttonRow'} justify={'center'}>
                <Button shape={'round'} onClick={() => props.handleChangeStep(0)} type={'default'}>  <FaBackward className={'exportedIconsLeft'}/> Voltar  </Button>
                <Button shape={'round'} onClick={() => props.handleChangeStep(2)} className={'ant-btn-sub-primary'}> Próximo <FaForward className={'exportedIcons'}/> </Button>
            </div>
        }

        <Modal footer={null} className={'pretenseModal'} centered={true} title={'Adicione o cargo pretendido'} visible={showModal} destroyOnClose={true} onCancel={() => {setShowModal(false); setPretenseJob(''); setPretenseJobId('')}}>
            <Row className={'formItem'}>
                <div className='resumeLabel' style={{fontSize: 16, marginBottom: 10}}><label><span>*</span> Busque por Cargo/Ocupação </label> </div>

                <AutoComplete
                    size="large"
                    className='height100Autocomplete'
                    onSelect={(value, option) => { setPretenseJob(value); setPretenseJobId(option.props.label); setAutoCompletePretensionSelected(true)}}
                    style={{ width: '100%', display: autocompletePretensionSelected&& 'none'}}
                    loading={autocompleteApi.loading}
                    dataSource={occupationsList?.map(item => {
                        return (<AutoComplete.Option key={item.name} label={item.occupation_id} value={item.name}>
                                {item.name}
                            </AutoComplete.Option>
                        )
                    })}
                    onSearch={(query) => autoCompleteSearch(query, false)}
                    optionLabelProp="text"
                >
                    <Input suffix={autocompleteApi.loading ?  <Icon type="loading"/> : <MdWork/> } size={'large'} placeholder={'Selecione sua ocupação/cargo'} />
                </AutoComplete>
                <div className={'ant-input ant-input-lg'} style={{ width: '100%', display: !autocompletePretensionSelected? 'none' : 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {pretenseJob}
                    <Icon className={'hoverIcon'} onClick={() =>{ setPretenseJob(''); setPretenseJobId(''); setAutoCompletePretensionSelected(false)}} type={'close'}/>
                </div>


                <div style={{width: '100%', marginTop: 20}}  className={'formItem'}>
                    <div className='resumeLabel'><label> <span>*</span> Tipo/Período de trabalho </label> </div>
                    {getFieldDecorator(`work_type`,
                        {
                            rules: [{required: true, message: 'Período de trabalho é obrigatório'}],
                            initialValue: experienceSelected? (experienceSelected.work_type || undefined) : undefined
                        }
                    )(
                        <Select
                            placeholder="Selecione o período de trabalho"
                            size={'large'}
                            style={{width: '100%'}}
                        >
                            {workTypeList.map((item, index) =>
                                <Select.Option key={index} value={item}>{item}</Select.Option>
                            )}
                        </Select>
                    )}
                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_type} </span> </div>
                </div>

                <Row style={{marginTop: 20, width: '100%', display: 'flex', flexFlow: 'row', justifyContent: 'flex-end'}}>
                    <Button style={{marginRight: 10}} onClick={() => {setShowModal(false); setPretenseJob(''); setPretenseJobId('')}} type={'default'}> Cancelar  </Button>
                    <Button loading={loading} disabled={loading} onClick={() => handleSavePretense()} className={'ant-btn-secondary'}> Adicionar </Button>
                </Row>
            </Row>
        </Modal>

    </div>)
};
export default Form.create({name: 'experienceForm'})(ExperienceComponent);
