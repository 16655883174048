import React from "react";
import {Fade} from "react-reveal";

const NotFound = (props) => {
    return (
        <Fade> <div className={'notFoundComponent'}>
            <div style={{marginBottom: props.mb || 0}}>
                <span style={{fontSize: 23}}> {props.title}</span>
                {props.subTitle && <> <br/>
                <span style={{fontSize: 18}}> {props.subTitle}</span> </>}
            </div>
            <div className={'imgDiv'}>
                <img src={props.img}/>
            </div>
        </div>
        </Fade>
    );
};

NotFound.propTypes = {};

export default NotFound;
