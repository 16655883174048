import React, {memo} from 'react';
import {Col, Form, Input} from "antd";
import PropTypes from "prop-types";

const GenericInput = (props) => {

    const {getFieldDecorator, getFieldError} = props.form;

    return (
        <Col span={24} style={{padding: 0}} className={'genericInput'}>

            <Form.Item
                required={typeof props.required === "boolean" ? props.required: false}
                validateStatus={props.error && 'error'}
                className={'hideDots'}
                help={props.error ? props.error : props.help}
                label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}><strong >{props.label} </strong> </span>}
            >

                {getFieldDecorator(props.name, {
                    rules: props.rules || [
                        {
                            required: props.required? props.required: false,
                            message: props.message || 'Campo Obrigatório',
                        }
                    ],

                    initialValue: props.initialValue || ''
                })(<Input style={{marginTop: 5}} disabled={props.disabled} suffix={props.suffix} type={props.type || 'text'} size={props.size || "large"} placeholder={props.placeholder || ""} />)}

            </Form.Item>

        </Col>
    )
}

GenericInput.propTypes = {
    name: PropTypes.string,
    form: PropTypes.any,
    help: PropTypes.string,
    rules: PropTypes.array,
    label: PropTypes.any,
    message: PropTypes.any,
    size: PropTypes.any,
    type: PropTypes.any,
    required: PropTypes.any,
    error: PropTypes.any,
    valueForMemo: PropTypes.any,
    initialValue: PropTypes.any,
    suffix: PropTypes.any,
    disabled: PropTypes.any
};

export default memo(GenericInput, (prevProps, nextProps) => {
    if(prevProps.valueForMemo !== nextProps.valueForMemo) return false;
    if(prevProps.error !== nextProps.error) return false;
    if(prevProps.initialValue !== nextProps.initialValue) return false;
    if(prevProps.disabled !== nextProps.disabled) return false;
    return true
});
