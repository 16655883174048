import React from 'react';
import {Env} from "../Helpers/Env";
import useLocalStorage from "./Storage";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {StorageVariables} from "../Helpers/StoragesVariables";


const useAuth = () => {
    const history = useHistory();
    const userStorage = useLocalStorage(StorageVariables.userProfile);
    const panelStorage = useLocalStorage(StorageVariables.lastPanel);
    const fcmStorage = useLocalStorage(StorageVariables.fcm);
    const geoLocation = useLocalStorage(StorageVariables.geolocation);
    const hasProfile = useLocalStorage(StorageVariables.hasSineProfile);
    const enterpriseStorage = useLocalStorage(StorageVariables.enterprise);
    const enterpriseDataStorage = useLocalStorage(StorageVariables.enterpriseData);
    const dispatch = useDispatch();
    const step = useLocalStorage(StorageVariables.curriculumStep);

    // const api = useApi({loadingControl: false});

    const login = (userObj:  {token:  string, name: string, email: string, nickname: string, avatar: string, addresses: any, user_id: any, gender: string, cpf: string, phone: string, birthday: string, last_profile: string}) => {
        Env.header[`X-Api-Key`] = userObj.token;
        dispatch({type: 'init_login', data: userObj});
        dispatch({type: 'panel_profile', data: userObj.last_profile});
        panelStorage.setPrimitive(userObj.last_profile);
        userStorage.setObject(userObj);

        let userGeolocation = [];
        if (userObj.addresses) {
            userObj.addresses.forEach((item) => {
                userGeolocation.push({lat: item.latitude, long: item.longitude, name: (item.street_name + ', ' + item.street_number + ' - ' + item.district + ', ' + item.city + ' - ' + item.state) })
            });
        }
        geoLocation.setObject({...geoLocation.getObject(), ...{userLat: userGeolocation}})
    };

    const store = (userObj:  {token:  string, name: string, email: string, nickname: string, avatar: string, addresses: any, user_id: any, gender: string, phone: string, birthday: string, last_profile: string}) => {
        Env.header[`X-Api-Key`] = userObj.token;
        dispatch({type: 'init_login', data: userObj});
        dispatch({type: 'panel_profile', data: panelStorage.getPrimitive()});
    };

    const logoutWithoutApi = () => {
        userStorage.remove();
        fcmStorage.remove();
        panelStorage.remove();
        geoLocation.remove();
        step.remove();
        hasProfile.remove();
        enterpriseStorage.remove();
        enterpriseDataStorage.remove();
        dispatch({type: 'logout'});
        delete Env.header[`X-Api-Key`];
        history.push('/');
    };

    const getUser = () => {
        return userStorage.getObject();
    }

    const changeAvatar = (image) => {
        userStorage.setObject({...userStorage.getObject(), avatar: Env.avatarLogo+image});
    }

    const isLogged = () => {
        return !!userStorage.getObject()?.email;
    }

    const storeEnterpriseOpportunities = (item) => {
        enterpriseStorage.setObject(item)
    };

    const getEnterpriseOpportunities = () => {
        return enterpriseStorage.getObject()
    }

    const storeEnterprise = (item) => {
        enterpriseDataStorage.setObject(item)
    }

    const getEnterprise = () => {
        return enterpriseDataStorage.getObject()
    }

    return {login,logoutWithoutApi,storeEnterprise,getEnterprise, getUser, isLogged, store,  changeAvatar, storeEnterpriseOpportunities, getEnterpriseOpportunities};
};

useAuth.propTypes = {};

export default useAuth;
