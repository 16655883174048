const pushNotificationRequestReducer = (state = 0, action) => {
    switch (action.type) {
        case 'call_push_request' :
            return state + action.data;
        default :
            return state
    }
}

export default pushNotificationRequestReducer;
