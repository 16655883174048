import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Form, Icon, Input, message, Modal, Radio} from "antd";
import useApi from "../../../../Hooks/Api";
import {useValidationShorts} from "../../../../Hooks/Helpers";
import moment from "moment";
import {Images} from "../../../../Helpers/Images";

const priceType = [
    {type: 'Barato', value: 1},
    {type: 'Preço na média', value: 3},
    {type: 'Caro', value: 5},
]

const RateTransactionForm = props => {
    const {getFieldDecorator, getFieldError, getFieldsValue, setFieldsValue, validateFields} = props.form;
    const api = useApi();
    const [loading, setLoading] = useState(false);

    const sendFeedback = async () => {
        validateFields(async (err, values) => {
            if(err) return 0;
            message.loading('Enviando Avaliação');

            if (!values.review) {
                values.review = null;
            }
            try {
                setLoading(true);
                const res = await api.post(`crafty-club/transaction/${props.viewTransaction?.id}/review`, values);
                message.success('Sua Avaliação foi enviada! ');
                setLoading(false);
                props.getData();
                props.setShowRateModal(false);
            } catch (e) {
                message.error(e.message || 'Não foi possivel a avaliação, tente novamente mais tarde');
                setLoading(false);
                props.setShowRateModal(false);
            }
        })
    }

    const {required} = useValidationShorts();

    return (
        <Modal visible={props.showRateModal} title={<strong> Avaliar Estabelecimento </strong>} confirmLoading={loading} onOk={() => sendFeedback()} onCancel={() => props.setShowRateModal(false)} okText={'Enviar Avaliação'}>
            <React.Fragment>
                <div style={{display: 'flex', marginBottom: 50}}>
                    <div>
                        <Avatar style={{height: 65, width: 65, marginRight: 10}} src={props.viewTransaction?.company?.profile_picture.thumbnail || Images.user_place_holder}/>
                        <div> <Icon type={'star'} theme={"filled"} style={{color: 'gold'}}/> {props.viewTransaction?.company?.ratings_avg} ({props.viewTransaction?.company?.ratings_count}) </div>
                    </div>
                    <div>
                        <div> <span style={{fontSize: 20, fontWeight: 'bold', color: "#52c41a"}}> {props.viewTransaction?.company?.name}</span></div>
                        <span style={{fontSize: 16, color: '#9e9e9e'}}> {props.viewTransaction?.company?.group} </span> <br/>
                        <span style={{ fontSize: 16, color: '#9e9e9e'}}> Compra em - {moment.utc(props.viewTransaction.created_at).local().format('DD/MM/YYYY')}</span>
                    </div>
                </div>

                <Form>
                    <Form.Item label="O que você achou dos preços neste estabelecimento?">
                        {getFieldDecorator('price', {
                            rules: [required()],
                        })(
                            <Radio.Group>
                                {priceType.map((item,index) =>
                                    <Radio key={index} value={item.value}>{item.type}</Radio>
                                )}
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item label="Nota do atendimento">
                        {getFieldDecorator('rating', {
                            rules: [required()],
                        })(
                            <Radio.Group>
                                <Radio value={1}> Péssimo</Radio>
                                <Radio value={2}> Ruim </Radio>
                                <Radio value={3}> Neutro </Radio>
                                <Radio value={4}> Bom </Radio>
                                <Radio value={5}> Otímo </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Form.Item label="Comentário">
                        {getFieldDecorator('review')(
                            <Input.TextArea rows={3} />
                        )}
                    </Form.Item>
                </Form>

            </React.Fragment>
        </Modal>

    );
};

RateTransactionForm.propTypes = {
    viewTransaction: PropTypes.any,
    user: PropTypes.any,
    handleSave: PropTypes.any,
    addressToEdit: PropTypes.any,
    showRateModal: PropTypes.any,
    setShowRateModal: PropTypes.any
};

export default Form.create({name: 'rateTransactionForm'})(RateTransactionForm);
