export const countries = [
    {
        value: "Afeganistão",
        label: "Afeganistão",
    },
    {
        value: "África do Sul",
        label: "África do Sul",
    },
    {
        value: "Albânia",
        label: "Albânia",
    },
    {
        value: "Alemanha",
        label: "Alemanha",
    },
    {
        value: "Andorra",
        label: "Andorra",
    },
    {
        value: "Angola",
        label: "Angola",
    },
    {
        value: "Anguilla",
        label: "Anguilla",
    },
    {
        value: "Antártida",
        label: "Antártida",
    },
    {
        value: "Antígua e Barbuda",
        label: "Antígua e Barbuda",
    },
    {
        value: "Arábia Saudita",
        label: "Arábia Saudita",
    },
    {
        value: "Argélia",
        label: "Argélia",
    },
    {
        value: "Argentina",
        label: "Argentina",
    },
    {
        value: "Armênia",
        label: "Armênia",
    },
    {
        value: "Aruba",
        label: "Aruba",
    },
    {
        value: "Austrália",
        label: "Austrália",
    },
    {
        value: "Áustria",
        label: "Áustria",
    },
    {
        value: "Azerbaijão",
        label: "Azerbaijão",
    },
    {
        value: "Bahamas",
        label: "Bahamas",
    },
    {
        value: "Bahrein",
        label: "Bahrein",
    },
    {
        value: "Bangladesh",
        label: "Bangladesh",
    },
    {
        value: "Barbados",
        label: "Barbados",
    },
    {
        value: "Belarus",
        label: "Belarus",
    },
    {
        value: "Bélgica",
        label: "Bélgica",
    },
    {
        value: "Belize",
        label: "Belize",
    },
    {
        value: "Benin",
        label: "Benin",
    },
    {
        value: "Bermudas",
        label: "Bermudas",
    },
    {
        value: "Bolívia",
        label: "Bolívia",
    },
    {
        value: "Bósnia-Herzegóvina",
        label: "Bósnia-Herzegóvina",
    },
    {
        value: "Botsuana",
        label: "Botsuana",
    },
    {
        value: "Brasil",
        label: "Brasil",
    },
    {
        value: "Brunei",
        label: "Brunei",
    },
    {
        value: "Bulgária",
        label: "Bulgária",
    },
    {
        value: "Burkina Fasso",
        label: "Burkina Fasso",
    },
    {
        value: "Burundi",
        label: "Burundi",
    },
    {
        value: "Butão",
        label: "Butão",
    },
    {
        value: "Cabo Verde",
        label: "Cabo Verde",
    },
    {
        value: "Camarões",
        label: "Camarões",
    },
    {
        value: "Camboja",
        label: "Camboja",
    },
    {
        value: "Canadá",
        label: "Canadá",
    },
    {
        value: "Canárias",
        label: "Canárias",
    },
    {
        value: "Cazaquistão",
        label: "Cazaquistão",
    },
    {
        value: "Ceuta e Melilla",
        label: "Ceuta e Melilla",
    },
    {
        value: "Chade",
        label: "Chade",
    },
    {
        value: "Chile",
        label: "Chile",
    },
    {
        value: "China",
        label: "China",
    },
    {
        value: "Chipre",
        label: "Chipre",
    },
    {
        value: "Cingapura",
        label: "Cingapura",
    },
    {
        value: "Colômbia",
        label: "Colômbia",
    },
    {
        value: "Comores",
        label: "Comores",
    },
    {
        value: "Congo",
        label: "Congo",
    },
    {
        value: "Coréia do Norte",
        label: "Coréia do Norte",
    },
    {
        value: "Coréia do Sul",
        label: "Coréia do Sul",
    },
    {
        value: "Costa do Marfim",
        label: "Costa do Marfim",
    },
    {
        value: "Costa Rica",
        label: "Costa Rica",
    },
    {
        value: "Croácia",
        label: "Croácia",
    },
    {
        value: "Cuba",
        label: "Cuba",
    },
    {
        value: "Curaçao",
        label: "Curaçao",
    },
    {
        value: "Diego Garcia",
        label: "Diego Garcia",
    },
    {
        value: "Dinamarca",
        label: "Dinamarca",
    },
    {
        value: "Djibuti",
        label: "Djibuti",
    },
    {
        value: "Dominica",
        label: "Dominica",
    },
    {
        value: "Egito",
        label: "Egito",
    },
    {
        value: "El Salvador",
        label: "El Salvador",
    },
    {
        value: "Emirados Árabes Unidos",
        label: "Emirados Árabes Unidos",
    },
    {
        value: "Equador",
        label: "Equador",
    },
    {
        value: "Eritréia",
        label: "Eritréia",
    },
    {
        value: "Eslováquia",
        label: "Eslováquia",
    },
    {
        value: "Eslovênia",
        label: "Eslovênia",
    },
    {
        value: "Espanha",
        label: "Espanha",
    },
    {
        value: "Estados Unidos",
        label: "Estados Unidos",
    },
    {
        value: "Estônia",
        label: "Estônia",
    },
    {
        value: "Etiópia",
        label: "Etiópia",
    },
    {
        value: "Fiji",
        label: "Fiji",
    },
    {
        value: "Filipinas",
        label: "Filipinas",
    },
    {
        value: "Finlândia",
        label: "Finlândia",
    },
    {
        value: "França",
        label: "França",
    },
    {
        value: "Gabão",
        label: "Gabão",
    },
    {
        value: "Gâmbia",
        label: "Gâmbia",
    },
    {
        value: "Gana",
        label: "Gana",
    },
    {
        value: "Geórgia",
        label: "Geórgia",
    },
    {
        value: "Gibraltar",
        label: "Gibraltar",
    },
    {
        value: "Grã-Bretanha (Reino Unido, UK)",
        label: "Grã-Bretanha (Reino Unido, UK)",
    },
    {
        value: "Granada",
        label: "Granada",
    },
    {
        value: "Grécia",
        label: "Grécia",
    },
    {
        value: "Groelândia",
        label: "Groelândia",
    },
    {
        value: "Guadalupe",
        label: "Guadalupe",
    },
    {
        value: "Guam (Território dos Estados Unidos)",
        label: "Guam (Território dos Estados Unidos)",
    },
    {
        value: "Guatemala",
        label: "Guatemala",
    },
    {
        value: "Guernsey",
        label: "Guernsey",
    },
    {
        value: "Guiana",
        label: "Guiana",
    },
    {
        value: "Guiana Francesa",
        label: "Guiana Francesa",
    },
    {
        value: "Guiné",
        label: "Guiné",
    },
    {
        value: "Guiné Equatorial",
        label: "Guiné Equatorial",
    },
    {
        value: "Guiné-Bissau",
        label: "Guiné-Bissau",
    },
    {
        value: "Haiti",
        label: "Haiti",
    },
    {
        value: "Holanda",
        label: "Holanda",
    },
    {
        value: "Honduras",
        label: "Honduras",
    },
    {
        value: "Hong Kong",
        label: "Hong Kong",
    },
    {
        value: "Hungria",
        label: "Hungria",
    },
    {
        value: "Iêmen",
        label: "Iêmen",
    },
    {
        value: "Ilha Bouvet",
        label: "Ilha Bouvet",
    },
    {
        value: "Ilha de Ascensão",
        label: "Ilha de Ascensão",
    },
    {
        value: "Ilha de Clipperton",
        label: "Ilha de Clipperton",
    },
    {
        value: "Ilha de Man",
        label: "Ilha de Man",
    },
    {
        value: "Ilha Natal",
        label: "Ilha Natal",
    },
    {
        value: "Ilha Pitcairn",
        label: "Ilha Pitcairn",
    },
    {
        value: "Ilha Reunião",
        label: "Ilha Reunião",
    },
    {
        value: "Ilhas Aland",
        label: "Ilhas Aland",
    },
    {
        value: "Ilhas Cayman",
        label: "Ilhas Cayman",
    },
    {
        value: "Ilhas Cocos",
        label: "Ilhas Cocos",
    },
    {
        value: "Ilhas Cook",
        label: "Ilhas Cook",
    },
    {
        value: "Ilhas Faroes",
        label: "Ilhas Faroes",
    },
    {
        value: "Ilhas Geórgia do Sul e Sandwich do Sul",
        label: "Ilhas Geórgia do Sul e Sandwich do Sul",
    },
    {
        value: "Ilhas Heard e McDonald (Território da Austrália)",
        label: "Ilhas Heard e McDonald (Território da Austrália)",
    },
    {
        value: "Ilhas Malvinas",
        label: "Ilhas Malvinas",
    },
    {
        value: "Ilhas Marianas do Norte",
        label: "Ilhas Marianas do Norte",
    },
    {
        value: "Ilhas Marshall",
        label: "Ilhas Marshall",
    },
    {
        value: "Ilhas Menores dos Estados Unidos",
        label: "Ilhas Menores dos Estados Unidos",
    },
    {
        value: "Ilhas Norfolk",
        label: "Ilhas Norfolk",
    },
    {
        value: "Ilhas Salomão",
        label: "Ilhas Salomão",
    },
    {
        value: "Ilhas Seychelles",
        label: "Ilhas Seychelles",
    },
    {
        value: "Ilhas Tokelau",
        label: "Ilhas Tokelau",
    },
    {
        value: "Ilhas Turks e Caicos",
        label: "Ilhas Turks e Caicos",
    },
    {
        value: "Ilhas Virgens (Estados Unidos)",
        label: "Ilhas Virgens (Estados Unidos)",
    },
    {
        value: "Ilhas Virgens (Inglaterra)",
        label: "Ilhas Virgens (Inglaterra)",
    },
    {
        value: "Índia",
        label: "Índia",
    },
    {
        value: "Indonésia",
        label: "Indonésia",
    },
    {
        value: "Irã",
        label: "Irã",
    },
    {
        value: "Iraque",
        label: "Iraque",
    },
    {
        value: "Irlanda",
        label: "Irlanda",
    },
    {
        value: "Islândia",
        label: "Islândia",
    },
    {
        value: "Israel",
        label: "Israel",
    },
    {
        value: "Itália",
        label: "Itália",
    },
    {
        value: "Jamaica",
        label: "Jamaica",
    },
    {
        value: "Japão",
        label: "Japão",
    },
    {
        value: "Jersey",
        label: "Jersey",
    },
    {
        value: "Jordânia",
        label: "Jordânia",
    },
    {
        value: "Kiribati",
        label: "Kiribati",
    },
    {
        value: "Kosovo",
        label: "Kosovo",
    },
    {
        value: "Kuait",
        label: "Kuait",
    },
    {
        value: "Laos",
        label: "Laos",
    },
    {
        value: "Lesoto",
        label: "Lesoto",
    },
    {
        value: "Letônia",
        label: "Letônia",
    },
    {
        value: "Líbano",
        label: "Líbano",
    },
    {
        value: "Libéria",
        label: "Libéria",
    },
    {
        value: "Líbia",
        label: "Líbia",
    },
    {
        value: "Liechtenstein",
        label: "Liechtenstein",
    },
    {
        value: "Lituânia",
        label: "Lituânia",
    },
    {
        value: "Luxemburgo",
        label: "Luxemburgo",
    },
    {
        value: "Macau",
        label: "Macau",
    },
    {
        value: "Macedônia (República Yugoslava)",
        label: "Macedônia (República Yugoslava)",
    },
    {
        value: "Madagascar",
        label: "Madagascar",
    },
    {
        value: "Malásia",
        label: "Malásia",
    },
    {
        value: "Malaui",
        label: "Malaui",
    },
    {
        value: "Maldivas",
        label: "Maldivas",
    },
    {
        value: "Mali",
        label: "Mali",
    },
    {
        value: "Malta",
        label: "Malta",
    },
    {
        value: "Marrocos",
        label: "Marrocos",
    },
    {
        value: "Martinica",
        label: "Martinica",
    },
    {
        value: "Maurício",
        label: "Maurício",
    },
    {
        value: "Mauritânia",
        label: "Mauritânia",
    },
    {
        value: "Mayotte",
        label: "Mayotte",
    },
    {
        value: "México",
        label: "México",
    },
    {
        value: "Micronésia",
        label: "Micronésia",
    },
    {
        value: "Moçambique",
        label: "Moçambique",
    },
    {
        value: "Moldova",
        label: "Moldova",
    },
    {
        value: "Mônaco",
        label: "Mônaco",
    },
    {
        value: "Mongólia",
        label: "Mongólia",
    },
    {
        value: "Montenegro",
        label: "Montenegro",
    },
    {
        value: "Montserrat",
        label: "Montserrat",
    },
    {
        value: "Myanma",
        label: "Myanma",
    },
    {
        value: "Namíbia",
        label: "Namíbia",
    },
    {
        value: "Nauru",
        label: "Nauru",
    },
    {
        value: "Nepal",
        label: "Nepal",
    },
    {
        value: "Nicarágua",
        label: "Nicarágua",
    },
    {
        value: "Níger",
        label: "Níger",
    },
    {
        value: "Nigéria",
        label: "Nigéria",
    },
    {
        value: "Niue",
        label: "Niue",
    },
    {
        value: "Noruega",
        label: "Noruega",
    },
    {
        value: "Nova Caledônia",
        label: "Nova Caledônia",
    },
    {
        value: "Nova Zelândia",
        label: "Nova Zelândia",
    },
    {
        value: "Omã",
        label: "Omã",
    },
    {
        value: "Países Baixos Caribenhos",
        label: "Países Baixos Caribenhos",
    },
    {
        value: "Palau",
        label: "Palau",
    },
    {
        value: "Palestina",
        label: "Palestina",
    },
    {
        value: "Panamá",
        label: "Panamá",
    },
    {
        value: "Papua-Nova Guiné",
        label: "Papua-Nova Guiné",
    },
    {
        value: "Paquistão",
        label: "Paquistão",
    },
    {
        value: "Paraguai",
        label: "Paraguai",
    },
    {
        value: "Peru",
        label: "Peru",
    },
    {
        value: "Polinésia Francesa",
        label: "Polinésia Francesa",
    },
    {
        value: "Polônia",
        label: "Polônia",
    },
    {
        value: "Porto Rico",
        label: "Porto Rico",
    },
    {
        value: "Portugal",
        label: "Portugal",
    },
    {
        value: "Qatar",
        label: "Qatar",
    },
    {
        value: "Quênia",
        label: "Quênia",
    },
    {
        value: "Quirguistão",
        label: "Quirguistão",
    },
    {
        value: "República Centro-Africana",
        label: "República Centro-Africana",
    },
    {
        value: "República Democrática do Congo",
        label: "República Democrática do Congo",
    },
    {
        value: "República Dominicana",
        label: "República Dominicana",
    },
    {
        value: "República Tcheca",
        label: "República Tcheca",
    },
    {
        value: "Romênia",
        label: "Romênia",
    },
    {
        value: "Ruanda",
        label: "Ruanda",
    },
    {
        value: "Rússia (antiga URSS) - Federação Russa",
        label: "Rússia (antiga URSS) - Federação Russa",
    },
    {
        value: "Saara Ocidental",
        label: "Saara Ocidental",
    },
    {
        value: "Saint-Pierre e Miquelon",
        label: "Saint-Pierre e Miquelon",
    },
    {
        value: "Samoa Americana",
        label: "Samoa Americana",
    },
    {
        value: "Samoa Ocidental",
        label: "Samoa Ocidental",
    },
    {
        value: "San Marino",
        label: "San Marino",
    },
    {
        value: "Santa Helena",
        label: "Santa Helena",
    },
    {
        value: "Santa Lúcia",
        label: "Santa Lúcia",
    },
    {
        value: "São Bartolomeu",
        label: "São Bartolomeu",
    },
    {
        value: "São Cristóvão e Névis",
        label: "São Cristóvão e Névis",
    },
    {
        value: "São Martim",
        label: "São Martim",
    },
    {
        value: "São Martinho",
        label: "São Martinho",
    },
    {
        value: "São Tomé e Príncipe",
        label: "São Tomé e Príncipe",
    },
    {
        value: "São Vicente e Granadinas",
        label: "São Vicente e Granadinas",
    },
    {
        value: "Senegal",
        label: "Senegal",
    },
    {
        value: "Serra Leoa",
        label: "Serra Leoa",
    },
    {
        value: "Sérvia",
        label: "Sérvia",
    },
    {
        value: "Síria",
        label: "Síria",
    },
    {
        value: "Somália",
        label: "Somália",
    },
    {
        value: "Sri Lanka",
        label: "Sri Lanka",
    },
    {
        value: "Suazilândia",
        label: "Suazilândia",
    },
    {
        value: "Sudão",
        label: "Sudão",
    },
    {
        value: "Sudão do Sul",
        label: "Sudão do Sul",
    },
    {
        value: "Suécia",
        label: "Suécia",
    },
    {
        value: "Suíça",
        label: "Suíça",
    },
    {
        value: "Suriname",
        label: "Suriname",
    },
    {
        value: "Svalbard",
        label: "Svalbard",
    },
    {
        value: "Tadjiquistão",
        label: "Tadjiquistão",
    },
    {
        value: "Tailândia",
        label: "Tailândia",
    },
    {
        value: "Taiwan",
        label: "Taiwan",
    },
    {
        value: "Tanzânia",
        label: "Tanzânia",
    },
    {
        value: "Território Britânico do Oceano índico",
        label: "Território Britânico do Oceano índico",
    },
    {
        value: "Territórios do Sul da França",
        label: "Territórios do Sul da França",
    },
    {
        value: "Timor-Leste",
        label: "Timor-Leste",
    },
    {
        value: "Togo",
        label: "Togo",
    },
    {
        value: "Tonga",
        label: "Tonga",
    },
    {
        value: "Trinidad e Tobago",
        label: "Trinidad e Tobago",
    },
    {
        value: "Tristão da Cunha",
        label: "Tristão da Cunha",
    },
    {
        value: "Tunísia",
        label: "Tunísia",
    },
    {
        value: "Turcomenistão",
        label: "Turcomenistão",
    },
    {
        value: "Turquia",
        label: "Turquia",
    },
    {
        value: "Tuvalu",
        label: "Tuvalu",
    },
    {
        value: "Ucrânia",
        label: "Ucrânia",
    },
    {
        value: "Uganda",
        label: "Uganda",
    },
    {
        value: "Uruguai",
        label: "Uruguai",
    },
    {
        value: "Uzbequistão",
        label: "Uzbequistão",
    },
    {
        value: "Vanuatu",
        label: "Vanuatu",
    },
    {
        value: "Vaticano",
        label: "Vaticano",
    },
    {
        value: "Venezuela",
        label: "Venezuela",
    },
    {
        value: "Vietnã",
        label: "Vietnã",
    },
    {
        value: "Wallis e Futuna",
        label: "Wallis e Futuna",
    },
    {
        value: "Zâmbia",
        label: "Zâmbia",
    },
    {
        value: "Zimbábue",
        label: "Zimbábue",
    },
]
