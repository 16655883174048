import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Icon, Input, message, Modal, Popover, Row} from "antd";
import {Fade} from "react-reveal";
import NumberFormat from "react-number-format";
import useApi from "../Hooks/Api";
import useAuth from "../Hooks/Auth";
import useLocalStorage from "../Hooks/Storage";
import {Texts} from "../Helpers/Texts";
import {useHistory} from "react-router-dom";

import {Env} from "../Helpers/Env";
import RegisterFinalForm from "../Components/PageComponents/Public/RegisterPage/RegisterFinalForm";
import {Images} from "../Helpers/Images";
import {StorageVariables} from "../Helpers/StoragesVariables";
import useApiClub from "../Hooks/ApiClub";
let messaging = window.firebase?.messaging?.isSupported()? window.firebase.messaging() : null;


const RegisterPageModal = (props) => {
    const history = useHistory();
    const {getFieldDecorator, validateFields} = props.form;
    const [step, setStep] = useState('regFinal'); // login, regNumber, regNumberAgain, regCode, regFinal
    const api = useApi();
    const [phone, setPhone] = useState(undefined);
    const [confirmPhone, setConfirmPhone] = useState();
    const {login, storeEnterpriseOpportunities} = useAuth();
    const [mail, setMail] = useState();
    const [cpf, setCpf] = useState();
    const [loading, setLoading] = useState(false);
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);
    const mobileToken = useRef(undefined);
    const token = useRef(undefined);
    const fcm_token = useRef(undefined);
    const [code, setCode] = useState('');
    const [verifyAfter, setVerifyAfter] = useState(false);
    const [resend, setResend] = useState(true);
    const [mobileVerified, setMobileVerified] = useState(false);
    const lastProfileAux = useRef(undefined);
    const externAccessUser = useLocalStorage(StorageVariables.externUserAccess)
    const apiExtern = useApiClub({apiUrl: Env.api_bluetrix})


    const handleExternAccessLogin = (values,messages) => {
        apiExtern.post('access/auth', {
            login: values.login, password: values.password
        },(res) => {
            Env.header_club.userToken = res.userToken;
            let aux = {...res.object, ...{userToken : res.userToken}}
            externAccessUser.setObject(aux);
            history.push('/acesso-externo/vagas-da-minha-empresa')
        }, (e) => {
            message.error(messages);
            setLoading(false)
        })
    }

    const handleResend = async () => {
        if (resend) {
            setResend(false);
            api.post('access/mobile-verify', {cellphone_number: '+55' + phone.replace(/\D/ig, ''), token: token.current}, (res) => {
                message.success('Codigo reenviado para o Número ' + phone);
                setTimeout(() => {
                    setResend(true);
                },[15000]);

                setTimeout(() => {
                    if(res.token) mobileToken.current = res.token;
                    setVerifyAfter(true)
                },[3000])
            }, (e) => {
                message.error(e.message || 'Não foi possivel reenviar o Código, tente novamente mais tarde');
            });
        }
    };

    const doAfterLogin = async (res) => {
        message.success({
            icon: <> </>,
            content: <div style={{display: 'flex', fontWeight: 'bold', flexFlow: 'column'}}>
                <img style={{height: 50, marginBottom: 10}} src={Images.logo_header}/> <span> Seja bem-vindo(a). </span>
            </div>
        });

        lastProfileAux.current = res.is_worker? 'worker' : 'client';

        const obj = {
            token: res.api_key,
            user_id: res.user_id,
            email: res.profile.email,
            nickname: res.profile.nickname,
            avatar: res.profile.profile_picture?.thumbnail,
            name: (res.profile.first_name + ' ' + res.profile.last_name),
            gender: res.profile.gender,
            phone: res.profile.cellphone_number,
            cpf: res.profile.cpf,
            addresses: res.addresses,
            birthday: res.profile.birthday,
            last_profile: lastProfileAux.current
        };
        login(obj);

        api.get(`sine/user/${res.user_id}/full`, (userID) => {
            if(userID.user_id !== null) {
                hasProfileStorage.setPrimitive(true)
            } else {
                hasProfileStorage.setPrimitive(false);
            }

            api.post(`sine/user/${res.user_id}/job-opportunities`, {
                "order": "desc",
                "page": 1,
                "status": 'open'
            },(opportunitiesList) => {
                if(opportunitiesList.job_opportunities) {
                    storeEnterpriseOpportunities(opportunitiesList.job_opportunities)
                }
                if(window.location.pathname.includes('vagas-de-emprego') || (window.location.pathname.includes('buscar-cursos'))) {}
                else if(lastProfileAux.current === 'worker') history.push('/painel');
                else if(window.location.pathname === '/') history.push('/painel');
            },(e) => {});
        }, (e) => {});
    };


    const handleLogin = async (e) => {
        if(e) e.preventDefault();

        validateFields(async (error, values) => {
            if(error) {
                return 0;
            }
            if(!values.password) {
                return 0;
            }
            setLoading(true);
            let md5 = require('md5');
            let md5Password = md5(values?.password);

            let loginType = values.login?.indexOf('@') > -1 ? 'email' : 'cpf';
            api.post(`access/login`, {[loginType]: loginType === 'cpf' ? window.getNumbers(values.login) : values.login, password: md5Password, application_name: Env.application_name, fcm_token: fcm_token.current}, (res) => {
                doAfterLogin(res);
                setStep('login');
                props.setVisible(false);
                setLoading(false);
            },(e) => {
                handleExternAccessLogin(values,e.message || 'Não foi possível efetuar o login, tente novamente');
               /* setLoading(false);
                message.error(e.message || 'Não foi possível efetuar o login, tente novamente');*/
            });


        })
    }


    const handleForgot = async () => {
        if(cpf && mail) {
            api.post(`access/forgot-password-web`, {email: mail, cpf: window.getNumbers(cpf), application_name: Env.application_name}, (res) => {
                message.success('As instruções de recuperação de senha foram enviadas para o seu Email!');
                setStep('login');
            }, (e) => {
                message.error(e.message || 'Não foi possível recuperar sua senha, tente novamente');
            });
        } else {
            message.warning('Informe o seu CPF e email cadastrados');
        }
    };

    const validateCode = async () => {
        if (code.length !== 6) {
            message.warning('Digite os 6 Dígitos corretamente');
            return;
        }
        setLoading(true);
        message.loading('Confirmando...');
        api.post('access/mobile-confirm', {cellphone_number: '+55' + phone.replace(/\D/ig, ''), token: token.current, code: code}, (res) => {
            message.destroy();
            message.success('Tudo certo');
            setMobileVerified(true);
            setLoading(false);
            setStep('regForm');
        }, (e) => {
            setLoading(false);
            message.destroy();
            message.error(e.message || 'Não foi possível validar o Código, tente novamente mais tarde');
        });
    };

    const validateNumber = async () => {

        if(!phone || phone.replace(/\D/ig, '').length !== 11) {
            message.warning('Digite um número válido');
            return;
        }
        if(window.validateMobilePhone(phone)) {
            if(Env.phone_validation) {
                setLoading(true);
                message.loading('Enviando SMS', 0);

                api.post('access/mobile-verify', {cellphone_number: '+55' + phone.replace(/\D/ig, '')}, (res) => {
                    message.destroy();
                    message.success('Enviado!');
                    token.current = res.token;
                    setStep('regCode');
                    setLoading(false);
                }, (e) => {
                    message.error(e.message || 'Não foi possível validar, tente novamente');
                    setLoading(false);
                });

            } else {
                setStep('regNumberAgain');
            }
        } else {
            message.warning('Digite um número válido');
        }
    };

    const getToken = () => {
        messaging?.getToken().then(async (currentToken) => {
            fcm_token.current = currentToken;
        })
    }

    useEffect(() => {
        getToken();
    },[])

    useEffect(() => {
        if(props.type) {
            setStep(props.type)
        }

        if(props.profile) {
            lastProfileAux.current = props.profile;
        } else {
            lastProfileAux.current = (window.location.pathname !== '/trabalhar' && !window.location.pathname.includes('/vagas-de-emprego') && !window.location.pathname.includes('/buscar-cursos'))? 'client' : 'worker';
        }
    },[props.type]);

    return (<div>

        <Modal
            visible={props.visible}
            footer={null}
            bodyStyle={{padding: 0}}
            width={'80%'}
            onCancel={() => {props.setVisible(false)}}
            className={'registerModalWidth'}
            destroyOnClose={true}
            style={{maxWidth: '800px'}}
        >
            <Row style={{display: 'flex', alignItems: 'stretch', flexWrap: 'wrap'}}>
                <Col xs={24} sm={8} className={'sideLogin'}>
                    <div className={'sideDiv'}>
                        <Fade left spy={step}> {(step === 'login' || step === 'regType') ?
                            <div className={'logTitle'}> {Texts.auth_page.login_title} <div className={'line'}/> </div>
                            : (step === 'regNumber')? <div className={'logTitle'}> {Texts.auth_page.reg_title} <div className={'line'}/> </div>
                                : (step === 'regNumberAgain')? <div className={'logTitle'}> {Texts.auth_page.reg_confirm} <div className={'line'}/> </div>
                                    : (step === 'regCode')? <div className={'logTitle'}> {Texts.auth_page.reg_number} <div className={'line'}/> </div>
                                        : (step === 'regForm')? <div className={'logTitle'}> {Texts.auth_page.reg_form}  <div className={'line'}/> </div>
                                            : (step === 'regEnd')? <div className={'logTitle'}> {Texts.auth_page.reg_end} <div className={'line'}/> </div>
                                                : (step === 'forgot')&& <div className={'logTitle'}> {Texts.auth_page.forgot}  <div className={'line'}/> </div>
                        } </Fade>

                        <Fade right spy={step}> {(step === 'login' || step === 'regType') ?
                            <span className={'logSubTitle'}> {Texts.auth_page.login_subTitle} </span>
                            : (step === 'regNumber')? <span className={'logSubTitle'}> {Texts.auth_page.reg_subTitle} </span>
                                : (step === 'regNumberAgain')? <div className={'logSubTitle'}> {Texts.auth_page.reg_subConfirm} </div>
                                    : (step === 'regCode')? <span className={'logSubTitle'}> {Texts.auth_page.reg_subNumber} </span>
                                        : (step === 'regForm')? <span className={'logSubTitle'}> {Texts.auth_page.reg_subForm}  </span>
                                            : (step === 'regEnd')? <span className={'logSubTitle'}>  {Texts.auth_page.reg_subEnd} </span>
                                                : (step === 'forgot')&& <span className={'logSubTitle'}> {Texts.auth_page.subForgot} </span>
                        }</Fade>
                    </div>
                </Col>
                <Col xs={24} sm={16} style={{padding: 30, paddingBottom: 0, transition: '400ms'}}>
                    {step === 'forgot' &&
                        <div>
                            <div className={'logRegItem'} style={{textAlign: 'center'}}>
                                <img style={{maxWidth: 300}} src={Images.logo}/>
                            </div>
                            <div className={'logRegItem'}>
                                <div className={'label'}> <label> Email </label> </div>

                                <Input value={mail} onChange={(e) => setMail(e.target.value)} placeholder={'Seu email'} size={'large'}/>

                            </div>

                            <div className={'logRegItem'}>
                                <div className={'label'}> <label> CPF </label> </div>

                                <Input value={cpf} onChange={(e) => setCpf(e.target.value)} placeholder={'Seu Cpf'} size={'large'}/>
                            </div>

                            <div className={'logRegItem'} style={{textAlign: 'center'}}>
                                <Button onClick={(e) => handleForgot(e)} type={'primary'}> Recuperar </Button>
                            </div>

                            <div className={'forgotSpan'}>
                                <span onClick={() => setStep('login')}>
                                    Entrar
                                </span>
                            </div>
                        </div>}

                    {step === 'login'&&
                        <div>
                            <div className={'logRegItem'} style={{textAlign: 'center'}}>
                                <img style={{maxWidth: 300}} src={Images.logo}/>
                            </div>
                            <div className={'logRegItem'}>
                                <div className={'label'}> <label> Login </label> </div>
                                {getFieldDecorator('login',
                                    {
                                        rules: [],
                                        required: true
                                    }
                                )(
                                    <Input placeholder={'Seu CPF ou E-mail'} size={'large'}/>
                                )}
                            </div>

                            <div className={'logRegItem'}>
                                <div className={'label'}> <label> Senha </label> </div>
                                {getFieldDecorator('password',
                                    {
                                        rules: [],
                                        required: true
                                    }
                                )(
                                    <Input.Password onKeyDown={(e) => {if(e.keyCode === 13) handleLogin(e)}} placeholder={'Sua senha'} size={'large'}/>
                                )}
                                <div className={'primary-sub-color'} style={{fontWeight: 'bold', textAlign: 'right', textDecoration: 'underline #00a6ca', cursor: 'pointer'}}>
                                    <span onClick={() => setStep('forgot')}>
                                        Esqueci minha senha
                                    </span>
                                </div>
                            </div>

                            <div className={'logRegItem'} style={{textAlign: 'center'}}>
                                <Button loading={loading} disabled={loading} onClick={(e) => handleLogin(e)} type={'primary'}> Entrar </Button>
                            </div>

                            <div className={'forgotSpan'}>
                                <span onClick={() => setStep('regType')}>
                                    Cadastrar
                                </span>
                            </div>
                        </div>}
                    {step === 'regType' &&
                        <div>
                            <Row gutter={[12,12]} style={{margin: 0, width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'stretch'}}>
                                <Col xs={24} md={24} lg={12} >
                                    <div className='regTypeCards'>
                                        <div style={{width: '100%'}}>
                                            <img style={{maxHeight: '300px', maxWidth: '100%', marginBottom: 20}} src={Images.client}/>
                                        </div>

                                        <div style={{textAlign: 'left', flex: 1, display: 'flex', flexFlow: 'column', justifyContent: 'space-around'}}>
                                            <div>
                                                <h3 className={'primary-sub-color'} style={{textAlign: 'center'}}>Procuro oportunidades no mercado</h3>
                                            </div>
                                            Estou em busca de vagas de emprego ou divulgar meus serviços.
                                        </div>
                                        <Button className={'ant-btn-sub-primary'} style={{ marginTop: 20}} onClick={() => {setStep('regNumber'); lastProfileAux.current = 'worker'}}> <span style={{color: 'white'}}> Continuar </span> </Button>

                                    </div>
                                </Col>
                                <Col xs={24} md={24} lg={12}>
                                    <div className='regTypeCards'>
                                        <div style={{width: '100%'}}>
                                            <img style={{maxHeight: '300px', maxWidth: '100%',  marginBottom: 20}} src={Images.worker}/>
                                        </div>
                                        <div style={{textAlign: 'left', flex: 1, display: 'flex', flexFlow: 'column', justifyContent: 'space-around'}}>
                                            <div>
                                                <h3 className={'secondary-sub-color'}  style={{textAlign: 'center'}}>Procuro por profissionais </h3>
                                            </div>
                                            Procuro currículos ou prestadores de serviço.
                                        </div>
                                        <Button className={"ant-btn-green"}  onClick={() => {setStep('regNumber'); lastProfileAux.current = 'client'}} style={{marginTop: 20}}> <span style={{color: 'white'}}> Continuar </span>  </Button>

                                    </div>
                                </Col>

                            </Row>
                        </div>
                    }
                    {step === 'regNumber' && <div>
                        <div className={'logRegItem'} style={{textAlign: 'center'}}>
                            <img style={{maxHeight: 250, margin: 'auto'}} src={Images.auth.number_step}/>
                        </div>
                        <div className={'logRegItem'}>
                            <NumberFormat
                                type={"tel"}
                                value={phone}
                                onValueChange={(e) => setPhone(e.value)}
                                style={{textAlign: 'center'}}
                                placeholder={'(99) 99999-9999'}
                                className='ant-input ant-input-lg'
                                format={'(##) #####-####'} mask="_"/>
                        </div>

                        <div className={'logRegItem'} style={{textAlign: 'center', display: 'flex', justifyContent: 'space-between'}}>
                            <Button type={'default'} onClick={() => setStep('login')}> Voltar  </Button>
                            <Button type={'primary'} loading={loading} disabled={loading} onClick={() => validateNumber()}> Próximo </Button>
                        </div>

                    </div>}

                    {step === 'regNumberAgain' &&<div>
                        <div className={'logRegItem'} style={{textAlign: 'center'}}>
                            <img style={{maxHeight: 250, margin: 'auto'}} src={Images.auth.number_step}/>
                        </div>
                        <div className={'logRegItem'}>
                            <NumberFormat
                                type={"tel"}
                                value={confirmPhone}
                                onValueChange={(e) => setConfirmPhone(e.value)}
                                placeholder={'(99) 99999-9999'}
                                style={{textAlign: 'center'}}
                                className='ant-input ant-input-lg'
                                format={'(##) #####-####'} mask="_"/>
                        </div>

                        <div className={'logRegItem'} style={{textAlign: 'center', display: 'flex', justifyContent: 'space-between'}}>
                            <Button type={'default'} onClick={() => setStep('regNumber')}> Voltar  </Button>
                            <Button type={'primary'} onClick={() => confirmPhone === phone? setStep('regForm') : message.error('O número deve ser o mesmo do digitado anteriormente')}> Próximo </Button>
                        </div>

                    </div>}

                    {step === 'regCode' &&
                        <div>
                            <div className='logRegItem' style={{textAlign: 'center'}}>
                                <img style={{maxHeight: 250}} src={Images.auth.number_step} />
                            </div>
                            <div style={{textAlign: 'right'}}>
                                <Popover title={'Reenviar Código'} content={'Código não recebido? Clique aqui para reenviar.'}>
                                    <Button disabled={!resend} onClick={() => handleResend()} icon={'rollback'}> Re-enviar </Button>
                                </Popover>
                            </div>
                            <Input className='codeInput'
                                   maxLength={6}
                                   value={code}
                                   onChange={(e) => setCode(e.target.value)}
                                   placeholder={'Digite aqui'} suffix={<Icon type="qrcode" className={'primary-sub-color'} style={{ fontSize: '20px'}}/>}/>

                            <Fade when={verifyAfter}>
                                <div style={{textAlign: 'center',  marginTop: 10}}>
                                    <span onClick={() => {setMobileVerified(false);}} className={'primary-sub-color'} style={{width: '100%', cursor: "pointer"}}> Desejo validar meu telefone depois </span>
                                </div>
                            </Fade>

                            <div className={'logRegItem'} style={{textAlign: 'center', display: 'flex', justifyContent: 'space-between'}}>
                                <Button type={'default'} onClick={() => setStep('regNumber')}> Voltar  </Button>
                                <Button loading={api.loading} disabled={api.loading} type={'primary'} onClick={() => validateCode()}> Próximo </Button>
                            </div>

                        </div>}

                    {step === 'regForm' &&
                    <RegisterFinalForm profile={lastProfileAux.current} setStep={() => setStep('regEnd')}  mobileToken={mobileToken.current} mobileVerified={mobileVerified} userCell={phone}/>
                    }

                    {step === 'regEnd'&&<div style={{minHeight: 350}}>
                        {lastProfileAux.current === 'worker'? <div>
                            <div style={{textAlign: 'center', fontSize: 20}}>
                                <strong style={{color: '#0f75bc'}}> Agora é só cadastrar o seu currículo! </strong>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <img style={{height: '100%', maxHeight: 320}} src={Images.workerPanel.edit_resume}/>
                            </div>
                            <div style={{padding: 20, display: "flex", justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <Button onClick={() =>{history.push('/painel'); props.setVisible(false);}} style={{borderColor: 'lightgray'}}> Depois </Button>
                                <Button onClick={() => {history.push('/painel/prestador/editar-meu-curriculo'); props.setVisible(false)}} className={'ant-btn-secondary'}> Cadastrar agora meu currículo </Button>
                            </div>
                        </div>
                        :
                        <div>
                            <div style={{textAlign: 'center', fontSize: 20}}>
                                <strong style={{color: '#424242'}}> Tudo pronto </strong> <br/>
                                <span style={{color: '#616161', fontSize: 16}}>  Agora você está pronto para buscar por prestadores de serviço, por currículos, ou divulgar vagas para seu negócio em todo o estado de {Env.state_restriction}! </span>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <img style={{height: '100%', maxHeight: 320}} src={Images.search_resumes}/>
                            </div>
                            <div style={{padding: 10, display: "flex", justifyContent: 'space-between', flexDirection: "column"}}>
                                <Button onClick={() => {
                                    window.gtag&& window.gtag('event', 'posRegWantToPublish');
                                    history.push('/painel/cliente/vagas');
                                    props.setVisible(false)}} style={{marginBottom: 10}} className={'ant-btn-primary'}> Divulgar Vaga </Button>
                                <Button onClick={() => {
                                    window.gtag&& window.gtag('event', 'posRegSearchCurriculum');
                                    if(!window.location.pathname.includes('/curriculos')) {
                                        history.push('/curriculos');
                                    }
                                    props.setVisible(false)}} style={{marginBottom: 10}} className={'ant-btn-sub-primary'}> Buscar Currículos </Button>
                                <Button onClick={() => {
                                    window.gtag&& window.gtag('event', 'posRegSearchWorker');
                                    if(!window.location.pathname.includes('/quero-contratar')) {
                                        history.push('/quero-contratar');
                                    }
                                    props.setVisible(false)}} className={'ant-btn-purple'}> Buscar Prestadores de Serviço </Button>

                                <span onClick={() => {
                                    window.gtag&& window.gtag('event', 'posRegSubmitCurriculum');
                                    history.push('/painel/prestador/editar-meu-curriculo');
                                    props.setVisible(false)}} style={{cursor: 'pointer', marginTop: 30, color: '#757575', fontWeight: 'bold', textAlign: 'center'}}>
                                    Quero divulgar meu currículo
                                </span>
                            </div>
                        </div>
                        }

                    </div>}
                </Col>
            </Row>
        </Modal>

    </div>)
};

export default Form.create({name: 'regLogForm'})(RegisterPageModal);


