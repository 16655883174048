import React, {forwardRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Col, Collapse, Divider, Drawer, Form, Icon, message, Modal, notification, Radio, Row, Select, Spin, Tag} from 'antd';

import {Fade, Slide} from 'react-reveal';
import useApi from "../../../../Hooks/Api";
import {useHistory} from "react-router-dom";

import {useValidationShorts} from "../../../../Hooks/Helpers";
import NumberFormat from "react-number-format";
import useAuth from "../../../../Hooks/Auth";
import * as moment from "moment";
import {maskPhone} from "../../../../Helpers/Functions";
import {Images} from "../../../../Helpers/Images";
import RegisterPageModal from "../../../../Pages/RegisterPageModal";
import {useSelector} from "react-redux";
import {Env} from "../../../../Helpers/Env";
import * as Sentry from "@sentry/react";
import {FaThumbsUp, MdStar, MdStarBorder} from "react-icons/all";
import {FaThumbsDown} from "react-icons/fa";
import FeedbackForm from "../../Restrict/RecruitmentsPage/FeedbackForm";

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}

const pStyle = {
    fontSize: 16,
    color: 'rgba(0,0,0,0.85)',
    lineHeight: '24px',
    display: 'block',
    marginBottom: 16,
};

const DescriptionItem = ({ title, content }) => (
    <div className="profile-description-item">
        <p>
            {title}:
        </p>
        {content}
    </div>
);

const ResumeDrawer = forwardRef((props, ref) => {
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const {getFieldsValue, getFieldDecorator, validateFields, getFieldError} = props.form;
    const [recruitLoading, setRecruitLoading] = useState(false);
    const api = useApi({customReturn: true, loadingControl: false});
    const [privateProfile, setPrivateProfile] = useState(false);
    const [visible, setVisible] = useState(false);
    const {required} = useValidationShorts();
    const {isLogged, getEnterpriseOpportunities,getEnterprise} = useAuth();
    const opportunitiesList = useRef(getEnterpriseOpportunities() || []);
    const [isFirstJob, setIsFirstJob] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const user = useSelector((state) => state).authReducer;
    const [showFeedback, setShowFeedback] = useState(false);
    const feedbackUser = useRef(null);
    const [showFirstViewModal, setShowFirstViewModal] = useState(props?.viewed === false)
    const [favorite, setFavorite] = useState(props?.favorite)

    const getData = async (id, cboId, recruited) => {

        setLoading(true);
        let endpointAux = '';
        if(recruited) {
            endpointAux = `sine/contact/${id}`;
            setPrivateProfile(true);
        } else {
            endpointAux = `sine/profile/${id}?cbo_occupation_id=${cboId}`;
        }
        api.get(endpointAux, (initialRes) => {
            let res = initialRes;
            res.occupations_interest.push(res.occupation?.name);
            res.occupation&& res.occupations_details.push(res.occupation);
            try {
                if(res.extra.work_experience.length > 0) {
                    for(let i = 0; i < res.extra.work_experience.length; i ++) {
                        if(res.extra.work_experience[i].start_date !== res.extra.work_experience[i].end_date) {
                            break;
                        }
                        if(i + 1 === res.extra.work_experience.length) {
                            setIsFirstJob(true);
                        }
                    }
                } else {
                    for(let i = 0; i < res.occupations_details.length; i ++) {
                        if(res.occupations_details[i].formal_experience !== "Nenhuma" || res.occupations_details[i].informal_experience !== "Nenhuma") {
                            break;
                        }
                        if(i + 1 === res.occupations_details.length) {
                            setIsFirstJob(true);
                        }
                    }
                }
            } catch (e) {
                console.log(res.occupations_details)
                Sentry.captureException(e);
            }

            setProfile(res);
            setLoading(false);
        }, (e) => {
            setProfile({});
            setLoading(false);
            message.warning('Ops, não foi possível recuperar os dados do currículo, tente novamente mais tarde')
        });
    };

    const doRecruit = async () => {
        setRecruitLoading(true);
        message.loading('Recuperando dados de contato', 10);
        let auxPrivateProfile = {};

        validateFields(async (error, values) => {
            if (error) {
                setRecruitLoading(false);
                message.destroy();
                message.warning('Digite o CPNJ corretamente');
            } else {
                let objToSend = {cbo_occupation_id: props.cboId};
                let apiUrl = '';
                if(values.cnpj) objToSend.cnpj = values.cnpj;

                if(values.type !== 'contrato' && values.type !== 'empresarial') {
                    apiUrl = `sine/user/${user.user_id}/recruitment-opportunity/${values.type}`;
                    objToSend.worker_id = profile.id;
                } else {
                    apiUrl = `sine/profile/${profile.id}/personal-data`;
                }
                objToSend.application = Env.application_alias

                api.post(apiUrl, objToSend, (item) => {
                    api.get(`sine/contact/${item.contact_id}`, (res) => {
                        const privateProfile = res;
                        setProfile(privateProfile);
                        auxPrivateProfile = privateProfile;
                        auxPrivateProfile.id = item.contact_id;
                        setPrivateProfile(true);

                        message.destroy();
                        message.success('Dados de contato agora disponíveis!');
                        setRecruitLoading(false);
                        setVisible(false);
                        props.handleRecruited();
                        document.getElementById('scrollDivDrawer').scrollTop = 0;
                    }, (e) => {
                        message.destroy();
                        message.success('Dados de contato agora disponíveis!');
                        setRecruitLoading(false);
                        setVisible(false);
                        props.handleRecruited();
                        props.setVisible(false)
                    });
                },(e) => {
                    message.destroy();
                    message.error(e.message || 'Não foi possivel obter os dados deste profissional');
                    setRecruitLoading(false);
                });
            }
        })
    };

    const convertDate = (end, start) => {
        let years;
        let months;
        if(end) {
            years = moment(end,'YYYY-MM').diff(start, 'years');
            months = moment(end,'YYYY-MM').diff(start, 'months');
        } else {
            years = moment().diff(start, 'years');
            months = moment().diff(start, 'months');
        }

        if(years < 1) {
            return <span> {months % 12 + ' meses'} </span>
        } else if (months % 12 > 0){
            return <span> {years + ' ano(s) e ' + months % 12 + ' mês(es)'} </span>
        } else {
            return <span> {years + ' ano(s)'} </span>
        }

    };

    const handleRecruit = () => {
        if(isLogged()) {
            setVisible(true);
        }
        else {
            const key = 'notificationLocalKey';
            notification.open({
                duration: 8,
                description: <div>
                    <div style={{marginBottom: 20, textAlign: 'center'}}>
                        <img src={Images.logo_header} style={{height: 100}}/>
                    </div>
                    <div style={{marginBottom: 20}}> Para solicitar os dados de contato de um profissional é necessário realizar o cadastro!</div>
                    <div style={{textAlign: 'center'}}> <Button onClick={() => {
                        notification.close('notificationLocalKey');
                        setRegisterModal('login')
                    }} icon={'caret-right'} type={'primary'}> Cadastre-se </Button> </div>

                </div>,
                key,
                placement: 'topLeft'
            });
        }
    };

    const handleArrowClick = (event) => {
        const key = event.key; // "ArrowRight", "ArrowLeft", "ArrowUp", or "ArrowDown"
        if(key === 'ArrowRight') {
            if(props.candidatesList[props.currentIndex + 1]) {
                let aux = props.candidatesList[props.currentIndex + 1];
                aux.index = props.currentIndex + 1;
                props.updateFeedbackUser(aux);
            } else {
                let aux = props.candidatesList[0];
                aux.index = 0;
                props.updateFeedbackUser(aux);
            }
        } else if(key === 'ArrowLeft') {
            if(props.candidatesList[props.currentIndex - 1]) {
                let aux = props.candidatesList[props.currentIndex - 1];
                aux.index = props.currentIndex - 1;
                props.updateFeedbackUser(aux);
            } else {
                let aux = props.candidatesList[props.candidatesList.length - 1];
                aux.index = props.candidatesList.length - 1;
                props.updateFeedbackUser(aux);
            }
        }
        document.getElementById('scrollDivDrawer').scrollTop = 0;

    };

    useEffect(() => {
        if(props.arrowShortCut) {
            if(props.visible) {
                setFavorite(props.favorite);
                setShowFirstViewModal(props?.viewed === false)
                window.addEventListener('keydown', handleArrowClick);
            }
            return () => window.removeEventListener('keydown', handleArrowClick);
        }
    }, [props.id]);

    useEffect(() => {
        setProfile({});
        setPrivateProfile(false);
        setIsFirstJob(false);
        if(props.id) {
            getData(props.id, props.cboId, props.recruited);
        }
    },[props.id]);

    return (
        <Drawer
            className="profile-drawer"
            placement="right"
            closable={false}
            onClose={() => {props.setVisible(false); props.onClose&& props.onClose()} }
            visible={props.visible}
        >
            <RegisterPageModal profile={'client'} visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />
            {showFeedback &&<FeedbackForm profile={{...profile, ...{interest_id: props?.interest_id}}} vacancyFeedback={props.vacancyFeedback} id={user.user_id} getData={() => {props.getData()}} showFeedback={showFeedback} setShowFeedback={(item) => {
                setShowFeedback(item);
                props.setVisible(false);
            }}/>}

            <div style={{overflow: 'auto', padding: 24, height: window.innerHeight}} id={'scrollDivDrawer'}>
                <Spin spinning={loading} tip="Carregando...">
                    <React.Fragment>

                        <Row style={{ marginBottom: 24, marginTop: 20}}>
                            <Col span={24} style={{display: "flex", justifyContent: 'space-between', flexWrap: "wrap"}}>
                                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                    <div style={{width: 'fit-content', display: 'flex', flex: 1}}>
                                        <Avatar style={{minWidth: 50, minHeight: 50}} src={profile.profile_picture} size="large" />
                                        <div>
                                            <h2 style={{verticalAlign: "middle", display: 'inline-block', margin: '0 0 0 12px'}}> {profile.name ? profile.name : profile.occupation?.name} </h2>
                                            {!!profile.name && <><br/><Tag color="blue" style={{marginLeft: 11}}> {profile.occupation?.name}</Tag></> }
                                        </div>
                                    </div>
                                    {privateProfile &&  <div style={{display: 'flex'}}>
                                        <Button  shape={"circle"} icon={'printer'} style={{height: 45, width: 45, marginRight: 5, color: 'gray', fontSize: 20}} onClick={() => window.open(window.location.origin + '/curriculo/privado/' + (profile.id || props.id))} />
                                        {!props.cboId && <>
                                            {props?.handleFavorite &&<Button className={favorite ? 'ant-btn-gold' : 'ant-btn-gold-ghost'}  shape={"circle"} icon={'star'} style={{height: 45, width: 45, marginRight: 5, color: 'gray', fontSize: 20}} onClick={() => {
                                                setFavorite(!favorite);
                                                props?.handleFavorite()
                                            }} />}

                                        {profile.status === 'pending'&& <div style={{display: 'flex', flexFlow: 'column'}}>
                                            <div
                                                className={'likeDislikeCandidate'}
                                                onClick={() => {
                                                    feedbackUser.current = profile;
                                                    setShowFeedback(true);
                                                }}
                                                style={{backgroundColor: '#e9e9e94d', borderRadius: '50%', height: 45, width: 45, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#1680ce', boxShadow: '0px 1px 1px 1px #e0e0e0', fontSize: 15}}>
                                                <FaThumbsUp style={{marginRight: 5, color: '#6bbe1e'}}/>
                                                <FaThumbsDown  style={{color: '#ff4e4e', transform: 'rotateY(180deg)'}}/>
                                            </div>
                                        </div>}
                                        </>}
                                    </div>}
                                </div>
                            </Col>
                        </Row>

                        {profile.name?
                            <Fade left>
                                <div>
                                    <p style={{...pStyle, color: "#2593fc"}}><Icon type="lock" /> Informações Privadas</p>
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="Nome" content={profile.name} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="CEP" content={profile.postal_code} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <DescriptionItem title="Endereço" content={profile.address} />
                                        </Col>
                                    </Row>
                                    {profile.emails?.length > 0 &&<Row>
                                        <Col span={24}>
                                            <DescriptionItem title="Emails" content={profile.emails?.join(", ")} />
                                        </Col>
                                    </Row>}
                                    {profile.phone_numbers?.length > 0 &&<Row>
                                        <Col span={24}>

                                            <div className="profile-description-item">
                                                <p>
                                                    Telefones:
                                                </p>
                                                {profile.phone_numbers.map((item, index) => {
                                                    let aux = item.replace(/\D/g, "");
                                                    return <span key={index} style={{marginRight: 10}}> {maskPhone(item)} <a target={'_blank'} href={'https://web.whatsapp.com/send?phone=+' + aux + '&text=Olá, ' + profile.name + '! Selecionei seu currículo pelo ' + Env.application_title + ', tudo bem?'}> <img style={{height: 20, marginBottom: 5, cursor: 'pointer'}}  src={Images.whats}/> </a> </span>
                                                })}
                                            </div>
                                        </Col>
                                    </Row>}
                                    <Divider />
                                </div>
                            </Fade>
                            :
                            null
                        }
                        {profile?.about_me &&<Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greyBg'} header={<span style={{fontWeight: 'bold', color: '#1a1a1a'}}> <Icon type="align-left" /> Sobre mim </span>} key="1">
                                <span>
                                    {profile.about_me}
                                </span>
                            </Collapse.Panel>
                        </Collapse>}

                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel header={<span style={{fontWeight: 'bold', color: '#0072d6'}}> <Icon type="audit" /> Experiências </span>} key="1">
                                {profile?.extra?.work_experience?.length > 0? profile?.extra?.work_experience?.map((item,index) =>
                                    {if((item.start_date !== item.end_date)) {
                                        return <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.extra?.work_experience?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#4caf50'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Cargo: </strong>{item.occupation}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Empresa: </strong> {privateProfile? item.company : 'Selecione este currículo para ver'}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Último salário: </strong>{privateProfile? (item.last_wage? <span>R$ {item.last_wage}</span> : 'Não informado') : 'Selecione este currículo para ver' }</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Tipo de trabalho: </strong>{item.work_type}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Local: </strong>{item.location}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Descrição do serviço: </strong>
                                                    <p>
                                                        {item.description? item.description : 'Não informado'}
                                                    </p>
                                                </label>
                                            </Col>

                                            {(item.work_type !== 'Freelance' && item.work_type !== 'Autônomo') ?
                                                <Col xs={24} md={24}>
                                                    <span>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Formal: </strong>
                                                        <span>{convertDate(item.end_date, item.start_date)}</span>
                                                        ( Inicio - {moment(item.start_date, 'YYYY-MM').format('MM/YYYY')} ) &nbsp; {item.end_date&& <span> {'('}Fim - {moment(item.end_date, 'YYYY-MM').format('MM/YYYY')} {')'}</span>}
                                                    </span>
                                                </Col>
                                                :
                                                <Col xs={24} md={24}>
                                                    <span>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Informal: </strong>
                                                        <span>{convertDate(item.end_date, item.start_date)}</span>
                                                        ( Inicio - {moment(item.start_date, 'YYYY-MM').format('MM/YYYY')} ) &nbsp; {item.end_date&& <span>{'('} Fim - {moment(item.end_date, 'YYYY-MM').format('MM/YYYY')} {')'}</span>}
                                                    </span>
                                                </Col>
                                            }
                                        </Row>
                                        }
                                    })
                                    : ((profile?.occupations_details?.length > 0) ? profile.occupations_details.map((item, index) =>
                                        {if(item?.formal_experience !== 'Nenhuma' || item?.informal_experience !== 'Nenhuma') {
                                            return <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.occupations_details?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#4caf50'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Cargo: </strong>{item.name}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Era estágio? </strong>{item.internship? 'Sim' : 'Não'}</label>
                                            </Col>

                                            <Col xs={24} md={24}>
                                                <span>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Formal: </strong>
                                                    <span>{item.formal_experience}</span>
                                                </span>
                                            </Col>

                                            <Col xs={24} md={24}>
                                                <span>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Informal: </strong>
                                                    <span>{item.informal_experience}</span>
                                                </span>
                                            </Col>
                                        </Row>}})
                                        :
                                        <span> Não possui </span>
                                    )
                                }

                                {isFirstJob&&
                                <Row type={'flex'}>
                                    <Col span={8}>
                                        <img src={Images.search_resumes} style={{maxWidth: '100%'}}/>
                                    </Col>
                                    <Col span={16} style={{alignSelf: 'center'}}>
                                        <h1 style={{fontSize: 23, textAlign: 'center'}}> Profissional em busca de sua primeira colocação </h1>
                                    </Col>
                                </Row>
                                }
                            </Collapse.Panel>
                        </Collapse>


                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel header={<span style={{fontWeight: 'bold', color: '#0072d6'}}> <Icon type="audit" /> Outros Interesses </span>} key="1">
                                {profile?.extra?.work_experience?.length > 0? profile?.extra?.work_experience?.map((item,index) =>
                                    {if((item.start_date === item.end_date)) {
                                        return <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.extra?.work_experience?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> {item.occupation} </strong>
                                                </label>
                                            </Col>
                                        </Row>
                                    }}
                                )
                                : (profile?.occupations_interest?.length > 0 ? profile.occupations_interest.map((item, index) =>
                                    {if(item?.formal_experience === 'Nenhuma' && item?.informal_experience === 'Nenhuma') {
                                        return <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.occupations_interest?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> {item} </strong>
                                                </label>
                                            </Col>
                                        </Row>
                                    }})
                                    :
                                    <span> Não possui </span>
                                )}
                            </Collapse.Panel>
                        </Collapse>


                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="book" /> Graduações ({profile?.extra?.academic?.length > 0? profile?.extra?.academic?.length : profile?.grads?.length })</span>} key="1">
                                {profile?.extra?.academic?.length > 0? profile?.extra?.academic?.map((item,index) =>
                                        <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.extra?.academic?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#0072d6'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item.course}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Instituição: </strong>{item.university}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Nível: </strong>{item.academic_degree}</label>
                                            </Col>

                                            <Col xs={24} md={12}>
                                                <span> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Inicio: </strong> {item.start_date? moment(item.start_date, 'YYYY-MM').format('MM/YYYY'): 'Não informado' }</span>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <span> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Fim: </strong> {item.end_date? moment(item.end_date, 'YYYY-MM').format('MM/YYYY'): 'Não informado' }</span>
                                            </Col>
                                        </Row>
                                    )
                                    : (profile?.grads?.length > 0 ? profile.grads.map((item, index) =>
                                            <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.grads?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                                <Col xs={24}>
                                                    <label style={{fontWeight: 'bold', color: '#0072d6'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item}</label>
                                                </Col>
                                            </Row>)
                                        :
                                        <span> Não possui </span>
                                    )
                                }
                            </Collapse.Panel>
                        </Collapse>



                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="control" /> Cursos ({profile?.courses?.length}) </span>} key="1">

                                {profile?.extra?.courses.length > 0 ? profile?.extra?.courses?.map((item,index) =>
                                        <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.extra?.courses?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#0072d6'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item.name}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Instituição: </strong>{item.institution}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Duração: </strong>{item.duration? <span> {item.duration} meses </span> : 'Não informado'} </label>
                                            </Col>
                                        </Row>
                                    )
                                    : (profile?.courses?.length > 0 ? profile.courses.map((item, index) =>
                                            <Row key={index} style={{marginBottom: 10, paddingBottom: 10,  borderBottom: profile?.courses?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                                <Col xs={24}>
                                                    <label style={{fontWeight: 'bold', color: '#0072d6'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item}</label>
                                                </Col>
                                            </Row>)
                                        :
                                        <span> Não possui </span>
                                    )
                                }
                            </Collapse.Panel>
                        </Collapse>

                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="contacts" /> Outras Informações</span>} key="1">
                                <Row>
                                    {profile.age &&<Col span={12}>
                                        <DescriptionItem title="Idade" content={profile.age + ' Anos'} />
                                    </Col>}
                                    {profile.gender &&<Col span={12}>
                                        <DescriptionItem title="Gênero" content={genders[profile.gender]} />
                                    </Col>}
                                </Row>
                                {profile.wage_claim &&<Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Pretensão salarial" content={privateProfile? 'R$ ' + profile.wage_claim: 'Selecione este currículo para ver'} />
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col span={12}>
                                        <DescriptionItem title="Estudante" content={profile.is_student ? "Sim" : "Não"} />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem title="Habilitação" content={profile.driver_license || 'Não tem'} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Escolaridade" content={profile.schooling} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Endereço" content={profile.address} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Disponibilidade para realizar viagens" content={profile.available_travel ? "Sim" : "Não"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Disponibilidade para dormir no local de trabalho" content={profile.available_sleep_duty ? "Sim" : "Não"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Disponibilidade para ausentar-se do domicílio por longo período" content={profile.available_leave_home ? "Sim" : "Não"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>

                                        <div className="profile-description-item">
                                            <p>
                                                Outras Idiomas:
                                            </p>
                                            {profile.languages?.length !== 0?
                                                <ul style={{ lineHeight: '22px' }}>
                                                    { profile.languages?.map((el, index) => <li key={index}>{el}</li>) }
                                                </ul>
                                                :
                                                'Nenhum'
                                            }
                                        </div>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24}>

                                        <div className="profile-description-item">
                                            <p>
                                                Veículos:
                                            </p>
                                            {profile.vehicles?.length !== 0 ?
                                                <ul style={{lineHeight: '22px'}}>
                                                    {profile.vehicles?.map((el, index) => <li key={index}>{el}</li>)}
                                                </ul> :
                                                'Nenhum'
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>

                                        <div className="profile-description-item">
                                            <p>
                                                Deficiências:
                                            </p>
                                            {profile.disabilities?.length !== 0 ?
                                                <ul style={{lineHeight: '22px'}}>
                                                    {profile.disabilities?.map((el, index) => <li key={index}>{el}</li>)}
                                                </ul> :
                                                'Nenhuma'
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>

                        {!privateProfile &&<Row>
                            <Button onClick={() => handleRecruit()} className="gx-btn" style={{background:"orange", borderColor: 'orange', fontWeight: 'bold'}} type="primary" block>
                                VER CONTATOS PARA ENTREVISTAR
                            </Button>
                        </Row>}

                        {props.arrowShortCut &&<div style={{display: 'flex', marginTop: 20, flexDirection: 'row', justifyContent: 'space-around'}}>
                            <div style={{cursor: 'pointer'}} onClick={() => handleArrowClick({key: 'ArrowLeft'})}>
                                <Icon type="arrow-left" />
                                <span style={{marginLeft: 5, fontWeight: 'bold'}}>Anterior</span>
                            </div>
                            <div style={{cursor: 'pointer'}} onClick={() => handleArrowClick({key: 'ArrowRight'})}>
                                <span style={{marginRight: 5, fontWeight: 'bold'}}>Próximo</span>
                                <Icon type="arrow-right" />
                            </div>
                        </div>}
                    </React.Fragment>
                </Spin>

                {(showFirstViewModal && profile.gender) &&<div style={{padding: 15, flexDirection: 'column', backgroundColor: 'white', justifyContent: 'center', boxShadow:'rgb(236 236 236) 0px 3px 6px 1px', borderRadius: 5,
                    border: '1px solid #dcdcdc94',
                    position: 'fixed',
                    bottom: 10,
                    right: 10
                }}>
                    <div style={{textAlign: 'center', fontSize: 17, marginBottom: 20}}>
                        <strong>Deseja manter esse currículo?</strong>
                    </div>
                    <div style={{justifyContent: 'center', display: "flex"}}>
                        <Button style={{marginRight: 10, flex: 1}} type={"primary"} icon={'check'} onClick={() => setShowFirstViewModal(false)}> Sim </Button>
                        <Button type={"danger"} ghost={true} icon={'close'} style={{flex: 1}} onClick={() => props?.disregardCurriculum()}> Não </Button>
                    </div>
                </div>}
            </div>
            <Modal className={'requestModal'} width={620} visible={visible} title={'Solicitar contato'} confirmLoading={recruitLoading} onOk={() => doRecruit()} onCancel={() => setVisible(false)} okText={'Solicitar dados'}>
                <div>
                    <div style={{marginBottom: 50, fontSize: 17}}> Este currículo será adicionado à sua lista de recrutamentos. Não se esqueça de enviar um feedback para nós informando-nos do resultado da entrevista :)</div>

                    <Divider/>
                    <div className={'mainDrawerModalRequest'}>
                        <div style={{textAlign: 'center', marginBottom: 20}}>
                            <img style={{width: '240px'}} src={Images.vacancy_img}/>
                        </div>

                        <div>
                            <Form.Item required={true} className='hideDots' validateStatus={getFieldError('type')&& 'error'} help={getFieldError('type')} label={<span style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}>Para onde será realizado o recrutamento</span>}>
                                {getFieldDecorator('type', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Obrigatório',
                                        },
                                    ],
                                })(<Select
                                    mode="default"
                                    style={{ width: '100%',marginTop: 5 }}
                                    placeholder={"Escolha aqui"}
                                    allowClear={false}
                                    size={'large'}
                                    suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}
                                >

                                    <Select.OptGroup label={'Não relacionar a nenhuma vaga'}>
                                        <Select.Option value={'contrato'}> Para mim mesmo (pessoa física) </Select.Option>
                                        <Select.Option value={'empresarial'}> Para minha empresa (pessoa jurídica) </Select.Option>
                                    </Select.OptGroup>
                                    <Select.OptGroup label={'Adicionar currículo como candidato à vaga'}>
                                        {opportunitiesList.current.map((item, index) =>
                                            <Select.Option value={item.id} key={index}> {item.occupation_title? item.occupation_title : item.occupation_name} </Select.Option>
                                        )}
                                    </Select.OptGroup>
                                </Select>)}
                            </Form.Item>

                            {getFieldsValue().type === 'empresarial'&& <Slide right> <Form.Item label="Informe o CNPJ">
                                {getFieldDecorator('cnpj', {
                                    rules: [required()],
                                    initialValue: getEnterprise()?.cnpj || undefined
                                })(
                                    <NumberFormat style={{width: '100%', fontSize: 17, fontWeight: 'bold', textAlign: 'center'}} placeholder={'##.###.###/####-##'} className={' ant-input ant-input-lg cellPhone'} format={'##.###.###/####-##'} mask="_"/>
                                )}
                            </Form.Item>  </Slide>}
                        </div>
                    </div>

                </div>
            </Modal>
        </Drawer>
    );
});

ResumeDrawer.propTypes = {
    cboId: PropTypes.any,
    id: PropTypes.any,
    setVisible: PropTypes.any,
    visible: PropTypes.any,
    recruited: PropTypes.any,
    favorite: PropTypes.any,
    handleFavorite: PropTypes.any,
    disregardCurriculum: PropTypes.any
};

export default Form.create({name: 'requestForm'})(ResumeDrawer);
