import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AutoComplete, Avatar, Button, Checkbox, Col, Divider, Icon, Input, message, Modal, Row} from "antd";
import {Images} from "../../../../../Helpers/Images";
import {maskReal} from "../../../../../Helpers/Functions";
import moment from "moment/moment";
import {
    FaCodepen,
    FaDollarSign,
    FaGraduationCap,
    FaIdCardAlt,
    FaLanguage,
    FaRegAddressBook,
    FaRegCalendarAlt,
    FaRegFileAlt,
    FaRegMap,
    FaRegThumbsUp,
    FaUserAltSlash,
    FaUserCheck,
    FaWheelchair,
    MdMore, MdSearch
} from "react-icons/all";
import {FaBriefcase, FaCalendarCheck, FaCheck, FaClock, FaPencilAlt, FaTools, FaUsers} from "react-icons/fa";
import useApi from "../../../../../Hooks/Api";
import VacancyFormComponent from "./VacancyFormComponent";
import Loading from "../../../../Generic/Loading";
import {debounce} from "lodash";


const VacancyDetailsComponent = (props) => {

    const api = useApi();
    const [summary, setSummary] = useState(null);
    const [showEndVacancy, setShowEndVacancy] = useState(false);
    const [showFeedback, setFeedback] = useState(false);
    const [reason, setReason] = useState('');
    const [candidates, setCandidates] = useState(null)
    const [candidatesList, setCandidatesList] = useState([])
    const [approved, setApproved] = useState([])
    const [approvedID, setApprovedID] = useState([])
    const [none, setNone] = useState(false)
    const [candidatesModal, setCandidatesModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const paginate = useRef({
        page: 1,
        status: ["pending"]
    });
    const nextPage = useRef(false);

    const getCandidates = useCallback(debounce(async (more, approv) => {
        more ? setLoadingMore(true) : setSearchLoading(true);

        if (more) {
            paginate.current.page = paginate.current.page + 1;
        }

        api.post(`sine/user/${props.user.user_id}/opportunity-interests/${props.vacancy.id}`, paginate.current, (res) => {
            res.next_page ? nextPage.current = true : nextPage.current = false;
            setCandidates(res.candidates)
            setSummary(res.summary);
            let auxArr = candidatesList

            if (more) {
                auxArr = auxArr.concat(res.candidates)
                // setCandidatesList(candidatesList.concat(res.candidates));
            } else {
                auxArr = res.candidates
                // setCandidatesList(res.candidates);
            }
            let approvedAux
            if(approv){
                approvedAux = approv
            }else{
                approvedAux = approved
            }

            for (let i = 0; i < approvedAux.length; i++) {
                for (let l = 0; l < auxArr.length; l++) {
                    if (auxArr[l].name === approvedAux[i].name) {
                        auxArr.splice(l, 1)
                    }
                }
            }

            setCandidatesList(auxArr)

            more ? setLoadingMore(false) : setSearchLoading(false);
        }, (e) => {
            more ? setLoadingMore(false) : setSearchLoading(false);
        });

    }, 400), []);


    const sendMultipleFeedback = async () => {
        if(approvedID.length > 0) {
            let objToSend = {};
            objToSend.last_event = 1;
            objToSend.interests_ids = approvedID;
            objToSend.date_admission =  moment().format('YYYY-MM-DD');
            objToSend.feedback_result = `Trabalhador Contratado`;
            message.loading('Enviando feedback');

            setLoading(true);
            api.post(`sine/user/${props.user.user_id}/interest-batch-status/${props.vacancy.id}`, objToSend, (res) => {
                setLoading(false);
                window.gtag&& window.gtag('event', 'endVacancyWithFeedBackSuccess');
                handleEndVacancy();
            }, (e) => {
                message.warning(e.message || "Não foi possível enviar o retorno dos candidatos");
                message.destroy();
                setLoading(false);
                window.gtag&& window.gtag('event', 'endVacancyWithMultipleFeedBackFail');
            });
        } else {
            handleEndVacancy();
        }
    }

    const handleEndVacancy = async () => {
        setLoading(true);
        api.post(`sine/user/${props.user.user_id}/close-opportunity/${props.vacancy.id}`, null,(res) => {
            message.success("Seu anúncio foi encerrado!");
            setShowEndVacancy(false);
            setCandidatesModal(false);
            setLoading(false);
            props.changeMenu('5');
            },(e) => {
            if(e.message === "Oportunidade já encerrada") {
                message.success("Seu anúncio foi encerrado!");
                setShowEndVacancy(false);
                setCandidatesModal(false);
                setLoading(false);
                props.changeMenu('5');
            } else {
                console.log(e);
                message.warning(e.message || "Não foi possível encerrar a publicação tente novamente");
                setLoading(false)
            }
        });
    };

    const showFeedbackModal = () => {
        setShowEndVacancy(false);
        setFeedback(true)
    }

    const onSearch = (searchText) => {
        // setCandidatesList(candidates.filter(i => i.name.toLowerCase().includes(searchText.toLowerCase())))
        paginate.current.page = 1;
        paginate.current.worker_name = searchText;
        getCandidates(false,approved);

    }

    const repopulate = () => {
        for (let i = 0; i < approved.length; i++) {
            setCandidatesList(candidatesList => [...candidatesList, approved[i]])
        }
        setApproved([])
    }

    const removeFromList = (item) => {
        setCandidatesList(candidatesList.filter(i => i.name !== item.name))
    }

    const addToList = (item) => {
        setCandidatesList(candidatesList => [...candidatesList, item])

    }

    useEffect(() => {
        window.scrollToElement('body');
        getCandidates()
    }, []);

    return (
        <div>
            <Modal visible={showEndVacancy} footer={null} onCancel={() => setShowEndVacancy(false)}>
                <div style={{textAlign: "center"}}>
                    <img style={{maxHeight: 200}} src={Images.endVacancy}/>

                    <h2> Tem certeza que deseja encerrar essa publicação?</h2>
                    <span> Ao encerrar uma publicação ela não será mais visível para os trabalhadores e não será mais possível aceitar candidatos </span>

                    <div style={{
                        paddingTop: 25,
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Button onClick={() => setShowEndVacancy(false)} type={"ghost"}
                                style={{marginRight: 10}}> Cancelar </Button>
                        <Button loading={loading} disabled={loading} onClick={() => showFeedbackModal()}
                                type={"primary"}> Encerrar Publicação </Button>
                    </div>
                </div>
            </Modal>

            <Modal visible={showFeedback} footer={null} onCancel={() => setFeedback(false)}>
                <div style={{textAlign: "center"}}>
                    <div>
                        <img style={{maxHeight: 200}} src={Images.deal2}/>
                        <h2>Conseguiu preencher sua vaga?</h2>
                        <span>Conseguiu contratar alguém através da nossa plataforma </span>

                        <div>
                            <div style={{
                                paddingTop: 25,
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <Button icon={'check'} onClick={() => setCandidatesModal(true)} type={"ghost"} style={{backgroundColor: "white", marginRight: 10,}}> Sim </Button>

                                <Button loading={loading} disabled={loading} icon={"cross"} onClick={() => handleEndVacancy()} type={"ghost"} style={{backgroundColor: "white", marginRight: 10,}}> Não </Button>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal bodyStyle={{padding: 0}} style={{top: 50}} visible={candidatesModal} footer={null} onCancel={() => setCandidatesModal(false)}>
                <div>
                    <div style={{
                        paddingTop: 25,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: 'column'
                    }}>
                        <div style={{paddingLeft: 20, paddingRight: 20, display: "flex",
                            justifyContent: "center",
                            flexDirection: 'column'}}>
                            <h3 style={{color: `#000000a6`, fontSize: 20}}>Se contratou pela nossa plataforma, nos informe quem contratou se já não tiver feito</h3>
                            <AutoComplete
                                options={candidatesList}
                                onSearch={onSearch}
                                style={{marginBottom: -8}}
                            >
                                <Input.Search style={{fontSize: 15, height: 37}} size="large" placeholder="Busque pelo nome..." />

                            </AutoComplete>

                            <div style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: 'row',
                                borderRadius: 5,
                                alignItems: "center",
                                marginBottom: 5,

                            }}>

                                <Checkbox style={{marginLeft: 2, fontSize: 16}} checked={none} onChange={(e) => {
                                    if (none) {
                                        setNone(false)
                                    } else {
                                        setNone(true)
                                        setApprovedID([])
                                        repopulate()
                                    }
                                }}/>
                                <div style={{
                                    textAlign: 'left',
                                    display: "flex",
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    color: `#a5a5a5`,
                                    padding: 10,
                                    paddingLeft: 5,
                                }}>
                                    <span>Nenhum destes listados abaixo</span>
                                </div>

                            </div>
                        </div>
                        <div style={{paddingLeft: 20, paddingRight: 20, maxHeight: window.innerHeight - 330, minHeight: 400, borderTop: `1px solid gainsboro`, paddingTop: 10, overflowY: "scroll"}}>
                            {
                                approved?.map((item, index) =>

                                    <div key={index} style={{flex: 1, display: "flex", flexDirection: 'row', border: '1px solid gainsboro', borderColor: "gainsboro", borderRadius: 5, alignItems: "center", marginBottom: 5}}>
                                        <Checkbox style={{marginLeft: 8,fontSize: 16}} checked={approvedID.includes(item.interest_id)} onChange={(e) => {
                                            if(approvedID.includes(item.interest_id)){
                                                setApprovedID(approvedID.filter(i => i !== item.interest_id))
                                                setApproved(approved.filter(i => i.name !== item.name))
                                                addToList(item)

                                            }else{
                                                setApprovedID(approvedID => [...approvedID, item.interest_id])
                                                setApproved(approved => [...approved, item])
                                                setNone(false)
                                                removeFromList(item)
                                            }

                                        }}/>
                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 5}}>
                                            <Avatar style={{width: 48, height: 48, padding: 10, backgroundColor: '#f5f5f5'}} size='default' src={Images.lettersPack[item.name[0].toLowerCase()] || Images.user_place_holder_2 }/>
                                            <div style={{ textAlign: 'left', display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: 10}}>
                                                <span>{item.name}</span>
                                                <span>{item.age} anos</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {searchLoading ?
                                <Loading/>
                                :
                                <>
                                    {
                                        candidatesList.map((item, index) =>
                                            item.age&& <div key={index} style={{flex: 1, display: "flex", flexDirection: 'row', border: '1px solid gainsboro', borderColor: "gainsboro", borderRadius: 5, alignItems: "center", marginBottom: 5}}>
                                                <Checkbox style={{marginLeft: 8,fontSize: 16}} checked={approvedID.includes(item.interest_id)} onChange={(e) => {
                                                    if(approvedID.includes(item.interest_id)){
                                                        setApprovedID(approvedID.filter(i => i !== item.interest_id))
                                                        setApproved(approved.filter(i => i.name !== item.name))
                                                        addToList(item)
                                                    }else{
                                                        setApprovedID(approvedID => [...approvedID, item.interest_id])
                                                        setApproved(approved => [...approved, item])
                                                        setNone(false)
                                                        removeFromList(item)
                                                    }

                                                }}/>
                                                <div style={{display: 'flex', alignItems: 'center', marginLeft: 5}}>
                                                    <Avatar style={{width: 48, height: 48, padding: 10, backgroundColor: '#f5f5f5'}} size='default' src={Images.lettersPack[item.name[0].toLowerCase()] || Images.user_place_holder_2 }/>
                                                    <div style={{ textAlign: 'left', display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: 10}}>
                                                        <span>{item.name}</span>
                                                        <span>{item.age} anos</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </>
                            }
                            {nextPage.current &&
                                <div className='loadMore' onClick={() => getCandidates(true)}> {loadingMore ?
                                    <Loading/> : "Ver Mais"} </div>}
                        </div>
                    </div>
                    <div style={{
                        paddingTop: 25,
                        paddingBottom: 25,
                        borderTop: "1px solid gainsboro",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Button onClick={() => setCandidatesModal(false)} type={"ghost"}
                                style={{marginRight: 10}}> Cancelar </Button>
                        <Button loading={loading} disabled={loading} onClick={() => sendMultipleFeedback()}
                                type={"primary"}> Encerrar Publicação </Button>
                    </div>
                </div>
            </Modal>
            {showEdit ?
                <VacancyFormComponent edit={true} changeMenu={(item) => props.changeMenu(item)} hideEdit={() => {
                    setShowEdit(false)
                }} vacancy={props.vacancy} enterprise={props.enterprise}/>
                :
                <div className={'mainComponentPanel'} style={{
                    boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`,
                    padding: 5,
                    borderRadius: 5,
                    backgroundColor: "white"
                }}>
                    <Row style={{borderBottom: "1px solid gainsboro", padding: 10}}>
                        <Col xs={24} style={{fontSize: 19, display: "flex"}}>
                            <Avatar style={{width: 45, height: 45, marginRight: 10}} size='default'
                                    src={props.vacancy.job_avatar || Images.user_place_holder}/>
                            <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                <span style={{fontSize: 16, color: "#757575"}}> Detalhes da divulgação </span>
                                <span>{props.vacancy.occupation_name}</span>
                            </div>
                            <div>
                                <div style={{textAlign: "right"}}>
                                    <span>#{props.vacancy.id}</span>
                                </div>
                                {!props.vacancy.closed && <div style={{display: 'flex'}}>
                                    <div onClick={() => setShowEdit(true)} style={{marginRight: 10}}
                                         className={"endVacancyButton"}>
                                        <FaPencilAlt style={{fontSize: 11, marginRight: 5}}/>
                                        <span> Editar </span>
                                    </div>
                                    <div onClick={() => setShowEndVacancy(true)} className={"endVacancyButton"}>
                                        <FaCheck style={{fontSize: 11, marginRight: 5}}/>
                                        <span> Encerrar Divulgação  </span>
                                    </div>
                                </div>}
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16, 32]} style={{margin: 0}}>
                        <Col xs={8}>
                            <div className="resumeCandidatesCard resumeBlue"
                                 onClick={() => props.changeMenu("subcandidates" + props.vacancy.id)}>
                                <FaUsers/>
                                <div>
                                    <span style={{fontSize: 15}}> Em Analise </span> <br/>
                                    <strong style={{fontSize: 20}}>{summary ? (summary.pending ? summary.pending : 0) :
                                        <Icon type={"loading"}/>}</strong>
                                </div>
                            </div>
                        </Col>
                        <Col xs={8}>
                            <div className="resumeCandidatesCard resumeGreen"
                                 onClick={() => props.changeMenu("subcandidates" + props.vacancy.id)}>
                                <FaUserCheck/>
                                <div>
                                    <span style={{fontSize: 15}}> Aprovados </span> <br/>
                                    <strong
                                        style={{fontSize: 20}}>{summary ? (summary.approved ? summary.approved : 0) :
                                        <Icon type={"loading"}/>} </strong>
                                </div>
                            </div>
                        </Col>
                        <Col xs={8}>
                            <div className="resumeCandidatesCard resumeRed"
                                 onClick={() => props.changeMenu("subcandidates" + props.vacancy.id)}>
                                <FaUserAltSlash/>
                                <div>
                                    <span style={{fontSize: 15}}> Reprovados </span> <br/>
                                    <strong
                                        style={{fontSize: 20}}>{summary ? (summary.rejected ? summary.rejected : 0) :
                                        <Icon type={"loading"}/>} </strong>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div style={{padding: 15, width: "100%"}}>
                        <div><span style={{fontSize: 19}}> Informações da Vaga </span> <Divider
                            style={{marginTop: 10}}/></div>

                        {props.vacancy.job_image &&
                        <div style={{width: "100%", marginBottom: 20, display: 'flex', justifyContent: 'center'}}>
                            <img src={props.vacancy.job_image}
                                 style={{maxWidth: "100%", borderRadius: 5, boxShadow: "1px 1px 5px 0px #75757540"}}/>
                        </div>}

                        {props.vacancy.job_video && <div className='iframeDiv'>
                            <iframe src={props.vacancy.job_video}
                                    allow='autoplay; encrypted-media'
                                    allowFullScreen
                                    title='video'
                            />
                        </div>}

                        {props.vacancy.closed_at &&
                        <Row gutter={[16, 32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <FaCalendarCheck style={{color: 'red'}}/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Encerrado em  </span>
                                    <span className='tagAnswer'
                                          style={{color: 'red'}}> {moment(props.vacancy.closed_at).format("DD/MM/YYYY")} </span>
                                </div>
                            </Col>
                        </Row>}

                        <Row gutter={[16, 32]} style={{margin: 0}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <FaIdCardAlt/>
                                <div style={{display: 'flex', flexFlow: 'column', width: "100%"}}>
                                    <span className='tagQuestion'> Cargo </span>
                                    <span style={{
                                        textOverflow: 'ellipsis',
                                        color: "#52c41a"
                                    }}> {props.vacancy.occupation_title ? props.vacancy.occupation_title : props.vacancy.occupation_name} </span>
                                    <div style={{color: "gray"}}>
                                        {props.vacancy.occupation_title && "(" + props.vacancy.occupation_name + ")"}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                <FaCodepen/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Tipo de cargo </span>
                                    <span className='tagAnswer'> {props.vacancy.job_type} </span>
                                </div>
                            </Col>

                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                <FaBriefcase/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Quantidade de vagas </span>
                                    <span className='tagAnswer'> {props.vacancy.job_openings} </span>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{margin: 0}}>
                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                <FaDollarSign/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Salário  </span>
                                    <span
                                        className='tagAnswer'> {props.vacancy.pay ? maskReal(props.vacancy.pay.toString()) : "Não informado"} </span>
                                </div>
                            </Col>

                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                <FaRegCalendarAlt/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Data da publicação </span>
                                    <span
                                        className='tagAnswer'> {moment(props.vacancy.start_date).format("DD/MM/YYYY")}</span>
                                </div>
                            </Col>
                        </Row>

                        {props.vacancy?.apply_message && <Row gutter={[16, 32]} style={{margin: 0}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <MdMore/>
                                <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Informação adicional da vaga </span>
                                    <span className='tagAnswer'> {props.vacancy?.apply_message} </span>
                                </div>
                            </Col>
                        </Row>}

                        <Row style={{backgroundColor: "#fafafad4", margin: 0}} gutter={[16, 32]}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <FaRegThumbsUp/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Benefícios  </span>
                                    <div style={{display: "flex", flexWrap: 'wrap'}}>
                                        {props.vacancy.incentives.map((item, index) =>
                                            <div key={index} style={{
                                                padding: 3,
                                                paddingRight: 8,
                                                paddingLeft: 8,
                                                backgroundColor: "#e7f3fb ",
                                                border: "1px solid #005c9f",
                                                color: "#005c9f",
                                                borderRadius: 5,
                                                margin: 5
                                            }}>
                                            <span>
                                                {item}
                                            </span>
                                            </div>
                                        )}
                                        {props.vacancy.incentives.length === 0 &&
                                        <div style={{
                                            padding: 4,
                                            paddingRight: 8,
                                            paddingLeft: 8,
                                            backgroundColor: "#d3d3d36e ",
                                            color: "#9e9e9e",
                                            borderRadius: 5,
                                            margin: 5
                                        }}>
                                            Nenhum beneficio informado
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{margin: 0}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <FaClock/>
                                <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Horário de Trabalho </span>
                                    <span
                                        className='tagAnswer'> {props.vacancy.schedule ? props.vacancy.schedule : "Não inserido"} </span>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{margin: 0}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <FaRegFileAlt/>
                                <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Descrição da vaga </span>
                                    <span
                                        className='tagAnswer'> {props.vacancy.job_description ? props.vacancy.job_description : "Nenhuma descrição inserida"} </span>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <FaRegMap/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Endereço a ser realizado </span>
                                        {props.vacancy.work_place === 'mobile'?
                                            <span> Trabalho Remoto / Home Office </span>
                                            :
                                            (props.vacancy.work_place === 'itinerant' ?
                                                <span> Sem endereço fixo </span>
                                                :
                                                (props.vacancy.work_address?
                                                    <div>
                                                        <span> <strong> Rua - </strong> {props.vacancy?.work_address.street} </span> <br/>
                                                        <span> <strong> Nº - </strong> {props.vacancy?.work_address.street_number} </span><br/>
                                                        <span> <strong> Bairro - </strong> {props.vacancy?.work_address.district} </span><br/>
                                                        <span> <strong> Cidade - </strong> {props.vacancy?.work_address.city} </span>
                                                    </div>
                                                    : null
                                                )
                                            )
                                        }
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div style={{padding: 15, width: "100%"}}>
                        <div><span style={{fontSize: 19}}> Informações de requisição aos candidatos </span> <Divider
                            style={{marginTop: 10}}/></div>

                        <Row style={{backgroundColor: "#fafafad4", margin: 0}} gutter={[16, 32]}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <FaTools/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Habilidades desejadas  </span>
                                    <div style={{display: "flex", flexWrap: 'wrap'}}>
                                        {props.vacancy.desired_skills?.map((item, index) =>
                                            <div key={index} style={{
                                                padding: 3,
                                                paddingRight: 8,
                                                paddingLeft: 8,
                                                backgroundColor: "#e7f3fb ",
                                                border: "1px solid #005c9f",
                                                color: "#005c9f",
                                                borderRadius: 5,
                                                margin: 5
                                            }}>
                                            <span>
                                                {item}
                                            </span>
                                            </div>
                                        )}
                                        {props.vacancy.desired_skills?.length === 0 &&
                                        <div style={{
                                            padding: 4,
                                            paddingRight: 8,
                                            paddingLeft: 8,
                                            backgroundColor: "#d3d3d36e ",
                                            color: "#9e9e9e",
                                            borderRadius: 5,
                                            margin: 5
                                        }}>
                                            Nenhum habilidade informada
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{margin: 0}}>
                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                <FaGraduationCap/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Escolaridade mínima </span>
                                    <span
                                        className='tagAnswer'> {props.vacancy.schooling ? props.vacancy.schooling : "Nenhuma"} </span>
                                </div>
                            </Col>

                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                <FaWheelchair/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Vaga destinada a PcD? </span>
                                    <span
                                        className='tagAnswer'> {props.vacancy.disabilities.length > 0 ? "Sim" : "Não"} </span>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{margin: 0, backgroundColor: "#fafafad4",}}>
                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                <FaLanguage/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'>Deve possuir fluência em outro Idioma?  </span>
                                    <div style={{display: "flex", flexWrap: "wrap"}}>
                                        {props.vacancy.languages.map((item, index) => <span key={index}
                                                                                            className='tagAnswer'> {item}{index === (props.vacancy.languages.length - 1) ? '.' : ','}</span>)}
                                        {props.vacancy.languages.length === 0 && "Não"}
                                    </div>
                                </div>
                            </Col>

                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                <FaRegAddressBook/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Deve possuir experiência no cargo </span>
                                    <span
                                        className='tagAnswer'> {props.vacancy.occupation_cbo_req ? "Sim" : "Não"}</span>
                                </div>
                            </Col>
                        </Row>


                        <Row gutter={[16, 32]} style={{margin: 0}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <Icon type='caret-right'/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Candidato deve ter disponibilidade para realizar viagens? </span>
                                    <span
                                        className='tagAnswer'> {props.vacancy.available_travel ? "Sim" : "Não"} </span>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{margin: 0, backgroundColor: "#fafafad4",}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <Icon type='caret-right'/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Candidato deve ter disponibilidade para dormir no local de trabalho? </span>
                                    <span
                                        className='tagAnswer'> {props.vacancy.available_sleep_duty ? "Sim" : "Não"} </span>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{margin: 0}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <Icon type='caret-right'/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Candidato deve ter disponibilidade para ausentar-se de seu domicílio por longos períodos?? </span>
                                    <span
                                        className='tagAnswer'> {props.vacancy.available_leave_home ? "Sim" : "Não"} </span>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} style={{margin: 0, backgroundColor: "#fafafad4",}}>
                            <Col xs={24} className='vacancyResumeTags'>
                                <Icon type='caret-right'/>
                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                    <span className='tagQuestion'> Permitir candidatos que não se encaixam nos requisitos a se candidatar a vaga? </span>
                                    <span className='tagAnswer'
                                          style={{color: props.vacancy.accept_proposal ? "green" : "red"}}> {props.vacancy.accept_proposal ? "Sim" : "Não"} </span>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>}
        </div>
    );
};

VacancyDetailsComponent.propTypes = {};

export default VacancyDetailsComponent;
