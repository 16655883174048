import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Col, Icon, List, Popover, Row, Skeleton, Spin} from "antd";
import Loading from "../../../Generic/Loading";
import {Images} from "../../../../Helpers/Images";
import {Env} from "../../../../Helpers/Env";

Spin.setDefaultIndicator(<Loading/>);

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}
const MainListComponent = (props) => {
    return (
        <Row style={{paddingTop: 15}}>
            <Col>
                {(props.resumesList.length > 0 || props.loading) &&<List
                    className='listAnt'
                    style={{overflow: 'auto', minHeight: '600px'}}
                    itemLayout="horizontal"
                    dataSource={props.resumesList}
                    loading={props.loading}
                    renderItem={(item,index) => (
                        <List.Item key={index} style={{padding: 20, position: 'relative', backgroundColor: 'white', opacity: item.has_feedback&& 0.7, borderLeft: item.has_feedback&& '2px solid limegreen'}} actions={[!item.has_feedback ?<Button onClick={() => props.showDrawer(item.id, !!item.name, index)} type="primary" icon="eye" size='default'> Visualizar </Button>:
                            <div style={{padding: 10, border: '2px solid limegreen', borderRadius: 5, borderRight: 0, borderLeft: 0}}> <span style={{fontSize: 15, color: 'limegreen'}}> Já selecionado </span></div>]}>
                            <Popover placement={"topLeft"} title={item.is_imported ? 'Curríco do SINE' : 'Currículo Contrata+'} content={item.is_imported ? 'Este currículo está na base do SINE, as informações dos currículos são simplificadas' : `Este é um currículo ${Env.application_title}`}>
                                <img style={{maxWidth: 35, maxHeight: 25, position: 'absolute', right: 5, top: 5 }} src={item.is_imported? Images.resume_search_list.iconImported : Images.resume_search_list.iconNotImported}/>
                            </Popover>
                            <List.Item.Meta
                                avatar={
                                    <Avatar style={{width: 64, height: 64}} size='default' src={item.profile_picture || Images.user_place_holder}/>
                                }
                                title={<span> <span style={{fontWeight: "bold"}}>{item.name? item.name : item.title}  </span> </span>}
                                description={<span>
                                    <span>
                                        {item.residence?
                                            <Popover placement={"topLeft"} title={'Residência'} content={'Este profissional reside nesta região'}>
                                                <span>
                                                    <Icon type='home'/> {window.getDistance(item.distance)} {item.age&& <span> - {item.age + ' Anos'} - {genders[item.gender]} </span>}
                                                </span>
                                            </Popover>
                                            :
                                            <Popover placement={"topLeft"} title={'Local de Interesse'} content={'Este profissional selecionou este lugar como um local de interesse para trabalhar'}>
                                                <span>
                                                    <Icon type='environment'/> Tem este local como interesse para trabalhar - {item.age + ' Anos'} - {genders[item.gender]}
                                                </span>
                                            </Popover>
                                        }
                                    </span>
                                    <br/>
                                    {item.description.replace(/\n+/g,' / ').replace('Disponibilidade - Indiferente', '')}</span> }
                            />
                        </List.Item>
                    )}
                /> }
            </Col>

            {props.nextPage&& <div className='loadMore' onClick={() => props.loadMore()}> Ver Mais </div>}
        </Row>
    );
};

MainListComponent.propTypes = {
    loading: PropTypes.any,
    resumesList: PropTypes.any,
    cboId: PropTypes.any,
    showDrawer: PropTypes.any
};

export default memo(MainListComponent, (prevProps, nextProps) => {
    if(prevProps.resumesList !== nextProps.resumesList) return false;
    if(prevProps.loading !== nextProps.loading) return false;
    return true
});
