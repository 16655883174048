import React, {useEffect, useState} from 'react'
import {Icon, Row, Col, Input} from "antd";
import {Fade, Slide} from "react-reveal";
import {Images} from "../../Helpers/Images";
const {Search} = Input

const GenericCommonQuestions = ({activeCard, titleActiveCard, handleBack}) => {

    const [openQuestions, setOpenQuestions] = useState([]);
    const [filterQuestions, setFilterQuestions] = useState([])
    const [textSearch, setTextSearch] = useState("")
    const [value, setValue] = useState(0);

    const [questionsProps, setQuestionsProps] = useState(activeCard)

    const handleClickQuestion = (index) => {
        let aux = openQuestions;
        let positionAlreadyOppened = openQuestions.indexOf(index);
        if (positionAlreadyOppened === -1) {
            aux.push(index);
        } else {
            aux.splice(positionAlreadyOppened, 1)
        }
        setOpenQuestions(aux);
        setValue(value + 1)
    }

    const handleClickSearch = (stringSearch) => {
        let convertLowerCase = stringSearch.toLowerCase()
        setTextSearch(convertLowerCase)

        let resultSearch = questionsProps.filter((str) => {
            return str.question.toLowerCase().includes(convertLowerCase)
        })
        setFilterQuestions(resultSearch)
    }

    useEffect(() => {
        window.scrollToElement('body');
    }, []);

    return (
        <Col xs={23} lg={22} xl={18} xxl={15} style={{textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems:"center",fontSize:15 ,marginBottom:32}}>
                    <Icon className="arrow-hover" type="arrow-left" onClick={() => {handleBack(false)}}/>
                    <div>
                        <a onClick={() => {handleBack(false)}}> Perguntas frequentes / </a>
                        <span style={{cursor:"default"}}>{titleActiveCard}</span>
                    </div>
                </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div>
                    <h1 style={{position: 'relative', fontSize: 24}} className={'title title-line'}> Dúvidas frequentes </h1>
                </div>
                <div style={{width: "100%", marginTop: 30}}>
                    <Search placeholder="O que está procurando?"
                            style={{width: "82%"}}
                            size={"large"}
                            onChange={(e) => {handleClickSearch(e.target.value)}}
                    />
                </div>
            </div>
            <div style={{display: "flex", margin: "32px auto", flexDirection: "column", width: "90%"}}>
                {textSearch === "" ?
                    <Slide left>
                        {questionsProps.map((questions, index) => {
                            return (
                                <div onClick={() => handleClickQuestion(index)} className="querys">
                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: 24, fontWeight: "400px", marginBottom: 16}}>
                                        <div style={{textAlign: "left"}}>{questions.question}</div>
                                    </div>
                                    {openQuestions.indexOf(index) !== -1 &&
                                        (
                                            <Fade delay={400}>
                                                      <span>
                                                          {questions.answer}
                                                      </span>
                                                <div style={{borderBottom: "4px solid #1680CE99", marginTop: "14px"}}/>
                                            </Fade>
                                        )}
                                </div>
                            )
                        })}
                    </Slide> :
                    <div>
                        {filterQuestions.length === 0 ?
                            <Slide right>
                                <div>
                                    <h2>Opsss, parece que o que você procura não foi encontrado, se preferir entre em
                                        contato conosco!</h2>
                                    <img style={{maxWidth: 300, marginTop: -32}} src={Images.notFoundSearch}/>
                                </div>
                            </Slide> :
                            <div>
                                {filterQuestions.map((questionFilter, index) => {
                                    return (
                                        <Row onClick={() => handleClickQuestion(index)} className="querys">
                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: 24, fontWeight: "400px", marginBottom: 16}}>
                                                <div style={{textAlign: "left"}}>{questionFilter.question}</div>
                                            </div>
                                            {openQuestions.indexOf(index) !== -1 &&
                                                (
                                                    <Fade delay={400}>
                                                        <span>{questionFilter.answer}</span>
                                                        <div style={{borderBottom: "4px solid #1680CE99", marginTop: "14px"}}/>
                                                    </Fade>
                                                )}
                                        </Row>
                                    )
                                })}
                            </div>

                        }
                    </div>
                }
            </div>
        </Col>
    )
}

export default GenericCommonQuestions