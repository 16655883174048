import React, {useEffect, useState} from 'react';
import Loading from "../Components/Generic/Loading";
import LoginForm from "../Components/PageComponents/Public/LoginPage/LoginForm";
import useApi from "../Hooks/Api";
import {message} from "antd";
import useAuth from "../Hooks/Auth";
import {useHistory} from "react-router-dom";
import ForgotForm from "../Components/PageComponents/Public/LoginPage/ForgotForm";
import {useDispatch} from "react-redux";
import {Env} from "../Helpers/Env";
import {Images} from "../Helpers/Images";


const LoginPageOld = props => {
    const history = useHistory();
    const [initialLoading, setInitialLoading] = useState(true);
    const api = useApi({initialLoading: false, customReturn: true});
    const {login} = useAuth();
    const [forgot, setForgot] = useState(false);
    const dispatch = useDispatch();

    const doAfterLogin = async (res) => {
        message.success({
            icon: <> </>,
            content: <div style={{display: 'flex', fontWeight: 'bold', flexFlow: 'column'}}>
                <img style={{height: 50, marginBottom: 10}} src={Images.logo_header}/> <span> Seja bem-vindo(a). </span>
            </div>
        });
        const obj = {
            token: res.api_key,
            user_id: res.user_id,
            email: res.profile.email,
            nickname: res.profile.nickname,
            avatar: res.profile.profile_picture?.thumbnail,
            addresses: res.addresses,
            name: (res.profile.first_name + ' ' + res.profile.last_name),
            last_profile: window.location.pathname === '/'? 'client' : 'worker'

        };
        login(obj);
        dispatch({type: 'init_login', data: obj});

        /*if (!res.crafty_club) {
            const res = await api.post(`crafty-club/register`, {});
        }*/
        history.push('/')
    }

    const doGoogleLogin = async (token) => {
        try {
            const res = await api.post(`access/login`, {google_token: token, password: null, is_worker: false});
            doAfterLogin(res);
        } catch (e) {
            message.error(e.message || 'Não foi possível efetuar o login com sua conta do google');
        }
    }

    const doFacebookLogin = async (token) => {
        try {
            const res = await api.post(`access/login`, {facebook_token: token, password: null, is_worker: false});
            doAfterLogin(res);
        } catch (e) {
            message.error(e.message || 'Não foi possível efetuar o login com sua conta do facebook');
        }
    }

    const doLogin = async (value,password,e) => {
        if(e) e.preventDefault();
        let md5 = require('md5');
        let md5Password = md5(password);
        try {
            let loginType = value.indexOf('@') > -1? 'email' : 'cpf';
            const res = await api.post(`access/login`, {[loginType]: loginType === 'cpf'? window.getNumbers(value): value, password: md5Password, is_worker: false});
            doAfterLogin(res);
        } catch(e) {
            message.error(e.message || 'Não foi possível efetuar o login, tente novamente');
        }

    };
    const doForgotPassword = async (cpf, email) => {
        if(cpf !== '' && email !== '') {
            api.post(`access/forgot-password-web`, {email: email, cpf: window.getNumbers(cpf), application_name: Env.application_name}, (res) => {
                message.success('As instruções de recuperação de senha foram enviadas para o seu Email!');
                setForgot(false);
            }, (e) => {
                message.error(e.message || 'Não foi possível recuperar sua senha, tente novamente');
            });
        }
    };

    useEffect(() => {
        window.scrollToElement('body');
        setTimeout(() => {
            setInitialLoading(false);
        },[1000])

    },[]);

    return (
        <div style={{display: 'flex', justifyContent: 'center', minHeight: 600}}>
            {initialLoading?
                <Loading/>
                :
                <ForgotForm loading={api.loading} setForgot={(item) => setForgot(item)} doForgotPassword={(cpf,email) => doForgotPassword(cpf,email)}/>
            }
        </div>
    );
};

LoginPageOld.propTypes = {};

export default LoginPageOld;
