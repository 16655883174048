import React, {useState} from "react";

import {Button, Carousel, Col, Row} from "antd";
import {GoChecklist} from "react-icons/go";
import {BsPersonLinesFill} from "react-icons/bs";
import {Images} from "../../../../Helpers/Images";
import RegisterPageModal from "../../../../Pages/RegisterPageModal";
import {Env} from "../../../../Helpers/Env";


const TopComponent = (props) => {
    const [registerModal, setRegisterModal] = useState(false);

    return <Row justify={'center'} style={{position: 'relative', display: 'flex', zIndex: 1}} className={'workerPageContent'}>
        <Carousel effect="fade" autoplay={true} autoplaySpeed={5000} className={'bannersImages hideMobile500'}>
            <div>
                <div style={{backgroundSize: 'cover', height: 360, width: '100%', position: 'relative', backgroundImage: `url(${Images.workerHomePage.worker_banner_1})`}}/>
            </div>
            <div>
                <div style={{backgroundSize: 'cover', height: 360, width: '100%', position: 'relative', backgroundImage: `url(${Images.workerHomePage.worker_banner_2})`}}/>
            </div>
            <div>
                <div style={{backgroundSize: 'cover', height: 360, width: '100%', position: 'relative', backgroundImage: `url(${Images.workerHomePage.worker_banner_3})`}}/>
            </div>
        </Carousel>
        <div style={{display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'stretch'}}>
            <div className={'bannerContent'}>
                <img style={{maxHeight: 100, margin: 'auto'}} src={Images.logo}/>
                <span className={'bigTitle'}> É prestador de serviço? </span>
                <span style={{fontSize: 16, marginBottom: 10}}> Baixe nosso APP {Env.application_title} para Trabalhadores e cadastre-se como prestador e aumente a visibilidade de seus serviços </span>
                <div className={'topComponentButtonDiv'}>
                    <Button onClick={() => window.redirectWorker(Env.worker_play)} className={'ant-btn-sub-primary'} style={{height: 45, borderRadius: 40, marginBottom: 10, marginRight: 5, boxShadow: '0 2px 0 rgba(0, 0, 0, 0.06)'}}>
                        <div>
                            <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.gplay_icon}/> Disponível no <strong> Google Play </strong>
                        </div>
                    </Button>

                    <Button onClick={() => window.redirectWorker(Env.worker_apple)} className={'ant-btn-sub-primary'} style={{height: 45, borderRadius: 40,  backgroundColor: '#8ccb57', marginLeft: 5, color: 'white', borderColor: '#8ccb57',  boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 3px 2px'}}>
                        <div>
                            <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.apple_icon}/> Disponível na <strong> App Store </strong>
                        </div>
                    </Button>
                </div>
            </div>
        </div>


        <RegisterPageModal profile={'worker'} visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />

    </Row>
};

export default TopComponent;
