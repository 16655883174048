import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Icon, Input, TreeSelect} from "antd";
import {Bounce} from "react-reveal";
import Select from "antd/es/select";
import Form from "antd/es/form";
import {useHistory} from "react-router-dom";

import {Images} from "../../../../Helpers/Images";

const SideBarFilter = (props) => {
    const history = useHistory();
    const {getFieldDecorator,getFieldError,setFieldsValue } = props.form;

    return (
        <>
        <div style={{minHeight: 300, paddingTop: 22, background: "white"}}  className='sidebarCol'>
            <label style={{fontSize: 16, fontWeight: 'bold'}}> Tem um nome em mente? </label>
            <Form.Item>
                {getFieldDecorator('company', {
                    initialValue: props.company&& props.company,
                    rules: []
                })( <Input style={{marginTop: 20, fontSize: 16, marginBottom: 8}} size={'large'} placeholder={'Nome'} suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: '19px' }} />}/>)}
            </Form.Item>

            <label style={{fontSize: 16, fontWeight: 'bold'}}> Que tipo de serviço está buscando? </label>
            <Form.Item required={true}  help={getFieldError('groups')}>
                {getFieldDecorator('groups', {
                    initialValue: (props.groups)&& props.groups,
                    rules: [
                        {},
                    ]
                })(<TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    dropdownStyle={{maxHeight: 400, overflow: 'auto' }}
                    placeholder="Selecione o Serviço Desejado"
                    allowClear
                    className='selectSubPage'
                    treeNodeFilterProp={'title'}
                    treeData={props.establishmentsTreeList}
                    searchPlaceholder='Digite o Serviço'
                />)}
            </Form.Item>

            <label style={{fontSize: 16, fontWeight: 'bold'}}> Filtrar </label>
            <Form.Item className='biggerSelect'>
                {getFieldDecorator('order', {
                    initialValue: props.order? props.order: 'distance',
                    rules: [],
                })(<Select
                    mode="default"
                    placeholder={"Selecione um filtro"}
                    className='selectSubPageFilter'
                    allowClear={true}
                    size={'large'}
                    style={{marginTop: 20, width: '100%' , fontSize: 16, marginBottom: 20}}
                >
                    {props.establishmentsOrders.map((item,index) =>
                        <Select.Option key={index} value={item.id}> <Icon style={{marginRight: 3}}/> {item.label}  </Select.Option>
                    )}

                  {/*  <Select.Option key={2}> <Icon type={'star'} style={{marginRight: 3}}/> Mais bem Avaliados </Select.Option>
                    <Select.Option key={3}> <Icon type={'environment'} style={{marginRight: 3}}/> Mais Proximos </Select.Option>
                    <Select.Option key={4}> <Icon type={'cloud'} style={{marginRight: 3}}/> Estabelecimentos Online </Select.Option>
                    <Select.Option key={5}> <Icon type={'thunderbolt'} style={{marginRight: 3}}/> Novos </Select.Option>*/}
                </Select>)}
            </Form.Item>

            <Button icon='search' className='searchButtonSubPage' onClick={() => props.searchAction()}> Buscar </Button>
        </div>
            <Bounce delay={4000} left>
                <Card
                    className={'sidebarCol'}
                    style={{textAlign: 'center', display: 'flex', marginTop: 18, border: 0}}
                    hoverable
                    onClick={() => history.push('/como-funciona/clube')}
                    cover={<div style={{height: '100%', display: 'flex'}}> <img style={{width: 70, paddingLeft: 8, margin: 'auto'}} alt="example" src={Images.club_img} /> </div>}
                >
                    <span style={{fontSize: 16}}>
                        Quer sua empresa na lista? <br/> <strong style={{color: 'limegreen'}}> Clique aqui! </strong>
                    </span>
                </Card>
            </Bounce>
    </>
    );
};

SideBarFilter.propTypes = {
    order: PropTypes.any,
    company: PropTypes.any,
    groups: PropTypes.any,
    searchAction: PropTypes.any,
    establishmentsTreeList: PropTypes.any,
    establishmentsOrders: PropTypes.any
};

export default SideBarFilter;
