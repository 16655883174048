const notificationsReducer = (state = 0, action) => {
    switch (action.type) {
        case 'init_count' :
            return action.data;
        case 'add_notification' :
            return state + 1;
        case 'remove_notification' :
            return state - 1;
        default :
            return state
    }
}

export default notificationsReducer;
