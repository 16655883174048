import React, {useEffect, useRef, useState} from 'react';
import {Affix, Col, Form, Row} from "antd";
import SideBarFilter from "../Components/PageComponents/Public/SearchEstablishmentPage/SideBarFilter";
import ListHeader from "../Components/PageComponents/Public/SearchEstablishmentPage/ListHeader";
import MainListComponent from "../Components/PageComponents/Public/SearchEstablishmentPage/MainListComponent";
import SearchEstablishmentsBanner from "../Components/PageComponents/Public/SearchEstablishmentPage/SearchEstablishmentsBanner";
import useApi from "../Hooks/Api";
import {useHistory} from "react-router-dom";

import useLocalStorage from "../Hooks/Storage";
import _ from "lodash";
import {StorageVariables} from "../Helpers/StoragesVariables";


const SearchEstablishmentPage = (props) => {
    const history = useHistory();
    const paginate = useRef({page: 1});
    const [loading, setLoading] = useState(false);
    const [establishmentsList, setEstablishmentsList] = useState([]);
    const [establishmentsTreeList, setEstablishmentsTreeList] = useState([]);
    const establishmentsOrderList = useRef([]);
    const storage = useLocalStorage(StorageVariables.geolocation);
    let filtersQuery = window.queryStringToObject();

    const api = useApi({customReturn: true, loadingControl: false});
    const {validateFields} = props.form;

    const getData = async () => {
        setLoading(true);
        const filters = {};
        if(storage.getObject().lat) filters.latitude = storage.getObject().lat; else return 0;
        if(storage.getObject().long) filters.longitude = storage.getObject().long; else return 0;
        if(filtersQuery.groups) filters.groups = [filtersQuery.groups];
        if(filtersQuery.company) filters.company = filtersQuery.company;
        if(filtersQuery.order) filters.order = filtersQuery.order;
        filters.page = paginate.current.page;

        const res = await api.post('crafty-club/search', filters);

        paginate.current = {nextPage: res.next_page, page: res.page};
        let aux;
        if (res.page === 2) {
            aux = establishmentsList.concat(res.companies);
        } else {
            aux = res.companies;
        }
        setEstablishmentsList(aux);
        setLoading(false);
    }

    const getCategories = async () => {
        const res = await api.get('crafty-club/search-filter');
        let treeData = [];
        res.groups.forEach((item) => {
            treeData.push({
                value: item.id,
                title: item.label,
            })
        });

        establishmentsOrderList.current = res.order;
        setEstablishmentsTreeList(treeData);
    };

    useEffect(() => {
        getCategories();
    },[]);


    useEffect(() => {
        if(window.location.search) {
            if (filtersQuery.lat && filtersQuery.long && filtersQuery.loc) {
                storage.setObject({lat: filtersQuery.lat, long: filtersQuery.long, loc: filtersQuery.loc, userLat: storage.getObject()?.userLat});
            }
            getData();
        } else if(storage.getObject()?.lat && storage.getObject()?.long) {
            getData();
        }
    }, [window.location.search]);

    const handleSearch = () => {
        validateFields(async (error, values) => {
            if (error) return;
            const objToFilter = {...values, ...storage.getObject()};
            filtersQuery = _.pickBy(objToFilter, _.identity);
            const urlParams = new URLSearchParams(filtersQuery);
            history.push(`/estabelecimentos/?${urlParams}`);
            getData();
        })
    };

    const loadMore = () => {
        paginate.current.page = paginate.current.page + 1;
        getData();
    };

    return (
        <div>
            <SearchEstablishmentsBanner/>
            <Row style={{display: 'flex', backgroundColor: 'white', justifyContent: 'center'}}>
                <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
                    <Row>
                        <Col  xs={0} sm={0} md={8} lg={6}>
                            <Affix offsetTop={70}>
                                <SideBarFilter order={filtersQuery.order} searchAction={() => handleSearch()} company={filtersQuery.company} groups={filtersQuery.groups} establishmentsOrders={establishmentsOrderList.current} establishmentsTreeList={establishmentsTreeList} form={props.form}/>
                            </Affix>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={18} style={{paddingLeft: 10}}>
                            <ListHeader company={filtersQuery.company} order={filtersQuery.order} location={storage.getObject()?.loc} groups={filtersQuery.groups} establishmentsOrders={establishmentsOrderList.current} establishmentsTreeList={establishmentsTreeList} form={props.form} searchAction={() => handleSearch()}/>
                            <MainListComponent loading={loading} establishments={establishmentsList}/>
                            {paginate.current.nextPage&& <div className='loadMore' onClick={() => loadMore()}> Ver Mais </div>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

SearchEstablishmentPage.propTypes = {};

export default Form.create({name: 'searchEstablishments'})(SearchEstablishmentPage);
