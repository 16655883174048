import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Badge, Button, Col, Divider, Dropdown, Icon, Layout, Menu, Popover, Row} from "antd";
import {useHistory} from "react-router-dom";

import useAuth from "../../../Hooks/Auth";
import Link from "react-router-dom/es/Link";
import useApi from "../../../Hooks/Api";
import {useDispatch, useSelector} from "react-redux";
import useVerifyServices from "../../../Hooks/VerifyServices";
import ProfilePage from "../../PageComponents/Restrict/ClientPanelPage/ProfilePage";
import RegisterPageModal from "../../../Pages/RegisterPageModal";
import {Images} from "../../../Helpers/Images";
import {FaBook, FaBriefcase, FaHome, FaSearch, FaTools} from "react-icons/fa";
import {MdDashboard} from "react-icons/md";
import {ImProfile} from "react-icons/im";
import {AiTwotoneShop} from "react-icons/ai";
import {GrUserWorker} from "react-icons/gr";
import {RiFileUserFill, RiFolderUserFill} from "react-icons/ri";
import RegisterEnterpriseModal from "../../../Pages/RegisterEnterpriseComponent";
import useLocalStorage from "../../../Hooks/Storage";
import {StorageVariables} from "../../../Helpers/StoragesVariables";

const Header = props => {
    const history = useHistory();
    const {isLogged, getUser, logoutWithoutApi} = useAuth();
    const api = useApi();
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);
    const profile = useSelector(state => state).authReducer;
    const notificationCount = useSelector(state => state).notificationsReducer;
    const dispatch = useDispatch();
    const setSidebarCollapsed = () => {
        props.setSidebarCollapsed(!props.sidebarCollapsed)
    };
    const chat = useSelector(state => state).chatNotificationsReducer;
    const preferences = useSelector(state => state).preferencesReducer;

    const [menuSelected, setMenuSelected] = useState('1');
    const {hasSineService, hasCraftyService, hasClubService,hasOnlySine,hasOnlyCrafty} = useVerifyServices();
    const [registerModal, setRegisterModal] = useState(false);

    useEffect(() => {
        if (window.location.pathname === '/') {
            setMenuSelected('1');
        } else if(window.location.pathname === '/painel/cliente/vagas' || window.location.pathname === '/painel/cliente/vagas/') {
            setMenuSelected('7')
        }  else if (window.location.pathname.includes('/painel')) {
            setMenuSelected('2')
        } else if (window.location.pathname.includes('/quero-contratar')) {
            setMenuSelected('3')
        } else if(window.location.pathname.includes('/estabelecimentos')) {
            setMenuSelected('4')
        } else if(window.location.pathname.includes('/curriculos')) {
            setMenuSelected('5')
        } else if(window.location.pathname.includes('/trabalhar')) {
            setMenuSelected('6')
        }  else if(window.location.pathname.includes('/vagas-de-emprego')) {
            setMenuSelected('7')
        } else if(window.location.pathname.includes('/buscar-cursos')) {
            setMenuSelected('8')
        } else {
            setMenuSelected('')
        }
    },[window.location.pathname]);

    return (
        <Layout.Header className='headerMain' style={{ background: '#fff', padding: 0 }}>
            <Row className='headerPage' style={{display: 'flex', justifyContent: 'center'}}>
                <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17} className='right-side-col'>
                    <div>
                        <div className='logo' style={{padding: 1, paddingLeft: 5, paddingRight: 10}} onClick={() => history.push('/')}>
                            <img src={Images.logo_header} />
                        </div>
                        <div className='navigation hideMobile998'>
                            <Menu
                                selectedKeys={[menuSelected]}
                                mode="horizontal"
                                style={{ lineHeight: '64px', display: 'inline-block' }}
                            >
                                {!isLogged() &&<Menu.Item key="1" onClick={() =>{ history.push('/'); setMenuSelected('1')}} > <FaHome className={'exportedIconsLeft'}/> Home </Menu.Item>}
                                {isLogged() &&<Menu.Item key="2" onClick={() =>{ history.push('/painel'); setMenuSelected('2')}} > <MdDashboard className={'exportedIconsLeft'} /> Painel </Menu.Item>}
                                {(hasCraftyService()  && preferences?.panel_profile !== 'worker') && <Menu.Item key="3" onClick={() => {history.push('/quero-contratar'); setMenuSelected('3')}}> <FaTools className={'exportedIconsLeft'}/> <span> Buscar</span>  </Menu.Item>}
                                <Menu.Item key="6" onClick={() => {history.push('/trabalhar'); setMenuSelected('6')}}> <FaBriefcase className={'exportedIconsLeft'}/> <span> Quero Trabalhar</span>  </Menu.Item>
                                {hasClubService()   && <Menu.Item key="4" onClick={() => {history.push('/estabelecimentos'); setMenuSelected('4')}}> <AiTwotoneShop className={'exportedIconsLeft'}/> Buscar Estabelecimentos </Menu.Item>}
                            </Menu>
                        </div>
                        {profile !== null ?
                            <div className='right-side'>
                                <div className='header-widget hideMobile500'>
                                    {/*<Popover placement={"bottom"} content={'Perguntas Frequentes'}>
                                        <div style={{cursor: 'pointer'}} className='header-notifications' onClick={() => {history.push('/ajuda')}}>
                                           <Icon type='question-circle'/>
                                        </div>
                                    </Popover>*/}

                                    <div className='header-notifications' style={{cursor: "pointer"}}>
                                        <Link className={'iconHeader'} to={'/notificacoes'}> <Badge style={{fontSize: 10, height: 18}} count={notificationCount}>
                                            <Icon type='bell'/>
                                        </Badge></Link>
                                    </div>

                                </div>
                                <div className='header-user-widget hideMobile500'>
                                    <Dropdown  overlay={
                                        <div style={{display: 'flex',flexFlow: 'column', width: 250, borderRadius: 5, backgroundColor: 'white', padding: 20, boxShadow: '0 2px 12px 0 rgba(0,0,0,.12)'}}>
                                            <div style={{display: 'flex'}}>
                                                <Badge offset={[-15,37]} style={{height: 9, width: 9}} status="success">
                                                    <img className='user-avatar' height={42} width={42} style={{backgroundSize: 'cover', borderRadius:21, marginRight: 10, backgroundImage: profile?.avatar? `url(${profile?.avatar})` : `url(${Images.user_place_holder})`}}/>
                                                </Badge>
                                                <div style={{display: 'flex', flexFlow: 'column', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    <span style={{fontSize: 16, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>  {profile?.name} </span>
                                                    <span style={{fontSize: 14, marginLeft: 2, color: '#888', overflow: 'hidden', textOverflow: 'ellipsis'}}> {profile?.email} </span>
                                                </div>
                                            </div>
                                            <Divider style={{marginBottom: 10, marginTop: 20}}/>
                                            <Menu style={{borderRight: 0, width: 'fit-content', alignSelf: 'center'}} >
                                                {hasClubService() && <Menu.Item  key="3" style={{padding: 0, margin: 0, marginBottom: 15, background: 'white', height: 30}} >
                                                    <Link to={'/painel/cartoes'} style={{fontSize: 15}}>
                                                        <Icon type={'credit-card'}/> Meus cupoms
                                                    </Link>
                                                </Menu.Item>}
                                                {hasClubService() && <Menu.Item  key="3" style={{padding: 0, margin: 0, marginBottom: 15, background: 'white', height: 30}} >
                                                    <Link to={'/painel/compras'} style={{fontSize: 15}}>
                                                        <Icon type={'shop'}/> Minhas compras
                                                    </Link>
                                                </Menu.Item>}
                                                <Menu.Item  key="3" style={{padding: 0, margin: 0, marginBottom: 15, background: 'white', height: 30}} >
                                                    <Link to={'/painel'} style={{fontSize: 15}}>
                                                        <Icon type={'shop'}/> Painel
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="5" onClick={() => {logoutWithoutApi(); dispatch({type: 'logout'});}} style={{padding: 0, textAlign: 'center', margin: 0, marginTop: 50, background: 'white'}}>
                                                    <Button className={'ant-btn-primary2'}>
                                                        <Icon style={{marginRight: 5}} type={'poweroff'}/> <span> Sair </span>
                                                    </Button>
                                                </Menu.Item>
                                            </Menu>
                                        </div>
                                    }>
                                        <Badge offset={[-8,50]} style={{height: 9, width: 9}} status="success"><img className='user-avatar'
                                             style={{backgroundSize: 'cover', backgroundImage: profile?.avatar? `url(${profile?.avatar})` : `url(${Images.user_place_holder})`}}/>
                                        </Badge>
                                    </Dropdown>
                                </div>
                                <div className='menu-icon showMobile998'>
                                    {!!props.setSidebarCollapsed &&
                                    <Icon
                                        className="sidebar-trigger"
                                        type={props.sidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
                                        onClick={setSidebarCollapsed}
                                    />
                                    }
                                </div>
                            </div>
                            :
                            <div className='right-side ' style={{display: 'flex', alignItems: 'center'}}>

                                {/*<div className='header-widget hideMobile500' style={{borderRight: '1px solid #e0e0e0'}}>
                                    <Popover placement={"bottom"} content={'Perguntas Frequentes'}>
                                        <div style={{cursor: 'pointer'}} className='header-notifications' onClick={() => {history.push('/ajuda')}}>
                                            <Icon type='question-circle'/>
                                        </div>
                                    </Popover>
                                </div>*/}
                                <div className={'headerAuthButtons'}>
                                    <Button type={"primary"} onClick={() => setRegisterModal('login')}  style={{borderRadius: 3, marginRight: 20}}> Entrar </Button>
                                    <Button className={'ant-btn-primary2 hideMobile500'} onClick={() => setRegisterModal('login')} style={{borderRadius: 3, color: 'white'}}> Cadastre-se </Button>
                                </div>
                                <div className='menu-icon showMobile998'>
                                    {!!props.setSidebarCollapsed &&
                                    <Icon
                                        className="sidebar-trigger"
                                        type={props.sidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
                                        onClick={setSidebarCollapsed}
                                    />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            <RegisterPageModal visible={registerModal !== false} type={registerModal} profile={'client'} setVisible={() => setRegisterModal(false)} />
        </Layout.Header>
    );
};

Header.propTypes = {
    sidebarCollapsed: PropTypes.bool,
    setSidebarCollapsed: PropTypes.func,
    paddingLogo: PropTypes.any
};

export default Header;
