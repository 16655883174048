import React, {useRef, useState} from 'react';
import {Button, Col, Icon, Layout, Row} from 'antd';
import {useHistory} from "react-router-dom";

import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";
import Slider from "react-slick";
import {Fade} from "react-reveal";

const ServiceProvidersCard = () => {
    const history = useHistory();

    const NextArrow = ({onClick}) => {
        return (
            <div title="Próximo" style={{width:32, height:32, display:"flex", alignItems:"center", justifyContent: "center"}} className="arrowServiceProvider next" onClick={onClick}>
                <Icon style={{fontSize:16}} type="arrow-right" />
            </div>
        )
    }
    const PrevArrow = ({onClick}) => {
        return (
            <div title="Anterior" style={{width:32, height:32, display:"flex", alignItems:"center", justifyContent: "center"}} className="arrowServiceProvider prev" onClick={onClick}>
                <Icon style={{fontSize:16}} type="arrow-left" />
            </div>
        )
    }
    const settings = {
        responsive: [

            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    pauseOnHover: true,
                    nextArrow: <NextArrow/>,
                    prevArrow: <PrevArrow/>,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    pauseOnHover: true,
                    nextArrow: <NextArrow/>,
                    prevArrow: <PrevArrow/>,
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    infinite: true,
                    lazyLoad: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    nextArrow: <NextArrow/>,
                    prevArrow: <PrevArrow/>,
                }
            },
            {
                breakpoint: 2561,
                settings: {
                    infinite: true,
                    lazyLoad: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    nextArrow: <NextArrow/>,
                    prevArrow: <PrevArrow/>,
                }
            },


        ]
    }
    const [controlTextCard, setControlTextCard] = useState({
        card01: {
            status: "closed",
        },
        card02: {
            status: "closed",
        },
        card03: {
            status: "closed",
        },
        card04: {
            status: "closed",
        },
        card05: {
            status: "closed",
        },
    })
    const handleCardClick = (label) => {
        if (controlTextCard[label].status === 'closed') {
            setControlTextCard({...controlTextCard, [label]: {status: "show"}})
        } else if (controlTextCard[label].status === 'show') {
            setControlTextCard({...controlTextCard, [label]: {status: "closed"}})
        }
    }

    return (
        <div>
            <div style={{display: "flex", flexDirection: "column", marginTop: 50}}>
                <Col style={{textAlign: "center", marginTop: 32}}>
                    <Row style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <Col sm={20} lg={20} xl={19} xxl={16} className='headerHowTo'
                             style={{padding: 5, textAlign: 'center'}}>
                            <img style={{filter: 'brightness(0.2)', width: 50}} src={Images.home.service_icon}/>
                            <h1 style={{position: 'relative', marginTop: 30}}
                                className={'title title-line'}> {Texts.howItWorksCrafty.mainTitle} </h1>
                            <Fade delay={600}>{Texts.howItWorksCrafty.mainText} </Fade>
                        </Col>
                    </Row>
                </Col>
                <div className={"customSlick"}>

                    <Slider {...settings} style={{margin: "0 -10px"}}>
                            <div className='serviceProviderCard' onClick={() => handleCardClick('card01')}>
                                <div>
                                    <img className="serviceProviderImg" src={Images.bestPlataform}/>
                                </div>
                                <div className="serviceProviderText">
                                    <h2 style={{textAlign: "center"}}>
                                        <span style={{fontWeight: 700, fontSize: 26, color: "#68BB23FF"}}>01.</span> Escolha a melhor plataforma para você
                                    </h2>
                                </div>
                                {controlTextCard.card01.status === "closed" ?
                                    <div style={{display:"flex", flexDirection: "column", cursor:"pointer"}}>
                                        <div style={{borderBottom: "1px solid", color:"gainsboro"}}/>
                                        <div>

                                        <div style={{display:"flex", justifyContent:"center", padding: 5, alignItems:"center"}}>
                                            <Icon type="down" style={{color:"gainsboro"}} />
                                            <span style={{color:"gainsboro", right:0, fontSize:12}}>Saiba mais</span>
                                        </div>
                                        </div>
                                    </div> :
                                    <>
                                    <div className="serviceProviderText" style={{marginTop:-32}}>
                                        <Fade delay={300}>
                                            {Texts.home_page.register.service_providers_card01}
                                        </Fade>
                                    </div>
                                        <div style={{position:"relative"}}>
                                            <div style={{display:"flex", flexDirection: "column", position:"absolute", cursor:"pointer", marginBottom:6, bottom:0}}>
                                                <div style={{borderBottom: "1px solid", color:"gainsboro"}}/>
                                                <div style={{display:"flex", justifyContent:"center"}}>
                                                    <Icon type="up" style={{marginTop:6, color:"gainsboro"}} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                   }
                            </div>

                        <div className='serviceProviderCard' onClick={() => handleCardClick('card02')}>
                            <div>
                                <img className="serviceProviderImg" src={Images.searchService}/>
                            </div>
                            <div className="serviceProviderText">
                                <h2 style={{textAlign: "center"}}>
                                    <span style={{fontWeight: 700, fontSize: 26, color: "#68BB23FF"}}>02.</span> Busque pelo prestador que deseja
                                </h2>
                            </div>
                            {controlTextCard.card02.status === "closed" ?
                                <div style={{display:"flex", flexDirection: "column", position:"relative", cursor:"pointer"}}>
                                    <div style={{borderBottom: "1px solid", color:"gainsboro"}}/>
                                    <div style={{display:"flex", justifyContent:"center", alignItems:"center",  padding: 5,}}>
                                        <Icon type="down" style={{color:"gainsboro"}} />
                                        <span style={{color:"gainsboro", fontSize:12}}>Saiba mais</span>
                                    </div>
                                </div> :
                                <>
                                    <div className="serviceProviderText">
                                        <Fade delay={300}>
                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                {Texts.home_page.register.service_providers_card02}
                                                <Button onClick={() => history.push('/curriculos')}
                                                        className={'ant-btn-green'} style={{display: "flex", alignItems: "center", margin: "auto", marginTop:16}}>
                                                    <Icon type='search' style={{margin: 0}}/>
                                                    <span style={{color: "white"}}>Buscar</span>
                                                </Button>
                                            </div>
                                        </Fade>
                                    </div>
                                    <div>

                                    </div>
                                    <div style={{display:"flex", flexDirection: "column", position:"relative", cursor:"pointer", marginBottom:6}}>
                                        <div style={{borderBottom: "1px solid", color:"gainsboro"}}/>
                                        <div style={{display:"flex", justifyContent:"center"}}>
                                            <Icon type="up" style={{marginTop:6, color:"gainsboro"}} />
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                        <div className='serviceProviderCard' onClick={() => handleCardClick('card03')}>
                            <div>
                                <img className="serviceProviderImg" src={Images.matchService}/>
                            </div>
                            <div className="serviceProviderText">
                                <h2 style={{textAlign: "center"}}>
                                    <span style={{fontWeight: 700, fontSize: 26, color: "#68BB23FF"}}>03.</span> Combine os detalhes com o prestador
                                </h2>
                            </div>
                            {controlTextCard.card03.status === "closed" ?
                                <div style={{display:"flex", flexDirection: "column", position:"relative", cursor:"pointer"}}>
                                    <div style={{borderBottom: "1px solid", color:"gainsboro"}}/>
                                    <div style={{display:"flex",  padding: 5, justifyContent:"center", alignItems:"center"}}>
                                        <Icon type="down" style={{ color:"gainsboro"}} />
                                        <span style={{color:"gainsboro", fontSize:12}}>Saiba mais</span>
                                    </div>
                                </div> :
                                <>
                                    <div className="serviceProviderText">
                                        <Fade delay={300}>
                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                {Texts.home_page.register.service_providers_card03}
                                            </div>
                                        </Fade>
                                    </div>
                                    <div>

                                    </div>
                                    <div style={{display:"flex", flexDirection: "column", position:"relative", cursor:"pointer", marginBottom:6}}>
                                        <div style={{borderBottom: "1px solid", color:"gainsboro"}}/>
                                        <div style={{display:"flex", justifyContent:"center"}}>
                                            <Icon type="up" style={{marginTop:6, color:"gainsboro"}} />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        <div className='serviceProviderCard' onClick={() => handleCardClick('card04')}>
                            <div>
                                <img className="serviceProviderImg" style={{objectPosition: "top"}}
                                     src={Images.feedbackService}/>
                            </div>
                            <div className="serviceProviderText">
                                <h2 style={{textAlign: "center"}}>
                                    <span style={{fontWeight: 700, fontSize: 26, color: "#68BB23FF"}}>04.</span> Não se esqueça de avaliar!
                                </h2>
                            </div>
                            {controlTextCard.card04.status === "closed" ?
                                <div style={{display:"flex", flexDirection: "column", position:"relative", cursor:"pointer"}}>
                                    <div style={{borderBottom: "1px solid", color:"gainsboro"}}/>
                                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <Icon type="down" style={{marginTop:6, color:"gainsboro"}} />
                                        <span style={{marginTop:6, color:"gainsboro", right:0, fontSize:12}}>Saiba mais</span>
                                    </div>
                                </div> :
                                <>
                                    <div className="serviceProviderText">
                                        <Fade delay={300}>
                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                {Texts.home_page.register.service_providers_card05}
                                            </div>
                                        </Fade>
                                    </div>
                                    <div>

                                    </div>
                                    <div style={{display:"flex", flexDirection: "column", position:"relative", cursor:"pointer", marginBottom:6}}>
                                        <div style={{borderBottom: "1px solid", color:"gainsboro"}}/>
                                        <div style={{display:"flex", justifyContent:"center"}}>
                                            <Icon type="up" style={{marginTop:6, color:"gainsboro"}} />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </Slider>
                </div>
                                {/*{controlTextCard.card04.status === "closed" ?*/}
                                {/*    <div>*/}
                                {/*        <Fade delay={300}>*/}
                                {/*            <Button onClick={() => handleCardClick('card04')}*/}
                                {/*                    style={{display: "flex", alignItems: "center", margin: "auto"}} className={'ant-btn-green'}>*/}
                                {/*                <span style={{color: "white"}}>Mostrar detalhes</span>*/}
                                {/*            </Button>*/}
                                {/*        </Fade>*/}
                                {/*    </div> :*/}
                                {/*    <Fade delay={300}>*/}
                                {/*        <div style={{display:"flex", flexDirection: "column"}}>{Texts.home_page.register.service_providers_card05}</div>*/}
                                {/*        <Button onClick={() => handleCardClick('card04')}*/}
                                {/*                style={{display: "flex", alignItems: "center", margin: "auto", marginTop: 32}} className={'ant-btn-green'}>*/}
                                {/*            <span style={{color: "white"}}>Ocultar detalhes</span>*/}
                                {/*        </Button>*/}
                                {/*    </Fade>*/}
                                {/*}*/}

            </div>
        </div>
    )
}
export default ServiceProvidersCard