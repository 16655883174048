import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Col, Rate, Row} from "antd";


const ServiceProviderHeader = (props) => {

    return (
        <div style={{height: 200, display: 'flex', marginBottom: 50}}>
            <div className='transparentBanner transparentBanner2' style={{height: 200}}/>
            <div className='serviceProviderHomeBanner' style={{backgroundImage: `url(${props.headerImg})`, backgroundPosition: 'center'}}/>
            <div/>
            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '150', flex: '1'}}>
                <div style={{textAlign: 'center', marginRight: '40px', alignItems: 'center'}}>
                    <Avatar style={{width: 130, height: 130}} size='default' src={props.avatar}/>
                </div>
                <div style={{flex: 1}}>
                    <div>
                        <span style={{fontSize: '25px', color: '#333'}}> {props.name} </span>
                    </div>
                    <div>
                        <span style={{fontSize: '20px', color: 'gray'}}> {props.actualOccupation.name}  </span>
                    </div>
                    <div>
                        {props.actualOccupation.price_avg !== 0 &&<span><strong style={{color: 'limegreen'}}> {props.actualOccupation.price_avg <= 2? '$' :(props.actualOccupation.price_avg <= 3)? '$$' :(props.actualOccupation.price_avg <= 4)? '$$$': (props.actualOccupation.price_avg <= 5)&& '$$$$'} </strong>  <strong style={{marginRight: 5, marginLeft: 5}}> / </strong>  </span> }
                        <span>
                            <Rate disabled allowHalf={true} defaultValue={props.actualOccupation.ratings_avg} /> ({props.actualOccupation.ratings_count})
                        </span>
                    </div>
                </div>
            </Row>
        </div>
    );
};

ServiceProviderHeader.propTypes = {
    userRatingCount: PropTypes.any,
    userRating: PropTypes.any,
    name: PropTypes.any,
    avatar: PropTypes.any,
    actualOccupation: PropTypes.any,
    price: PropTypes.any
};

export default ServiceProviderHeader;
