import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Col, DatePicker, Form, Input, List, message, Modal, Popover, Radio, Row, Select, Tag} from "antd";
import useApi from "../../../../Hooks/Api";
import {useValidationShorts} from "../../../../Hooks/Helpers";
import moment from "moment";
import {Images} from "../../../../Helpers/Images";
import locale from 'antd/es/date-picker/locale/pt_BR';

const feedbackType = [
    {type: 'entrevista1', value: 'Tentou contatar o trabalhador, mas não conseguiu realizar o contato'},
    {type: 'contrato1', value: 'O trabalhador não foi contratado porque recusou a vaga de emprego ofertada'},
    {type: 'contrato2', value: 'O trabalhador não foi contratado porque não atendeu aos requisitos de qualificação da vaga de emprego'},
    {type: 'contrato3', value: 'O trabalhador não foi contratado por outro motivo:'},
    {type: 'contrato4', value: 'O trabalhador foi contratado'},
];

const newFeedbackType = [
    {type: 1, value: 'Trabalhador Contratado'},
    {type: 2, value: 'Não foi contatado', label: 'Não entrei em contato'},
    {type: 7, value: 'Não foi contratado, vaga preenchida de outra forma'},
    {type: 8, value: 'Não foi contratado, falta de interesse do trabalhador em ocupar o cargo'},
    {type: 11, value: 'Não foi contratado. Não possui as qualificações exigidas para a ocupação'},
]
const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}

const FeedbackForm = props => {
    const {getFieldDecorator, getFieldError, getFieldsValue, setFieldsValue, validateFields} = props.form;
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(undefined)

    const sendFeedback = async () => {
        validateFields(async (err, values) => {
            if(err) return 0;
            if(!selectedValue) {
                message.warning('Escolha um tipo de retorno');
                return 0;
            }
            message.loading('Enviando feedback');
            if (values.date_admission) {
                values.date_admission =  moment(values.date_admission, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }

            let apiUrl = '';
            setLoading(true);
            values.last_event = selectedValue;
            newFeedbackType.forEach((item) => {
                if (item.type === selectedValue) values.feedback_result = item.value;
            })

            if(props.vacancyFeedback) {
                apiUrl = `sine/user/${props.id}/interest-status/${props.profile.interest_id}`;
            } else {
                apiUrl = `sine/contact/${props.id}/send-feedback`;
            }
            api.post(apiUrl, values, (res) => {
                message.destroy();
                message.success('Seu feedback foi enviado! ');
                setLoading(false);
                props.setShowFeedback(false);
                window.gtag&& window.gtag('event', 'feedBackSuccess');
                props.getData();
            }, (e) => {
                message.destroy();
                if(e.message === 'Request failed') {
                    message.error('Não foi possível enviar esse feedback para o SINE, tente novamente.');
                } else if(e.message === 'Você alcançou o limite de recrutamentos sem retorno. Conclua seus recrutamentos pendentes para voltar a consultar.') {
                    message.error('Você alcançou o limite de recrutamentos sem retorno. Envie o feedback dos currículos em GERENCIAR VAGAS para continuar consultando.');
                } else {
                    message.error(e.message || 'Não foi possivel enviar o feedback deste profissional');
                }
                setLoading(false);
                window.gtag&& window.gtag('event', 'feedBackFail');
                props.setShowFeedback(false);
            });
        })
    }

    const {required} = useValidationShorts();

    return (
        <Modal bodyStyle={{paddingBottom: 5}} className={'feedbackModal'} visible={props.showFeedback} title={'Enviar Retorno'} cancelText={'Fechar'}  confirmLoading={loading} onOk={() => sendFeedback()} onCancel={() => props.setShowFeedback(false)} okText={'Enviar Retorno'}>
            <React.Fragment>
                <Row style={{ marginBottom: 24}}>
                    <Col span={24} style={{alignItems: 'center', cursor: 'pointer', position: 'relative', display: 'flex'}}>
                        <Avatar style={{minWidth: 64, height: 64, padding: 10, backgroundColor: '#f5f5f5'}} size='default' src={props.profile.name? Images.lettersPack[props.profile.name[0].toLowerCase()] : Images.user_place_holder_2 }/>

                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                            <span style={{fontWeight: "bold"}}>{props.profile?.name}  </span>
                            <div>
                                {props.profile?.age && <span style={{color: "darkgray", fontSize: 13, fontWeight: 400}}> {genders[props.profile?.gender]} - {props.profile?.age + ' Anos'} </span>}

                            </div>
                            {props.profile?.occupation && <span> <img src={Images.resume?.job_case} style={{marginTop: -4}} height={15} width={15}/> {props.profile?.occupation?.name || props.profile?.occupation} </span>}
                        </div>
                    </Col>
                </Row>

                <Form>
                    <div style={{marginBottom: 10}}>
                        <h3>Informe a situação do currículo</h3>
                        <div style={{display: 'flex', flexDirection: 'row', flexWrap : 'wrap'}}>
                            {newFeedbackType.map((item, index) =>
                                <div style={{padding: 5,cursor: 'pointer', fontWeight: selectedValue === item.type ? 'bold' : 'normal', backgroundColor: selectedValue === item.type ? 'aliceblue' : 'white', color: selectedValue === item.type ? '#005c9f' : '#757575', borderRadius: 5, border: selectedValue === item.type ?  '1px solid #005c9f' : '1px solid gainsboro' , margin: 5, paddingRight: 10, paddingLeft: 10}} key={index}
                                     onClick={() => {setSelectedValue(item.type)}}>{item.label || item.value}
                                </div>
                            )}
                        </div>
                    </div>

                    {selectedValue === 11 &&
                    <Form.Item required={false} style={{padding: 5}} label="Selecione a qualificação que não foi atendida">
                        {getFieldDecorator('qualifications', {
                            rules: [required()],
                        })(
                            <Select placeholder="Selecione aqui">
                                <Select.Option value={'Capacitação'}>Capacitação</Select.Option>
                                <Select.Option value={'Distância entre residência e local de trabalho'}>Distância entre residência e local de trabalho</Select.Option>
                                <Select.Option value={'Apresentação pessoal'}>Apresentação pessoal</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    }

                    {selectedValue === 1 &&
                    <Form.Item style={{padding: 5}} label="Data de admissão" required={false}>
                        {getFieldDecorator('date_admission', {
                            rules: [required()],
                        })(
                            <DatePicker locale={locale} style={{width: "100%"}} format={"DD/MM/YYYY"} />
                        )}
                    </Form.Item>
                    }

                    {(selectedValue && selectedValue !== 1) &&
                    <Form.Item style={{padding: 5}} label="Descreva com mais detalhes o motivo se necessário">
                        {getFieldDecorator('observation',{

                        })(
                            <Input.TextArea rows={3} />
                        )}
                    </Form.Item>}

                    <div style={{textAlign: 'right', marginTop: 20, fontSize: 12}}><span> Os detalhes das informações não serão disponibilizadas ao dono do currículo. Apenas se ele foi reprovado ou aprovado.</span></div>
                </Form>

            </React.Fragment>
        </Modal>

    );
};

FeedbackForm.propTypes = {
    user: PropTypes.any,
    handleSave: PropTypes.any,
    addressToEdit: PropTypes.any,
    showFeedback: PropTypes.any,
    setShowFeedback: PropTypes.any
};

export default Form.create({name: 'feedbackForm'})(FeedbackForm);
