const preferencesReducer = (state = {panel_profile: undefined}, action) => {
    switch (action.type) {
        case 'panel_profile' :
            return {...state, panel_profile: action.data};
        case 'logout' :
            return {};
        default :
            return state
    }
}

export default preferencesReducer;
