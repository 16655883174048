import React, {useEffect, useRef, useState} from 'react';
import {Col, message, Row, Spin} from "antd";
import useApi from "../Hooks/Api";
import EstablishmentHeader from "../Components/PageComponents/Public/EstablishmentPage/EstablishmentHeader";
import EstablishmentMain from "../Components/PageComponents/Public/EstablishmentPage/EstablishmentMain";
import EstablishmentExtras from "../Components/PageComponents/Public/EstablishmentPage/EstablishmentExtras";


const EstablishmentPage = (props) => {
    const [establishment, setEstablishment] = useState({});
    const [reviews,setReviews] = useState([]);
    const reviewsPaginate = useRef({page: 1});
    const discountsPaginate = useRef({page: 1});
    const [loading, setLoading] = useState(true);
    const [reviewsLoading, setReviewsLoading] = useState(true);
    const [discountsLoading, setDiscountLoadings] = useState(true);
    const [discounts,setDiscounts] = useState([]);
    const api = useApi({customReturn: true, loadingControl: false});

    const getData = async (id) => {

        api.get(`crafty-club/company/${id}/full`, (res) => {
            setEstablishment(res);
            setLoading(false);
        }, (e) => {
            message.warning('Ops, não foi possível recuperar os dados do profissional, tente novamente mais tarde')
            setLoading(false);
        });
    };

    const getReviews = async () => {
        setReviewsLoading(true);
        const reviewsRes = await api.get(`crafty-club/company/${props.match.params.id}/reviews?page=${reviewsPaginate.current.page}&order=desc`);
        reviewsPaginate.current = {nextPage: reviewsRes.next_page, page: reviewsRes.page + 1};
        let aux = reviews.concat(reviewsRes.reviews);
        setReviews(aux);
        setReviewsLoading(false);
    };

    const getDiscounts = async () => {
        setDiscountLoadings(true);
        const discountsRes = await api.get(`crafty-club/company/${props.match.params.id}/discounts?page=${discountsPaginate.current.page}`);
        discountsPaginate.current = {nextPage: discountsRes.next_page, page: discountsRes.page + 1};
        let aux = discounts.concat(discountsRes.partners);
        setDiscounts(aux);
        setDiscountLoadings(false)
    }

    useEffect(() => {
        if(props.match.params.id) {
            getData(props.match.params.id).then(() => {
                getDiscounts().then(() => {
                    getReviews();
                    window.scrollToElement('body');
                });
            })
        }
    },[]);

    return (
        <div style={{minHeight: '500px'}}> {loading? <Spin style={{marginTop: '15%', marginLeft: '48%'}}/> :
            <div style={{backgroundColor: 'white'}}>
                <EstablishmentHeader occupations={establishment.profile?.group}  avatar={establishment.profile?.profile_picture?.full} userRating={establishment.profile?.ratings_avg} userRatingCount={establishment.profile?.ratings_count} name={establishment.profile?.name}/>

                <Row style={{display: 'flex', backgroundColor: 'white', justifyContent: 'center'}}>
                    <Col xs={23} sm={23} xl={19} xxl={16}>
                        <Row gutter={[32,32]}>
                            <Col xs={24} sm={14} md={16} xl={18} style={{padding: 20}}>
                                <EstablishmentMain coverPicture={establishment.profile?.cover_picture} discountsLoading={discountsLoading} reviewsLoading={reviewsLoading} loadMoreDiscounts={() => getDiscounts()} loadMoreReviews={() => getReviews()} discounts={discounts} discountsNextPage={discountsPaginate.current.next_page} reviewsNextPage={reviewsPaginate.current.next_page} reviews={reviews} address={establishment.address} bio={establishment.profile?.bio}/>
                            </Col>
                            <Col xs={24} sm={10} md={8} xl={6}>
                                <EstablishmentExtras address={establishment.address} occupations={establishment.profile?.group}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        }
        </div>
    );
};

EstablishmentPage.propTypes = {};

export default EstablishmentPage;
