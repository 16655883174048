import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Col, Icon, List, Row, Skeleton, Spin} from "antd";
import Loading from "../../../Generic/Loading";
import Link from "react-router-dom/es/Link";
import NotFound from "../../../Generic/NotFound";
import {Images} from "../../../../Helpers/Images";
import {useHistory} from "react-router-dom";

Spin.setDefaultIndicator(<Loading/>);

const MainListComponent = (props) => {
    const history = useHistory();

    return (
        <Row>
            <Col>
                {(props.servicesList.length > 0 || props.loading) &&<List
                    className='listAnt hireServicesList'
                    style={{overflow: 'auto', minHeight: '600px'}}
                    itemLayout="horizontal"
                    dataSource={props.servicesList}
                    loading={props.loading}
                    renderItem={item => (
                        <List.Item style={{padding: 20,  backgroundColor: 'white'}} actions={[<Link target={'_blank'} className={'hideMobile500'} to={'/prestador-de-servico/' + item.id + '/' + props.occupation_id}><Button type="primary" icon="user" size='default'> Visualizar </Button></Link>]}>
                            <Skeleton avatar title={false} loading={false} active>
                                <List.Item.Meta
                                    style={{alignItems: 'center'}}
                                    avatar={
                                        <Avatar style={{width: 64, height: 64}} size='default' src={item.profile_picture.thumbnail || Images.user_place_holder}/>
                                    }
                                    title={<div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span style={{fontWeight: "bold"}}><Link target={'_blank'} to={'/prestador-de-servico/' + item.id + '/' + props.occupation_id}> {item.name} </Link>  </span>
                                        <span style={{ color: item.ratings_avg === 0? 'lightgray' : '#ffc53d'}}>

                                            {item.price_avg !== 0 &&<span><strong style={{color: 'limegreen'}}> {item.price_avg <= 2? '$' :(item.price_avg <= 3)? '$$' :(item.price_avg <= 4)? '$$$': (item.price_avg <= 5)&& '$$$$'} </strong>  <strong style={{marginRight: 5, marginLeft: 5}}> / </strong>  </span> }
                                            <Icon type='star' theme="filled"/>  { item.ratings_avg === 0? 'Novo' : <span>{item.ratings_avg.toFixed(1)} ({item.ratings_count})</span>}
                                        </span>
                                        <div> <span style={{lineHeight: 1}}> {item.bio?.length > 300? item.bio?.slice(0, 200) + ' ... ' : item.bio} </span> </div>
                                    </div>}
                                    description={<div>
                                        <div>
                                            <span>
                                                <Icon className={'primary-sub-color'} type='environment'/> {window.getDistance(item.distance)}</span>
                                        </div>
                                        <div className={'showMobile500'} style={{marginTop: 5}}>
                                            <Button onClick={() => history.push('/prestador-de-servico/' + item.id + '/' + props.occupation_id)} type="primary" icon="user" size='default'> Visualizar </Button>
                                        </div>
                                    </div>}
                                />
                            </Skeleton>
                        </List.Item>
                    )}
                /> }
                {props.servicesList.length === 0 && !props.loading &&
                <NotFound md={-50} title={'Ainda não há prestadores de serviço para essa pesquisa'} subTitle={'Verifique novamente mais tarde se algum prestador já se cadastrou'}  img={Images.service_not_found}/>
                }
            </Col>
        </Row>
    );
};

MainListComponent.propTypes = {
    loading: PropTypes.any,
    servicesList: PropTypes.any,
    occupation_id: PropTypes.any
};

export default memo(MainListComponent, (prevProps, nextProps) => {
    if(prevProps.servicesList !== nextProps.servicesList) return false;
    if(prevProps.loading !== nextProps.loading) return false;
    if(prevProps.occupation_id !== nextProps.occupation_id) return false;
    return true
});
