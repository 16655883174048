const chatNotificationsReducer = (state = {iterator: 0, pushEnabled: false, saw: true}, action) => {
    switch (action.type) {
        case 'new_chat_notification' :
            return {...action.data, iterator: state.iterator + 1, pushEnabled: state.pushEnabled, saw: false};
        case 'logout' :
            return {};
        case 'enabled_push' :
            return {...action.data, iterator: state.iterator, pushEnabled: action.data, saw: state.saw};
        case 'saw_push' :
            return {...action.data, iterator: state.iterator, pushEnabled: action.data, saw: true};
        default :
            return state
    }
}

export default chatNotificationsReducer;
