export const ethnicityList = {'Atikum': ['Aldeia Cabeceira', 'Outra'],
    'Guarani Kaiowa': ['Aldeia Guyraroka','Aldeia Guassuty', 'Aldeia Amambai', 'Aldeia Jaguary', 'Aldeia Limăo Verde', 'Aldeia Campestre', 'Aldeia Cerro Marangatu', 'Aldeia Pirakuá', 'Aldeia Te’yi kuę', 'Aldeia Takuapery', 'Acampamento Kurussu/Amba', 'Aldeia Panambi', 'Aldeia Jaguapiru', 'Aldeia Bororo', 'Aldeia Panambizinho', 'Aldeia Cerrito', 'Acampamento Yvy Katu', 'Aldeia Porto Lindo', 'Aldeia Jarará', 'Aldeia Takuara', 'Guaimbé', 'Aldeia Rancho Jacaré', 'Aldeia Sucuri’y', 'Aldeia Cerro’y', 'Aldeia Arroyo Korá', 'Aldeia Paraguassu', 'Aldeia Pirajuí', 'Aldeia Potrero Guassu', 'Aldeia Sete Cerros', 'Acampamento Y’poy', 'Aldeia Lima Campo', 'Aldeia Kokue-i', 'Acampamento Sombrerito', 'Aldeia Jaguapiré', 'Aldeia Sassoró', 'Outra'],
    'Guarani Nhandeva': ['Aldeia Amambai', 'Aldeia Limăo Verde', 'Aldeia Te’yi kuę', 'Aldeia Bororo', 'Aldeia Panambizinho', 'Aldeia Cerrito', 'Aldeia Cerro’y', 'Acampamento Sombrerito', 'Outra'],
    'Guato': ['Aldeia Uberaba', 'Outra'],
    'Kadiweu': ['Aldeia Barro Preto', 'Aldeia Tomázia', 'Aldeia Alves de Barros', 'Aldeia Campina', 'Aldeia Córrego do Ouro', 'Aldeia Săo Joăo', 'Outra'],
    'Kinikinau': ['Aldeia Lalima', 'Aldeia Măe Terra', 'Aldeia Cabeceira', 'Outra'],
    'Nhandeva': ['Aldeia Panambi', 'Outra'],
    'Terena': ['Aldeia Colônia Nova', 'Aldeia Água Branca', 'Aldeia Ipegue', 'Aldeia Bananal', 'Aldeia Lagoinha', 'Aldeia Morrinho', 'Aldeia Imbirussu', 'Aldeia Limăo Verde', 'Aldeia Córrego Seco', 'Aldeia Burutizinho', 'Aldeia Aldeinha', 'Aldeia Campestre', 'Aldeia Água Azul', 'Aldeia Barreirinho', 'Aldeia Buriti', 'Nova Buriti', 'Aldeia Olho D’água', 'Aldeia Oliveira', 'Aldeia Recanto', 'Aldeia Panambi', 'Aldeia Bororo', 'Aldeia Panambizinho', 'Aldeia Argola', 'Aldeia Morrinho', 'Aldeia Cachoeirinha', 'Aldeia Lagoinha', 'Aldeia Babaçu', 'Aldeia Moreira', 'Aldeia Passarinho', 'Aldeia Lalima', 'Aldeia Măe Terra', 'Aldeia Brejăo', 'Aldeia Taboquinha', 'Aldeia Água Branca', 'Aldeia Cabeceira', 'Aldeia Bálsamo', 'Aldeia 10 de Maio', 'Aldeia Córrego do Meio', 'Aldeia Lagoinha', 'Aldeia Tereré', 'Outra'],
    'Outro': ['Aldeia Ofaié', 'Aldeia Marçal de Souza', 'Aldeia Tarsila do Amaral', 'Aldeia Água Bonita', 'Aldeia Darcy Ribeiro', 'Porto Cambira', 'Outra']
}
