import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Button, Col, Divider, Icon, message, Modal, Rate, Row} from "antd";
import useApi from "../Hooks/Api";
import Loading from "../Components/Generic/Loading";
import moment from "moment";
import RateTransactionForm from "../Components/PageComponents/Restrict/ShopListPage/RateTransactionForm";
import {Link} from "react-router-dom";
import {useHistory} from 'react-router-dom'

const ShopListPage = props => {
    const api = useApi();
    const [transactions, setTransactions] = useState([]);
    const [loading,setLoading] = useState(true);
    const paginate = useRef({page: 0});
    const viewTransaction = useRef();
    const [loadingTransaction, setLoadingTransaction] = useState();
    const indexLoading = useRef();
    const [showRateModal, setShowRateModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const history = useHistory();

    const getData = async () => {
        setLoading(true);
        try {
            const res = await api.get(`crafty-club/transactions?page=${paginate.current.page + 1}&order=desc`);
            paginate.current = {nextPage: res.next_page, page: res.page};
            let transactionsAux = transactions.concat(res.transactions);
            setTransactions(transactionsAux);
            setLoading(false);
        } catch {
            message.error('Ops, não foi possível recuperar seus endereços, tente novamente')
            setLoading(false);
        }
    }

    const getTransaction = async (id,index,type) => {
        indexLoading.current = index;
        setLoadingTransaction(true);
        try {
            viewTransaction.current = await api.get(`crafty-club/transaction/${id}`);
            if(type === 'rating') {
                setShowRateModal(true);
            } else {
                setShowDetailsModal(true);
            }
            setLoadingTransaction(false);
        } catch {
            message.error('Ops, não foi possível recuperar seus endereços, tente novamente')
            setLoadingTransaction(false);
        }
    }

    useEffect(() => {
        getData();
        window.scrollToElement('body');
    },[]);


    return (
        <Row gutter={[22,22]} className={'mainComponentPanel'}>
            <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
                <Row gutter={[24, 0]}>
                    <Col xs={0} sm={0} md={8} lg={5} className={'sideBarPanelCards'}>
                        <div className={'back-side-button'} onClick={() => history.push('/painel')}>
                            <Icon className={'craftyIcon'} type={'arrow-left'}/>
                            <span> Voltar </span>
                        </div>

                        <div className={'divCard primary-color'} onClick={() => history.push('/painel/cliente/cartoes')}>
                            <Icon className={'craftyIcon'} type={'credit-card'}/>
                            <span> Cartões </span>
                            <div className={'roundCardThing primary-color-bg'}/>
                        </div>
                        <div className={'divCardDefault selected-sub-primary'} onClick={() => history.push('/painel/cliente/compras')}>
                            <Icon className={'craftyIcon'} type={'shopping'}/>
                            <span> Minhas compras  </span>
                            <div className={'arrowCard'}/>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={19}>
                        <Row style={{fontSize: 20, color: '#52c41a', paddingLeft: 5, marginBottom: 15}}>
                            <Icon type={'shopping'}/>
                            <span > Suas Compras </span> <br/>
                            <span style={{fontSize: 14, color: 'gray'}}> Histórico de suas compras realizads em lojas parceiras </span>
                        </Row>
                        {loading?
                            <div style={{margin: "auto"}}> <Loading/> </div>
                            :
                            <Row gutter={[12,12]} style={{display: 'flex', flexWrap: 'wrap', padding: 10}}>
                                {transactions.map((item,index) =>
                                    <Col span={12} key={index} style={{position: 'relative'}}>
                                        <div
                                            className={'cardBodyTransaction'}
                                            style={{ marginTop: 16, position: 'relative' }}
                                        >
                                            <span style={{position: 'absolute', right: 7, color: '#9e9e9e', top: 3, fontSize: 13}}> {moment(item.created_at).format('DD/MM/YYYY')}</span>
                                            <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'space-between', height: '100%'}}>
                                                <div style={{display: 'flex'}}>
                                                    <Avatar style={{height: 50, width: 50, marginRight: 10}} src={item.company.profile_picture.thumbnail || '/img/craftyClub.png'}/>
                                                    <div>
                                                        <div> <span style={{fontSize: 18, fontWeight: 'bold', color: "#52c41a"}}> {item.company.name}</span></div>
                                                        <span style={{fontSize: 14, color: '#9e9e9e'}}> {item.company.group} </span>
                                                    </div>
                                                </div>
                                                <Divider style={{margin: 10}}/>
                                                {item.rating ?
                                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        <div style={{wordBreak: 'break-word'}}><strong> Sua
                                                            Avaliação </strong> - {item.review} <br/>
                                                            <Rate allowHalf disabled defaultValue={item.rating}/>
                                                        </div>

                                                        <Button
                                                            onClick={() => getTransaction(item.id, index)}
                                                            style={{marginTop: 'auto', fontSize: 17}}
                                                            loading={loadingTransaction && indexLoading.current === index}
                                                            disabled={loadingTransaction} shape={'round'}> Detalhes </Button>
                                                    </div>
                                                    :
                                                    <div style={{width: '100%'}}>
                                                        <Button icon={'star'} size={'large'} style={{width: '100%'}} onClick={() => getTransaction(item.id,index,'rating')} type={'primary'}> Avaliar Compra </Button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                )}
                                {paginate.current.nextPage&& <div style={{width: '100%', marginTop: 25, paddingBottom: 10}}>  <div className='loadMore2' style={{color: '#52c41a', borderColor: '#52c41a'}} onClick={()=> getData()}> <Icon type={'reload'}/> Carregar Mais </div> </div>}
                            </Row>
                        }
                    </Col>
                </Row>

                {(transactions.length === 0 && !loading) &&
                <div className={'empty-card empty-card-green'}>
                    <div style={{display: 'flex'}}>
                        <img src={'/img/man.svg'} height={60}/>
                        <div style={{marginLeft: '20px', color: '#545a5d'}}>
                                   <span> <span style={{fontWeight: 'bold'}}> Você não possui nenhuma compra realizada! </span> <br/> Faça compras nos <Link to={'/estabelecimentos'}> estabelecimentos parceiros </Link> e veja todas elas aqui!
                                </span>
                        </div>

                    </div>
                </div>}
            </Col>
            <Modal visible={showDetailsModal} footer={null} onCancel={() => setShowDetailsModal(false)}>
                <div
                    style={{ width: '100%', padding: 0}}
                >
                    <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'space-between', height: '100%'}}>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Avatar style={{height: 65, width: 65, marginRight: 10}} src={viewTransaction.current?.company?.profile_picture.thumbnail || '/img/craftyClub.png'}/>
                                <div> <Icon type={'star'} theme={"filled"} style={{color: 'gold'}}/> {viewTransaction.current?.company?.ratings_avg} ({viewTransaction.current?.company?.ratings_count}) </div>
                            </div>
                            <div>
                                <div> <span style={{fontSize: 20, fontWeight: 'bold', color: "#52c41a"}}> {viewTransaction.current?.company?.name}</span></div>
                                <span style={{fontSize: 16, color: '#9e9e9e'}}> {viewTransaction.current?.company?.group} </span> <br/>
                                <span style={{ fontSize: 16, color: '#9e9e9e'}}> Compra em - {moment(viewTransaction.current?.created_at).format('DD/MM/YYYY')}</span>
                            </div>
                        </div>
                        <Divider style={{margin: 10}}/>
                        {viewTransaction.current?.rating ?
                            <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}>
                                <div style={{
                                    wordBreak: 'break-word',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                    <strong style={{fontSize: 17}}> Sua Avaliação </strong>
                                    <div><Rate allowHalf disabled
                                               defaultValue={viewTransaction.current?.rating}/> (<strong>{viewTransaction.current?.rating}</strong>)
                                    </div>
                                </div>
                                <div> {viewTransaction.current?.review} </div>
                            </div>
                            :
                            <div style={{width: '100%'}}>
                                <Button style={{width: '100%'}} onClick={() => setShowRateModal(true)} size={'large'} icon={'star'} type={'primary'}> Avaliar Compra </Button>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
            {showRateModal &&<RateTransactionForm getData={() => getData()} viewTransaction={viewTransaction.current} showRateModal={showRateModal} setShowRateModal={(item) => setShowRateModal(item)}/>}
        </Row>
    );
};

ShopListPage.propTypes = {};

export default ShopListPage;
