import React, {useState} from 'react';
import {Badge, Button, Col, Divider, Drawer, Icon, Layout, Menu, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../Hooks/Auth";
import {useHistory} from "react-router-dom";
import useVerifyServices from "../../../Hooks/VerifyServices";
import {FaBook, FaBriefcase, FaCalendarCheck, FaHome, FaSearch, FaSignature} from "react-icons/fa";
import {AiFillClockCircle, AiFillWechat, AiTwotoneShop, AiTwotoneStar} from "react-icons/ai";
import {RiCloseFill, RiFileUserFill} from "react-icons/ri";
import RegisterPageModal from "../../../Pages/RegisterPageModal";
import {MdDashboard} from "react-icons/md";
import {StorageVariables} from "../../../Helpers/StoragesVariables";
import useLocalStorage from "../../../Hooks/Storage";
import {Images} from "../../../Helpers/Images";
import {BiCheckDouble, BiRotateRight} from "react-icons/bi";
import {GrClose} from "react-icons/gr";
import {FiCornerDownLeft, FiCornerDownRight} from "react-icons/fi";


const { Sider } = Layout;

const Sidebar = props => {
    const history = useHistory();
    const profile = useSelector(state => state).authReducer;
    const hasSineProfile = useLocalStorage(StorageVariables.hasSineProfile);
    const {isLogged, getUser, logoutWithoutApi} = useAuth();
    const notificationsCount = useSelector(state => state).notificationsReducer;
    const dispatch = useDispatch();
    const {hasSineService, hasCraftyService, hasClubService,hasOnlySine,hasOnlyCrafty} = useVerifyServices();
    const [registerModal, setRegisterModal] = useState(false);

    const goTo = (path) => {
        history.push(path);
        props.setSidebarCollapsed(!props.collapsed)
    };

    return (
        <Drawer
            placement="left"
            onClose={() => props.setSidebarCollapsed(!props.collapsed)}
            visible={props.collapsed}
            closable={false}
            maskClosable={true}
            bodyStyle={{padding: 0}}
            headerStyle={{padding: 0}}
        >
                <aside className="sidebar">
                    {isLogged()&&<header>
                        <Row style={{textAlign: 'center', marginTop: 10}}>
                            <Col span={24}>
                                <Badge offset={[-8,50]} style={{height: 9, width: 9}} status="success">
                                <img className='user-avatar'
                                     style={{backgroundSize: 'cover', height: 60, width: 60, borderRadius: '50%', backgroundImage: profile?.avatar? `url(${profile?.avatar})` : `url(${Images.user_place_holder})`}}/>
                                </Badge>
                            </Col>

                            <Col span={24}>
                                 <div>
                                    <h4 className='ant-list-item-meta-title' style={{lineHeight: '25px', fontSize: 18, fontWeight: 'bold'}}>   {getUser().name} </h4>
                                    <h4 className='ant-list-item-meta-title' style={{lineHeight: '25px'}}>  {getUser().email}  </h4>
                                </div>
                            </Col>

                        </Row>

                        <Row className='topMenuIcons'>
                            <Col span={isLogged()? 12 : 24} onClick={() => history.push('/')}>
                                <Icon type={'home'}/>
                            </Col>
                            {isLogged() &&<Col span={12} onClick={() => {logoutWithoutApi(); dispatch({type: 'logout'});}}>
                                <Icon type={'poweroff'}/>
                            </Col>}

                            <Divider style={{marginTop: 10, marginBottom: 5}}/>
                        </Row>
                    </header>}

                    <nav className="sidebar-nav">

                        <ul>

                            {isLogged()&& <>
                                <li>
                                    <a onClick={() => goTo('/painel')}> <MdDashboard className={'exportedIconsLeft primary-color'} /> <span className="">Painel</span> </a>
                                </li>

                                {hasCraftyService() &&<li>
                                    <a onClick={() => goTo('/quero-contratar')} href="#"><span className=""><FaSearch className={'exportedIconsLeft primary-sub-color'}/> Buscar prestadores</span></a>
                                </li>}
                                {hasClubService()&&
                                <li>
                                    <a onClick={() => goTo('/estabelecimentos')}>
                                        <span className=""><AiTwotoneShop className={'exportedIconsLeft primary-sub-color'}/> Buscar Estabelecimentos</span>
                                    </a>
                                </li>}

                                {hasSineService() && <li>
                                    <a onClick={() => goTo('/painel/cliente/vagas')}><RiFileUserFill className={'exportedIconsLeft primary-sub-color'}/> <span>Gerenciar currículos</span></a>
                                </li>}


                                {hasCraftyService() &&<li>
                                    <a href="#" onClick={() => goTo('/painel/cliente/contatos')}><span className=""><AiFillClockCircle className={'exportedIconsLeft primary-sub-color'}/> Contatos Solicitados</span></a>
                                </li>}

                                <li>
                                    <a onClick={() => goTo('/notificacoes')}><Icon type='bell'/> <span className="">Notificações</span>   <Badge count={notificationsCount}/></a>
                                </li>


                                <li>
                                    <a href="#" ><Icon type='user'/> <span className="">Perfil</span></a>
                                    <ul className="nav-flyout">
                                        <li>
                                            <a onClick={() => goTo('/perfil')}><Icon type='user'/>Editar perfil</a>
                                        </li>
                                        <li>
                                            <a onClick={() => goTo('/enderecos')}><Icon type='home'/>Endereços</a>
                                        </li>
                                    </ul>
                                </li>

                                {hasClubService() && <li>
                                    <a><Icon type='star'/> <span>Cupons</span></a>
                                </li>}

                                {hasClubService() && <li>
                                    <a><Icon type='star'/> <span>Compras</span></a>
                                </li>}


                                {/*<li>
                                    <a onClick={() => {history.push('/ajuda'); props.setSidebarCollapsed(!props.collapsed)}}><Icon type='question-circle'/> <span className="">Perguntas Frequentes</span>   <Badge  count={!chat.saw? 1 : 0}/></a>

                                </li>*/}

                            </>}

                            {!isLogged() &&<li style={{padding: 10, marginTop: 20}}>
                                <Button onClick={() => setRegisterModal('login')} style={{ width: '100%'}}> Entre </Button>
                                <Divider> ou </Divider>
                                <Button  onClick={() => setRegisterModal('regType')} className={'ant-btn-sub-primary'} style={{ width: '100%'}}> Cadastre-se</Button>
                            </li>}

                            {!isLogged() &&
                                <>
                                    <li>
                                        <a onClick={() => goTo('/')}>
                                            <span className=""><FaHome className={'exportedIconsLeft primary-sub-color'}/> Home</span>
                                        </a>
                                    </li>

                                    {hasCraftyService() &&<li>
                                        <a onClick={() => goTo('/quero-contratar')} >
                                            <span className=""><Icon className={'primary-sub-color'} type='search'/> Buscar Prestadores</span>
                                        </a>
                                    </li>}
                                    {hasClubService()&&
                                    <li>
                                        <a onClick={() => goTo('/estabelecimentos')}>
                                            <span className=""><AiTwotoneShop className={'exportedIconsLeft primary-sub-color'}/> Buscar Estabelecimentos</span>
                                        </a>
                                    </li>}


                                    <li>
                                        <a onClick={() => goTo('/trabalhar')}>
                                            <span className=""><FaBriefcase className={'exportedIconsLeft primary-sub-color'}/> Quero Trabalhar</span>
                                        </a>
                                    </li>

                                    {/*<li>
                                        <a onClick={() => {history.push('/ajuda'); props.setSidebarCollapsed(!props.collapsed)}}><Icon type='question-circle'/> <span className="">Perguntas Frequentes</span>   <Badge  count={!chat.saw? 1 : 0}/></a>

                                    </li>*/}
                                </>
                            }
                        </ul>
                    </nav>

                </aside>

                <RegisterPageModal visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />
        </Drawer>

    );
};

Sidebar.propTypes = {};

export default Sidebar;
